import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Form } from "react-bootstrap";
import Select from "react-select";
import {
  blNumbList,
  blValidation,
  generateDeliveryReferenceNo,
} from "../../blRequestInstruction/components/actions/Instruction";
import {
  // faArrowAltCircleLeft,
  faRedo,
  faSave,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import {
  faArrowAltCircleLeft,
  faCheckCircle,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toggleDocumentHistoryColumnShort } from "../../blDetails/slices/documentHistorySlice";
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useState, useEffect } from "react";
import { AppDispatch, RootState } from "../../../store";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { AESDecrypt } from "../../../../encrypt-util";
import DataTable from "../../../common/components/DataTable";
import { IDataTableColumn } from "../../../common/types/dataTable";
import UploadIndicator from "../../../../components/common/UploadIndicator";
import {
  updateSelectedAttachmentFilePath,
  updateSelectedAttachmentName,
  updateSelectedDocId,
  updateSelectedFileServerPath,
} from "../../blRequestInstruction/components/instructionSlice/DemurrageSlice";
import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosSetup";
import {
  fetchVesselSuggestions,
  generateInvReferenceNo,
  getDoRequest,
  getMstCodeListVal,
  submitDeliveryRequest,
  submitInvoiceRequest,
} from "../../blRequestInstruction/components/actions/Instruction";
import { useBackNavigation } from "../../../../hooks/BackNavigation";
import { tpoId } from "../../blRequestInstruction/components/instructionSlice/AgentNominationSlice";
import { customDropdownStyles } from "../../../common/customStyles";
import axios from "axios";
import { useAsyncDebounce } from "react-table";
import { count } from "console";
import { updateDashbaordRefresh } from "../../../common/components/DataTable/slices/DataTable";
import useDebounce from "../../../../hooks/useDebounce";
import React from "react";
import { useLoading } from "../../../../middleware/loadingMiddleware";

const DeliveryOrderInstruction = () => {
  const { t } = useTranslation();
  const { userID } = useParams();

  const doId = AESDecrypt(userID);
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const onBack = useBackNavigation();
  const navigate = useNavigate();
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );

  const blNumbListVal: any = useSelector(
    (state: RootState) => state.permitrequest.blNumbList
  );

  const serverFilePath = useSelector(
    (state: RootState) => state.demurrage.selectedFileServerPath
  );

  const selectedAttachmentFilePath = useSelector(
    (state: RootState) => state.demurrage.selectedAttachmentFilePath
  );
  const selectedDocId = useSelector(
    (state: RootState) => state.demurrage.selectedDocId
  );
  const loadingVesselList = useLoading([
    "PermitRequest/fetchVesselSuggestions",
  ]);
  const selectedLinerId = useSelector(
    (state: RootState) => state.userProfile.selectedLinerId
  );
  const VesselData: any = useSelector(
    (state: RootState) => state.permitrequest.data
  );
  const selectedAttachmentName = useSelector(
    (state: RootState) => state.demurrage.selectedAttachmentName
  );
  const tpoId = useSelector((state: RootState) => state.agentDelegation.tpoId);
  const doRefNumber = useSelector(
    (state: RootState) => state.creditBalanceBlPayment.generateDoReferenceNo
  );
  const mstcodeID = useSelector(
    (state: RootState) => state.creditBalanceBlPayment.mstcodeList
  );

  const docList = useSelector((state: RootState) => state.demurrage.docList);
  const docExport = useSelector(
    (state: RootState) => state.demurrage.docExport
  );

  const docAttachment = useSelector(
    (state: RootState) => state.demurrage.attData
  );

  const docAttachmentExport = useSelector(
    (state: RootState) => state.demurrage.attDataExport
  );

  const docId = AESDecrypt(doId);

  const [attachedFileSize, setAttachedFileSize] = useState(1000);

  const [trafficVal, setTrafficVal] = useState("");
  const [VesselList, setVesselList] = useState<any>();
  const [view, setView] = useState<boolean>(false);
  const [loadingFileState, setLoadingFileState] = useState<{
    [key: string]: number;
  }>({});
  const [refNo, setRefNo] = useState("");
  const [vessel, setVessel] = useState("");
  const [submitaction, setSubmitAction] = useState(false);
  const [line, setLine] = useState("Import");
  const [vesselLine, setVesselLine] = useState("");

  const [blNo, setBlNo] = useState("");
  const [doNumber, setDoNumber] = useState("");
  const [effectiveDate, setEffectiveDate] = useState(new Date());
  const [effectiveDateView, setEffectiveDateView] = useState();
  const [expiryDate, setExpiryDate] = useState(new Date());
  const [expiryDateView, setExpiryDateView] = useState<any>();
  useEffect(() => {
    dispatch(generateDeliveryReferenceNo("TZ")).then((response: any) => {
      setRefNo(response.data);
    });
    dispatch(getMstCodeListVal());
  }, []);
  // var date1 = new Date(effectiveDate);
  // var date2 = new Date(expiryDate);
  const validateConsigneeTin = (value: any) => {
    if (!value || value == "") return true;
    const ele = value.split("-");
    if (
      ele.length !== 3 ||
      ele[0].length !== 3 ||
      ele[1].length !== 3 ||
      ele[2].length !== 3
    ) {
      return false;
    }
    return true;
  };
  const validationSchema = Yup.object().shape({
    vessel: Yup.object().required("vessel is required").nullable(),
    line: Yup.string().required("Shipping Line is required"),
    blno: Yup.string()
      .required("BL Number is required")
      .test("SO/BL number is invalid", (val) => {
        if (blNumbListVal.find((item: any) => item.blNumber == val))
          return true;
        return false;
      }),
    CFATin: Yup.string()
      .required("CFA TIN is required")
      .matches(/^[0-9-]+$/, "CFA TIN must be a number"),
    // .test('validateCFATin', 'Invalid CFA Tin number', async (value) => {
    //   try {
    //     const response = await axios.post(
    //       `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/v1/customer/findCustomerByTaxId?countryCode=TZ&taxId=${value}`
    //     );
    //     return response.data && response.data.partnerNm;
    //   } catch (error) {
    //     console.error(error);
    //     return false;
    //   }
    // })
    CFAName: Yup.string().required("CFA Name is required"),
    totalContainers: Yup.string().required("Total containers is required"),
    doNumber: Yup.string().required("DO  Number is required"),
    consigneeTin: Yup.string()
      .matches(/^([0-9-]*)$/, "Shipper/Consignee TIN must be a number")
      .test(
        "is-valid-consigneeTin",
        "Invalid Consignee TIN Number",
        validateConsigneeTin
      ),
    consigneeName: Yup.string(),
    effectiveDate: Yup.date()
      .typeError("Effective Date is required")
      .required("Effective Date is required")
      .max(
        Yup.ref("expiryDate"),
        "Effective Date must be earlier than Expiry Date"
      )
      .nullable(false)
      .default(undefined),
    expiryDate: Yup.date()
      .typeError("Expiry Date is required")
      .required("Expiry Date is required")
      .min(
        Yup.ref("effectiveDate"),
        "Expiry Date must be later than Effective Date"
      )
      .nullable(false)
      .default(undefined),
  });

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    control,
    clearErrors,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  useEffect(() => {
    // Clear the form state when the component mounts
    setVessel("");
    setVesselLine("");
    setValue("totalContainers", "");
    setValue("blno", "");
    setVesselList([]);

    clearErrors("blno");
  }, []); // Empty dependency array ensures this effect runs only on component mount
  const handleEffectiveDateChange = (e: any) => {
    const dateValue = e.target.value;
    if (dateValue) {
      setEffectiveDate(dateValue);
      setValue("effectiveDate", dateValue); //,{ shouldValidate: true });
      trigger("effectiveDate");
      clearErrors("expiryDate");
    }

    //trigger("effectiveDate");
    return undefined;
  };
  const handleExpiryDateChange = async (e: any) => {
    const dateValue = e.target.value;
    if (dateValue) {
      setExpiryDate(dateValue);
      setValue("expiryDate", dateValue); //, { shouldValidate: true });
      trigger("expiryDate");
      clearErrors("effectiveDate");
    }
    //await trigger("effectiveDate");
  };
  const handleFileChange = (e: any, fileDocId: any, filePaths: any) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file.size > 10 * 1024 * 1024) {
        toast.error(
          "File size is greater than 10MB. Please upload file below 10MB"
          ,
          {
            toastId: 'cc0a8d6'
          });
        e.target.value = null;
        return;
      }
      let fileExt = file.name.slice(-4);

      if (fileExt.toLowerCase() != ".pdf") {
        toast.error("Please upload Only PDF File", {
          toastId: '1241dd7'
        });
        e.target.value = null;
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      try {
        // delete any existing file
        //if(filePaths[fileDocId]!==undefined){
        //  axiosInstance.post('deleteURL', filePaths[fileDocId])}

        // add file to bucket
        setLoadingFileState((state: any) => {
          return { ...state, [fileDocId]: 0 };
        });
        axiosInstance
          .post("/api/invoice-management/v1/view/file", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress(progressEvent) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / (progressEvent.total ?? 1)
              );
              setLoadingFileState((state: any) => {
                return { ...state, [fileDocId]: percentCompleted };
              });
            },
          })
          .then((response) => {
            setLoadingFileState((state: any) => {
              return { ...state, [fileDocId]: 100 };
            });
            dispatch(
              updateSelectedFileServerPath({
                ...filePaths,
                [fileDocId]: [response.data.path, response.data.tempFileName],
              })
            );
          })
          .catch(() => {
            setLoadingFileState((state: any) => {
              return { ...state, [fileDocId]: -1 };
            });
          });

        // add server path to redux
      } catch (error) {
        console.error("File Upload Error: ", error);
        console.error("File Upload Error: ", error);
      }

      dispatch(
        updateSelectedAttachmentName({
          ...selectedAttachmentName,
          [fileDocId]: file,
        })
      );
      dispatch(
        updateSelectedAttachmentFilePath({
          ...selectedAttachmentFilePath,
          [fileDocId]: null,
        })
      );

      dispatch(
        updateSelectedDocId({
          ...selectedDocId,
          [fileDocId]: file[0],
        })
      );
    }
  };
  // const [currentDate, setCurrentDate] = useState('');
  // useEffect(() => {
  //   const today = new Date().toISOString().split('T')[0];
  //   setCurrentDate(today);
  // }, []);
  const TableCellTruckerId = (rowData: any) => {
    const { blContainerId, containerNo } = rowData.row.original;

    if (true) {
      return (
        <input
          type="text"
          className="fs--1 form-control"
          // disabled={true}
          title={containerNo}
          // defaultValue={selectedtruckerId?.[containerNo] || ''}
          onBlur={(e) => {
            e.preventDefault();

            // dispatch(
            //   updateSelectedtruckerId({
            //     ...selectedtruckerId,
            //     [containerNo]: e.target.value,
            //   })
            // );
          }}
        />
      );
    } else {
      return (
        <span>
          {/* {
            demurrageViewForm?.list?.find?.(
              (f: any) => f?.cntNo === containerNo
            )?.truckerId
          } */}
        </span>
      );
    }
  };
  const formatTIN = (value: string) => {
    const cleaned = value.replace(/[^\w]/g, "");
    const match = cleaned.match(/^(\w{0,3})(\w{0,3})(\w{0,3})$/);

    if (match) {
      return [match[1], match[2], match[3]].filter((part) => part).join("-");
    }

    return value;
  };

  const handleTINChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const formattedValue = formatTIN(value);
    setValue("CFATin", formattedValue, { shouldValidate: true });
  };
  const handleTinName = useAsyncDebounce(async () => {
    if (!getValues("CFATin") || getValues("CFATin").trim() === "") {
      setValue("CFAName", "");
      setSubmitAction(true);
      return;
    }
    try {
      const response: any = await axios.post(
        `${
          process.env.REACT_APP_API_GATEWAY_HOST
        }/api/user-management/v1/customer/findCustomerByTaxId?countryCode=TZ&taxId=${getValues(
          "CFATin"
        )}`
      );
      if (response.data) {
        setValue("CFAName", response.data.partnerNm);
        trigger("CFAName");
        setSubmitAction(false);
        return;
      } else {
        setValue("CFAName", "");
        // toast.error('invalid CFA Tin number');
        setSubmitAction(true);
        setError("CFATin", { message: "Invalid CFA Tin number" });
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }, 100);
  const handleTINChangeConsignee = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;

    const formattedValue = formatTIN(value);

    setValue("consigneeTin", formattedValue);

    //  setConsigneeTin(formattedValue);
    setValue("consigneeTin", formattedValue, { shouldValidate: true });
  };
  const ConsigneeTinVal = () => {
    var ele = getValues("consigneeTin").toString().split("-");
    if (
      ele.length !== 3 ||
      ele[0].length !== 3 ||
      ele[1].length !== 3 ||
      ele[2].length !== 3
    ) {
      // toast.error('Invalid Consignee Tin Number');
      setSubmitAction(true);
      return;
    }
  };
  useEffect(() => {
    if (view) {
      clearErrors();
    }
  }, [view]);
  useEffect(() => {
    if (VesselData) {
      if (Array.isArray(VesselData)) {
        const formattedOptions = VesselData.map((vessel: any) => ({
          value: `${vessel.visitKey}`,
          label: `${vessel.vesselName} - ${vessel.visitKey}${
            vessel.ata ? " - " + new Date(vessel.ata).toLocaleString() : ""
          }`,
          visitKey: vessel.visitKey,
        }));
        setVesselList(formattedOptions);
      }
    }
  }, [VesselData]);
  const customFilterOption = (option: any, inputValue: any) => {
    // if (inputValue.length < 3) {
    //   return false;
    // }
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  };
  const handleInputChange = useDebounce((inputValue: string) => {
    if (inputValue.length >= 3) {
      /*  dispatch(fetchVesselSuggestions(inputValue.toUpperCase())); */
      dispatch(
        fetchVesselSuggestions({
          vesselKey: inputValue.toUpperCase(),
          requestType: 0,
          permitId: 0,
        })
      );
    }
    if (inputValue.length < 3) {
      setVesselList([]);
    }
  }, 500);

  useEffect(() => {
    if (blNumbListVal?.length > 0 && getValues("blno")) {
      const selectedBl = blNumbListVal.find(
        (item: any) => item.blNumber === getValues("blno")
      );

      if (selectedBl) {
        setVesselLine(selectedBl.lineOp);
        setValue("line", selectedBl.lineOp);
        trigger("line");
      } else {
        setVesselLine("");
        setValue("line", "");
      }
    }
  }, [getValues("blno")]);

  useEffect(() => {
    //   if (requesttp && invoice_request_id) {
    if (AESDecrypt(location.pathname.split("/")[3]) == AESDecrypt(userID)) {
      dispatch(getDoRequest(userID)).then((response: any) => {
        setTimeout(() => {
          setRefNo(response.payload.data.doRefNo);
        }, 100);
        if (
          response.payload.data.vessel &&
          response.payload.data.vessel != vessel
        ) {
          dispatch(
            blNumbList({
              visit: response.payload.data.vessel,
              permit: "Import",
            })
          );
        }
        setVessel(response.payload.data.vessel);
        setLine(response.payload.data.shippingLine);
        setBlNo(response.payload.data.blNo);
        setValue("blno", response.payload.data.blNo);
        setVesselLine(response.payload.data.shippingLine);
        setValue("line", response.payload.data.shippingLine);
        setValue("CFATin", response.payload.data.cfaTin);
        setValue("totalContainers", response.payload.data.noOfContainer);
        setValue("CFAName", response.payload.data.cfaName);
        setDoNumber(response.payload.data.doNo);
        setValue("consigneeTin", response.payload.data.consigneeTin);
        setValue("consigneeName", response.payload.data.consigneeName);
        setEffectiveDateView(response.payload.data.startDate);
        setExpiryDateView(response.payload.data.endDate);
      });
      setView(true);
    } /* else if (location.pathname.includes('instruction')) {
      dispatch(getDoRequest(AESDecrypt(location.pathname.split('/')[3]))).then(
        (response: any) => {
          console.log('myResponse', response.payload.data.requestedOn);

          setRefNo(response.payload.data.refNo);
          setVessel(response.payload.data.vessel);
          setLine(response.payload.data.shippingLine);
          setBlNo(response.payload.data.blNo);
          setCFATin(response.payload.data.cfaTin);
          setTotalContainers(response.payload.data.noOfContainer);
          setCFAName(response.payload.data.cfaName);
          setDoNumber(response.payload.data.doNo);
          setConsigneeTin(response.payload.data.consigneeTin);
          setConsigneeName(response.payload.data.consigneeName);
          setEffectiveDateView(response.payload.data.requestedOn);
          setExpiryDateView(response.payload.data.expiryDate);
        }
      );
      setView(true);
    } */
  }, [userID, location.pathname]);

  const resetForm = () => {
    setValue("referenceNumber", "");
    clearErrors("blno");
    clearErrors("CFATin");
    clearErrors("effectiveDate");
    clearErrors("expiryDate");
    setValue("vessel", "");
    setValue("line", "");
    setValue("blno", "");
    setLine("");
    setBlNo("");
    setDoNumber("");
    setValue("effectiveDate", "");
    setValue("expiryDate", "");
    setValue("CFATin", "");
    setValue("CFAName", "");
    setValue("totalContainers", "");
    setValue("doNumber", "");
    setValue("consigneeTin", "");
    setValue("consigneeName", "");
    //  setValue('totalContainers', '');
    // setValue('CFAName', '');
    //  setValue('consigneeTin', '');
    // setValue('doNumber', '');
    //  setValue('effectiveDate', '');
    // setValue('consigneeName', '');
    // setValue('expiryDate', '');
    setTrafficVal("");
    dispatch(updateSelectedAttachmentName({}));
    setLoadingFileState({});
    dispatch(updateSelectedFileServerPath({}));
  };

  const validateBl = (val: any) => {
    var containerList: any = [];
    /*     if (val.length > 0) {
      dispatch(blValidation({ blnumber: val, shipmentType: line })).then(
        (response: any) => {
          debugger;
          if (response.payload.data.length > 0) {
            const transformedData = response.payload.data.map((item: any) => ({
              containerNo: item.containerNumber,
            }));
            setTotalContainers(response.payload.data.length);

            clearErrors('blno');
          } else {
            setTotalContainers('');
            setError('blno', {
              type: 'manual',
              message: 'SO/BL number is invalid',
            });
          }
        }
      );
    } */
    if (val.length > 0) {
      if (blNumbListVal.find((item: any) => item.blNumber == val)) {
        setBlNo(val);
        clearErrors("blno");
      } else {
        setError("blno", {
          type: "manual",
          message: "SO/BL number is invalid",
        });
      }
      dispatch(blValidation({ blnumber: val, shipmentType: line })).then(
        (response: any) => {
          const transformedData = response.payload.data.map((item: any) => ({
            containerNo: item.containerNumber,
          }));
          setValue("totalContainers", transformedData.length);
          trigger("totalContainers");
        }
      );
      /*  dispatch(blValidation({ blnumber: val, shipmentType: permitParam })).then(
        (response: any) => {
          const transformedData = response.payload.data.map((item: any) => ({
            containerNo: item.containerNumber,
          }));
         
          setTotalContainers(transformedData.length);
         
          console.log(
            'containernumberss',
            response.payload.data.map((item: any) => item.containerNumber)
          );
        }
      ); */
    }
  };

  const uploadDoument = (rowData: any) => {
    const { docId, dndInstructionAttId, fileName } = rowData.row.original;

    const charLimit = (name: string, limit: number) =>
      name && name?.length >= limit ? name?.substring(0, limit) + "..." : name;
    if (true) {
      return (
        <div className="d-flex justify-content-start align-items-center">
          <label
            className="btn btn-sm btn-falcon-primary m-0 me-2"
            htmlFor={`${docId}-input`}
          >
            <FontAwesomeIcon icon={faUpload} />
          </label>
          <input
            id={`${docId}-input`}
            title="Input"
            type="file"
            className="fs--2 form-control ms-1"
            // disabled={disableFields}
            accept=".pdf"
            onClick={() => {
              // updateSelectedFileServerPath({
              //   ...serverFilePath,
              //   [docId]: [null, null],
              // });
            }}
            onChange={(e) => {
              handleFileChange(e, docId, serverFilePath);
            }}
            hidden
          />
          {selectedAttachmentFilePath?.[docId] ? (
            <span
              title={fileName}
              className="text-primary cursor-pointer"
              onClick={() => {
                window.open(
                  `${
                    process.env.REACT_APP_CLIENT_APP_HOST
                  }/api/invoice-management/v1/common/openDocument?id=${dndInstructionAttId}&moduleTp=DND&linerId=${
                    profileDetails.partnerType === "liner"
                      ? profileDetails?.partnerId
                      : selectedLinerId
                  }`,
                  "_blank"
                );
              }}
            >
              {charLimit(fileName, 45)}
            </span>
          ) : (
            <>
              <span title={selectedAttachmentName?.[docId]?.name}>
                {charLimit(selectedAttachmentName?.[docId]?.name, 45)}
              </span>
              {selectedAttachmentName?.[docId]?.name && (
                <UploadIndicator
                  style={{ marginLeft: "20px" }}
                  progress={loadingFileState[docId]}
                  fail={loadingFileState[docId] === -1}
                />
              )}
            </>
          )}
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-start align-items-center">
          {/*  <label
            className='btn btn-sm btn-dark m-0 me-2'
            htmlFor={`${docId}-input`}>
            Choose
          </label>
          <input
            id={`${docId}-input`}
            title='Input'
            type='file'
            className='fs--2 form-control ms-1'
            disabled={disableFields}
            onChange={(e) => {
              handleFileChange(e, docId);
            }}
            hidden
          /> */}

          <span
            className="text-primary cursor-pointer"
            onClick={() => {
              window.open(
                `${
                  process.env.REACT_APP_CLIENT_APP_HOST
                }/api/invoice-management/v1/common/openDocument?id=${dndInstructionAttId}&moduleTp=DND&linerId=${
                  profileDetails.partnerType === "liner"
                    ? profileDetails?.partnerId
                    : selectedLinerId
                }`,
                "_blank"
              );
            }}
          >
            {charLimit(fileName, 50)}
          </span>
        </div>
      );
    }
  };
  const handleDoNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const allow = /^[a-zA-Z0-9]*$/;
    const { value } = event.target;
    if (allow.test(value)) {
      setDoNumber(value);
      setValue("doNumber", value);
    }
  };
  const docAttachmentColumns: IDataTableColumn[] = [
    {
      accessor: "dndInstructionAttId",
      Header: t("resource_1:dndInstructionAttId"),
    },
    {
      accessor: "docId",
      Header: t("resource_1:document"),
      Cell: (rowData: any) => {
        const { docId } = rowData.row.original;
        if (trafficVal == "Export") {
          return (
            <>
              {docExport.find((v: any) => v?.id === docId)?.m === true ? (
                <span className="mandatory">*</span>
              ) : (
                ""
              )}
              {docExport.find((v: any) => v?.id === docId)?.name || ""}
            </>
          );
        } else {
          return (
            <>
              {docList?.find((v: any) => v?.id === docId)?.m === true ? (
                <span className="mandatory">*</span>
              ) : (
                ""
              )}
              {docList?.find((v: any) => v?.id === docId)?.name || ""}
            </>
          );
        }
      },
      width: 200,
      maxWidth: 150,
    },
    {
      accessor: "byteArray",
      Header: t("resource_1:upload"),
      Cell: uploadDoument,
      width: 450,
      maxWidth: 450,
    },
    {
      accessor: "fileName",
      Header: t("resource_1:fileName"),
    },
  ];
  const dateValidation = () => {
    let date1 = new Date(effectiveDate);
    let date2 = new Date(expiryDate);

    if (date2 < date1) {
      toast.error("Effective Date should be before Expiry Date", { toastId: '3c265fe' });
      return true;
    } else {
      return false;
    }
  };
  const handleTINChangeAndName = (event: any) => {
    handleTINChange(event);
  };
  const onSubmit = handleSubmit(async (data: any) => {
    /*    const formattedData = {
      doNo: 'DO5237365',
      blNo: 'HLCU826879632',
      shippingLine: 'HAPAG LOYDD',
      doRefNo: 'DOUSXXXX1',
      tpoId: 24205,
      vessel: 'S555 vessel 1',
      noOfContainer: 2,
      cfaTin: 'gfyf',
      cfaName: 'jbj',
      consigneeTin: 'jhvgh',
      consigneeName: 'hvhg',
      startDate: '2024-02-10',
      endDate: '2024-07-13',
    }; */

    const formattedData = {
      doNo: doNumber,
      blNo: blNo,
      shippingLine: vesselLine,
      shipmentType: line,
      doRefNo: doRefNumber,
      tpoId: tpoId,
      vessel: vessel,
      noOfContainer: getValues("totalContainers"),
      cfaTin: getValues("CFATin"),
      cfaName: getValues("CFAName"),
      consigneeTin: getValues("consigneeTin"),
      consigneeName: getValues("consigneeName"),
      startDate: effectiveDate,
      endDate: expiryDate,
    };
    let date1 = new Date(effectiveDate);
    let date2 = new Date(expiryDate);

    try {
      dispatch(submitDeliveryRequest(formattedData)).then((response: any) => {
        if (response?.payload?.data?.responseCode === "SUCCESS") {
          setTimeout(() => {
            onBack();
            setTimeout(() => {
              dispatch(updateDashbaordRefresh(true));
            }, 2000);
          }, 500);
          _onCloseForm();
        } else {
          // toast.error(response?.data?.message, {
          //   theme: 'colored',
          // });
          // resetForm();
        }
      });
      dispatch(generateDeliveryReferenceNo("TZ"));

      _onCloseForm();
      // resetForm()
    } catch (err: any) {
      console.error(err.message);
    }
  });

  // const _onCloseForm = () => {
  //   dispatch(toggleDocumentHistoryColumnShort(false));
  //   navigate('/instruction');
  // };

  const _onCloseForm = () => {
    dispatch(toggleDocumentHistoryColumnShort(false));
    setTimeout(() => {
      onBack();
      setTimeout(() => {
        dispatch(updateDashbaordRefresh(true));
      }, 1000);
    }, 500);
    setTimeout(() => {
      // onBack();
      if (location.pathname.includes("instruction")) {
        navigate("/opc/instruction");
      } else {
        navigate("/opc/doupload");
      }
    }, 500);
  };

  return (
    <Card style={{ height: "90vh", overflow: "auto" }}>
      <Card.Header className="d-flex align-items-center border-bottom sticky-top bg-white">
        <FontAwesomeIcon
          icon={faArrowAltCircleLeft}
          className="text-warning cursor-pointer fa-lg mb-1 me-1"
          onClick={_onCloseForm}
        />
        <h2 className="fs-0 text-primary mb-1">{t("Delivery Order")}</h2>
      </Card.Header>
      <Card.Body>
        <div className="container">
          <Form onSubmit={onSubmit} autoComplete="off">
            <div className="row">
              <div className="col-lg-6 mb-3">
                <Form.Group controlId="referenceNumber">
                  <span className="mandatory">*</span>
                  <Form.Label>{t("resource_2:reference_no")}</Form.Label>
                  <Form.Control
                    size="sm"
                    {...register("refNo")}
                    className={`fs--1 form-control form-control-sm ${
                      errors.refNo ? "is-invalid" : ""
                    }`}
                    type="text"
                    maxLength={20}
                    value={view ? refNo : doRefNumber}
                    disabled={true}
                    /*    onChange={({ target }) => {
                  setRefNo(target.value);
                }} */
                  />
                  <div className="invalid-feedback">
                    {errors.refNo?.message?.toString()}
                  </div>
                </Form.Group>
              </div>
              {
                /* profileDetails?.opcPartnerType === 'CFA' ||
                  profileDetails?.opcPartnerType === 'ICD' */ true && (
                  <div className="col-lg-6 mb-3">
                    <Form.Group style={{ padding: "0px" }} controlId="vessel">
                      <span className="mandatory">*</span>
                      <Form.Label>{t("resource_1:vessel")}</Form.Label>
                      {!view && (
                        <div>
                          <Controller
                            name="vessel"
                            control={control}
                            rules={{ required: "This field is required" }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                options={VesselList}
                                //  isDisabled={view}
                                noOptionsMessage={() => null}
                                isClearable={true}
                                maxMenuHeight={150}
                                onChange={(e: any) => {
                                  if (e === null) {
                                    field.onChange(null);
                                    setVesselLine("");
                                    setValue("blno", "");
                                    clearErrors("blno");
                                    setValue("totalContainers", "");
                                  } else {
                                    setVesselLine("");
                                    setValue("blno", "");
                                    clearErrors("blno");
                                    setValue("totalContainers", "");
                                    field.onChange({
                                      label: e.label,
                                      value: e.value,
                                      visitKey: e.visitKey,
                                    });
                                    const vessellinedata = VesselData.filter(
                                      (vessel: any) => {
                                        if (e.visitkey === vessel.visitkey) {
                                          // setVesselLine(vessel.line);
                                          // setValue("line", vessel.line);
                                          // trigger("line");
                                          return true;
                                        }
                                        return false;
                                      }
                                    );
                                    setVessel(e.value);
                                    dispatch(
                                      blNumbList({
                                        visit: e.visitKey,
                                        permit: "Import",
                                      })
                                    );
                                  }
                                }}
                                styles={customDropdownStyles}
                                filterOption={customFilterOption}
                                onInputChange={handleInputChange}
                                className={`fs--1 form-control form-control-sm ${
                                  errors.vessel ? "is-invalid" : ""
                                } ${loadingVesselList ? "loadingPulse" : ""}`}
                              />
                            )}
                          />
                          <div className="invalid-feedback">
                            {errors.vessel?.message?.toString()}
                          </div>
                        </div>
                      )}
                      {view && (
                        <div>
                          <Form.Control
                            disabled={true}
                            value={vessel}
                            className="form-control form-control-sm"
                            type="text"
                          />
                        </div>
                      )}
                    </Form.Group>
                  </div>
                )
              }

              <div className="col-lg-6 mb-3">
                <Form.Group controlId="line">
                  <span className="mandatory">*</span>
                  <Form.Label>{t("resource_2:shipping_line")}</Form.Label>
                  {/*              <Controller
                    name='line'
                    control={control}
                    render={({ field }) => (
                      <Form.Select
                        {...field} // This ensures the field is properly controlled
                        className={`fs--1 form-control form-control-sm ${
                          errors.line ? 'is-invalid' : ''
                        }`}
                        onChange={(e) => {
                          field.onChange(e);
                          setLine(e.target.value);
                        }}
                        value={line}
                        disabled={view}
                      >
                        <option value=''>Select Line</option>
                        <option value='Import'>Import</option>
                        <option value='Export'>Export</option>
                      </Form.Select>
                    )}
                  /> */}
                  <Form.Control
                    size="sm"
                    /*    {...register('line')}
                    className={`fs--1 form-control form-control-sm ${
                      errors.CFAName ? 'is-invalid' : ''
                    }`} */
                    type="text"
                    value={vesselLine}
                    disabled={true}
                    /*    onChange={({ target }) => {
                      setCFAName(target.value);
                    }} */
                    maxLength={20}
                  />
                  {/*      <div className='invalid-feedback'>
                    {errors.line?.message?.toString()}
                  </div> */}
                </Form.Group>
              </div>

              <div className="col-lg-6 mb-3">
                <Form.Group controlId="blno">
                  <span className="mandatory">*</span>
                  <Form.Label>{t("resource_2:blNumber")}</Form.Label>
                  <Controller
                    name="blno"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        size="sm"
                        {...field}
                        className={`fs--1 form-control form-control-sm ${
                          errors.blno ? "is-invalid" : ""
                        }`}
                        //value={blNo}
                        onChange={(e) => {
                          const blNumber = e.target.value.trim();
                          field.onChange(e);
                          if (!blNumber) {
                            setError("blno", {
                              type: "manual",
                              message: "BL number is required",
                            });
                          } else {
                            clearErrors("blno");
                          }
                        }}
                        disabled={view}
                        /*    onChange={(e) => {
                          field.onChange(e);
                          setBlNo(e.target.value);
                          validateBl(e.target.value);
                        }} */
                        onBlur={(e) => validateBl(e.target.value.trim())}
                        type="text"
                        maxLength={20}
                      />
                    )}
                  />
                  <div className="invalid-feedback">
                    {errors.blno?.message?.toString()}
                  </div>
                </Form.Group>
              </div>

              <div className="col-lg-6 mb-3">
                <Form.Group controlId="CFATin">
                  <span className="mandatory">*</span>
                  <Form.Label>{t("resource_2:cfa_tin")}</Form.Label>
                  <Controller
                    name="CFATin"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        size="sm"
                        {...field}
                        className={`fs--1 form-control form-control-sm ${
                          errors.CFATin ? "is-invalid" : ""
                        }`}
                        type="text"
                        value={getValues("CFATin")}
                        disabled={view}
                        onChange={(e) => {
                          field.onChange(e);
                          handleTINChangeAndName(e);
                        }}
                        onBlur={handleTinName}
                        maxLength={20}
                      />
                    )}
                  />
                  <div className="invalid-feedback">
                    {errors.CFATin?.message?.toString()}
                  </div>
                </Form.Group>
              </div>

              <div className="col-lg-6 mb-3">
                <Form.Group controlId="totalContainers">
                  <span className="mandatory">*</span>
                  <Form.Label>{t("resource_2:totalContainers")}</Form.Label>
                  <Controller
                    name="totalContainers"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        size="sm"
                        {...field}
                        className={`fs--1 form-control form-control-sm ${
                          errors.totalContainers ? "is-invalid" : ""
                        }`}
                        value={getValues("totalContainers")}
                        disabled={true}
                        /*  onChange={(e) => {
                          field.onChange(e);
                          setTotalContainers(e.target.value);
                        }} */
                        type="text"
                        maxLength={20}
                      />
                    )}
                  />
                  <div className="invalid-feedback">
                    {errors.totalContainers?.message?.toString()}
                  </div>
                </Form.Group>
              </div>

              <div className="col-lg-6 mb-3">
                <Form.Group controlId="CFAName">
                  <span className="mandatory">*</span>
                  <Form.Label>{t("resource_2:cfa_name")}</Form.Label>
                  <Form.Control
                    size="sm"
                    {...register("CFAName")}
                    className={`fs--1 form-control form-control-sm ${
                      errors.CFAName ? "is-invalid" : ""
                    }`}
                    type="text"
                    value={getValues("CFAName")}
                    disabled={true}
                    /*    onChange={({ target }) => {
                      setCFAName(target.value);
                    }} */
                    maxLength={20}
                  />
                  <div className="invalid-feedback">
                    {errors.CFAName?.message?.toString()}
                  </div>
                </Form.Group>
              </div>

              <div className="col-lg-6 mb-3">
                <Form.Group controlId="doNumber">
                  <span className="mandatory">*</span>
                  <Form.Label>{t("resource_2:do_number")}</Form.Label>
                  <Controller
                    name="doNumber"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        size="sm"
                        {...register("doNumber")}
                        className={`fs--1 form-control form-control-sm ${
                          errors.doNumber ? "is-invalid" : ""
                        }`}
                        onChange={(e: any) => {
                          field.onChange(e);
                          handleDoNumberChange(e);
                        }}
                        value={doNumber}
                        disabled={view}
                        type="text"
                        maxLength={20}
                      />
                    )}
                  />
                  <div className="invalid-feedback">
                    {errors.doNumber?.message?.toString()}
                  </div>
                </Form.Group>
              </div>

              <div className="col-lg-6 mb-3">
                <Form.Group controlId="consigneeTin">
                  <Form.Label>{t("resource_2:consignee_tin")}</Form.Label>
                  <Form.Control
                    size="sm"
                    {...register("consigneeTin")}
                    className={`fs--1 form-control form-control-sm ${
                      errors.consigneeTin ? "is-invalid" : ""
                    }`}
                    type="text"
                    value={getValues("consigneeTin")}
                    disabled={view}
                    // onChange={({ target }) => {
                    //setConsigneeTin(target.value);
                    //  }}
                    onChange={handleTINChangeConsignee}
                    onBlur={ConsigneeTinVal}
                    maxLength={20}
                  />
                  <div className="invalid-feedback">
                    {errors.consigneeTin?.message?.toString()}
                  </div>
                </Form.Group>
              </div>

              <div className="col-lg-6 mb-3">
                <Form.Group controlId="effectiveDate">
                  <span className="mandatory">*</span>
                  <Form.Label>{t("resource_2:effectiveDate")}</Form.Label>
                  {!view && (
                    <Controller
                      name="effectiveDate"
                      control={control}
                      render={({ field }) => (
                        <Form.Control
                          size="sm"
                          {...field}
                          className={`fs--1 form-control form-control-sm ${
                            errors.effectiveDate ? "is-invalid" : ""
                          }`}
                          type="date"
                          disabled={view}
                          onChange={(e) => {
                            field.onChange(e);
                            handleEffectiveDateChange(e);
                          }}
                        />
                      )}
                    />
                  )}
                  {view && (
                    <Form.Control
                      type="text"
                      disabled={view}
                      value={effectiveDateView}
                      className="form-control form-control-sm"
                    />
                  )}
                  <div className="invalid-feedback">
                    {errors.effectiveDate?.message?.toString()}
                  </div>
                </Form.Group>
              </div>

              <div className="col-lg-6 mb-3">
                <Form.Group controlId="consigneeName">
                  <Form.Label>{t("resource_2:consignee_name")}</Form.Label>
                  <Controller
                    name="consigneeName"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        size="sm"
                        {...field}
                        className={`fs--1 form-control form-control-sm ${
                          errors.consigneeName ? "is-invalid" : ""
                        }`}
                        onChange={(e) => {
                          field.onChange(e);
                          setValue("consigneeName", e.target.value);
                        }}
                        onInput={(e: any) => {
                          e.target.value = e.target.value.replace(
                            /[^a-zA-Z0-9 ]/g,
                            ""
                          );
                        }}
                        value={getValues("consigneeName")}
                        disabled={view}
                        type="text"
                        maxLength={20}
                      />
                    )}
                  />
                  <div className="invalid-feedback">
                    {errors.consigneeName?.message?.toString()}
                  </div>
                </Form.Group>
              </div>

              <div className="col-lg-6 mb-3">
                <Form.Group controlId="expiryDate">
                  <span className="mandatory">*</span>
                  <Form.Label>{t("resource_2:expiry_date")}</Form.Label>
                  {!view && (
                    <Controller
                      name="expiryDate"
                      control={control}
                      render={({ field }) => (
                        <Form.Control
                          size="sm"
                          {...field}
                          className={`fs--1 form-control form-control-sm ${
                            errors.expiryDate ? "is-invalid" : ""
                          }`}
                          type="date"
                          disabled={view}
                          onChange={(e) => {
                            field.onChange(e);
                            handleExpiryDateChange(e);
                          }}
                        />
                      )}
                    />
                  )}
                  {view && (
                    <Form.Control
                      type="text"
                      disabled={view}
                      value={expiryDateView}
                      className="form-control form-control-sm"
                    />
                  )}
                  <div className="invalid-feedback">
                    {errors.expiryDate?.message?.toString()}
                  </div>
                </Form.Group>
              </div>
              {!view && (
                <div className="col-lg-8 offset-lg-2 mt-2 d-flex justify-content-center align-items-center">
                  <Button
                    variant="falcon-primary me-2"
                    size="sm"
                    //  disabled={submitaction}
                    // disabled={!agentNominationId}
                    onClick={onSubmit}
                  >
                    <FontAwesomeIcon icon={faSave} className="me-2" />
                    Submit
                  </Button>

                  <Button
                    variant="falcon-danger"
                    onClick={() => {
                      resetForm();
                    }}
                    size="sm"
                  >
                    <FontAwesomeIcon icon={faRedo} className="me-2" />
                    Reset
                  </Button>
                </div>
              )}
            </div>
          </Form>
        </div>
      </Card.Body>
    </Card>
  );
};

export default DeliveryOrderInstruction;
