/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';

import TableComponent from './TableComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getDashboardData } from '../../dashboard/actions/dashboard';
import queryString from 'query-string';
import { getColumnViews } from '../../../common/actions/dataTable';
import {
  updateDashbaordRefresh,
  updateSortStateDataTable,
} from '../../../common/components/DataTable/slices/DataTable';
import {
  updateCurrentPageInstructionDashboard,
  updateCurrentPageRegistrationDashboard,
  updatePrveDependenciesBlDashboard,
  updateSetDefaultViewBL,
} from '../../dashboard/slices/dashboardSlice';
import { updateParams } from '../../../common/components/DataTable/slices/DataTable';
import { AESEncrypt } from '../../../../encrypt-util';
import { updateDashbaordName } from '../../../common/slices/navbarTop';
import { getUserProfile } from '../../../common/actions/userDetails';
import moment from 'moment';

const MainTable = () => {
  const [previousState, setPreviousState] = useState<{
    filterState: string | null;
    search: string | null;
    grids: any[] | null;
  }>({
    filterState: null,
    search: null,
    grids: null,
  });
  const location = useLocation();

  const instructionTopSearchState = useSelector(
    (state: RootState) => state.navbarTop.instructionTopSearchState
  );

  const dispatch = useDispatch<AppDispatch>();

  const filterState = useSelector(
    (state: RootState) => state.dataTable.filterState
  );
  const selectedConfigId = useSelector(
    (state: RootState) => state.dataTable.selectedConfigId
  );
  const searchInputValue = useSelector(
    (state: RootState) => state.navbarTop.searchInputValue
  );

  const tpoId = useSelector((state: RootState) => state.agentDelegation.tpoId);
  const linerId = useSelector((state: RootState) => state.navbarTop.linerId);
  const filterStateLocal = filterState?.['usersDetails'];

  const sort = (queryString.parse(location.search)?.sort as string)
    ?.split?.(',')
    ?.join?.(' ');

  const setDefaultViewBL = useSelector(
    (state: RootState) => state.instructionDashboard.setDefaultViewBL
  );
  const grids = useSelector(
    (state: RootState) => state.dataTable.registrationMasterGrid
  );
  const currentPage = useSelector(
    (state: RootState) => state.dashboard.currentPageRegistration
  );

  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const userGrids = useSelector(
    (state: RootState) => state.dataTable.userGrids
  );

  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );
  const storedCountry = window.localStorage.getItem('@user-selected-country');

  const dataCountry = linerDataCountry
    ? linerDataCountry
    : profileDetails.partnerCountry || null;
  useEffect(() => {
    dispatch(updateDashbaordName('OPC_USER_DASHBOARD'));
  }, []);

  useEffect(() => {
    //  if (location?.pathname && dataCountry && grids.length == 0 && linerId) {

    if (dataCountry && tpoId) {
      setTimeout(() => {
        //  dispatch(getUserGridsDefinition('TZ'));

        dispatch(
          getColumnViews({
            gridName: 'OPC_USER_DASHBOARD',
            country: dataCountry,
            linerId: tpoId,
          })
        );
      }, 150);
    }

    //  }
    dispatch(updateParams({}));
    // if (datatableState != 'INSTRUCTION_DASHBOARD') {
    //   dispatch(
    //     updateSortStateDataTable({
    //       key: null,
    //     })
    //   );
    // }
  }, [dataCountry, userGrids, tpoId]);

  useEffect(() => {
    const configViews = grids?.find?.(
      (g: any) => g?.gridName == 'OPC_USER_DASHBOARD'
    )?.configGroup;

    if (configViews && configViews?.length > 0 && setDefaultViewBL) {
      dispatch(updateSetDefaultViewBL(false));
    }
  }, []);

  const prevPageRegistrationDashbaord = useSelector(
    (state: RootState) =>
      state.dashboard.prevDependencies.prevPageRegistrationDashbaord
  );
  const setPrevPageRegistrationDashbaord = (state: string) => {
    dispatch(
      updatePrveDependenciesBlDashboard({
        key: 'prevPageRegistrationDashbaord',
        value: state,
      })
    );
  };
  function useDebouncedEffect(effect: any, deps: any, delay: any) {
    const callback = useRef<any>();

    useEffect(() => {
      callback.current = effect;
    }, [effect]);

    useEffect(() => {
      const handler = () => {
        if (callback.current) {
          callback.current();
        }
      };

      const timer = setTimeout(handler, delay);

      return () => clearTimeout(timer);
    }, [...deps, delay]);
  }
  useDebouncedEffect(
    () => {
      const currentStateValue = {
        filterState: JSON.stringify(filterStateLocal),
        search: location.search,
        grids: grids,
      };
      if (JSON.stringify(previousState) !== JSON.stringify(currentStateValue)) {
        loadOpcUsersDashboard(true);
        const currentState = {
          filterState: JSON.stringify(filterStateLocal),
          search: location.search,
          grids: grids,
        };
        setPreviousState(currentState);
      }
    },
    [filterStateLocal, location.search, grids, linerId, sort],
    700
  );
  useEffect(() => {
    const currentStateValue = {
      filterState: JSON.stringify(filterStateLocal),
      search: location.search,
      grids: grids,
    };

    if (JSON.stringify(previousState) !== JSON.stringify(currentStateValue)) {
      loadOpcUsersDashboard(true);
      const currentState = {
        filterState: JSON.stringify(filterStateLocal),
        search: location.search,
        grids: grids,
      };
      setPreviousState(currentState);
    }
  }, [filterStateLocal, location.search, grids]);

  useEffect(() => {
    if (currentPage && currentPage !== prevPageRegistrationDashbaord) {
      setPrevPageRegistrationDashbaord(currentPage);
      loadOpcUsersDashboard(false);
    }
  }, [currentPage]);

  const setCurrentPage = (page: number) => {
    dispatch(updateCurrentPageRegistrationDashboard(page));
  };
  const selectedTopSearchFilter = useSelector(
    (state: RootState) => state.navbarTop.selectedTopSearchFilter
  );

  const refresh = useSelector(
    (state: RootState) => state.dataTable.refreshDashboard
  );

  useEffect(() => {
    if (refresh) loadOpcUsersDashboard(false);
    // server sometimes takes time to update the view so the delay
    setTimeout(() => dispatch(updateDashbaordRefresh(false)), 2000);
  }, [refresh]);

  const loadOpcUsersDashboard = (filterUpdated: boolean) => {
    if (filterUpdated) {
      setCurrentPage(1);
    }

    if (filterStateLocal) {
      if (Object.keys(filterStateLocal)?.length > 0) {
        const filterApiState: any = {};

        const filterKeys = Object.keys(filterStateLocal);

        for (let k of filterKeys) {
          if (filterStateLocal?.[k]?.from) {
            filterApiState[k] = [
              filterStateLocal?.[k]?.from || '',
              filterStateLocal?.[k]?.to || '',
            ].filter((i) => i);
          } else if (filterStateLocal?.[k]?.from !== undefined) {
            filterApiState[k] = undefined;
          } else {
            filterApiState[k] = filterStateLocal?.[k]
              ? typeof filterStateLocal?.[k] === 'string'
                ? [filterStateLocal?.[k]]
                : filterStateLocal?.[k]
              : undefined || undefined;
          }
        }

        if (Object.keys(filterApiState)?.length > 0) {
          const finalFilterApiState: any =
            selectedTopSearchFilter?.id &&
              searchInputValue.trim() != '' &&
              Object.keys(instructionTopSearchState).length != 0
              ? { [selectedTopSearchFilter?.id]: [searchInputValue.trim()] }
              : {};
          const currentDate = new Date();

          if (userGrids[0]) {
            let timeFlag = '';
            let timeDiff = 0;
            let dateRange: any = {};
            const newdata = userGrids[0].configGroup?.find(
              (c: any) => c.userGridId == selectedConfigId?.['usersDetails']
            )?.columnConfigGrid;
            if (newdata) {
              timeFlag = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'timestamp'
              )?.filterState;
              timeDiff = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'timestamp'
              )?.timeDiff;
              dateRange = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'timestamp'
              )?.dateRange;
            }

            if (filterApiState.timestamp && timeFlag == 'day' && dateRange) {
              filterApiState.timestamp[1] = moment()
                .subtract(Math.abs(dateRange.to), 'days')
                .endOf('day')
                .toISOString();
              // const newDate = new Date(
              //   new Date(currentDate.toISOString()).getTime() -
              //     timeDiff * 24 * 60 * 60 * 1000
              // );
              filterApiState.timestamp[0] = moment()
                .subtract(Math.abs(dateRange.from), 'days')
                .startOf('day')
                .toISOString();
            }
          }
          for (let i of Object.keys(filterApiState)) {
            if (filterApiState[i]) {
              finalFilterApiState[i] = filterApiState[i];
            }
          }

          if (
            /*      profileDetails &&
            ((profileDetails.partnerType === 'customer' &&
              profileDetails.partnerId &&
              instructionsGrids?.[0]?.whereClause) ||
              profileDetails?.partnerType === 'liner') */
            tpoId
          ) {
            setTimeout(() => {
              dispatch(
                getDashboardData({
                  gridName: 'OPC_USER_DASHBOARD',
                  payload: {
                    fields: [],

                    filters: /*   selectedTopSearchFilter?.where === ''
                        ? instructionTopSearchState
                        :  */ finalFilterApiState ? finalFilterApiState : {},
                    size: 20,
                    sorts: sort ? [sort] : ['user_id desc'],
                    page: filterUpdated ? 1 : currentPage,
                  },
                  dataCountry: dataCountry,
                  partnerId: profileDetails.partnerId,

                  where: userGrids?.[0]?.whereClause
                    ? AESEncrypt(userGrids?.[0]?.whereClause)
                    : null,
                  parameter:
                    selectedTopSearchFilter?.where !== ''
                      ? {
                        searchParameter: searchInputValue,
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                      : {},
                })
              );
              // dispatch(
              //   /*    getDashboardData({
              //     gridName: 'OPC_INSTRUCTION_DASHBOARD',
              //     payload: {
              //       fields: [],

              //       filters: /*  !selectedTopSearchFilter?.where
              //         ? finalFilterApiState
              //         : */ //{},

              //   //   size: 20,
              //   //  sorts: '',
              //   /*  sortState &&
              //         sortState?.key != null &&
              //         sortState?.value != null
              //           ? [`${sortState?.key} ${sortState?.value}`]
              //           : sort
              //           ? [sort]
              //           : [''] */
              //   //  page: filterUpdated ? 1 : currentPage,
              //   //  },
              //   // dataCountry: dataCountry,
              //   // partnerId:
              //   //  profileDetails.partnerType === 'customer'
              //   //  ? profileDetails.partnerId
              //   //  : null,
              //   // where: instructionsGrids?.[0]?.whereClause
              //   //? AESEncrypt(instructionsGrids?.[0]?.whereClause)
              //   // : null,
              //   //  parameter:
              //   //  selectedTopSearchFilter?.where !== ''
              //   //  ? {
              //   //    searchParameter: searchInputValue,
              //   //  searchWhere: selectedTopSearchFilter?.where,
              //   // }
              //   // : {},
              //   //  })
              //   getDashboardData({
              //     fields: [],
              //     filters: {},
              //     size: 20,
              //     sorts: sort ? [sort] : ['requested_on desc'],
              //     /* sortState?.key != null && sortState?.value != null
              //         ? [`${sortState?.key} ${sortState?.value}`]
              //           ? [sort]
              //           : ['requested_on desc']
              //         : ['requested_on desc'], */
              //     page: 1,
              //     view: 'E58NbJpkbNRw9+y/FKnz4+ZLO9CsHv/yAz6QBCjYEpM=',
              //     where: '',
              //   })
              // );
            }, 150);
          }
        }
      }
    }
  };
  return <TableComponent />;
};

export default MainTable;
