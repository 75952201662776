import moment from "moment";
import { getBadges } from "../features/dashboard/components/helpers";
import { IDataTableColumn } from "../common/types/dataTable";
import {
  Link,
  Location,
  NavigateFunction,
  matchPath,
  useParams,
} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faChevronCircleRight,
  faMailBulk,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import {
  AESDecrypt,
  AESEncrypt,
  AESEncryptforDashboard,
  Base64Encode,
  encryptWithSecret,
} from "../../encrypt-util";
import { toast } from "react-toastify";
import {
  faBell,
  faBellSlash,
  faCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { updateSortStateDataTable } from "../common/components/DataTable/slices/DataTable";
import { useDispatch } from "react-redux";
import { updateInstructionID } from "../features/blDetails/slices/documentHistorySlice";
import { AppDispatch, RootState } from "../store";
import { useSelector } from "react-redux";
import { updateAssigneTo } from "../features/dashboard/slices/dashboardSlice";
import { formatISODateOnlyToLocale, formatISODateToLocale, getDateTimeFormat } from "../../helpers/dateFormat";

export interface IRequestConfig {
  accessor: string | null;
  columnId: string | number | null;
  dataType: string | null;
  filterType: string | null;
  filterValues: any[] | null;
  filterable: string | boolean | null;
  fixed: string | boolean | null;
  hide: boolean | null;
  hyperlink: string | null;
  label: string | null;
  sort_order: string | null;
  sortable: string | boolean | null;
  timeFormat: string | null;
  tooltip: string | null;
  type: string | null;
  visible: string | boolean | null;
  width: string | number | null;
  maxWidth: string | number | null;
  link: string | null | any;
  hyperLink: string | null;
  slider: string | null | any;
  assert?: any;
}

export interface IConfigsDefinition {
  columns: IDataTableColumn[];
  staticHiddenCols: string[];
  defaultHiddenCols: string[];
}

export const generateColumnConfig = (
  configs: IRequestConfig[],
  getParams?: () => any,
  navigate?: NavigateFunction,
  selectedData?: any,
  dispatch?: AppDispatch
): IConfigsDefinition => {
  const generateHeaderLabel = (s: any) => {
    let count = 1;
    for (let i in s) {
      if (s.charAt(i) === s.charAt(i).toUpperCase()) {
        count++;
      }
    }

    return count ? `resource_${count}:${s}` : s;
  };
  const columnConfigs: any = configs.map((c) => {
    const commonKeys = {
      accessor: c?.accessor || null,
      Header: c?.accessor ? generateHeaderLabel(c.accessor) : "",
      minWidth: c?.width
        ? typeof c?.width === "string"
          ? parseInt(c.width) - 60
          : c.width - 60
        : 120,
      width: c?.width
        ? typeof c?.width === "string"
          ? parseInt(c.width)
          : c.width
        : 300,
      maxWidth: c?.maxWidth ? +c?.maxWidth
        : c?.width ? typeof c?.width === "string"
          ? parseInt(c.width) + 100
          : c.width + 100
          : 400,
      columnFilterType: c?.filterType || null,
      columnFilterOptions: c?.filterValues || null,
      disableSort: c?.sortable === "false" ? true : false,
      fixed: c?.fixed === "true" || c?.fixed ? true : false,
      link: c?.link || null,
      hyperLink: c?.hyperLink || null,
      slider: c?.slider || null,
      hide: c?.hide || false,
    };

    const formatNumber = (value: string): string => {
      const numericValue = parseFloat(value);

      if (isNaN(numericValue)) {
        return "Invalid Number";
      }

      const trimmedValue = numericValue.toFixed(2);

      const numberWithCommas = trimmedValue.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      return numberWithCommas;
    };

    return {
      ...commonKeys,
      Cell: (rowData: any) => {
        const value = c?.accessor
          ? c?.dataType === "datetime" || c?.dataType === "date"
            ? rowData?.row?.original?.[c.accessor]
              ? new Date(rowData?.row?.original?.[c.accessor])
              : ""
            : rowData?.row?.original?.[c.accessor]
          : "";
        const getSelection = (path: string, encrypt = true) => {
          const params = getParams ? getParams() : {};

          let returnResponse = false;
          //  const pattern = /:[A-Za-z]*/g;
          const pattern = /:\w*/g;

          const variables = path.match(pattern);

          const combineLogicFields = ["inst_id", "doc_id"];
          const combineLogicFields2 = ["request_id"];
          if (c?.accessor === "inst_type" && combineLogicFields?.length > 0) {
            let comibneLogicDecider = [];
            if (
              variables &&
              variables?.length > 0 &&
              matchPath(path, window.location.pathname)
            ) {
              for (let i of variables) {
                const variable = i.slice(1);
                const value = rowData?.row?.original?.[variable];

                if (
                  combineLogicFields.includes(variable) &&
                  value &&
                  variable &&
                  params?.[variable] === value
                ) {
                  comibneLogicDecider.push(variable);
                }
              }
            }

            return comibneLogicDecider?.length === combineLogicFields?.length;
          } else if (
            c?.accessor === "request_id" &&
            combineLogicFields?.length > 0
          ) {
            let comibneLogicDecider = [];
            if (
              variables &&
              variables?.length > 0 &&
              matchPath(path, window.location.pathname)
            ) {
              for (let i of variables) {
                const variable = i.slice(1);
                const value = rowData?.row?.original?.[variable];
                if (
                  combineLogicFields2.includes(variable) &&
                  value &&
                  variable &&
                  params?.[variable] === value
                ) {
                  comibneLogicDecider.push(variable);
                }
              }
            }

            return comibneLogicDecider?.length === combineLogicFields2?.length;
          } else if (c?.accessor == "instruction_name") {
            if (
              variables &&
              variables?.length > 0 &&
              window.location.pathname.includes(":bl_id")
            ) {
              for (const i of variables) {
                const variable = i.slice(1);
                const value = rowData?.row?.original?.[variable];
                if (value && variable && params?.[variable] === value) {
                  returnResponse = true;
                }
              }
            }
          } else {
            if (
              variables &&
              variables?.length > 0 &&
              matchPath(path, window.location.pathname)
            ) {
              for (let i of variables) {
                const variable = i.slice(1);
                const value = rowData?.row?.original?.[variable];

                if (value && variable && params?.[variable] === value) {
                  returnResponse = true;
                }
              }
            }
          }

          return returnResponse;
        };

        const getRouteLink = (path: string, encrypt = true, dashencrypt = false) => {
          let returnPath = path;

          const pattern = /:[A-Za-z_]*/g;

          const variables = path.match(pattern);

          if (variables && variables?.length > 0) {
            for (let i of variables) {
              const variable = i.slice(1);

              const value = rowData?.row?.original?.[variable];

              if (value)
                returnPath = returnPath.replace(
                  i,
                  encrypt &&
                    //   variable !== 'inst_id' &&
                    //  variable !== 'doc_id' &&
                    // variable !== 'standing_instruction_id' &&
                    variable !== "instructionid" &&
                    variable !== "doc_number" &&
                    variable !== "draftNo"
                    ? AESEncrypt(value)
                    : dashencrypt &&
                      variable !== "permitType" &&
                      variable !== "permitNbr"
                      ? Base64Encode(AESEncryptforDashboard(value))
                      : value
                );
            }
          }

          if (
            variables?.includes(":bl_id") &&
            variables?.includes(":standing_instruction_id")
          ) {
            const parts = returnPath.split("/");
            if (parts.length >= 3) {
              const thirdPosition = parts[3];
              parts[3] = AESEncrypt(thirdPosition);

              return parts.join("/");
            }
          }

          if (
            variables?.includes(":permitNbr") &&
            variables?.includes(":permitType")
          ) {
            const encryptedUrl = returnPath.replace(
              /(appointmentNbr=|permitType=)([^&]*)/g,
              (_, key, value) =>
                `${key}${Base64Encode(AESEncryptforDashboard(value))}`
            );
            return encryptedUrl;
          }

          if (variables?.includes(":draftNo")) {
            const encryptedUrl = returnPath.replace(
              /(draftNbr=)([^&]*)/g,
              (_, key, value) =>
                `${key}${Base64Encode(AESEncryptforDashboard(value))}`
            );
            return encryptedUrl;
          }

          return returnPath;
        };

        const setTimeFormat = (val: string) => {
          try {
            return c?.dataType === "date" || c?.dataType === "datetime" || c?.dataType === 'datetimeformat' || c?.dataType === 'dateformat'
              ? val
                ? c?.dataType === "date"
                  ? new Date(val).toLocaleDateString(
                    new Intl.DateTimeFormat().resolvedOptions().locale,
                    { timeZone: "UTC" }
                  )
                  : c?.dataType === "datetimeformat"
                    ? formatISODateToLocale(val)
                    : c?.dataType == 'dateformat'
                      ? formatISODateOnlyToLocale(val)
                      : new Date(val).toLocaleString()
                : ""
              : val;
          } catch (e) {
            return val;
          }
        };

        const getLink = (value: string | null | any) => {
          if (
            c?.accessor === "permitEmail"
          ) {
            return (
              <div
                className="text-center"
              >
                <Link
                  to={getRouteLink(`${c?.link}${window.location.search || ""}`)}
                  className={`${getSelection(c?.link) ? "fw-bold" : "text-warning"
                    }`}
                >
                  <FontAwesomeIcon
                    icon={faMailBulk}
                    className='ps-1'

                    size='lg'

                  />

                </Link>
              </div>
            );
          }
          else if (
            c?.accessor === "qr"
          ) {
            return (
              <div
                className="text-center"
              >
                <Link
                  to={''} style={{ color: '#f5822a' }}>
                  <FontAwesomeIcon
                    icon={faQrcode}
                    className='ps-1'

                    size='lg'
                    onClick={(e) => {
                      e.preventDefault();
                      if (c?.accessor == 'qr') {
                        window.open(
                          `${process.env.REACT_APP_CLIENT_APP_HOST}${getRouteLink(
                            c?.hyperlink || "",
                            false, true
                          )}`,
                          "_blank"
                        );
                        return
                      }
                      window.open(
                        `${process.env.REACT_APP_CLIENT_APP_HOST}${getRouteLink(
                          c?.hyperlink || "",
                          false
                        )}`,
                        "_blank"
                      );
                    }}
                  />

                </Link>
              </div>
            );
          }
          else if (
            c?.accessor === "snoozed" ||
            c?.accessor === "snoozenotification"
          ) {
            return (
              <div>
                {/*  <FontAwesomeIcon
                    icon={faBell}
                     className='text-primary ps-1'
                     size='lg'
                    /> */}
                <Link
                  to={getRouteLink(`${c?.link}${window.location.search || ""}`)}
                  className={`${getSelection(c?.link) ? "fw-bold" : "text-primary"
                    }`}
                >
                  {value === "Yes" ? (
                    <FontAwesomeIcon
                      icon={faBell}
                      className="text-primary ps-1"
                      size="lg"
                    />
                  ) : value === "No" ? (
                    <FontAwesomeIcon
                      icon={faBellSlash}
                      className="text-danger ps-1 "
                      size="lg"
                    />
                  ) : (
                    ""
                  )}
                </Link>
              </div>
            );
          } else if (c?.link && c?.slider) {
            return (
              <div>
                <Link
                  to={
                    rowData?.row?.original?.[c?.assert?.field] !==
                      c?.assert?.value
                      ? getRouteLink(window.location.pathname)
                      : window?.location?.search
                        ? getRouteLink(`${c?.link}${window.location.search}`)
                        : getRouteLink(c?.link)
                  }
                  onClick={(event) => {
                    if (
                      c?.accessor == "bl_no" ||
                      c?.accessor == "blno" ||
                      c?.accessor == ""
                    ) {
                      if (
                        rowData?.row?.original?.[c?.assert?.field] !==
                        c?.assert?.value
                      ) {
                        toast.error(
                          c?.assert?.errormsg || "Unable to open link"
                          , {
                            toastId: 'aa90933'
                          }
                        );
                        event.preventDefault();
                      } else {
                      }
                    }
                  }}
                  className={`${getSelection(c?.link) || getSelection(c?.slider)
                    ? "fw-bold"
                    : "text-primary"
                    }`}
                >
                  {value}
                </Link>
                <span
                  onClick={() => {
                    if (c?.accessor == "bl_no" || c?.accessor == "blno") {
                      if (
                        rowData?.row?.original?.[c?.assert?.field] !==
                        c?.assert?.value
                      ) {
                        toast.error(
                          c?.assert?.errormsg || "Unable to open link"
                          ,
                          {
                            toastId: '99a9789'
                          });
                      } else {
                        if (navigate)
                          navigate(
                            window?.location?.search
                              ? `${getRouteLink(c?.slider)}${window.location.search
                              }`
                              : getRouteLink(c?.slider)
                          );
                      }
                    }
                  }}
                  className="infoIcon cursor-pointer ms-1"
                >
                  <FontAwesomeIcon icon={faChevronCircleRight} />
                </span>
              </div>
            );
          } else if (c?.hyperlink && c?.slider) {
            return (
              <div>
                <span
                onClick={(e) => {
                    e.preventDefault();
                    if (window.location.pathname.includes('vessel-master')) {
                      if (navigate)
                        navigate(
                          window?.location?.search
                            ? `${getRouteLink(c?.hyperlink || "", true)}${window.location.search
                            }`
                            : getRouteLink(c?.hyperlink || "", true)
                        );
                    } else {
                      window.open(
                        `${process.env.REACT_APP_CLIENT_APP_HOST}${getRouteLink(
                          c?.hyperlink || "",
                          false
                        )}`,
                        "_blank"
                      );
                    }


                  }}
                  className={`${getSelection(c?.slider)
                    ? "fw-bold cursor-pointer"
                    : "text-primary cursor-pointer"
                    }`}
                >
                  {value}
                </span>
                <span
                  onClick={() => {
                    
                        if (navigate)
                          navigate(
                            window?.location?.search
                              ? `${getRouteLink(c?.slider)}${window.location.search
                              }`
                              : getRouteLink(c?.slider)
                          );
                  
                    
                  }}
                  className="infoIcon cursor-pointer ms-1"
                >
                  <FontAwesomeIcon icon={faChevronCircleRight} />
                </span>
              </div>
            );
          } else if (
            c?.link &&
            AESDecrypt(value) &&
            !(rowData?.row?.original?.doc_category === 202)
          ) {
            return (
              <Link
                to={getRouteLink(`${c?.link}${window.location.search || ""}`)}
                className={`${getSelection(c?.link) ? "fw-bold" : "text-primary"
                  }`}
              >
                {value}
              </Link>
            );
          } else if (c?.slider && value) {
            return (
              <div>
                <span className={`${getSelection(c?.slider) ? "fw-bold" : ""}`}>
                  {value}
                </span>
                <span
                  onClick={() => {
                    if (navigate)
                      navigate(
                        window?.location?.search
                          ? `${getRouteLink(c?.slider)}${window.location.search
                          }`
                          : getRouteLink(c?.slider)
                      );
                  }}
                  className="infoIcon cursor-pointer ms-1"
                >
                  <FontAwesomeIcon icon={faChevronCircleRight} />
                </span>
              </div>
            );
          } else if (c?.hyperlink?.includes("verifyPermit")) {
            if (
              rowData?.row?.original?.[c?.assert?.field] === c?.assert?.value
            ) {
              return (
                <span
                  onClick={(e) => {
                    e.preventDefault();

                    window.open(
                      `${process.env.REACT_APP_CLIENT_APP_HOST}${getRouteLink(
                        c?.hyperlink || "",
                        false
                      )}`,
                      "_blank"
                    );
                  }}
                  className="text-primary cursor-pointer"
                >
                  {value}
                </span>
              );
            } else {
              return (
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    toast.error(c?.assert?.errormsg || "Unable to open link", {
                      toastId: 'db665c9'
                    });
                  }}
                  className="text-primary cursor-pointer"
                >
                  {value}
                </span>
              );
            }
          } else if (c?.hyperlink && !c?.hyperlink?.includes("verifyPermit")) {
            if (
              rowData?.row?.original?.[c?.assert?.field] === c?.assert?.value
            ) {
              return (
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    if (window.location.pathname.includes('vessel-master')) {
                      if (navigate)
                        navigate(
                          window?.location?.search
                            ? `${getRouteLink(c?.hyperlink || "", true)}${window.location.search
                            }`
                            : getRouteLink(c?.hyperlink || "", true)
                        );
                    } else {
                      window.open(
                        `${process.env.REACT_APP_CLIENT_APP_HOST}${getRouteLink(
                          c?.hyperlink || "",
                          false
                        )}`,
                        "_blank"
                      );
                    }


                  }}
                  className="text-primary cursor-pointer"
                >
                  {value}
                </span>
              );
            } else {
              return (
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    toast.error(c?.assert?.errormsg || "Unable to open link", {
                      toastId: 'b27715c'
                    });
                  }}
                  className="text-primary cursor-pointer"
                >
                  {value}
                </span>
              );
            }
          } else {
            if (
              c?.accessor == "invamt" ||
              c?.accessor == "pmt_amt" ||
              c?.accessor == "totalCharges" ||
              c?.accessor == "pmt_amt" ||
              c?.accessor == "taxes" ||
              c?.accessor == "owed" ||
              c?.accessor == "pmt_amt_string" ||
              c?.accessor == "amountLocal"
            ) {
              const newVal = formatNumber(value);
              return (
                <div className="text-end" style={{ paddingRight: "30px" }}>
                  {newVal}
                </div>
              );
            } else if (c?.accessor == "no_of_container") {
              return (
                <div className="text-end" style={{ paddingRight: "20px" }}>
                  {value}
                </div>
              );
            } else {
              return value;
            }
          }
        };

        if (c?.tooltip) {
          const tooltipValue = rowData?.row?.original?.[c.tooltip];

          return (
            <div
              className="text-truncate"
              data-toggle="tooltip"
              title={tooltipValue || value}
            >
              {c?.type === "badge"
                ? getBadges(value)
                : getLink(setTimeFormat(value || ""))}
            </div>
          );
        } else {
          if (c?.accessor === "pmtstatus") {
            return value === "Yes" ? (
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-primary ps-1"
                size="lg"
              />
            ) : value === "No" ? (
              ""
            ) : (
              <div
                className="text-truncate"
                data-toggle="tooltip"
                title={value}
              >
                {c?.type === "badge" ? getBadges(value) : ""}
              </div>
            );
          }
          return (
            <div
              className="text-truncate"
              data-toggle="tooltip"
              title={setTimeFormat(value || "")}
            >
              {c?.type === "badge"
                ? getBadges(value)
                : getLink(setTimeFormat(value || ""))}
            </div>
          );
        }
      },
    };
  });
  return {
    columns: columnConfigs,
    staticHiddenCols: configs
      .filter((c: any) => c?.hide === "true" || c?.hide)
      .map((c: any) => c.accessor),
    defaultHiddenCols: configs
      .filter((c: any) => c?.visible === "false")
      .map((c: any) => c.accessor),
  };
};

export const generateActiveColuns = (
  configs: IRequestConfig[]
): { accessor: string; label: string; timeFormat: string }[] => {
  return configs
    .filter(
      (c) => !((c?.hide as any) === "true" || c?.hide) && c?.visible === "true"
    )
    .map((m) => ({
      accessor: m?.accessor || "",
      label: m?.label || "",
      timeFormat: m?.timeFormat || "",
      dataType: m?.dataType,
    }));
};
