import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import './assets/scss/main.scss';
import './helpers/initFA';
import './assets/scss/main.scss';

import './app/assets/locales';
import Main from './Main';
import Layout from './layouts/Layout';

import { Provider } from 'react-redux';
import { store } from './app/store';

// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./mocks/browsers');
//   worker.start();
// }

// import './axiosSetup';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


  const tpdcs = window.location.href.includes('tpdcs')
  if(tpdcs) document.title = "PORT-CONNECT"
root.render(
  <Main>
    <Provider store={store}>
      <Router>
        <Layout />
      </Router>
    </Provider>
  </Main>
);

reportWebVitals();
