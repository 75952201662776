/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { Form } from 'react-bootstrap';
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useResizeColumns,
  useBlockLayout,
} from 'react-table';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { t } from 'i18next';


const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();

    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <Form.Check
        type='checkbox'
        className='form-check fs-0 mb-0 d-flex align-items-center'
      >
        <Form.Check.Input type='checkbox' ref={resolvedRef} {...rest} />
      </Form.Check>
    );
  }
);

const AdvanceTableWrapper = ({
  children,
  columns,
  data,
  sortable,
  selection,
  selectionColumnWidth,
  pagination,
  perPage = 20,
  onCustomSelectChange,
  onSelectRow,
  selectionInitialState,
  disableRowSelection,
  staticHiddenCols,
  uniqueFilterKey,
  isChecked,
  isAllChecked
}) => {
  const dispatch = useDispatch();
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 40,
      width: 120,
      maxWidth: 40,
    }),
    []
  );

  const selectedAmount = useSelector(
    (state) => state.multiBlPayment.selectedAmountAll
  );
  const selectedBls = useSelector(
    (state) => state.multiBlPayment.selectedRowsMultiPay
  );
  const selecteCredData = useSelector(
    (state) => state.creditBalance.selectedCreditData
  );
  const editPayment = useSelector((state) => state.blPayment.editPayment);
  const amountDetails = useSelector((state) => state.blPayment.amountDetails);

  const {
    getTableProps,
    getTableBodyProps,
    headers,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    gotoPage,
    pageCount,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter },
    rows,
  } = useTable(
    {
      columns,

      data,
      disableSortBy: !sortable,
      initialState: pagination
        ? {
            pageSize: perPage,
            selectedRowIds: selectionInitialState,
            hiddenColumns: staticHiddenCols,
          }
        : {
            pageSize: Math.max(data?.length, perPage),
            selectedRowIds: selectionInitialState,
            hiddenColumns: staticHiddenCols,
          },

      defaultColumn: defaultColumn,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    useResizeColumns,
    useBlockLayout,
    (hooks) => {
      if (!selection) return  

        if (uniqueFilterKey === 'invDashboar') {
          hooks.visibleColumns.push((columns) => [
            {
              id: 'selection',
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <IndeterminateCheckbox
                  // disabled
                  disabled={editPayment}
                  onClick={(e) => {
                    e.preventDefault();
                    const rowsToSelect = [];
                    const rowsToDeselect = [];
                    rows.forEach((r) => {
                      if (r.original.owed !== "0.0") {
                        rowsToSelect.push(r);
                      } else {
                        rowsToDeselect.push(r);
                      }
                    });

                    onSelectRow({
                      action: e.target.checked ? 'addHeader' : 'removeHeader',
                      data: rowsToSelect.map((r) => r.original),
                    });
                    
                  }}
                  {...getToggleAllRowsSelectedProps()}
                />
              ),
              headerProps: {
                style: {
                  maxWidth: selectionColumnWidth,
                },
              },
              cellProps: {
                style: {
                  maxWidth: selectionColumnWidth,
                },
              },
              Cell: ({ row }) => {
                if (
                  row?.original?.owed == "0.0"
                ) {
                  return (
                    <IndeterminateCheckbox
                      disabled={true}
                      checked={false}
                      onClick={(event) => {
                      }}
                    />
                  );
                } else {
                  return (
                    <IndeterminateCheckbox
                      {...row.getToggleRowSelectedProps()}
                      onClick={(event) => {
                        
                          if (row?.original?.pmtstatus == 'Yes') {
                            event.target.checked = false;
                            toast.error(
                              t('resource_message:invoiceAlreadyPaid'),
                              {
                                toastId:'11da21d'
                              }
                            );
                          } else if (row?.original?.pmtreference) {
                            event.target.checked = false;
                            toast.error(
                              t('resource_message:paymentAlreadyInitiated') +
                                row?.original?.pmtreference,
                                {
                                  toastId:'fa99c81'
                                }
                            );
                          } else {
                            if (event.target.checked) {
                              onSelectRow({
                                action: 'add',
                                data: row.original,
                              });
                            } else {
                              onSelectRow({
                                action: 'remove',
                                data: row.original,
                              });
                            }
                          }

                          if (row.original.unAllocatedAmount) {
                            if (event.target.checked) {
                              const selectedCreditAmount = selecteCredData
                                .map((i) => Number(i.unAllocatedAmount))
                                .reduce((partialSum, a) => partialSum + a, 0);

                              if (
                                selectedCreditAmount > 0 &&
                                (amountDetails > 0) &
                                  (selectedCreditAmount > amountDetails)
                              ) {
                                event.target.checked = false;
                                toast.error(
                                  'Selected Invoice amount is greater than the Credit Balance',{
                                    toastId:'04b539f'
                                  }
                                );
                              }
                            } else {
                              onSelectRow({
                                action: 'remove',
                                data: row.original,
                              });
                            }
                          }

                          if (row?.original?.pmtstatus !== 'Yes') {
                            if (event.target.checked) {


                            } else {
                            }
                          }
                      }}
                      disabled={
                        editPayment ||
                        (disableRowSelection?.accessor &&
                          disableRowSelection?.value &&
                          row.values[disableRowSelection.accessor] ===
                            disableRowSelection.value)
                      }
                    />
                  );
                }
              },
            },
            ...columns,
          ]);
        } else {
          hooks.visibleColumns.push((columns) => [
            {
              id:"selection",
              Header:(row)=><IndeterminateCheckbox
              checked={isAllChecked?.(row.data) == 'Y'}
              indeterminate={isAllChecked?.(row.data) == 'S'}
              onClick={()=>{
                if(isAllChecked?.(row?.data) == 'Y'){
                  onSelectRow({ action:'removeAll', rows:row?.data })
                } else {
                  onSelectRow({ action:'addAll', rows:row?.data })
                }
              }}
              ></IndeterminateCheckbox>,
              
              Cell:({row})=><IndeterminateCheckbox
              checked={isChecked?.(row?.original) == 'Y'}
              onClick={()=>{
                if(isChecked?.(row?.original)== 'Y'){
                  onSelectRow({action:'remove', row:row?.original})
                }else{
                  onSelectRow({action:'add', row:row?.original})
                }
              }}
              ></IndeterminateCheckbox>
            },
            ...columns
          ]);
        }
    }
  );

  const recursiveMap = (children) => {
    return React.Children.map(children, (child) => {
      if (child?.props?.children) {
        return React.cloneElement(child, {
          children: recursiveMap(child.props.children),
        });
      } else {
        if (child?.props?.table) {
          return React.cloneElement(child, {
            ...child.props,
            getTableProps,
            getTableBodyProps,
            headers,
            headerGroups,
            page,
            rows,
            prepareRow,
            canPreviousPage,
            canNextPage,
            nextPage,
            previousPage,
            gotoPage,
            pageCount,
            pageIndex,
            selectedRowIds,
            pageSize,
            setPageSize,
            globalFilter,
          });
        } else {
          return child;
        }
      }
    });
  };

  return <>{recursiveMap(children)}</>;
};

export default AdvanceTableWrapper;
