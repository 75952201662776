import { createSlice, isAsyncThunkAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

/**
 * Reducer Slice for handling loading state for the
 * Loading Middleware
 */

const initialState = {
  globalLoading: false,
  loadingList: {}
};

const loadingSlice = createSlice({
  name: 'loadingSlice',
  initialState,
  reducers: {
    updateGlobalLoading: (state, action) => {
      state.globalLoading = action.payload
    },
    addToLoadingList: (state, action) => {
      state.loadingList = { ...state.loadingList, ...action.payload }
    },
    removeFromLoadingList: (state, action) => {
      delete state.loadingList[action.payload]
    },
    incrementLoadCount: (state, action) => {
      state.loadingList[action.payload] += 1
    },
    decrementLoadCount: (state, action) => {
      state.loadingList[action.payload] -= 1
    }

  },
});

const { updateGlobalLoading, addToLoadingList, removeFromLoadingList,
  incrementLoadCount, decrementLoadCount
} = loadingSlice.actions


/* 
Add non-critical thunk action names to be ignored by global loading
in the ignore array below,

You can find this as the first param of the createAsyncThunk() initialization 
of that particular action
*/
const ignore = [
  'userDetails/getRbacDetails',
  "userDetails/getAppConfig",
  'pymtModedropdown/getInstructionAgentTypes',
  'pymtModedropdown/getInstructionTypes',
  'agentDelegation/getPartyListByBlId',
  'instruction/getTrukerNameList',
  'registrationCompany/fetchAddressSuggestions2',
  'registrationCompany/fetchAddressSuggestions',
  'getCurrencyDDList',
  "PermitRequest/fetchTruckingCompanySuggestions",
  "bestChoiceDashboard/ICDCode",
  "PermitRequest/fetchVesselSuggestions",
  "PermitRequest/blNumbList",
  "mps/getTrafficOptions",
  "mps/getTransitOptions",
  "mps/getCategory",
  "mps/getTypes",
  "mps/instructionConfig",
  "mps/getMpsDocList",
  "mps/getMpsDocNames",
  "pymtModedropdown/getPymtModeOptions",
  "instruction/getMstCodeList",
  "payment/getPymtDetailsByPymtNo",
  "instruction/getInvoiceDetailsByPmtId",
  "instruction/getInvoiceRequest",
  "instruction/getInvoiceRequestByReferenceNo",
  "/getGroupList",
  "userDetails/getUserProfile",
  "mps/getUserTin",
  "bestChoiceDashboard/bestPickCodes",
  "bestChoiceDashboard/bestPickView",
  "creditBalanceBlPayment/getMstCodeListVal",
  "instruction/getDoRequest",
  "PermitRequest/blNumbList",
  "invoiceChange/getVesselMasterData",
  "mps/containerByBl",
];

let pendingActions = 0;

/**
 * use the useLoading hook to add any custom loading states outside of
 * the global loading
 * 
 * pass an Array of all the thunk actions you want to track for the loading
 * The hook will return a boolean state variable that will turn true if the
 * actions are pending
 */
const useLoading = (thunkIds) => {
  const dispatch = useDispatch()
  const ref = useRef()
  const loading = useSelector((state) => state.loading.loadingList[ref.current])
  useEffect(() => {
    ref.current = keyCount
    keyCount++
    thunkList[ref.current] = thunkIds
    dispatch(addToLoadingList({ [ref.current]: 0 }))
    return () => {
      delete thunkList[ref.current]
      dispatch(removeFromLoadingList(ref.current))
    }
  }, [])
  return loading !== 0
}
const thunkList = {}
let keyCount = 0

/**
 * Redux middleware detects when a dispatch is a thunk action
 * and mantains a pending actions count, updating the global Loading
 * state when count hits 0
 *
 * NOTE: This middleware should always be AFTER thunk in the middleware
 * chain of the store
 */
const loadingMiddleware = (store) => (next) => (action) => {
  if (
    isAsyncThunkAction(action) &&
    ignore.every((type) => !action.type.includes(type))
  ) {
    if (action?.meta?.requestStatus === 'pending') {
      pendingActions++;
      store.dispatch(updateGlobalLoading(true));
    } else {
      pendingActions--;
      if (pendingActions === 0) store.dispatch(updateGlobalLoading(false));
    }
  }

  if (isAsyncThunkAction(action)) {
    for (const key in thunkList) {
      if (thunkList[key].some((type) => action.type.includes(type))) {
        if (action?.meta?.requestStatus === 'pending') {
          store.dispatch(incrementLoadCount(key));
        } else {
          store.dispatch(decrementLoadCount(key));
        }
      }
    }

  }


  return next(action);
};

export default loadingMiddleware;
export { useLoading, loadingSlice }
