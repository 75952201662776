import { useEffect, useMemo} from 'react';
import { Form, Row, Col, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { useDispatch } from 'react-redux';
import { getPlatformFees } from './actions/quickPayActions';
import { useLoading } from '../../../middleware/loadingMiddleware';

const formatNumber = (stringValue: string): string => {
  const numericValue = parseFloat(stringValue);

  if (isNaN(numericValue)) {
    return 'Invalid Number';
  }

  const trimmedValue = numericValue.toFixed(2);

  const numberWithCommas = trimmedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return numberWithCommas;
};

const PayableAmount = ({ selectedAmount }: any) => {
  const dispatch = useDispatch<AppDispatch>()

  const { t } = useTranslation();
  const platformFees = useSelector((state:RootState)=>state.quickpay.platformFees)
  const feeLoading = useLoading(['quickPay/getPaymentFees'])

  const filteredselectedAmount = useMemo(()=>selectedAmount.filter(
    (item: { ccy: any }) => item.ccy === selectedAmount[0]?.ccy
  ),[selectedAmount])
  
  const finalAmount = useMemo(()=>filteredselectedAmount.reduce(
    (accumulator: any, currentObj: any) => {
      const amountAsNumber: number = parseFloat(currentObj.amount);
      if (!isNaN(amountAsNumber)) {
        return accumulator + amountAsNumber;
      }
      return accumulator;
    },
    0
  ),[filteredselectedAmount])

  useEffect(() => {
    
    if (finalAmount && filteredselectedAmount !== ''){
      dispatch(getPlatformFees({amount:finalAmount, ccy:filteredselectedAmount?.[0]?.invcurr}))
    }
  }, [finalAmount, filteredselectedAmount?.[0]?.invcurr]);


  return (
    <div className='d-flex' style={{ flexDirection: 'column' }}>
      <Form.Group as={Row} controlId='payableAmount.input'>
        <div
          style={{
            display: 'grid',
            grid: '3fr 3fr 5fr/1fr 2fr',
            alignItems: 'center',
          }}
        >
          <Form.Label className='fs--1 opacity-75 my-0'>
            Invoice Amount
          </Form.Label>
          <Form.Label className='fs--1 opacity-75 my-0 mx-1'>
            {filteredselectedAmount?.[0]?.invcurr}
            <span className='mx-2 my-0'>{formatNumber(`${finalAmount}`)}</span>
          </Form.Label>
          <Form.Label className='fs--1 opacity-75 my-0'>
            Platform Fees
          </Form.Label>
          <Form.Label className={`fs--1 my-0 mx-1 ${feeLoading?'loadingPulse':'opacity-75'}`}>
            {filteredselectedAmount?.[0]?.invcurr}
            <span className='mx-2 my-0'>{formatNumber(`${platformFees}`)}</span>
          </Form.Label>

          <Form.Label className='fs--1 my-2'>
            {t('resource_2:payableAmount')}
          </Form.Label>

          <InputGroup className={`my-2 ${feeLoading?'loadingPulse':''}`}>
            <Col lg={5}>
              <Form.Select value={''} disabled={true}>
                <option value={filteredselectedAmount?.[0]?.invcurr}>
                  {filteredselectedAmount?.[0]?.invcurr}
                </option>
                {/*<option value='zar'>ZAR</option>*/}
              </Form.Select>
            </Col>
            <Col lg={7}>
              <Form.Control
                aria-label={t('resource_2:payableAmount')}
                aria-describedby='payable-amount'
                value={formatNumber(`${finalAmount + platformFees}`)}
                disabled={true}
              />
            </Col>
          </InputGroup>
        </div>
      </Form.Group>
    </div>
  );
};

export default PayableAmount;
