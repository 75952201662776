
import FilterView from '../../../common/components/FilterView';
import { useLocation } from 'react-router-dom';
import { toggleColumnSettingsDashboard } from '../slices/dashboardSlice';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';

const DashboardRoot = () => {
  const location = useLocation();
  const dataLoading = useSelector(
    (state: RootState) => state.dashboard.dataLoading
  );
  const grids = useSelector((state: RootState) => state.dataTable.bestPickGrids);

  // const data = useSelector((state: RootState) => state.instructionDashboard.data);
  const data = useSelector(
    (state: RootState) => state.dashboard.bestPickDashboardList
  );


  return (
    <FilterView
      showColumnSettings
      toggleColumnSettings={toggleColumnSettingsDashboard}
      showExport
      columnSettingsPersistKey='@odex/maintable/v6'
      uniqueFilterKey='bestPickDetails'
      dataLoading={dataLoading}
      //  configViews={grids?.find?.((g) => g?.gridId === 1)?.configGroup || []}
      configViews={
        grids?.find?.((g: any) => g?.gridName === 'OPC_BESTPICK_DASHBOARD')
          ?.configGroup || []
      }
      dashboardName='OPC_BESTPICK_DASHBOARD'
      data={data}
      showRightDiv={location.pathname === '/opc/bestPick'}
      gridDef={grids}
    />
  );
};

export default DashboardRoot;
