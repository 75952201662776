/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { IDataTableColumn } from '../types/dataTable';
import DataTable from '../../common/components/DataTable';
import { useTranslation } from 'react-i18next';
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toggleCarrierHaulage } from './OblDetailSlice';
import { getOBLDetails } from '../../features/dashboard/actions/instruction';
import OdexSpinner from './OdexSpinner';
import {
  updateInstructionID,
  updateSelectedEventIdDH,
} from '../../features/blDetails/slices/documentHistorySlice';
import { AESDecrypt } from '../../../encrypt-util';
import { updateBlDetailsRowColumns } from '../../features/blDetails/slices/blDetailsTable';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import { updateSortStateDataTable } from './DataTable/slices/DataTable';
import { useBackNavigation } from '../../../hooks/BackNavigation';

const OblDetails = ({ blslider }: { blslider?: any }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const { blNum } = useParams();
  const navigate = useNavigate();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const onBack = useBackNavigation()
  const backNavUrl = useSelector(
    (state: RootState) => state.documentView.backNavUrl
  );

  const isLoading = useSelector(
    (state: RootState) => state.oblDetails.isOblDetailsLoading
  );

  const datatableState = useSelector(
    (state: RootState) => state.dashboard.currentGridName
  );

  const oblForm = useSelector((state: RootState) => state.oblDetails.oblForm);

  const selectedRouter = useSelector(
    (state: RootState) => state.documentHistory.selectedRouter
  );

  const vesselDetails = useSelector(
    (state: RootState) => state.oblDetails.vesselDetails
  );

  const containerDetails = useSelector(
    (state: RootState) => state.oblDetails.data
  );

  const dashboardData = useSelector((state: RootState) => state.dashboard.data);

  const selectedLinerId = useSelector(
    (state: RootState) => state.userProfile.selectedLinerId
  );

  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );

  useEffect(() => {
    if (blNum)
      dispatch(
        getOBLDetails({
          blNumber: AESDecrypt(blNum) || null,
          selectedLinerId:
            profileDetails.partnerType === 'liner'
              ? profileDetails?.partnerId
              : selectedLinerId,
        })
      );
  }, [blNum]);

  useEffect(() => {
    oblForm.haulage === 'Carrier Haulage'
      ? dispatch(toggleCarrierHaulage(true))
      : dispatch(toggleCarrierHaulage(false));
  }, [blNum]);
  useEffect(() => {
    if (containerDetails?.partyList?.length > 0) {
      setIsDataLoaded(true);
    }

    if (containerDetails?.blJourneyList?.length > 0) {
      setIsDataLoaded(true);
    }
  }, [containerDetails]);

  function extractValuesFromUrl(url: any) {
    const params = new URLSearchParams(url);
    const sortParam = params.get('sort');

    if (sortParam) {
      const arr: any = sortParam.split('%2C');
      return arr;
    } else {
      return [];
    }
  }

  const _onCloseForm = () => {

    dispatch(updateSelectedEventIdDH(null));
    
    if (selectedRouter === 'payment') {
      if (window.location.search) {
        const paramVal = extractValuesFromUrl(window.location.search);
        const splitedval = paramVal[0].split(',');
        setTimeout(() => {
          dispatch(
            updateSortStateDataTable({
              key: splitedval[0],
              value: splitedval[1],
            })
          );
        }, 100);
      }
    } else {
      setTimeout(() => {
        if (datatableState === 'INSTRUCTION_DASHBOARD') {
          dispatch(updateInstructionID(blNum));
        }
      }, 1000);
    }
    onBack()
  };

  const columns: IDataTableColumn[] = [
    {
      accessor: 'vesselType',
      Header: t('resource_1:type'),
      minWidth: 100,
      width: 200,
      maxWidth: 120,
      Cell: (rowData: any) => {
        const { vesselType } = rowData.row.original;

        return <b>{vesselType}</b>;
      },
    },
    {
      accessor: 'ExternalVoyage',
      Header: t('resource_2:externalVoyage'),
      minWidth: 200,
      width: 200,
      maxWidth: 280,
    },
    {
      accessor: 'InternalVoyage',
      Header: t('resource_2:internalVoyage'),
      minWidth: 200,
      width: 200,
      maxWidth: 280,
    },

    {
      accessor: 'VesselName',
      Header: t('resource_2:vesselname'),
      minWidth: 200,
      width: 200,
      maxWidth: 280,
    },
  ];

  const blJourneyListColumns: IDataTableColumn[] = [
    {
      accessor: 'type',
      Header: t('resource_1:type'),
      minWidth: 80,
      width: 80,
      maxWidth: 100,
    },
    {
      accessor: 'externalVoyageNo',
      Header: t('resource_2:externalVoyage'),
      minWidth: 130,
      width: 130,
      maxWidth: 200,
    },
    {
      accessor: 'internalVoyageNo',
      Header: t('resource_2:internalVoyage'),
      minWidth: 130,
      width: 130,
      maxWidth: 200,
    },

    {
      accessor: 'vesselName',
      Header: t('resource_2:vesselname'),
      minWidth: 140,
      width: 140,
      maxWidth: 280,
    },
    {
      accessor: 'pol',
      Header: t('resource_1:pol'),
      minWidth: 70,
      width: 70,
      maxWidth: 100,
    },
    {
      accessor: 'pod',
      Header: t('resource_1:pod'),
      minWidth: 70,
      width: 70,
      maxWidth: 100,
    },
    {
      accessor: 'eta',
      Header: t('resource_1:etac'),
      minWidth: 100,
      width: 100,
      maxWidth: 150,
    },
    {
      accessor: 'etd',
      Header: t('resource_1:etd'),
      minWidth: 100,
      width: 100,
      maxWidth: 150,
    },
  ];

  const contactDetailsColumns: IDataTableColumn[] = [
    {
      accessor: 'containerNo',
      Header: t('resource_2:containerNo'),
      columnFilterType: 'search',
      minWidth: 120,
      width: 200,
      maxWidth: 150,
    },
    {
      accessor: 'type',
      Header: t('resource_1:type'),
      columnFilterType: 'search',
      minWidth: 200,
      width: 300,
      maxWidth: 150,
    },
    {
      accessor: 'size',
      Header: t('resource_1:size'),
      columnFilterType: 'search',
      minWidth: 200,
      width: 200,
      maxWidth: 300,
    },
    {
      accessor: 'trucker',
      Header: t('resource_2:truckerCode'),
      columnFilterType: 'search',
      minWidth: 250,
      width: 200,
      maxWidth: 250,
    },
    {
      accessor: 'releaseDate',
      Header: t('resource_2:releaseDate'),
      columnFilterType: 'search',
      minWidth: 120,
      width: 150,
      maxWidth: 150,
      Cell: (rowData: any) => {
        const { releaseDate } = rowData.row.original;

        // return new Date(time).toLocaleString();
        if (releaseDate !== null)
          return new Date(releaseDate).toLocaleDateString();
      },
    },
    {
      accessor: 'releaseStatus',
      columnFilterType: 'search',
      Header: t('resource_2:releaseStatus'),
      minWidth: 120,
      width: 160,
      maxWidth: 150,
    },
    {
      accessor: 'lastFreeDate',
      columnFilterType: 'search',
      Header: t('resource_3:lastFreeDate'),
      minWidth: 120,
      width: 160,
      maxWidth: 150,
      Cell: (rowData: any) => {
        const { lastFreeDate } = rowData.row.original;

        // return new Date(time).toLocaleString();
        if (lastFreeDate !== null)
          return new Date(lastFreeDate).toLocaleDateString();
      },
    },

    {
      accessor: 'releasePin',
      columnFilterType: 'search',
      Header: t('resource_3:pickUpNumber'),
      minWidth: 160,
      width: 200,
      maxWidth: 180,
    },
    {
      accessor: 'releaseLoc',
      columnFilterType: 'search',
      Header: t('resource_2:releaseLoc'),
      minWidth: 160,
      width: 200,
      maxWidth: 180,
    },
  ];

  const blPartyDetailsColumns: IDataTableColumn[] = [
    {
      accessor: 'partyType',
      Header: t('resource_2:partyType'),
      minWidth: 120,
      width: 200,
      maxWidth: 150,
    },
    {
      accessor: 'partyName',
      Header: t('resource_2:partyName'),
      minWidth: 120,
      width: 400,
      maxWidth: 600,
    },
    {
      accessor: 'internalCode',
      Header: t('resource_2:internalCode'),
      minWidth: 120,
      width: 150,
      maxWidth: 600,
    },
    {
      accessor: 'customerCode',
      Header: t('resource_2:customerCode'),
      minWidth: 120,
      width: 120,
      maxWidth: 600,
    },
  ];

  return (
    <Card style={{ height: '100%', overflow: 'auto' }}>
      {blNum && (
        <Card.Header className='d-flex justify-content-between align-items-center border-bottom p-3 sticky-top bg-white'>
          <FontAwesomeIcon
            style={{ position: 'absolute', left: '1rem' }}
            icon={faArrowAltCircleLeft}
            onClick={_onCloseForm}
            className='cursor-pointer fa-lg'
          />
          <h2 className='fs-0 text-primary mb-0 ms-4'>
            {' '}
            {t('resource_3:BillofLadingInformation')}
          </h2>
        </Card.Header>
      )}
      <Card.Body className='p-0'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 mb-3'>
              <Form.Group controlId='blReqIns.companyName'>
                <Form.Label className='fs--1'>
                  {' '}
                  {t('resource_2:blNumber')}
                </Form.Label>
                <Form.Control
                  size='sm'
                  //   placeholder={t('resource_2:blNumber')}
                  disabled
                  value={oblForm?.number || ''}
                />
              </Form.Group>
            </div>
            <div className='col-lg-4 mb-3'>
              <Form.Group controlId='blReqIns.companyName'>
                <Form.Label className='fs--1'>
                  {t('resource_2:shipmentNo')}
                </Form.Label>
                <Form.Control
                  size='sm'
                  //   placeholder={t('resource_2:shipmentNo')}
                  disabled
                  value={oblForm?.shipmentNo || ''}
                />
              </Form.Group>
            </div>
            <div className='col-lg-4 mb-3'>
              <Form.Group controlId='blReqIns.companyName'>
                <Form.Label className='fs--1'>
                  {t('resource_2:blType')}
                </Form.Label>
                <Form.Control
                  size='sm'
                  //   placeholder={t('resource_2:blType')}
                  disabled
                  value={oblForm?.blType || ''}
                />
              </Form.Group>
            </div>
            <div className='col-lg-3 mb-3'>
              <Form.Group controlId='blReqIns.companyName'>
                <Form.Label className='fs--1'>
                  {t('resource_2:impHaulage')}
                </Form.Label>
                <Form.Control
                  size='sm'
                  //   placeholder={t('resource_1:etd')}
                  disabled
                  value={oblForm?.haulage || ''}
                />
              </Form.Group>
            </div>
            <div className='col-lg-3 mb-3'>
              <Form.Group controlId='blReqIns.companyName'>
                <Form.Label className='fs--1'>
                  {t('resource_2:movementType')}
                </Form.Label>
                <Form.Control
                  size='sm'
                  //   placeholder={t('resource_1:etac')}
                  disabled
                  value={oblForm?.movementType || ''}
                />
              </Form.Group>
            </div>

            <div className='col-lg-3 mb-3'>
              <Form.Group controlId='blReqIns.companyName'>
                <Form.Label className='fs--1'>{t('resource_1:etd')}</Form.Label>
                <Form.Control
                  size='sm'
                  //   placeholder={t('resource_1:etac')}
                  disabled
                  value={oblForm?.etd || ''}
                />
              </Form.Group>
            </div>

            <div className='col-lg-3 mb-3'>
              <Form.Group controlId='blReqIns.companyName'>
                <Form.Label className='fs--1'>
                  {t('resource_1:etac')}
                </Form.Label>
                <Form.Control
                  size='sm'
                  //   placeholder={t('resource_1:etac')}
                  disabled
                  value={oblForm?.eta || ''}
                />
              </Form.Group>
            </div>

            <div className='col-lg-12'>
              <div className='row'>
                <div className='col-lg-3 mb-3'>
                  <Form.Group controlId='blReqIns.companyName'>
                    <Form.Label className='fs--1'>
                      {t('resource_2:startPOL')}
                    </Form.Label>
                    <Form.Control
                      size='sm'
                      //   placeholder={t('resource_1:spod')}
                      disabled
                      value={oblForm?.startPol || ''}
                    />
                  </Form.Group>
                </div>
                <div className='col-lg-3 mb-3'>
                  <Form.Group controlId='blReqIns.companyName'>
                    <Form.Label className='fs--1'>
                      {t('resource_1:pol')}
                    </Form.Label>
                    <Form.Control
                      size='sm'
                      // placeholder={t('resource_1:pol')}
                      disabled
                      value={oblForm?.pol || ''}
                    />
                  </Form.Group>
                </div>
                <div className='col-lg-3 mb-3'>
                  <Form.Group controlId='blReqIns.companyName'>
                    <Form.Label className='fs--1'>
                      {t('resource_1:pod')}
                    </Form.Label>
                    <Form.Control
                      size='sm'
                      //   placeholder={t('resource_1:pod')}
                      disabled
                      value={oblForm?.pod || ''}
                    />
                  </Form.Group>
                </div>
                <div className='col-lg-3 mb-3'>
                  <Form.Group controlId='blReqIns.companyName'>
                    <Form.Label className='fs--1'>
                      {t('resource_2:fPODend')}
                    </Form.Label>
                    <Form.Control
                      size='sm'
                      //    placeholder={t('resource_1:fpod')}
                      disabled
                      value={oblForm?.fpod || ''}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </div>

        {containerDetails.partyList.length > 0 &&
        oblForm?.isAgentUser?.toUpperCase() !== 'Y' &&
        isDataLoaded ? (
          <div className='container'>
            <div className='mt-1 d-flex flex-column justify-content-start align-items-start'>
              <h2 className='fs-0 mb-2 ms-0'>
                {' '}
                {t('resource_2:partyDetails')}
              </h2>
              {isLoading && false ? (
                <div className='d-flex justify-content-center align-items-center w-100 p-2'>
                  <OdexSpinner />
                </div>
              ) : (
                <DataTable
                  data={/* isLoading ? [] :  */ containerDetails.partyList}
                  columns={blPartyDetailsColumns}
                  gridName='PARTY_LIST'
                  sortable={true}
                  searchable={false}
                  showColumnFilter={false}
                  selection={false}
                  perPage={10}
                  pagination={false}
                  showExport={false}
                  uniqueFilterKey='oblDetails2'
                  autoHeight={true}
                  staticHiddenCols={
                    profileDetails?.partnerType === 'liner'
                      ? []
                      : ['internalCode', 'customerCode']
                  }
                />
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className='container'>
          <div className='mt-1 d-flex flex-column justify-content-start align-items-start'>
            <h2 className='fs-0 mb-2 ms-0'> {t('resource_2:vesselDetails')}</h2>

            {isLoading ? (
              <div className='d-flex justify-content-center align-items-center w-100 p-2'>
                <OdexSpinner />
              </div>
            ) : (
              <DataTable
                data={[1, 2, 3].map((i) => ({
                  vesselType: i === 1 ? 'Pre' : i === 2 ? 'Main' : 'On',
                  ExternalVoyage:
                    i === 1
                      ? vesselDetails?.preExternalVoyage
                      : i === 2
                      ? vesselDetails?.mainExternalVoyage
                      : vesselDetails?.onExternalVoyage,
                  InternalVoyage:
                    i === 1
                      ? vesselDetails?.preInternalVoyage
                      : i === 2
                      ? vesselDetails?.mainInternalVoyage
                      : vesselDetails?.onInternalVoyage,

                  VesselName:
                    i === 1
                      ? vesselDetails?.preVesselName
                      : i === 2
                      ? vesselDetails?.mainVesselName
                      : vesselDetails?.onVesselName,
                }))}
                columns={columns}
                gridName='VESSEL'
                sortable={true}
                searchable={false}
                showColumnFilter={false}
                selection={false}
                perPage={10}
                pagination={false}
                showExport={false}
                uniqueFilterKey='oblDetails'
                autoHeight={true}
                tableMinHeight={150}
              />
            )}
          </div>
        </div>
        <div className='container'>
          <div className='mt-1 d-flex flex-column justify-content-start align-items-start'>
            {isLoading ? (
              <div className='d-flex justify-content-center align-items-center w-100 p-2'>
                <OdexSpinner />
              </div>
            ) : (
              containerDetails?.blJourneyList && false && (
                <>
                  {' '}
                  <h2 className='fs-0 mb-2 ms-0'>
                    {t('resource_3:bLJourneyDetails')}
                  </h2>
                  <DataTable
                    data={containerDetails.blJourneyList}
                    columns={blJourneyListColumns}
                    gridName='BLJOURNEYLiST'
                    sortable={true}
                    searchable={false}
                    showColumnFilter={false}
                    selection={false}
                    perPage={10}
                    pagination={false}
                    showExport={false}
                    uniqueFilterKey='oblDetails3'
                    autoHeight={true}
                  />
                </>
              )
            )}
          </div>
        </div>
        <div className='container'>
          <div className='mt-1 d-flex flex-column justify-content-start align-items-start containerDetails '>
            <h2 className='fs-0 mb-2 ms-0'>Container Details</h2>

            {isLoading ? (
              <div className='d-flex justify-content-center align-items-center w-100 p-2'>
                <OdexSpinner />
              </div>
            ) : (
              <DataTable
                data={isLoading ? [] : containerDetails.list}
                columns={contactDetailsColumns}
                gridName='CONTACT_DETAILS'
                sortable={true}
                searchable={false}
                showColumnFilter={false}
                localFilter={true}
                selection={false}
                perPage={10}
                pagination={false}
                showExport={false}
                uniqueFilterKey='oblDetails2'
                autoHeight={true}
                staticHiddenCols={
                  oblForm?.haulage === t('resource_2:carrierHaulage')
                    ? ['releasePin']
                    : []
                }
              />
            )}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default OblDetails;
