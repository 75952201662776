import { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import is from "is_js";
import MainLayout from "./MainLayout";
import SettingsPanel from "../components/settings-panel/SettingsPanel";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { CloseButton } from "../components/common/Toast";

import AppContext from "../context/Context";
import Landing from "../app/components/pages/landing/Landing";

import ErrorLayout from "./ErrorLayout";
import Error404 from "../components/errors/Error404";
import Error500 from "../components/errors/Error500";

import BlDetails from "../app/features/blDetails/BlDetails";
import BlPayment from "../app/features/blPayment/BlPayment";
import AuthCardLayout from "./AuthCardLayout";
import Registration from "../app/features/registration/Registration";
import MainTableRoe from "../app/features/dashboard/components/roeDashboard/MainTable";
import DocumentView from "../app/common/components/DocumentView";
import StandingInstruction from "../app/features/standingInstruction/StandingInstruction";
import PaymentDashboard from "../app/features/paymentdashboard/PaymentDashboard";

import { useLocation } from "react-router-dom";
import queryString from "query-string";
import ResetPassword from "../app/features/registration/ResetPassword";
import ForgotPassword from "../app/features/registration/ForgotPassword";
import LoginForm from "../app/features/registration/LoginForm";
import ProfileAccount from "../app/features/profile/ProfileAccount";
import { useSelector } from "react-redux";
import AccountDtls from "../app/features/profile/AccountDtls";
import UserDetails from "../app/features/createUser/UserDetails";
import User from "../app/features/createUser/User";
import CustomerMappingDtls from "../app/features/profile/CustomerMappingDtls";
import InstructionDtls from "../app/features/instructionDashboard/instructionDetails";
import PaymentFund from "../app/features/paymentFund/PaymentFund";
import ConsumerNominateAgent from "../app/features/dashboard/components/ConsumerNominateAgent";
import ConsigneeNominateAgent from "../app/features/dashboard/components/ConsigneeNominateAgent";
import DocumentHistory from "../app/features/blDetails/DocumentHistory";
import RegistrationMasterDetails from "../app/features/registrationMaster/registrationMasterDetails";
import BlanketDelegation from "../app/features/dashboard/components/BlanketDelegation";
import InstructionMapper from "../components/common/InstructionMapper";
import RegistrationDetails from "../app/features/registrationMaster/components/registrationDetails";
import { AESDecrypt } from "../encrypt-util";

import ReportDashboard from "../app/features/reportDashboard/ReportDashboard";
import Snooze from "../app/features/blRequestInstruction/components/Snooze";
import QuickPay from "../app/features/quickpay/QuickPay";
import QuickPayment from "../app/features/quickpay/PaymentQuickPay";
import InvoiceRequestInstruction from "../app/features/dashboard/components/InvoiceRequestInsturction";
import PermitRequest from "../app/features/blRequestInstruction/components/PermitRequest";
import DeliveryOrderInstruction from "../app/features/dashboard/components/deliveryOrderInstruction";
import DoMaster from "../app/features/doMaster/DoMaster";

import Commonapp from "../app/components/Commonapp";
import MainTable from "../app/features/doMaster/MainTable";
import DashboardRoot from "../app/features/doMaster/DashboardRoot";
import InstructionDetails from "../app/features/instructionDetails/components/InstructionDetails";
import DashboardContainer from "../components/common/DashboardContainer";
import InvoiceDtls from "../app/features/invoiceDashboard/invoiceDetails";
import PayableAmount from "../app/features/blPayment/PayableAmount";
import PermitTrackingDtls from "../app/features/permit TrackingDashboard/permitTrackingDetails";
import OpcUserDetails from "../app/features/opc-users/registrationMasterDetails";
import AuthCardLayoutNew from "./AuthCardLayoutNew";
import OpcVesselMaster from "../app/features/opc-vesselMaster/registrationMasterDetails";
import VesselMaster from "../app/features/vesselMaster/VesselMaster";
import SSRRequestInstruction from "../app/features/dashboard/components/ssrReqestInstruction";
import InvoiceRequestDocList from "../app/features/dashboard/components/InvoiceRequestDocList";
import SendQrDetails from "../app/features/blRequestInstruction/components/SendQrDetails";
import OpcBestPickDetails from "../app/features/opc-bestPick/bestPickDetails";
import BestPick from "../app/features/createBestPick/BestPick";
import EnquiryDashboard from "../app/features/EnquiryDashboard/InvoicedBlsDashboard";
import EnquiryDashboardForContainer from "../app/features/EnquiryDashboard/EnquiryDashboardForContainer";
import InvoicedBlsSlider from "../app/features/EnquiryDashboard/InvoicedBlsSlider";

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName("html")[0].classList;
  const profileDetails = useSelector((state) => state.userProfile.profile);
  const gridName = useSelector(
    (state) => state.navbarTop.selectedDashbaordName
  );
  useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add("windows");
    }
    if (is.chrome()) {
      HTMLClassList.add("chrome");
    }
    if (is.firefox()) {
      HTMLClassList.add("firefox");
    }
  }, [HTMLClassList]);

  const location = useLocation();

  useEffect(() => {
    const query = queryString.parse(location.search);
    if (query?.setActiveUser) {
      window.localStorage.setItem("@odex/activeuser", query.setActiveUser);
      window.location.href = "opc/home";
    }
  }, []);

  const parts = location.pathname.split("/");
  const page = parts[parts.length - 1];

  const decpage = AESDecrypt(page);

  return (
    <>
      <Routes>
        <>
          <Route path="/" element={<Landing />} />

          <Route element={<AuthCardLayout />}>
            <Route path="login" element={<LoginForm />} />
          </Route>
          <Route path="/app" element={<Commonapp />} />
          <Route element={<AuthCardLayoutNew />}>
            <Route path="register" element={<Registration />} />
          </Route>
          <Route path="resetpassword" element={<ResetPassword />} />
          <Route path="forgotPassword" element={<ForgotPassword />} />
          <Route path="quickPay" element={<QuickPay />}>
            <Route path="pay" element={<QuickPayment />} />
          </Route>

          <Route
            path="opc/InvoiceReqDoclist/:invReqId"
            element={<InvoiceRequestDocList />}
          />

          <Route element={<MainLayout />}>
            {/* </Route> */}
            <Route path="opc/user/profile" element={<ProfileAccount />}>
              <Route path="details/:accountId" element={<AccountDtls />} />
              <Route
                path="custMapDetails/:custMapId"
                element={<CustomerMappingDtls />}
              />
            </Route>
            <Route path="opc/payment-fund" element={<PaymentFund />} />
            <Route path="opc/paymentDashboard" element={<PaymentDashboard />} />
            <Route
              path="opc/doupload"
              element={
                <DashboardContainer>
                  <MainTable />
                </DashboardContainer>
              }
            >
              <Route path="details" element={<DeliveryOrderInstruction />} />
              <Route path=":userID" element={<DeliveryOrderInstruction />} />
            </Route>
            <Route
              path="opc/roe"
              element={
                <DashboardContainer>
                  <MainTableRoe />
                </DashboardContainer>
              }
            >
              <Route path="details" element={<DeliveryOrderInstruction />} />
              <Route path=":userID" element={<DeliveryOrderInstruction />} />
            </Route>
            <Route path="/opc/users" element={<OpcUserDetails />}>
              <Route path="details/:userID" element={<User />} />
            </Route>

            <Route path="/opc/bestPick" element={<OpcBestPickDetails />}>
              <Route
                path="details/:best_pick_request_id"
                element={<BestPick />}
              />
            </Route>

            <Route path="/opc/vessel-master" element={<OpcVesselMaster />}>
              <Route path=":navisKey" element={<VesselMaster />} />
            </Route>
            <Route path="opc/home" element={<InvoiceDtls />} >
              <Route path="invoiced/:draft" element={<InvoicedBlsSlider/>}/>
            </Route>
            <Route path="opc/blinclusions" element={<EnquiryDashboard />} />
            <Route
              path="opc/enqContainer"
              element={<EnquiryDashboardForContainer />}
            />
            <Route path="blslider/:blNum" element={<BlDetails />} />
            <Route path="blsliderpayment/:blNum" element={<PaymentDashboard />}>
              <Route index element={<BlPayment />} />
            </Route>
            <Route path="report" element={<ReportDashboard />} />
            {profileDetails?.partnerType !== "liner" && (
              <Route path="multiBl/:blNum" element={<BlDetails />}>
                <Route
                  path="instruction/:encrypt"
                  element={<InstructionMapper />}
                />
              </Route>
            )}
            {/*Standing Dashboard*/}
            <Route path="standing" element={<StandingInstruction />}>
              <Route path=":encrypt" element={<BlanketDelegation />} />
              <Route
                path=":blNum/:encrypt/:encryptdoc"
                element={<BlanketDelegation />}
              />
            </Route>
            {/*Instruction Dashboard*/}
            <Route path="opc/instruction" element={<InstructionDtls />}>
              <Route path="snooze/:blEventId" element={<Snooze />}></Route>

              <Route
                path=":requesttp/:invoice_request_id"
                element={<InstructionMapper />}
              />
              <Route path=":requesttp" element={<InstructionMapper />} />

              <Route path="bl/:blNum">
                <Route path=":encrypt" element={<InstructionMapper />} />
                <Route
                  path=":encrypt/:encryptdoc"
                  element={<InstructionMapper />}
                />
                <Route path="snooze/:blEventId" element={<Snooze />}></Route>
                <Route path=":docType" element={<DocumentView />} />
                <Route path="payment/:blId" element={<BlPayment />} />
                <Route path="unpaid/payment" element={<BlPayment />} />
                {profileDetails?.partnerType !== "liner" && (
                  <Route path="instruction">
                    <Route path=":encrypt" element={<InstructionMapper />} />
                    <Route
                      path=":encrypt/:blId"
                      element={<InstructionMapper />}
                    />
                  </Route>
                )}
              </Route>
            </Route>
            {/*Invoice Dashboard*/}

            <Route path="opc/invoice" element={<InvoiceDtls />}>
              <Route path="snooze/:blEventId" element={<Snooze />}></Route>
              <Route path=":blNum" element={<DocumentHistory />} />
              <Route path="payment" element={<BlPayment />} />

              <Route
                path=":requesttp/:invoice_request_id"
                element={<InstructionMapper />}
              />
              {/*liner */}
              {decpage == "911" && (
                <Route path={page} element={<InvoiceRequestInstruction />} />
              )}
              {decpage == "903" && (
                <Route path={page} element={<SSRRequestInstruction />} />
              )}
              {decpage == "902" && (
                <Route path={page} element={<PermitRequest />} />
              )}
              {(decpage == "811" || decpage == "815") && (
                <Route path={page} element={<ConsumerNominateAgent />} />
              )}
              {/*customer*/}
              {(decpage == "812" || decpage == "813") && (
                <Route path={page} element={<ConsigneeNominateAgent />} />
              )}
              <Route path="bl/:blNum">
                <Route path=":encrypt" element={<InstructionMapper />} />
                <Route
                  path=":encrypt/:encryptdoc"
                  element={<InstructionMapper />}
                />
                <Route path="snooze/:blEventId" element={<Snooze />}></Route>
                <Route path=":docType" element={<DocumentView />} />
                <Route path="payment/:blId" element={<BlPayment />} />
                <Route path="unpaid/payment" element={<BlPayment />} />
                {profileDetails?.partnerType !== "liner" && (
                  <Route path="instruction">
                    <Route path=":encrypt" element={<InstructionMapper />} />
                    <Route
                      path=":encrypt/:blId"
                      element={<InstructionMapper />}
                    />
                  </Route>
                )}
              </Route>
            </Route>
            {/*Permit Tracking Dashboard*/}
            <Route path="opc/permit-tracking" element={<PermitTrackingDtls />}>
              <Route
                path="sendQRDetails/:permitNbr"
                element={<SendQrDetails />}
              ></Route>
              <Route path="snooze/:blEventId" element={<Snooze />}></Route>
            </Route>
            <Route path="opc/home" element={<InvoiceDtls />}>
              <Route path="snooze/:blEventId" element={<Snooze />}></Route>
              <Route path=":blNum" element={<DocumentHistory />} />
              <Route path="payment" element={<BlPayment />} />
              <Route
                path=":requesttp/:invoice_request_id"
                element={<InstructionMapper />}
              />
              {/*liner */}
              {decpage == "911" && (
                <Route path={page} element={<InvoiceRequestInstruction />} />
              )}
              {decpage == "902" && (
                <Route path={page} element={<PermitRequest />} />
              )}

              {(decpage == "811" || decpage == "815") && (
                <Route path={page} element={<ConsumerNominateAgent />} />
              )}
              {/*customer*/}
              {(decpage == "812" || decpage == "813") && (
                <Route path={page} element={<ConsigneeNominateAgent />} />
              )}
              <Route path="bl/:blNum">
                <Route path=":encrypt" element={<InstructionMapper />} />
                <Route
                  path=":encrypt/:encryptdoc"
                  element={<InstructionMapper />}
                />
                <Route path="snooze/:blEventId" element={<Snooze />}></Route>
                <Route path=":docType" element={<DocumentView />} />
                <Route path="payment/:blId" element={<BlPayment />} />
                <Route path="unpaid/payment" element={<BlPayment />} />
                {profileDetails?.partnerType !== "liner" && (
                  <Route path="instruction">
                    <Route path=":encrypt" element={<InstructionMapper />} />
                    <Route
                      path=":encrypt/:blId"
                      element={<InstructionMapper />}
                    />
                  </Route>
                )}
              </Route>
            </Route>
            {/*Resgistration Master Dashboard*/}
            <Route
              path="opc/registrationmaster"
              element={<RegistrationMasterDetails />}
            />
            <Route
              path="blsliderinstruction/:blNum"
              element={<InstructionDtls />}
            />
            <Route
              path="registrationmaster/:requestId"
              element={<RegistrationDetails />}
            />
            
            <Route path="bl/:blNum" element={<BlDetails />}>
              <Route path="payment/:blId" element={<BlPayment />} />
              <Route path=":encrypt" element={<InstructionMapper />} />

              <Route path="snooze/:blEventId" element={<Snooze />}></Route>

              <Route
                path=":encrypt/:encryptdoc"
                element={<InstructionMapper />}
              ></Route>
              <Route path=":docType" element={<DocumentView />} />
              <Route path="unpaid/payment" element={<BlPayment />} />
              {profileDetails?.partnerType !== "liner" && (
                <Route path="instruction">
                  <Route path=":encrypt" element={<InstructionMapper />} />
                  <Route
                    path=":encrypt/:blId"
                    element={<InstructionMapper />}
                  />
                </Route>
              )}
            </Route>
          </Route>

          {/* //--- MainLayout Starts  */}

          {/* //--- MainLayout end  */}

          <Route element={<ErrorLayout />}>
            <Route path="errors/404" element={<Error404 />} />
            <Route path="errors/500" element={<Error500 />} />
          </Route>
        </>
      </Routes>
      <SettingsPanel />
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.TOP_RIGHT}
      />
    </>
  );
};

export default Layout;
