import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
//todo bllist

import {
  getInvoiceList,
  getInvoiceListFilter,
} from '../dashboard/actions/dashboard';
import {
  addRegistrationDetails,
  addRegistrationDetailsCompany,
  fetchAddressSuggestions,
  fetchLinerNameByCountry,
} from './actions/Registration';

/* export interface RegistrationDetails {
  company_name: string;
  address_details:string;
  state: string;
  country_code: string;
  request_id:string;
  source:string;
  company_number:string;

} */
/* export interface RegistrationDetails {
  country: string;
   state: string;
   company_name:string
 
   reg_number: string;
   current_status:string;
   address:string;
 } */
export interface RegistrationDetails {
  filterCountries: string;
  text: string;
}
export interface IRegistrationList {
  list: RegistrationDetails[];
}

export interface IRegistrationAddressSuggesstion {
  text: string;
  placeId: string;
}

export interface RegistrationSlice {
  data: IRegistrationList;
  dataLoading: boolean;
  // showColumnSettings: boolean;
  registrationAddressSuggesstion: IRegistrationAddressSuggesstion[];
  selectedAddressResult: string;
  selectedPlaceId: string;
  isAddressSuggesstionsLoading: boolean;
  taxId: any;
  companyName: any;
  address: any;
  countryOptions: any;
  linerOptions: any;
}

const initialState: RegistrationSlice = {
  data: {
    list: [],
  },

  dataLoading: true,
  //howColumnSettings: false,

  selectedAddressResult: '',
  selectedPlaceId: '',
  isAddressSuggesstionsLoading: false,

  registrationAddressSuggesstion: [],
  taxId: {},
  companyName: {},
  address: {},
  countryOptions: [],
  linerOptions: [],
};

export const RegistrationReducer = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    updateSelectedAddressResult: (state, action: PayloadAction<any>) => {
      state.selectedAddressResult = action.payload.text;
      state.selectedPlaceId = action.payload.placeId;
    },
    updateTax: (state, action: PayloadAction<any>) => {
      state.taxId = action.payload;
    },
    updateCompanyName: (state, action: PayloadAction<any>) => {
      state.companyName = action.payload;
    },
    updateAddress: (state, action: PayloadAction<any>) => {
      state.address = action.payload;
    },
    updateCountryOptions: (state, action: PayloadAction<any>) => {
      state.countryOptions = action.payload;
    },
    updateLinerOptions: (state, action: PayloadAction<any>) => {
      state.linerOptions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addRegistrationDetails.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(addRegistrationDetails.fulfilled, (state: any, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchAddressSuggestions.pending, (state, action) => {
      state.isAddressSuggesstionsLoading = true;
    });

    builder.addCase(fetchAddressSuggestions.fulfilled, (state, action) => {
      state.isAddressSuggesstionsLoading = false;
      state.registrationAddressSuggesstion = action.payload;
    });
    builder.addCase(fetchLinerNameByCountry.pending, (state, action) => {
      state.dataLoading = true;
    });

    builder.addCase(fetchLinerNameByCountry.fulfilled, (state, action) => {
      state.dataLoading = false;
      //state.data = action.payload;
    });
  },
});

export const {
  updateSelectedAddressResult,
  updateTax,
  updateCompanyName,
  updateAddress,
  updateCountryOptions,
  updateLinerOptions,
} = RegistrationReducer.actions;

export default RegistrationReducer.reducer;
