/* eslint-disable react-hooks/exhaustive-deps */
import {
  faShip,
  faCreditCard,
  faPlus,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown } from 'react-bootstrap';
import { getBadges } from '../../features/dashboard/components/helpers';
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { getBlDetail } from '../../features/blDetails/data/blDetails';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  toggleDocumentHistoryColumnShort,
  updateInstructionID,
} from '../../features/blDetails/slices/documentHistorySlice';
import { useEffect, useMemo, useState } from 'react';
import {
  getOBLDetails,
  getPartyListByBlId,
  getUnpaidInvoice,
} from '../../features/dashboard/actions/instruction';
import { AESDecrypt, AESEncrypt } from '../../../encrypt-util';
import {
  updateSelectedDocIdDH,
  updateSelectedEventIdDH,
} from '../../features/blDetails/slices/documentHistorySlice';
import { updateSortStateDataTable } from './DataTable/slices/DataTable';
import { useBackNavigation } from '../../../hooks/BackNavigation';
import { toast } from 'react-toastify';
import useDataCountry from '../../../hooks/useDataCountry';

interface IProps {
  backNavRoute?: string;
  paymentStatus: any;
  instructionStatus?: any;
  releaseStatus: any;
}

const Header = ({
  paymentStatus,
  instructionStatus,
  releaseStatus,
  backNavRoute,
}: IProps) => {
  const location = useLocation();
  const goBack = useBackNavigation(false);
  const { blNum } = useParams();
  const oblLoading = useSelector(
    (state: RootState) => state.oblDetails.isOblDetailsLoading
  );
  const listLoading = useSelector(
    (state: RootState) => state.documentHistory.loadInstructList
  );
  const dashboard = (() => {
    if (
      matchPath('/instruction/:blnum', location.pathname) ||
      location.pathname.includes('instruction/bl')
    )
      return '/instruction';
    if (
      matchPath('/payment/:blnum', location.pathname) ||
      location.pathname.includes('payment/bl')
    )
      return '/payment';
    return '';
  })();
  useEffect(() => {
    if (
      matchPath(
        `${dashboard}/bl/:blNum/instruction/:document`,
        location.pathname
      )
    ) {
      dispatch(toggleDocumentHistoryColumnShort(false));
    }

    if (
      matchPath(`${dashboard}/bl/:blNum/instructionpayment`, location.pathname)
    ) {
      dispatch(toggleDocumentHistoryColumnShort(false));
    }
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { t } = useTranslation();
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );

  const partnerCountry = profileDetails?.partnerCountry;

  function extractValuesFromUrl(url: any) {
    const params = new URLSearchParams(url);
    const sortParam = params.get('sort');

    if (sortParam) {
      const arr: any = sortParam.split('%2C');
      return arr;
    } else {
      return [];
    }
  }

  const _onBackNav = () => {
    dispatch(updateSelectedEventIdDH(null));
    goBack();
    if (
      datatableState === 'INVOICE_DASHBOARD' &&
      location.pathname === `/payment/${encodeURIComponent(blNum || '')}` &&
      window.location.search
    ) {
      const paramVal = extractValuesFromUrl(window.location.search);
      const splitedval = paramVal[0].split(',');
      setTimeout(() => {
        dispatch(
          updateSortStateDataTable({
            key: splitedval[0],
            value: splitedval[1],
          })
        );
      }, 100);
    }
  };

  // const instructionTypes = useSelector(
  //   (state: RootState) => state.documentHistory.instructionTypes
  // );
  // const instructionTypes = [
  //   {value: 804, text: 'Permit Request', index: 0}
  // ]

  const showhaulage = useSelector(
    (state: RootState) => state.oblDetails.showHaulage
  );
  const tableData: any = useSelector(
    (state: RootState) => state.documentHistory.data
  );
  const blid = useMemo(() => tableData?.[0]?.blId, [tableData]);
  const oblForm = useSelector((state: RootState) => state.oblDetails.oblForm);
  const datacountry = useDataCountry();
  const isImport: boolean =
    // tableData.filter((v: any) => v?.invAccess !== null && v?.invAccess === 'Y')
    //   .length > 0 &&
    tableData.filter(
      (v: any) =>
        (v?.instructionId == 805 ||
          v?.instructionId == 812 ||
          v?.instructionId == 811) &&
        v?.docStatus == 'Y'
    ).length > 0;
  const isExport: boolean =
    tableData.filter((v: any) => v?.invAccess !== null && v?.invAccess === 'Y')
      .length > 0 &&
    tableData.filter(
      (v: any) =>
        (v?.instructionId == 814 ||
          v?.instructionId == 813 ||
          v?.instructionId == 815) &&
        v?.docStatus == 'Y'
    ).length > 0;
  // var dataCheck = { DOE: false, AD: true, IC: false };
  //const [dataCheckDOE, setDataCheckDOE] = useState(true);
  const [dataCheck, setDataCheck] = useState({
    DOE: false,
    AD: true,
    IC: false,
    DM: false,
    RI: false,
  });
  const [dataCheckDOE, setDataCheckDOE] = useState(false);
  const [dataCheckAD, setDataCheckAD] = useState(true);
  const [dataCheckADS, setDataCheckADS] = useState(true);
  const [dataCheckIC, setDataCheckIC] = useState(false);
  const [dataCheckDM, setDataCheckDM] = useState(false);
  const [dataCheckRI, setDataCheckRI] = useState(false);
  const [dataCheckPI, setdataCheckPI] = useState(true);
  const [instructionAllowed, setInstructionAllowed] = useState(false);
  const [isShipper, setIsShipper] = useState(false);
  const [isConsignee, setIsConsignee] = useState(false);
  const selectedLinerId = useSelector(
    (state: RootState) => state.userProfile.selectedLinerId
  );
  useEffect(() => {
    if (blNum) {
      setInstructionAllowed(false);
      dispatch(getPartyListByBlId(blNum));
      dispatch(
        getOBLDetails({
          blNumber: AESDecrypt(blNum) || null,
          selectedLinerId:
            profileDetails.partnerType === 'liner'
              ? profileDetails?.partnerId
              : selectedLinerId,
        })
      );
    }
  }, [blNum]);
  const blPartyList = useSelector(
    (state: RootState) => state.documentHistory.blPartyList
  );
  useEffect(() => {
    setIsShipper(false);
    setIsConsignee(false);
    blPartyList?.forEach((item: any) => {
      if (
        (parseInt(item.partyId) == profileDetails.partnerId &&
          item.partyType == 'CN') ||
        oblForm.isAgentUser === 'Y'
      ) {
        // dataCheck.AD = false;
        setDataCheck({ ...dataCheck, AD: false });

        setInstructionAllowed(true);
      }
      if (
        item?.partyId !== undefined &&
        parseInt(item?.partyId) == profileDetails.partnerId &&
        item.partyType == 'CZ' &&
        oblForm.pol?.substring(0, 2) === profileDetails.partnerCountry
      ) {
        setIsShipper(true);
      }
      if (
        item?.partyId !== undefined &&
        parseInt(item?.partyId) == profileDetails.partnerId &&
        item.partyType == 'CN' &&
        oblForm.pod?.substring(0, 2) === profileDetails.partnerCountry
      ) {
        setIsConsignee(true);
      }
    });
  }, [blPartyList, oblForm]);
  /*  blPartyList.forEach((item) => {

    if (
      item.partyId == profileDetails.partnerId &&
      (item.partyType == 'CZ' || item.partyType == 'CN')
    ) {
      // dataCheck.AD = false;
      // setDataCheck({ ...dataCheck, AD: false });
    }
  }); */

  useEffect(() => {
    const tableData801 = tableData.filter(
      (v: any) => v?.instructionId == 801 && v?.event === 'Submitted'
    );
    const tableData805 = tableData.filter(
      (v: any) =>
        v?.instructionId == 805 &&
        v?.event === 'Completed' &&
        v?.docStatus == 'Y'
    );

    const tableData814 = tableData.filter(
      (v: any) =>
        v?.instructionId == 814 &&
        v?.event === 'Completed' &&
        v?.docStatus == 'Y'
    );
    const tableData802 = tableData.filter(
      (v: any) => v?.instructionId == 802 && v?.event === 'Submitted'
    );
    const tableData804 = tableData.filter((v: any) => v?.instructionId == 804);
    const tableData806 = tableData.filter((v: any) => v?.instructionId == 806);

    const tableData808 = tableData.filter((v: any) => v?.instructionId == 808);
    //tableData?.forEach((item) => {
    if (tableData801?.[0]?.docStatus === null) {
      // dataCheck.DOE = true;
      //setDataCheck({ ...dataCheck, DOE: true });
      setDataCheckDOE(true);

      setDataCheckDOE(true);
    } else if (
      // tableData801?.[0].instructionId === '801' /* item.event === 'Submitted' ||
      //   item.event === 'Completed' || */ &&
      tableData801?.[0]?.docStatus !== null ||
      !tableData801
    ) {
      // dataCheck.DOE = false;
      //setDataCheck({ ...dataCheck, DOE: false });
      setDataCheckDOE(false);
      // setDataCheckDOE(false);
    } else if (
      // tableData801?.[0].instructionId === '801' /* item.event === 'Submitted' ||
      //   item.event === 'Completed' || */ &&
      tableData804?.[0]?.docStatus !== null
    ) {
      // dataCheck.DOE = false;
      setDataCheck({ ...dataCheck, DM: false });
      // setDataCheckDOE(false);
    } else if (
      // tableData801?.[0].instructionId === '801' /* item.event === 'Submitted' ||
      //   item.event === 'Completed' || */ &&
      tableData806?.[0]?.docStatus !== null
    ) {
      // dataCheck.DOE = false;
      setDataCheck({ ...dataCheck, RI: false });
      // setDataCheckDOE(false);
    }

    if (
      //  tableData805?.[0].instructionId === '805' /* item.event === 'Submitted' ||
      //  item.event === 'Completed' || */ &&
      // item.docStatus === null
      tableData805?.[0]?.docStatus === null ||
      (tableData805?.[0]?.docStatus === 'Y' &&
        tableData805?.[0]?.partnerId === profileDetails.partnerId) ||
      oblForm?.isAgentUser === 'Y'
    ) {
      // dataCheck.AD = true;
      // setDataCheck({ ...dataCheck, AD: true });
      setDataCheckAD(true);
      // setDataCheckDOE(true);
    } else if (
      // tableData801?.[0].instructionId === '801' /* item.event === 'Submitted' ||
      // item.event === 'Completed' || */ &&
      tableData805?.[0]?.docStatus !== null ||
      !tableData805 ||
      tableData805?.[0]?.docStatus !== 'Y' ||
      (tableData805?.[0]?.partnerId !== profileDetails.partnerId &&
        oblForm?.isAgentUser !== 'Y')
    ) {
      //  dataCheck.AD = false;
      // setDataCheck({ ...dataCheck, AD: false });
      setDataCheckAD(false);
      // setDataCheckDOE(false);
    }
    if (
      //  tableData805?.[0].instructionId === '805' /* item.event === 'Submitted' ||
      //  item.event === 'Completed' || */ &&
      // item.docStatus === null
      tableData814?.[0]?.docStatus === null ||
      (tableData814?.[0]?.docStatus === 'Y' &&
        tableData814?.[0]?.partnerId === profileDetails.partnerId) ||
      oblForm?.isAgentUser === 'Y'
    ) {
      // dataCheck.AD = true;
      // setDataCheck({ ...dataCheck, AD: true });
      setDataCheckADS(true);
      // setDataCheckDOE(true);
    } else if (
      // tableData801?.[0].instructionId === '801' /* item.event === 'Submitted' ||
      // item.event === 'Completed' || */ &&
      tableData814?.[0]?.docStatus !== null ||
      !tableData814 ||
      tableData814?.[0]?.docStatus !== 'Y' ||
      (tableData814?.[0]?.partnerId !== profileDetails.partnerId &&
        oblForm?.isAgentUser !== 'Y')
    ) {
      //  dataCheck.AD = false;
      // setDataCheck({ ...dataCheck, AD: false });
      setDataCheckADS(false);
      // setDataCheckDOE(false);
    }
    if (
      tableData808?.[0]?.docStatus === null ||
      tableData808?.[0]?.event === 'Submitted'
    ) {
      setdataCheckPI(true);
    } else if (
      tableData808?.[0]?.docStatus !== null ||
      tableData808?.[0]?.docStatus === 'Y' ||
      tableData808?.[0]?.event === 'Submitted' ||
      !tableData808
    ) {
      setdataCheckPI(false);
    }

    if (
      // tableData802?.[0].instructionId === '802' /* item.event === 'Submitted' ||
      //  item.event === 'Completed' || */ &&
      // item.docStatus === null
      tableData802?.[0]?.docStatus === null
    ) {
      // dataCheck.IC = true;
      //setDataCheck({ ...dataCheck, IC: true });
      setDataCheckIC(true);
      // setDataCheckDOE(true);
    } else if (
      // tableData804?.[0].instructionId === '801' /* item.event === 'Submitted' ||
      // item.event === 'Completed' || */ &&
      tableData802?.[0]?.docStatus !== null ||
      !tableData802
    ) {
      //  dataCheck.IC = false;
      //  setDataCheck({ ...dataCheck, IC: false });
      setDataCheckIC(false);
      // setDataCheckDOE(false);
    }
    if (
      // tableData802?.[0].instructionId === '802' /* item.event === 'Submitted' ||
      //  item.event === 'Completed' || */ &&
      // item.docStatus === null
      tableData804?.[0]?.docStatus === null ||
      tableData804?.[0]?.docStatus === ' '
    ) {
      // dataCheck.IC = true;
      //setDataCheck({ ...dataCheck, IC: true });
      setDataCheckDM(true);
      // setDataCheckDOE(true);
    } else if (
      // tableData804?.[0].instructionId === '801' /* item.event === 'Submitted' ||
      // item.event === 'Completed' || */ &&
      tableData804?.[0]?.docStatus !== null ||
      !tableData804 ||
      tableData804?.[0]?.docStatus !== ' '
    ) {
      //  dataCheck.IC = false;
      //  setDataCheck({ ...dataCheck, IC: false });
      setDataCheckDM(false);
      // setDataCheckDOE(false);
    }

    if (
      // tableData802?.[0].instructionId === '802' /* item.event === 'Submitted' ||
      //  item.event === 'Completed' || */ &&
      // item.docStatus === null
      tableData806?.[0]?.docStatus === null ||
      tableData806?.[0]?.docStatus === ' '
    ) {
      // dataCheck.IC = true;
      //setDataCheck({ ...dataCheck, IC: true });
      setDataCheckRI(true);
      // setDataCheckDOE(true);
    } else if (
      // tableData804?.[0].instructionId === '801' /* item.event === 'Submitted' ||
      // item.event === 'Completed' || */ &&
      tableData806?.[0]?.docStatus !== null ||
      !tableData806 ||
      tableData806?.[0]?.docStatus !== ' '
    ) {
      //  dataCheck.IC = false;
      //  setDataCheck({ ...dataCheck, IC: false });
      setDataCheckRI(false);
      // setDataCheckDOE(false);
    }

    /*  if (
      item.instructionId == 805 &&
      (item.event === 'Submitted' || item.event === 'Completed')
    ) {
      dataCheck.AD = true;
    } */
    //});
  }, [tableData]);

  const data = useSelector((state: RootState) => state.documentHistory.data) || [];
  const datatableState = useSelector(
    (state: RootState) => state.dashboard.currentGridName
  );

  const [disablePayment, setDisablePayment] = useState(true);
  const rbac = useSelector((state: RootState) => state.userProfile);

  useEffect(() => {
    data.map((i: any) => {
      if (
        (i.instructionId == 780 ||
          i.instructionId == 102 ||
          i.instructionId == 103 ||
          i.instructionId == 381) &&
        i.docStatus !== 'Y' &&
        disablePayment &&
        rbac.rbacDetails.createUpdatePayment == 'Y'
      ) {
        setDisablePayment(false);
      } else if (
        (i.instructionId == 780 ||
          i.instructionId == 102 ||
          i.instructionId == 103 ||
          i.instructionId == 381) &&
        i.docStatus === 'Y' &&
        !disablePayment
      ) {
        setDisablePayment(true);
      }
    });
  }, [data]);

  // const instructionItems = instructionTypes.map((option: any) => {
  //   let completed = false;
  //   //these instructions will be disabled on dropdown if submitted or completed
  //   const oneTimeInstructions = [805, 814, 808];

  //   if (oneTimeInstructions.some((inst) => inst === option.value)) {
  //     completed =
  //       tableData?.find((data:any) => data.document === option.text)?.event ===
  //       'Completed';
  //     completed =
  //       completed ||
  //       tableData?.find((data:any) => data.document === option.text)?.event ===
  //         'Submitted';
  //   }

  //   const disablePortCrr = [
  //     ['MYBTU', 'MY'],
  //     ['MYKCH', 'MY'],
  //     ['MYBKI', 'MY'],
  //   ].some(([port, country]) => {
  //     return oblForm?.pod === port && partnerCountry === country;
  //   });

  //   const releaseDraft = tableData?.find((data:any) => (
  //     data.event === 'Draft' ||
  //     data.event == '308'
  //   ) && data.instructionId == option.value)

  //   return (
  //     <div>
  //       {option.index !== 0 ? <Dropdown.Divider /> : <></>}
  //       <Dropdown.Item
  //         onClick={async () => {
  //           dispatch(updateSelectedDocIdDH(''));
  //           dispatch(updateSelectedEventIdDH(''));
  //           dispatch(updateInstructionID(option.value));
  //           if (
  //             option.value == 804 &&
  //             (rbac.rbacDetails.crrUnpaidHardStop == 'Y'
  //             || rbac.rbacDetails.crrUnpaidSoftStop == 'Y')){
  //             dispatch(clearUnpaidData())
  //             if(await dispatch(getUnpaidInvoice({blid, country: datacountry})).unwrap().then(
  //               (data)=>{
  //                 if(data && data.length > 0){
  //                   return true
  //                 }
  //               }
  //             )){
  //               navigate(`${dashboard}/bl/${encodeURIComponent(
  //                 blNum || ''
  //               )}/unpaid`)
  //               return
  //             }
  //           }

  //           navigate(
  //             `${dashboard}/bl/${encodeURIComponent(
  //               blNum || ''
  //             )}/instruction/${AESEncrypt(option.value)}${
  //               location?.search || ''
  //             }`
  //           );
  //         }}
  //         disabled={
  //           instructionAllowed
  //             ? completed
  //               ? true
  //               : (option.value == 801 &&
  //                   (showhaulage == true ||
  //                     rbac.rbacDetails.deliveryCUD != 'Y' ||
  //                     dataCheckDOE == true ||
  //                     (!isImport && oblForm?.isAgentUser === 'Y'))) ||
  //                 (!isConsignee && oblForm?.isAgentUser !== 'Y')
  //               ? true
  //               : /*  : option.value == 801 &&
  //               (dataCheckDOE == true || rbac.rbacDetails.deliveryCUD != 'Y')
  //             ? true */
  //               (option.value == 802 &&
  //                   (dataCheckIC == true ||
  //                     rbac.rbacDetails.invoiceChangeCUD != 'Y' ||
  //                     (!isImport && oblForm?.isAgentUser === 'Y'))) ||
  //                 (!isConsignee && oblForm?.isAgentUser !== 'Y')
  //               ? true
  //               : option.value == 805 &&
  //                 (dataCheckAD == true ||
  //                   !isConsignee ||
  //                   rbac.rbacDetails.nominactionCUD != 'Y')
  //               ? true
  //               : option.value == 814 &&
  //                 (dataCheckADS == true ||
  //                   !isShipper ||
  //                   rbac.rbacDetails.nominactionCUD != 'Y')
  //               ? true
  //               : (option.value == 804 &&
  //                   /* dataCheckDM == true || */ (rbac.rbacDetails
  //                     .demurrageCUD != 'Y' ||
  //                     (!isImport && oblForm?.isAgentUser === 'Y'))) ||
  //                 disablePortCrr ||
  //                 (!isConsignee && oblForm?.isAgentUser !== 'Y')
  //               ? true
  //               : option.value == 806 && (dataCheckRI == true || !isImport) //|| //rbac.rbacDetails.demurrageCUD != 'Y'
  //               ? true
  //               : (option.value == 808 &&
  //                   (dataCheckPI == true ||
  //                     rbac.rbacDetails.pickUpCUD != 'Y' ||
  //                     (!isImport && oblForm?.isAgentUser === 'Y'))) ||
  //                 (!isConsignee && oblForm?.isAgentUser !== 'Y')
  //               ? true
  //               : false
  //             : option.value == 805 &&
  //               dataCheckAD == false &&
  //               rbac.rbacDetails.nominactionCUD == 'Y' &&
  //               isConsignee
  //             ? false
  //             : option.value == 814 &&
  //               dataCheckADS == false &&
  //               rbac.rbacDetails.nominactionCUD == 'Y' &&
  //               isShipper
  //             ? false
  //             : true
  //         }
  //         >
  //         {option.text}
  //       </Dropdown.Item>
  //     </div>
  //   );
  // });

  return (
    <div className='p-0 ps-3 pe-3 d-flex justify-content-between align-items-center odex-mh-60px w-100 border-bottom'>
      <div className='d-flex justify-content-start align-items-center'>
        {/*   {  (location.pathname === "/standing-instruction/") ?
       "": */}{' '}
        <FontAwesomeIcon
          icon={faArrowAltCircleLeft}
          className='text-warning me-3 cursor-pointer'
          size='lg'
          onClick={_onBackNav}
        />
        <>
          {profileDetails?.opcPartnerType !== 'PTO' && (
            <div
              className='d-flex justify-content-start align-items-between me-2'
              style={oblLoading ? { pointerEvents: 'none' } : {}}
            >
              <Dropdown drop='down'>
                <Dropdown.Toggle
                  className='no-caret-dropdown-toggle cursor-pointer'
                  as='div'
                >
                  <Button
                    variant='falcon-primary'
                    size='sm'
                    style={{ marginRight: '0.5rem', position: 'relative' }}
                    disabled={oblLoading || listLoading}
                  >
                    <div className='d-flex'>
                      <FontAwesomeIcon icon={faPlus} className='me-2 mt-1 ' />
                      <span>Request</span>
                    </div>
                  </Button>
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ minWidth: 'auto', width: 'auto' }}>
                  {!location.pathname.includes('instruction') &&
                    (profileDetails?.opcPartnerType === 'ICD' ||
                      profileDetails?.opcPartnerType === 'CFA') && (
                      <Dropdown.Item
                        style={{ minWidth: 'auto', width: '105px' }}
                        onClick={async () => {
                          navigate(
                            `${dashboard}/bl/${encodeURIComponent(
                              blNum || ''
                            )}/instruction/${AESEncrypt('902')}${location?.search || ''
                            }`
                          );
                        }}
                      >
                        {'Permit'}
                      </Dropdown.Item>
                    )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
          <div className='d-flex justify-content-start align-items-between me-2 d-none'>
            <Button
              variant='falcon-warning'
              className='filters-header-odex-md me-2'
              style={{ pointerEvents: 'none' }}
              onClick={() => {
                navigate(
                  `${dashboard}/bl/${encodeURIComponent(
                    blNum || ''
                  )}/instructionpayment`
                );
              }}
              disabled={disablePayment}
            >
              <div className={disablePayment ? 'disableIcon' : ''}>
                <div
                  className='disablePayment'
                  style={{ color: '#ccc !important' }}
                >
                  <FontAwesomeIcon
                    icon={faCreditCard}
                    size='lg'
                    style={{ color: '#ccc !important' }}
                  />
                </div>
              </div>
            </Button>

            <div className='d-flex flex-column justify-content-between align-items-start'>
              <h6 className='fs--2 m-0'>
                <b>{t('resource_1:payment')}</b>
              </h6>

              <span>{getBadges(paymentStatus)}</span>
            </div>
          </div>

          <div className='d-flex justify-content-start align-items-between d-none'>
            {
              <Button
                variant='falcon-warning'
                className='filters-header-odex-md me-2'
              >
                <div style={{ color: '#ccc' }}>
                  <FontAwesomeIcon icon={faShip} size='lg' />
                </div>
              </Button>
            }
            <div className='d-flex flex-column justify-content-between align-items-start'>
              <h6 className='fs--2 m-0'>
                <b>{t('resource_2:linerRelease')}</b>
              </h6>
              <span>{getBadges(releaseStatus)} </span>
            </div>
          </div>
        </>
        {/* : (
          ''
        )} */}
      </div>
      {location.pathname === '/standing-instruction' ? (
        ''
      ) : (
        <div
          className='d-flex flex-row justify-content-end align-items-center'
          style={{ height: 38 }}
        >
          {profileDetails?.partnerType !== 'liner' &&
            getBlDetail(blNum ? AESDecrypt(blNum) : '')?.hasMessage ? (
            <Button
              variant='falcon-default'
              className='filters-header-odex-md me-3'
              onClick={() =>
                navigate(
                  `${dashboard}/bl/${encodeURIComponent(
                    blNum || ''
                  )}/query-message`
                )
              }
            >
              <div>
                <FontAwesomeIcon icon={faEnvelope} size='lg' />
              </div>
            </Button>
          ) : (
            ''
          )}
        </div>
      )}
    </div>
  );
};

export default Header;
