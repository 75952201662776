/* eslint-disable react-hooks/exhaustive-deps */
import DataTable from '../../common/components/DataTable';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';

import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import {
  IConfigsDefinition,
  generateColumnConfig,
} from '../../utils/columnConfig';
import { useEffect, useState } from 'react';

import { AESDecrypt } from '../../../encrypt-util';
import { IDataTableColumn } from '../../common/types/dataTable';

import { updateInstructionID } from '../blDetails/slices/documentHistorySlice';
import useDataCountry from '../../../hooks/useDataCountry';
import DashboardContainer from '../../../components/common/DashboardContainer';
import { updateDashbaordName, updateSearchInputValueNT } from '../../common/slices/navbarTop';
import { updateDashbaordRefresh, updateParams } from '../../common/components/DataTable/slices/DataTable';
import { getColumnViews } from '../../common/actions/dataTable';
import FilterView from '../../common/components/FilterView';
import { getEnquiryTableData } from '../dashboard/actions/dashboard';
import { AnyAction } from '@reduxjs/toolkit';
import { Form, InputGroup } from 'react-bootstrap';
import { clearEnquirtDashboard } from '../blRequestInstruction/components/instructionSlice/InvoiceChangeSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';

const gridName = 'OPC_ENQ_DASHBOARD'
const pathname = '/opc/blinclusions'
const uniqueFilterKey = 'enqDashboard'
const EnquiryDashboard = () => {
  const { encryptdoc, blNum, encrypt } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const refresh = useSelector(
    (state: RootState) => state.dataTable.refreshDashboard
  );

  const tpoId = useSelector((state: RootState) => state.agentDelegation.tpoId);

  const [draftNumber, setDraftNumber] = useState('')

  const grids = useSelector(
    (state: RootState) => state.dataTable.enquiryDashboardGrids
  );
  const dataCountry = useDataCountry()
  const tableData = useSelector(
    (state: RootState) => state.invoiceChange.enquiryDashboardList
  );

  const [columns, setColumns] = useState<IDataTableColumn[]>([]);
  const [showSettings, setShowSettings] = useState(false)
  const [configs, setConfigs] = useState<IConfigsDefinition>({
    columns: [],
    staticHiddenCols: [],
    defaultHiddenCols: [],
  });

  useEffect(() => {
    // if (configViews && configViews?.length > 0 && setDefaultViewBL) {
    //   dispatch(updateSetDefaultViewBL(false));
    // }
    dispatch(updateDashbaordName(gridName));
    dispatch(updateSearchInputValueNT(''));
  }, []);

  useEffect(() => {
    if (refresh) loadEnquiryDashboard();
    setTimeout(() => dispatch(updateDashbaordRefresh(false)), 2000);
  }, [refresh]);

  useEffect(() => {
    if (location.pathname == pathname)
      loadEnquiryDashboard()
  }, [tpoId, draftNumber])

  const getParams = () => {
    dispatch(
      updateInstructionID(AESDecrypt(location?.pathname?.split?.('/')?.[3]))
    );
    return {
      bl_id: blNum ? AESDecrypt(blNum) : '',
      doc_id: encryptdoc ? AESDecrypt(encryptdoc) : '',
      inst_id: encrypt ? AESDecrypt(encrypt) : '',
    };
  };

  useEffect(() => {
    const blDashboardDefinition = grids?.[0]?.columnConfigGrid || '';

    if (!blDashboardDefinition) return;

    try {
      const configs = generateColumnConfig(
        JSON.parse(JSON.parse(blDashboardDefinition)),
        getParams,
        navigate,
        dispatch
      );

      setConfigs(configs);
      setColumns(configs?.columns);
    } catch (e) {
      console.error('Column Error: ', e);
    }
  }, [grids]);

  useEffect(() => {
    if (dataCountry && tpoId) {
      setTimeout(() => {
        dispatch(
          getColumnViews({
            gridName: gridName,
            country: dataCountry,
            linerId: tpoId,
          })
        );
      }, 150);
    }
    dispatch(updateParams({}));
  }, [dataCountry, grids, tpoId]);

  const loadEnquiryDashboard = () => {
    if (tpoId && draftNumber && draftNumber.trim() != '') {

      dispatch(
        getEnquiryTableData({
          draftNumber: draftNumber
        })
      );
    }
  };

  return (
    <DashboardContainer
      sideWidth={0}
    >
      {columns?.length > 0 ? (
        <>
          <FilterView
            showColumnSettings={true}
            toggleColumnSettings={(v: boolean) => {
              setShowSettings(v)
              const x: AnyAction = { type: 'dummyactionmeansnothing' }
              return x
            }}
            showExport
            columnSettingsPersistKey='@odex/maintable/v6'
            uniqueFilterKey={uniqueFilterKey}
            dataLoading={false}
            configViews={
              grids?.find?.((g: any) => g?.gridName === gridName)
                ?.configGroup || []
            }
            showRightDiv={
              !!matchPath(location.pathname, pathname) && draftNumber != ''
            }
            dashboardName={gridName}
            data={{ hasNext: true, result: tableData, size: 20 }}
            gridDef={grids}
            csvByMail={false}
            additionalChildren={
              <div className='d-flex' style={{width:'85%'}}>
                <InputGroup.Text
                  className={`rounded-pill-l bg-100 fs--1`}
                >
                  Draft Number
                </InputGroup.Text>
                <Form.Control
                  size="sm"
                  className={`fs--1 rounded-pill-r text-primary`}
                  type="text"
                  disabled={false}
                  onBlur={(e) => {
                    const cleanVal = e.target.value.replace(/[^a-zA-Z0-9]/g, '')
                    setDraftNumber(cleanVal)
                    e.target.value = cleanVal
                    if (cleanVal == '') {
                      setDraftNumber('')
                      dispatch(clearEnquirtDashboard())
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value == '') {
                      setDraftNumber('')
                      dispatch(clearEnquirtDashboard())
                    }
                  }}
                  onKeyDown={(e)=>{
                    if(e.key == 'Enter'){
                      e.currentTarget.blur()
                    }
                  }}
                />
              </div>}
          />
          {draftNumber == '' && (
            <div className='flex-1 d-flex h-100 align-items-center justify-content-center'>
              <span
                className='f-1'
                style={{
                  userSelect: 'none',
                }}
              >
                <FontAwesomeIcon icon={faXmarkCircle} /> Please Enter Draft Number
                <br />
                <br />
                <br />
                <br />
              </span>
            </div>
          )}
          {columns.length > 0 && draftNumber != '' &&<DataTable
            data={tableData || []}
            columns={columns}
            sortable={true}
            searchable={false}
            showColumnFilter={false}
            configGroup={
              grids?.find?.((g: any) => g?.gridName === gridName)
                ?.configGroup || []
            }
            perPage={20}
            pagination={true}
            selection={false}
            showExport={false}
            localFilter={true}
            showColumnSettings={showSettings}
            onHide={() => { setShowSettings(false) }}
            gridName={gridName}
            columnSettingsPersistKey='@odex/enqtable/v1'
            customSort={false}
            shortPrepend='EQ_'
            uniqueFilterKey={uniqueFilterKey}
            staticHiddenCols={configs?.staticHiddenCols || []}
            defaultHiddenCols={configs?.defaultHiddenCols || []}
            autoHeight={false}
            tableMinHeight={400}
            dataCountry={dataCountry}
            gridId={grids?.[0]?.gridId || 0}
            dataLoading={false}
            manualFilter={true}
          />}
        </>
      ) : (
        ''
      )}
    </DashboardContainer>
  );
};

export default EnquiryDashboard;
