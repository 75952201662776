import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { boolean } from 'yup';
import { extensionTypeList, getExtensionRequest, getOBLDetails, getOBLForOverStay, populateExtensionRequest } from '../../../dashboard/actions/instruction';

export interface overstayExtensionDetails {
    needExtensionFor:string | null;
    plannedPickupDate?: Date | null;
    userRemark?: string;
    status:string;
    rejectRemarks:string;
    extensionRequestId:number | null;

}

export interface overstayExtensionTable {
   
    needExtensionFor:string | null;
    plannedPickupDate?: Date | null;
    containerNo:string;
   
}

  export interface Isnooze {
    data: Array<overstayExtensionTable>;
    overstayExtension: overstayExtensionDetails;
    extensionTypeOptions:any[];
    //containerList:any[]
   // dataLoading: boolean;
    
  }
  

const initialState: Isnooze = {
    data: [],
    //  dataLoading: true,
    overstayExtension: {
    needExtensionFor: null,
    plannedPickupDate: null,
    userRemark:'',
    status:'',
    rejectRemarks:'',
    extensionRequestId:null
   
  },
    extensionTypeOptions:[],
    //containerList:[]



};

export const overstayExtensionSlice = createSlice({
  name: 'overstayExtension',
  initialState,
  reducers: {
    clearData:(state)=>{
      state.overstayExtension = {
        needExtensionFor: '0',
        plannedPickupDate: null,
        userRemark: '',
        status:'',
        rejectRemarks:'',
        extensionRequestId:null
        }
      state.data = []
      
    },
    updateSelectedneedExtensionFor: (state, action: PayloadAction<any>) => {
        state.data.forEach((Item)=>{Item.needExtensionFor=action.payload})
    },
    updateSelectedPlannedPickupDate: (state, action: PayloadAction<any>) => {
      state.data.forEach((Item)=>{Item.plannedPickupDate=action.payload})
    },
      updateSelectedContainerNo: (state, action: PayloadAction<any>) => {
        const row = state.data.find((Item)=>Item.containerNo==action.payload.containerNo)
        if(!row)return
        row.needExtensionFor = action.payload.needExtensionFor
      }, 
      updateSelectedPickupDate: (state, action: PayloadAction<any>) => {
        const row = state.data.find((Item)=>Item.containerNo==action.payload.containerNo)
        if(!row)return
        row.plannedPickupDate = action.payload.plannedPickupDate
      }, 
      clearTableInputValues:(state)=>{
        state.data.forEach((item)=>{
          item.needExtensionFor='0'
          item.plannedPickupDate=null
        })
      }
},
  extraReducers: (builder) => {
  builder.addCase(getExtensionRequest.pending, (state, action) => {
     // state.dataLoading = true;
     state.overstayExtension = {
      needExtensionFor: '0',
      plannedPickupDate: null,
      userRemark: '',
      status:'',
      rejectRemarks:'',
      extensionRequestId:null
      }
    state.data = []

    });
  
  /*   builder.addCase(getSnooze.fulfilled, (state, action) => {
      state.snoozeData = {
        noOfDays: action?.payload?.data?.snoozeDays,
        snooze:action?.payload?.data?.snoozeStatus,
        userSnoozeRemark:action?.payload?.data?.snoozeRemarks,
        emailTo:action?.payload?.data?.snoozeEmailId,
        subject:action?.payload?.data?.snoozeSubject,
        remarkby:action?.payload?.data?.snoozedBy
        
      };
    }); */

    builder.addCase(getExtensionRequest.fulfilled, (state, action) => {
      state.overstayExtension.status=action?.payload?.data?.status
      state.overstayExtension.rejectRemarks=action?.payload?.data?.remarks
      state.overstayExtension.userRemark= action?.payload?.data?.userRemarks
      state.overstayExtension.extensionRequestId=action?.payload?.data?.extensionRequestId
      state.data =[]
    // console.log("containerDetails",action.payload.data?.containerDetails)
      action.payload.data?.containerDetails?.forEach?.((item:any)=>{
          state.data.push({
            needExtensionFor:item.pickupLocation??0,
            plannedPickupDate:new Date(item.pickupDate),
            containerNo:item.cntNo
          })
      })

    });

    builder.addCase(populateExtensionRequest.fulfilled, (state, action) => {
      state.overstayExtension.status=action?.payload?.data?.status
      state.overstayExtension.rejectRemarks=action?.payload?.data?.remarks
      state.overstayExtension.userRemark= action?.payload?.data?.userRemarks
      state.overstayExtension.extensionRequestId=action?.payload?.data?.extensionRequestId
      
    // console.log("containerDetails",action.payload.data?.containerDetails)
      action.payload.data?.containerDetails?.forEach?.((item:any)=>{
          const row = state.data.find((i)=>item.cntNo == i.containerNo)
          if(!row){return}
         
          row.needExtensionFor = item.pickupLocation??0
          row.plannedPickupDate = new Date(item.pickupDate)
          
          
      })

    });

    builder.addCase(extensionTypeList.fulfilled,(state,action)=>{
      state.extensionTypeOptions = action.payload;

 })
 builder.addCase(getOBLForOverStay.fulfilled,(state,action)=>{
  const containerList =  action.payload.containerList.map(
    (i:any)=>
      ({
        containerNo: i.containerNo,
        needExtensionFor:0,
        plannedPickupDate:  null,
      }));

  state.data.forEach((i)=>{
    const row :overstayExtensionTable |null = containerList.find((x:overstayExtensionTable)=>i.containerNo === x.containerNo)
    if(!row)return
    row.needExtensionFor = i.needExtensionFor
    row.plannedPickupDate = i.plannedPickupDate
  })
  
  state.data = containerList
})
  },
});


export const { clearData,updateSelectedneedExtensionFor,updateSelectedContainerNo,updateSelectedPickupDate,updateSelectedPlannedPickupDate,clearTableInputValues } = overstayExtensionSlice.actions; 
export default overstayExtensionSlice.reducer;
