import {
  faArrowAltCircleLeft,
  faEdit,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { useBackNavigation } from "../../../hooks/BackNavigation";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { customDropdownStyles } from "../../common/customStyles";
import MpsDocumentTable from "./MpsDocumentTable";
import MpsContainerTable from "./MpsContainerTable";
import {
  faCancel,
  faCheck,
  faRedo,
  faSave,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { useDispatch } from "react-redux";
import {
  cancelMPSRequest,
  getContainerByBl,
  getInstructionConfig,
  getMpsCategory,
  getMPSRequest,
  getMpsTypes,
  getStatusOptions,
  getTrafficOptions,
  getTransitOptions,
  MpsApproveReject,
  saveMPSRequest,
} from "./mpsActions";
import { toast } from "react-toastify";
import useDataCountry from "../../../hooks/useDataCountry";
import {
  blNumbList,
  fetchVesselSuggestions,
  generateMPSReferenceNo,
} from "../blRequestInstruction/components/actions/Instruction";
import { useParams } from "react-router-dom";
import { AESDecrypt } from "../../../encrypt-util";
import {
  clearContainerList,
  clearDocumentList,
  clearMpsConfig,
  clearMpsTypes,
} from "./mpsSlice";
import { updateDashbaordRefresh } from "../../common/components/DataTable/slices/DataTable";
import { getUserTin } from "../../common/actions/userDetails";
import useDebounce from "../../../hooks/useDebounce";
import React from "react";
import { useLoading } from "../../../middleware/loadingMiddleware";

const MpsRequest = () => {
  const onBack = useBackNavigation();
  const { t } = useTranslation();
  const config = useSelector((state: RootState) => state.mps.config);
  const trafficOptions = useSelector(
    (state: RootState) => state.mps.trafficOptions
  );
  const transitOptions = useSelector(
    (state: RootState) => state.mps.transitOptions
  );
  const typeOptions = useSelector((state: RootState) => state.mps.typeOptions);
  const categoryOptions = useSelector(
    (state: RootState) => state.mps.categoryOptions
  );
  const tpoId = useSelector((state: RootState) => state.agentDelegation.tpoId);
  const dispatch = useDispatch<AppDispatch>();
  const documentData = useSelector(
    (state: RootState) => state.mps.documentData
  );
  const containerData = useSelector(
    (state: RootState) => state.mps.containerData
  );
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const companyTin = useSelector(
    (state: RootState) => state.userProfile.customerTin
  );
  const rbac = useSelector((state: RootState) => state.userProfile.rbacDetails);
  const dataCountry = useDataCountry();
  const mpsRefNumber = useSelector(
    (state: RootState) => state.creditBalanceBlPayment.generateMPSReferenceNo
  );
  const VesselData: any = useSelector(
    (state: RootState) => state.permitrequest.data
  );
  const statusOptions = useSelector(
    (state: RootState) => state.mps.statusOptions
  );
  const [isDisable, setIsDisable] = useState(true);
  const [status, setStatus] = useState<any>(null);
  const validBlList: any = useSelector(
    (state: RootState) => state.permitrequest.blNumbList
  );
  const [cancel, setCancel] = useState(false);
  const [backendValues, setBackendValues] = useState<any>({});
  const [labels, setLabels] = useState({
    type: { label: "", value: -1 },
    category: { label: "", value: -1 },
  });
  const loadingVesselList = useLoading(["fetchVesselSuggestions"]);
  const accessGrp = useMemo(
    () => rbac?.mpsApproveReject?.split?.(",") ?? [],
    [rbac]
  );

  const { invoice_request_id } = useParams();
  const mpsId = useMemo(() => {
    const dec = AESDecrypt(invoice_request_id);
    return dec && dec != "" ? dec : null;
  }, [invoice_request_id]);
  const isNew = !mpsId;

  interface formState {
    referenceNumber: string;
    category: number;
    type: number;
    companyTin: string;
    companyName: string;
    traffic: number;
    vessel: string;
    vesselInput: string;
    transit: number;
    bl_so: string;
    remarks: string;
    linerRemarks: string;
    navisDraftId: string;
  }
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    clearErrors,
    watch,
    setError,
    getValues,
  } = useForm<formState>();
  const vesselList = useMemo(
    () =>
      getValues("vesselInput")?.length > 2 && VesselData?.map
        ? VesselData.map((vessel: any) => ({
          value: `${vessel.visitKey}`,
          label: `${vessel.vesselName} - ${vessel.visitKey}${vessel.ata ? " - " + new Date(vessel.ata).toLocaleString() : ""
            }`,
          visitKey: vessel.visitKey,
        })) ?? []
        : [],
    [VesselData, watch("vesselInput")]
  );

  useEffect(() => {
    if (isNew) {
      setValue("type", 0);
      setValue("category", 0);
      setValue("traffic", 0);
      setValue("transit", 0);
      setIsDisable(false);
      dispatch(clearContainerList());
      dispatch(getUserTin());
    }
    dispatch(clearMpsConfig());
    dispatch(clearMpsTypes());
    clearErrors();
  }, [isNew]);

  useEffect(() => {
    if (tpoId && dataCountry) {
      dispatch(getTrafficOptions({ tpoId, country: dataCountry }));
      dispatch(getTransitOptions({ tpoId, country: dataCountry }));
      if (!isNew) {
        dispatch(getStatusOptions({ tpoId, country: dataCountry }));
      }
    }
  }, [tpoId, dataCountry, isNew]);

  useEffect(() => {
    if (
      tpoId &&
      dataCountry &&
      profileDetails?.partnerId &&
      (isNew || backendValues?.requestedPartnerType)
    ) {
      dispatch(
        getMpsCategory({
          tpoId,
          dataCountry,
          partner: isNew
            ? profileDetails.opcPartnerType
            : backendValues?.requestedPartnerType,
        })
      );
    }
  }, [
    tpoId,
    dataCountry,
    profileDetails?.opcPartnerType,
    backendValues?.requestedPartnerType,
    isNew,
  ]);

  useEffect(() => {
    if (
      tpoId &&
      dataCountry &&
      profileDetails?.partnerId &&
      watch("category") &&
      (isNew || backendValues?.requestedPartnerType)
    ) {
      dispatch(
        getMpsTypes({
          tpoId,
          dataCountry,
          partner: isNew
            ? profileDetails.opcPartnerType
            : backendValues?.requestedPartnerType,
          type: watch("category"),
        })
      );
    }
  }, [
    tpoId,
    dataCountry,
    profileDetails?.opcPartnerType,
    watch("category"),
    backendValues?.requestedPartnerType,
    isNew,
  ]);

  useEffect(() => {
    if (
      tpoId &&
      dataCountry &&
      profileDetails?.partnerId &&
      watch("type") &&
      watch("category") &&
      (isNew || backendValues?.requestedPartnerType)
    ) {
      dispatch(
        getInstructionConfig({
          tpoId,
          dataCountry,
          partner: isNew
            ? profileDetails.opcPartnerType
            : backendValues?.requestedPartnerType,
          type: watch("type"),
          category: watch("category"),
        })
      );
      clearErrors();
    }
  }, [
    tpoId,
    dataCountry,
    profileDetails?.opcPartnerType,
    watch("type"),
    watch("category"),
    backendValues?.requestedPartnerType,
    isNew,
  ]);

  useEffect(() => {
    if (isNew) {
      setValue("companyTin", companyTin);
      setValue("companyName", profileDetails.partnerName ?? "");
    }
  }, [profileDetails, companyTin]);

  useEffect(() => {
    if (dataCountry && isNew) dispatch(generateMPSReferenceNo(dataCountry));
  }, [dataCountry]);

  useEffect(() => {
    if (isNew) {
      setValue('referenceNumber', mpsRefNumber ?? '')
    }
  }, [mpsRefNumber])

  const getVesselSuggestions = useDebounce((vessel: string) => dispatch(fetchVesselSuggestions(
    {
      vesselKey: vessel,
      requestType: 904,
      permitId: 0
    }
  )), 500)

  useEffect(() => {
    if (invoice_request_id) setIsDisable(true);
  }, [invoice_request_id]);

  useEffect(() => {
    if (
      !isNew &&
      tpoId &&
      mpsId &&
      transitOptions?.length > 0 &&
      trafficOptions?.length > 0
    ) {
      dispatch(getMPSRequest({ tpoId, id: mpsId }))
        .unwrap()
        .then((data: any) => {
          [
            "referenceNumber",
            "category",
            "type",
            "companyTin",
            "companyName",
            "traffic",
            "vessel",
            "transit",
            "bl_so",
            "remarks",
            "linerRemarks",
            "navisDraftId",
          ].forEach((s: any) => {
            setValue(s, data?.[s] ?? "");
          });
          setValue("vesselInput", data?.vessel ?? "");
          setStatus(data?.status);
          setBackendValues({
            requestedOn: data?.requestedOn,
            nextLevel: data?.nextLevel,
            level: data?.level,
            requestedPartnerType: data?.requestedPartnerType,
          });
          setLabels({
            type: { label: data?.typeNm ?? "", value: data?.type },
            category: { label: data?.categoryNm ?? "", value: data?.category },
          });
        });
    }
  }, [isNew, tpoId, mpsId, transitOptions, trafficOptions]);

  useEffect(() => {
    if (
      getValues("traffic") &&
      getValues("vessel") &&
      getValues("vessel") &&
      !isDisable
    )
      dispatch(
        blNumbList({
          visit: getValues("vessel"),
          permit: getValues("traffic") == 1 ? "Import" : "Export",
        })
      );
  }, [getValues("traffic"), getValues("vessel"), isDisable]);

  const validation = () => {
    let isError = false;

    if (!typeOptions?.find((x) => x.value == getValues("type"))) {
      setError("type", { message: "Invalid type selected" });
      isError = true;
    }

    if (!categoryOptions?.find((x) => x.value == getValues('category'))) {
      setError('category', { message: 'Invalid category selected' })
      isError = true
    }
    [
      'referenceNumber',
      'category',
      'type',
      'companyTin',
      'companyName',
      'traffic',
      'vessel',
      'transit',
      'bl_so',
      'containerCount',
      'remarks'
    ].forEach((key: any) => {
      if (Object.hasOwn(config, key) && config[key].m) {
        const field = getValues(key);
        if (!field || field == '') {
          setError(key, { message: "This Field is Required" })
          isError = true
        }
      }
    })
    if (config.bl_so.s && getValues('bl_so') && getValues('bl_so') != '' &&
      !validBlList.find((item: any) => item.blNumber == getValues('bl_so'))) {
      setError('bl_so', { message: "Invalid BL/SO Number" })
      isError = true
    }
    if (config?.containerList?.m && !containerData.find((x) => x.selected)) {
      toast.error("Please Select Atlease One Container", {
        toastId: 'bdb3f5a'
      })
      isError = true
    }
    if (config.documentList.s) {
      let missingDoc = false
      documentData.filter((x) => x.m).forEach((x) => {
        if (!missingDoc && (!x.filePath || x.filePath == '') &&
          (!x.serverFilePath || x.serverFilePath == '')) {
          toast.error('Please Upload All Mandatory Documents', {
            toastId: '30df49e'
          })
          isError = true
          missingDoc = true
        }
      })
    }
    return isError
  }

  const onSubmit = handleSubmit((payload) => {
    if (validation()) return
    const hidden = Object.entries(config).filter(([key, val]) => !val.s
      && !['documentList', 'containerList', 'referenceNumber', 'type', 'category'].includes(key)).reduce((a, [key, _]) => ({
        ...a,
        [key]: null
      }), {})
    dispatch(saveMPSRequest({
      ...payload,
      tpoId,
      requestId: mpsId ?? null,
      requestedOn: backendValues?.requestedOn ?? undefined,
      ...hidden
    }))
    onBack()
    setTimeout(() => {
      dispatch(updateDashbaordRefresh(true))
    }, 1000)
  }, (e) => { console.error(e, 'submiterror') })

  const approveRejectValidation = (status: any) => {
    let isError = false;
    if (
      status == 311 &&
      backendValues?.nextLevel == 311 &&
      (!getValues("navisDraftId") || getValues("navisDraftId") == "")
    ) {
      isError = true;
      setError("navisDraftId", {
        message: "MPS Draft Invoice Number is required for approval",
      });
    }
    if (
      status == 304 &&
      (!getValues("linerRemarks") || getValues("linerRemarks") == "")
    ) {
      isError = true;
      setError("linerRemarks", {
        message: "Remarks are required for rejection",
      });
    }
    return isError;
  };

  const onApproveReject = ({ status }: any) => {
    if (approveRejectValidation(status)) return;
    dispatch(
      MpsApproveReject({
        ...backendValues,
        tpoId,
        requestId: mpsId ?? null,
        navisDraftId:
          watch("navisDraftId") != "" ? watch("navisDraftId") : null,
        ptoRemarks: watch("linerRemarks"),
        status,
      })
    );
    onBack();
    setTimeout(() => {
      dispatch(updateDashbaordRefresh(true));
    }, 1000);
  };

  return (
    <>
      <Card style={{ minHeight: "100%" }}>
        <Card.Header className="d-flex align-items-center border-bottom sticky-top bg-white">
          <FontAwesomeIcon
            icon={faArrowAltCircleLeft}
            className="text-warning cursor-pointer fa-lg mb-1 me-1"
            onClick={onBack}
          />
          <h2 className="fs-0 text-primary mb-1">Miscellaneous Port Service</h2>
        </Card.Header>

        <Card.Body>
          <Form className="row" autoComplete="off">
            {config.referenceNumber.s && (
              <div className="col-lg-6 mb-3">
                <Form.Group controlId="invoiceNumber">
                  {config.referenceNumber.m && (
                    <span className="mandatory">*</span>
                  )}
                  <Form.Label>
                    {t("resource_3:invoiceReferenceNumber")}
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    className={`fs--1 form-control form-control-sm ${errors.referenceNumber ? "is-invalid" : ""
                      }`}
                    type="text"
                    maxLength={20}
                    disabled={true}
                    {...register("referenceNumber")}
                  />
                  <div className="invalid-feedback">
                    {errors.referenceNumber?.message?.toString()}
                  </div>
                </Form.Group>
              </div>
            )}

            <div className="col-lg-6 mb-3">
              <Form.Group controlId="invoiceNumber">
                <span className="mandatory">*</span>
                <Form.Label>MPS Category</Form.Label>
                <Form.Select
                  size="sm"
                  className={`fs--1 form-control form-control-sm ${errors.category ? "is-invalid" : ""
                    }`}
                  disabled={isDisable || categoryOptions.length == 0}
                  onChange={(e) => {
                    setValue("category", +e.target.value);
                    clearErrors("category");
                    dispatch(clearMpsConfig());
                    dispatch(clearMpsTypes());
                    setValue("type", 0);
                    setValue("traffic", 0);
                    setValue("bl_so", "");
                    setValue("transit", 0);
                    setValue("vessel", "");
                    setValue("vesselInput", "");
                    setValue("remarks", "");
                    dispatch(clearContainerList());
                    dispatch(clearDocumentList());
                    clearErrors();
                  }}
                  value={watch("category")}
                >
                  {isDisable &&
                    !categoryOptions?.find(
                      (x) => x.value == labels.category.value
                    ) && (
                      <option value={labels.category.value}>
                        {labels.category.label}
                      </option>
                    )}
                  <option value={0}>
                    {isDisable ? "" : "Select Category"}
                  </option>
                  {categoryOptions.map(({ value, text }) => (
                    <option value={value} key={value}>
                      {text}
                    </option>
                  ))}
                </Form.Select>
                <div className="invalid-feedback">
                  {errors.category?.message?.toString()}
                </div>
              </Form.Group>
            </div>

            <div className="col-lg-6 mb-3">
              <Form.Group controlId="invoiceNumber">
                <span className="mandatory">*</span>
                <Form.Label>MPS Type</Form.Label>
                <Form.Select
                  size="sm"
                  className={`fs--1 form-control form-control-sm ${errors.type ? "is-invalid" : ""
                    }`}
                  disabled={isDisable || typeOptions.length == 0}
                  onChange={(e) => {
                    setValue("type", +e.target.value);
                    clearErrors("type");
                    if (+e.target.value == 0) {
                      dispatch(clearMpsConfig());
                      setValue("traffic", 0);
                      setValue("bl_so", "");
                      setValue("transit", 0);
                      setValue("vessel", "");
                      setValue("vesselInput", "");
                      setValue("remarks", "");
                      dispatch(clearContainerList());
                      dispatch(clearDocumentList());
                      clearErrors();
                    }
                  }}
                  value={watch("type")}
                >
                  {isDisable &&
                    !typeOptions?.find((x) => x.value == labels.type.value) && (
                      <option value={labels.type.value}>
                        {labels.type.label}
                      </option>
                    )}
                  <option value={0}>{isDisable ? "" : "Select Type"}</option>
                  {typeOptions.map(({ value, text }) => (
                    <option value={value} key={value}>
                      {text}
                    </option>
                  ))}
                </Form.Select>
                <div className="invalid-feedback">
                  {errors.type?.message?.toString()}
                </div>
              </Form.Group>
            </div>

            {config.companyTin.s && (
              <div className="col-lg-6 mb-3">
                <Form.Group controlId="invoiceNumber">
                  {config.companyTin.m && <span className="mandatory">*</span>}
                  <Form.Label>Requester Company TIN</Form.Label>
                  <Form.Control
                    size="sm"
                    className={`fs--1 form-control form-control-sm ${errors.companyTin ? "is-invalid" : ""
                      }`}
                    type="text"
                    maxLength={20}
                    disabled={true}
                    {...register("companyTin")}
                  />
                  <div className="invalid-feedback">
                    {errors.companyTin?.message?.toString()}
                  </div>
                </Form.Group>
              </div>
            )}

            {config.companyName.s && (
              <div className="col-lg-6 mb-3">
                <Form.Group controlId="invoiceNumber">
                  {config.companyName.m && <span className="mandatory">*</span>}
                  <Form.Label>Requester Company Name</Form.Label>
                  <Form.Control
                    size="sm"
                    className={`fs--1 form-control form-control-sm ${errors.companyName ? "is-invalid" : ""
                      }`}
                    type="text"
                    maxLength={20}
                    disabled={true}
                    {...register("companyName")}
                  />
                  <div className="invalid-feedback">
                    {errors.companyName?.message?.toString()}
                  </div>
                </Form.Group>
              </div>
            )}

            {config.traffic.s && (
              <div className="col-lg-6 mb-3">
                <Form.Group controlId="invoiceNumber">
                  {config.traffic.m && <span className="mandatory">*</span>}
                  <Form.Label>{t("resource_1:traffic")}</Form.Label>
                  <Form.Select
                    size="sm"
                    className={`fs--1 form-control form-control-sm ${errors.traffic ? "is-invalid" : ""
                      }`}
                    disabled={isDisable || trafficOptions.length == 0}
                    onChange={(e) => {
                      setValue("traffic", +e.target.value);
                      setValue("bl_so", "");
                      clearErrors("bl_so");
                      clearErrors("vessel");
                      clearErrors("traffic");
                      dispatch(clearContainerList());
                    }}
                    value={watch("traffic")}
                  >
                    <option value={0}>
                      {isDisable ? "" : "Select Traffic"}
                    </option>
                    {trafficOptions.map(({ value, text }) => (
                      <option value={value} key={value}>
                        {text}
                      </option>
                    ))}
                  </Form.Select>
                  <div className="invalid-feedback">
                    {errors.traffic?.message?.toString()}
                  </div>
                </Form.Group>
              </div>
            )}

            {config.vessel.s && (
              <div className="col-lg-6 mb-3">
                <Form.Group style={{ padding: "0px" }} controlId="vessel">
                  {config.vessel.m && <span className="mandatory">*</span>}
                  <Form.Label className="fs--1">
                    {t("resource_1:vessel")}
                  </Form.Label>

                  {!isDisable && (
                    <div>
                      <Select
                        options={vesselList}
                        isDisabled={false}
                        noOptionsMessage={() => null}
                        inputValue={watch("vesselInput")}
                        isClearable={true}
                        maxMenuHeight={150}
                        onChange={(e: any) => {
                          if (!watch("traffic")) {
                            setValue("vessel", "");
                            setError("vessel", {
                              type: "manual",
                              message: "Please select traffic first.",
                            });
                            e.target.value = null;
                          } else if (e === null) {
                            setValue("vessel", "");
                            setValue("vesselInput", "");
                            setValue("bl_so", "");
                            clearErrors("bl_so");
                          } else {
                            setValue("vessel", e.value);
                            setValue("bl_so", "");
                            clearErrors("bl_so");
                            clearErrors("vessel");
                          }
                        }}
                        styles={customDropdownStyles}
                        filterOption={(option: any, inputValue: any) => {
                          return option.label
                            .toLowerCase()
                            .includes(inputValue.toLowerCase());
                        }}
                        onInputChange={(inputValue: string) => {
                          setValue("vesselInput", inputValue);
                          if (inputValue.length >= 3) {
                            getVesselSuggestions(
                                inputValue.toUpperCase(),
                              );
                          }
                        }}
                        className={`fs--1 form-control form-control-sm ${errors.vessel ? "is-invalid" : ""
                          } ${loadingVesselList &&
                            getValues("vesselInput")?.length > 2 &&
                            VesselData?.map
                            ? "loadingPulse"
                            : ""
                          }`}
                      />
                      <div className="invalid-feedback">
                        {errors.vessel?.message?.toString()}
                      </div>
                    </div>
                  )}

                  {isDisable && (
                    <div>
                      <Form.Control
                        disabled={isDisable}
                        size="sm"
                        {...register("vessel")}
                        className={`fs--1 form-control form-control-sm ${errors.vessel ? "is-invalid" : ""
                          }`}
                        type="text"
                        maxLength={20}
                      />
                    </div>
                  )}
                </Form.Group>
              </div>
            )}

            {config.transit.s && (
              <div className="col-lg-6 mb-3">
                <Form.Group controlId="invoiceNumber">
                  {config.transit.m && <span className="mandatory">*</span>}
                  <Form.Label>{t("resource_2:local_transit")}</Form.Label>
                  <Form.Select
                    size="sm"
                    className={`fs--1 form-control form-control-sm ${errors.transit ? "is-invalid" : ""
                      }`}
                    disabled={isDisable || transitOptions.length == 0}
                    onChange={(e) => {
                      setValue("transit", +e.target.value);
                      clearErrors("transit");
                    }}
                    value={watch("transit")}
                  >
                    <option value={0}>
                      {isDisable ? "" : "Select Transit"}
                    </option>
                    {transitOptions.map(({ value, text }) => (
                      <option value={value} key={value}>
                        {text}
                      </option>
                    ))}
                  </Form.Select>
                  <div className="invalid-feedback">
                    {errors.transit?.message?.toString()}
                  </div>
                </Form.Group>
              </div>
            )}

            {config.bl_so.s && (
              <div className="col-lg-6 mb-3">
                <Form.Group controlId="invoiceNumber">
                  {config.bl_so.m && <span className="mandatory">*</span>}
                  <Form.Label>{t("resource_2:bl_so_number")}</Form.Label>
                  <Form.Control
                    size="sm"
                    className={`fs--1 form-control form-control-sm ${errors.bl_so ? "is-invalid" : ""
                      }`}
                    type="text"
                    maxLength={20}
                    disabled={isDisable}
                    {...register("bl_so")}
                    onBlur={(e) => {
                      if (!e.target.value || e.target.value == "") {
                        dispatch(clearContainerList());
                        return;
                      }
                      if (
                        !getValues("vessel") ||
                        !getValues("traffic") ||
                        getValues("vessel") == ""
                      ) {
                        setError("bl_so", {
                          message: "Please select traffic and vessel first.",
                        });
                        setValue("bl_so", "");
                        dispatch(clearContainerList());
                        return;
                      }

                      if (
                        !validBlList.find(
                          (item: any) => item.blNumber == e.target.value
                        )
                      ) {
                        setError("bl_so", { message: "Invalid BL/SO Number" });
                        dispatch(clearContainerList());
                      } else {
                        clearErrors("bl_so");
                        dispatch(
                          getContainerByBl({
                            blnumber: e.target.value,
                            shipmentType:
                              getValues("traffic") == 1 ? "Import" : "Export",
                          })
                        );
                      }
                    }}
                  />
                  <div className="invalid-feedback">
                    {errors.bl_so?.message?.toString()}
                  </div>
                </Form.Group>
              </div>
            )}

            {config.containerCount.s && (
              <div className="col-lg-6 mb-3">
                <Form.Group controlId="invoiceNumber">
                  {config.containerCount.m && (
                    <span className="mandatory">*</span>
                  )}
                  <Form.Label>
                    {t("resource_4:totalNumberOfContainers")}
                  </Form.Label>

                  <Form.Control
                    size="sm"
                    className={`fs--1 form-control form-control-sm `}
                    type="text"
                    maxLength={20}
                    disabled={true}
                    value={containerData?.length}
                  />
                  <div className="invalid-feedback">
                  </div>
                </Form.Group>
              </div>)}

            {config.containerList.s && <MpsContainerTable showdata={isDisable} />}
            {config.documentList.s && <MpsDocumentTable newData={isNew} showdata={isDisable}
              type={watch('type')} requestPartner={backendValues?.requestedPartnerType} />}

            {config.remarks.s && <div className="col-lg-6 mb-3">
              <Form.Group controlId="invoiceNumber">
                {config.remarks.m && <span className="mandatory">*</span>}
                <Form.Label>
                  {t("resource_2:userRemark")}
                </Form.Label>
                <Form.Control
                  size="sm"
                  className={`fs--1 form-control form-control-sm ${errors.remarks ? "is-invalid" : ""
                    }`}
                  as="textarea"
                  disabled={isDisable}
                  {...register("remarks")}
                />
                <div className="invalid-feedback">
                  {errors.remarks?.message?.toString()}
                </div>
              </Form.Group>
            </div>}

            {(
              (profileDetails?.opcPartnerType == "PTO" && status == 303)
              || (status != 303 && status != 307 && !isNew)
            ) && <div className="col-lg-6 mb-3">
                <Form.Group controlId="invoiceNumber">
                  {profileDetails.opcPartnerType === "PTO" && <span className="mandatory">*</span>}
                  <Form.Label>
                    Approver Remarks
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    className={`fs--1 form-control form-control-sm ${errors.linerRemarks ? "is-invalid" : ""
                      }`}
                    as="textarea"
                    disabled={
                      !(profileDetails.opcPartnerType === "PTO" && !['311', '307', '304'].includes(`${status}`))
                    }
                    {...register("linerRemarks")}
                  />
                  <div className="invalid-feedback">
                    {errors.linerRemarks?.message?.toString()}
                  </div>
                </Form.Group>
              </div>}

            {(
              (profileDetails?.opcPartnerType == "PTO" && status == 303)
              || (status != 303 && status != 307 && !isNew)
            ) && <div className="col-lg-6 mb-3">
                <Form.Group>
                  {profileDetails.opcPartnerType === "PTO" && <span className="mandatory">*</span>}
                  <Form.Label>MPS Draft Invoice Number</Form.Label>
                  <Form.Control
                    size="sm"
                    className={`fs--1 form-control form-control-sm ${errors.navisDraftId ? "is-invalid" : ""
                      }`}
                    type="text"
                    maxLength={20}
                    disabled={
                      !(profileDetails.opcPartnerType === "PTO" &&
                        !['311', '307', '304'].includes(`${status}`)) || backendValues?.nextLevel != 311
                    }
                    {...register("navisDraftId")}
                  />
                  <div className="invalid-feedback">
                    {errors.navisDraftId?.message?.toString()}
                  </div>
                  {profileDetails.opcPartnerType === "PTO" && <Form.Label className="fs--1" style={{ color: '#f5822a' }}>
                    In case MPS request is non-chargeable, please insert OK and proceed to approve
                  </Form.Label>}
                </Form.Group>
              </div>}

            {!isNew && <div className="col-lg-6 mb-3"><Form.Group>
              <Form.Label>Request Status</Form.Label>
              <Form.Select
                size="sm"
                className={`fs--1 form-control form-control-sm 
                            }`}

                disabled={true}
                value={status}
              >
                <option></option>
                {statusOptions.map(({ value, text }) => <option value={value} key={value}>
                  {text}
                </option>
                )}
              </Form.Select>
            </Form.Group>
            </div>
            }
          </Form>

          {!isDisable && (
            <div className="col-lg-8 offset-lg-2 mt-2 d-flex justify-content-center align-items-center">
              <Button
                variant="falcon-primary me-2"
                size="sm"
                // disabled={!agentNominationId}
                onClick={() => {
                  clearErrors();
                  onSubmit();
                }}
              >
                <FontAwesomeIcon icon={faSave} className="me-2" />
                {t("resource_1:submit")}
              </Button>

              <Button
                variant="falcon-danger"
                onClick={() => {
                  setValue("traffic", 0);
                  setValue("bl_so", "");
                  setValue("category", 0);
                  setValue("type", 0);
                  setValue("transit", 0);
                  setValue("vessel", "");
                  setValue("vesselInput", "");
                  setValue("remarks", "");
                  dispatch(clearContainerList());
                  dispatch(clearDocumentList());
                  dispatch(clearMpsConfig());
                  dispatch(clearMpsTypes());
                  clearErrors();
                }}
                size="sm"
              >
                <FontAwesomeIcon icon={faRedo} className="me-2" />
                Reset
              </Button>
            </div>
          )}
          {profileDetails.opcPartnerType === "PTO" &&
            !["311", "307", "304"].includes(`${status}`) &&
            accessGrp.includes(`${backendValues.nextLevel}`) && (
              <div className="d-flex justify-content-center align-items-center mt-4">
                <>
                  <Button
                    variant="success"
                    className="me-4 approve"
                    size="sm"
                    onClick={() => {
                      clearErrors();
                      onApproveReject({ status: 311 });
                    }}
                  >
                    <FontAwesomeIcon icon={faCheck} className="me-2 approve" />
                    {t("resource_1:approve")}
                  </Button>

                  <Button
                    variant="danger reject"
                    size="sm"
                    onClick={() => {
                      clearErrors();
                      onApproveReject({ status: 304 });
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} className="me-2 reject" />
                    {t("resource_1:reject")}
                  </Button>
                </>
              </div>
            )}
          {profileDetails.opcPartnerType !== "PTO" &&
            !["311", "307", "304"].includes(`${status}`) &&
            isDisable && (
              <div className="col-lg-8 offset-lg-2 mt-2 d-flex justify-content-center align-items-center">
                <Button
                  className="me-2"
                  variant="falcon-danger"
                  size="sm"
                  type="button"
                  onClick={() => {
                    setIsDisable(false);
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} className="me-2" />
                  Edit
                </Button>

                <Button
                  className="me-2"
                  variant="falcon-danger"
                  size="sm"
                  type="button"
                  onClick={() => setCancel(true)}
                >
                  <FontAwesomeIcon icon={faCancel} className="me-2" />
                  Cancel Request
                </Button>
              </div>
            )}
        </Card.Body>
      </Card>
      <Modal show={cancel} onHide={() => setCancel(false)}>
        <Modal.Header className="p-2 pb-1" closeButton>
          <Modal.Title className="h6">
            Do you want to Cancel the Miscellaneous Port Service request?
          </Modal.Title>
        </Modal.Header>

        <div className="d-flex justify-content-center align-items-center p-2">
          <Button
            variant="falcon-primary"
            onClick={() => {
              dispatch(cancelMPSRequest({ id: mpsId }));
              setCancel(false);
              onBack();
              dispatch(updateDashbaordRefresh(true));
            }}
            size="sm"
            className="me-2"
          >
            Yes
          </Button>
          <Button
            className="me-2"
            onClick={() => setCancel(false)}
            variant="falcon-danger"
            size="sm"
          >
            No
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default MpsRequest;
