import { Card } from 'react-bootstrap';

import FilterView from '../../../common/components/FilterView';
import { Outlet, matchPath, useLocation } from 'react-router-dom';
import { toggleColumnSettingsDashboard } from '../slices/dashboardSlice';
import { RootState } from '../../../store';
import dataTableBgImg from '../../../../assets/img/illustrations/cornerRight-1.png';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const DashboardRoot = () => {
  const location = useLocation();
  const [tabelData, settabelData] = useState<any>({ result: [] });
  const dataLoading = useSelector(
    (state: RootState) => state.dashboard.dataLoading
  );
  const grids = useSelector(
    (state: RootState) => state.dataTable.invoiceDataGrids
  );
  const invGridName = useSelector(
    (state: RootState) => state.multiBlPayment.selectedInvoiceType == "CWPDRAFTINVOICES" ?
      "OPC_INV_DASHBOARD" : "OPC_INV_DASHBOARD_TAX"
  );
  const data = useSelector(
    (state: RootState) => state.invoiceChange.invoiceDataList
  );

  useEffect(() => {
    if (data) {
      settabelData({ hasNext: true, result: data, size: 20 });
    }
  }, [data]);

  return (
    <FilterView
      showColumnSettings={true}
      //  showPayButton={true}
      toggleColumnSettings={toggleColumnSettingsDashboard}
      showExport
      columnSettingsPersistKey='@odex/maintable/v6'
      uniqueFilterKey='invDashboard'
      dataLoading={dataLoading}
      //  configViews={grids?.find?.((g) => g?.gridId === 1)?.configGroup || []}
      configViews={
        grids?.find?.((g: any) => g?.gridName === invGridName)
          ?.configGroup || []
      }
      showRightDiv={
        !!matchPath(location.pathname, '/opc/invoice') ||
        !!matchPath(location.pathname, '/opc/home')
      }
      manualFilter
      dashboardName={invGridName}
      data={tabelData}
      gridDef={grids}
      csvByMail={false}
      hideAll={location.pathname !== '/opc/invoice' &&
              location.pathname !== '/opc/home' }
    />
  );
};

export default DashboardRoot;
