import { useParams } from "react-router-dom";
import { AESDecrypt } from "../../encrypt-util";
import PermitRequest from "../../app/features/blRequestInstruction/components/PermitRequest";
import InvoiceRequestInstruction from "../../app/features/dashboard/components/InvoiceRequestInsturction";
import DeliveryOrderInstruction from "../../app/features/dashboard/components/deliveryOrderInstruction";
import SSRRequestInstruction from "../../app/features/dashboard/components/ssrReqestInstruction";
import MpsRequest from "../../app/features/mpsRequest/MpsRequest";

const map = {
  Permit: <PermitRequest />,
  Invoice: <InvoiceRequestInstruction />,
  "Devivery Order Request": <DeliveryOrderInstruction />,
  "Invoice cut-off extension": <SSRRequestInstruction />,
  "Late Gate In": <SSRRequestInstruction />,
  "Miscellaneous Port Service": <MpsRequest/>,
  902: <PermitRequest />,
  911: <InvoiceRequestInstruction />,
  "Devivery Order Request": <DeliveryOrderInstruction />,
  903: <SSRRequestInstruction />,
  904: <MpsRequest/>,
  "Miscellaneous Port Service": <MpsRequest/>
};
const InstructionMapper = () => {
  const { requesttp } = useParams();
  const instruction = AESDecrypt(requesttp);
  return map[instruction];
};

export default InstructionMapper;
