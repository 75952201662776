import { Card } from "react-bootstrap";

import FilterView from "../../../common/components/FilterView";
import { Outlet, useLocation } from "react-router-dom";
import { toggleColumnSettingsDashboard } from "../slices/dashboardSlice";
import { RootState } from "../../../store";
import dataTableBgImg from "../../../../assets/img/illustrations/cornerRight-1.png";
import { useSelector } from "react-redux";

const DashboardRoot = () => {
  const location = useLocation();
  const dataLoading = useSelector(
    (state: RootState) => state.dashboard.dataLoading
  );
  const grids = useSelector((state: RootState) => state.dataTable.vesselGrids);

  // const data = useSelector((state: RootState) => state.instructionDashboard.data);
  const data = useSelector(
    (state: RootState) => state.dashboard.vesselDashboardList
  );

  const obldetails = useSelector(
    (state: RootState) => state.registrationDashboard.obldetails
  );

  return (
    <FilterView
      showColumnSettings
      toggleColumnSettings={toggleColumnSettingsDashboard}
      showExport
      columnSettingsPersistKey="@odex/maintable/v6"
      uniqueFilterKey="vesselDetails"
      dataLoading={dataLoading}
      //  configViews={grids?.find?.((g) => g?.gridId === 1)?.configGroup || []}
      configViews={
        grids?.find?.((g: any) => g?.gridName === "OPC_VESSEL_DASHBOARD")
          ?.configGroup || []
      }
      dashboardName="OPC_VESSEL_DASHBOARD"
      data={data}
      showRightDiv={location.pathname === "/opc/vessel-master"}
      gridDef={grids}
    />
  );
};

export default DashboardRoot;
