import { Button, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppDispatch, RootState } from "../../../store";
import { useSelector } from "react-redux";
import { matchPath, useNavigate, useParams } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { getBadges } from "../../dashboard/components/helpers";
import { useTranslation } from "react-i18next";
import { AESEncrypt } from "../../../../encrypt-util";
import { useLocation } from "react-router-dom";
import Divider from "../../../../components/common/Divider";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { AsyncThunkAction } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { updateInstructionID } from "../../blDetails/slices/documentHistorySlice";
import { axiosCacheInstance } from "../../../../axiosSetup";
import retry from "../../../../helpers/axiosRetry";

interface IProps {
  isDisabled?: boolean;
  selectedRows?: any[];
}

interface instructionTypesUpdatedType {
  sequenceNo: number;
  codeId: number;
  codeDesc: string;
}

const AddInstruction = ({ isDisabled, selectedRows }: IProps) => {
  const location = useLocation();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { blNum } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );

  const tpoId = useSelector((state: RootState) => state.agentDelegation.tpoId);

  const partnerCountry = profileDetails.partnerCountry;
  const instructionStatus = useSelector(
    (state: RootState) => state.dashboard.showInstruction
  );
  const rbac: any = useSelector((state: RootState) => state.userProfile);

  const instructionTypes = useSelector(
    (state: RootState) => state.documentHistory.instructionTypes
  );

  const standingInstructionTypes = useSelector(
    (state: RootState) => state.standingInstructionList.instructionTypes
  );

  const [dataCheck, setDataCheck] = useState({
    DOE: false,
    AD: true,
    IC: false,
    DM: false,
    RI: false,
  });
  const [instructionTypesUpdated, setInstructionTypesUpdated] = useState<
    instructionTypesUpdatedType[]
  >([]);

  const selectedLinerId = useSelector(
    (state: RootState) => state.userProfile.selectedLinerId
  );

  const [isShipper, setIsShipper] = useState(false);
  const [isConsignee, setIsConsignee] = useState(false);
  const blPartyList = useSelector(
    (state: RootState) => state.documentHistory.blPartyList
  );
  const oblForm = useSelector((state: RootState) => state.oblDetails.oblForm);
  useEffect(() => {
    blPartyList.forEach((item: any) => {
      if (
        parseInt(item.partyId) == profileDetails.partnerId &&
        item.partyType == "CZ"
      ) {
        setIsShipper(true);
      }
      if (
        parseInt(item.partyId) == profileDetails.partnerId &&
        item.partyType == "CN"
      ) {
        setIsConsignee(true);
      }
    });
  }, [blPartyList, oblForm]);
  const instructionItems = instructionTypesUpdated && instructionTypesUpdated?.map(
    ({ sequenceNo, codeId, codeDesc }) => {
      return (
        <div>
          {sequenceNo != 0 ? <></> : <></>}
          <Dropdown.Item
            disabled={
              // (codeId === 911 && accessDetails?.InvoiceRequestCUD === 'N') ||
              // (codeId === 902 && accessDetails?.PermitCUD === 'N') ||
              // (codeId === 903 && accessDetails?.ssrCUD === 'N') ||
              (codeId === 903 &&
                profileDetails.opcPartnerType !== "CFA" &&
                /* codeId === 903 && */ profileDetails.opcPartnerType !==
                "SLA") ||
              (codeId === 911 && profileDetails.opcPartnerType == "SLA") ||
              (codeId === 902 && profileDetails.opcPartnerType == "SLA")
            }
            onClick={() => {
              // dispatch(updateSelectedDocIdDH(''));
              //  dispatch(updateSelectedEventIdDH(''));
              dispatch(updateInstructionID(codeId));
              navigate(
                `${encodeURIComponent(
                  blNum || ""
                )}/opc/instruction/${AESEncrypt(codeId.toString())}${location?.search || ""
                }`
              );
            }}
          >
            {codeDesc}
          </Dropdown.Item>
        </div>
      );
    }
  );
  const standingInstructionItems = standingInstructionTypes.map(
    (option: any) => {
      return (
        <div>
          {option.index !== 0 ? <Dropdown.Divider /> : <></>}
          <Dropdown.Item
            disabled={
              option?.value === "812" && !isConsignee
                ? true
                : option?.value === "813" && !isShipper
                  ? true
                  : false
            }
            onClick={() => {
              dispatch(updateInstructionID(option.value));
              // dispatch(updateSelectedDocIdDH(''));
              //  dispatch(updateSelectedEventIdDH(''));
              navigate(`/standing/${AESEncrypt(option.value)}`);
            }}
          >
            {option.text}
          </Dropdown.Item>
        </div>
      );
    }
  );

  const getRequestTypeOptions = async () => {
    try {
      const { data: requestTypeOptions } = await retry(async () => {
        const response = await axiosCacheInstance.get(
          `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=REQUEST_TYPE&linerId=${tpoId}&countryCode=TZ`,
          {
            cache: {
              ttl: 10 * 60 * 1000,
            },
            id: 'request-type'
          }
        )
        if (response.data?.length > 0 && response.data?.[0]?.codeMstType != 'REQUEST_TYPE') {
          axiosCacheInstance.storage.remove('request-type')
          throw Error('Wrong code type in response!!')
        }
        return response
      }, 3, 1000);
      setInstructionTypesUpdated(requestTypeOptions);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getRequestTypeOptions();
  }, [tpoId]);

  return (
    <>
      {/*   {profileDetails?.partnerType !== 'liner' &&
      rbac.rbacDetails.multiInstructCreate === 'Y' &&
      location.pathname === '/opc/home' ? ( */}
      {/* <div className='d-flex justify-content-start align-items-between me-2 '>
        <Dropdown drop='down'>
          <Dropdown.Toggle
            className='no-caret-dropdown-toggle cursor-pointer z-inxe-agent'
            as='div'
          >
            <Button
              variant='falcon-primary'
              size='sm'
              style={{ marginRight: '0.5rem', position: 'relative' }}
            >
              <div className='d-flex'>
                <FontAwesomeIcon icon={faPlus} className='me-2 mt-1 ' />
                <span>Request</span>
              </div>
            </Button>
          </Dropdown.Toggle>
          <Dropdown.Menu
            style={{
              position: 'relative',
              zIndex: 1000000,
            }}
          >
            <div>
              <Dropdown.Item
                onClick={() => {
                  dispatch(updateInstructionID(805));
                  if (selectedRows)
                    navigate(
                      `/multiBl/${AESEncrypt(
                        selectedRows
                          .map((v) => v.blid)
                          .filter(
                            (value: any, index: any, self: any) =>
                              self.indexOf(value) === index
                          )
                          .join(',')
                          .toString()
                      )}/instruction/${AESEncrypt('805')}`
                    );
                }}
                disabled={isDisabled}
              >
                {t('resource_4:logisticsPartnerDelegationByConsignee')}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  dispatch(updateInstructionID(814));
                  if (selectedRows)
                    navigate(
                      `/multiBl/${AESEncrypt(
                        selectedRows
                          .map((v) => v.blid)
                          .filter(
                            (value: any, index: any, self: any) =>
                              self.indexOf(value) === index
                          )
                          .join(',')
                          .toString()
                      )}/instruction/${AESEncrypt('814')}`
                    );
                }}
                disabled={isDisabled}
              >
                {t('resource_4:logisticsPartnerDelegationByShipper')}
              </Dropdown.Item>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div> */}

      {/*  {profileDetails?.partnerType !== 'liner' &&
      rbac.rbacDetails.agentCreate === 'Y' &&
      location.pathname === '/opc/instruction' ? ( */}
      {location.pathname === "/opc/instruction" &&
        profileDetails?.opcPartnerType !== "PTO" && (
          <div className="d-flex justify-content-start align-items-between me-2">
            <Dropdown drop="down">
              <Dropdown.Toggle
                className="no-caret-dropdown-toggle cursor-pointer z-inxe-agent"
                as="div"
              >
                <Button
                  variant="falcon-primary"
                  size="sm"
                  style={{ marginRight: "0.5rem", position: "relative" }}
                >
                  <div className="d-flex">
                    <FontAwesomeIcon icon={faPlus} className="me-2 mt-1 " />
                    <span>Request</span>
                  </div>
                </Button>
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ minWidth: "106px", width: "auto" }}>
                <div>
                  {instructionItems}
                  {/* <Dropdown.Item
                  onClick={() => {
                    navigate(`/instruction/805`);
                  }}
                  disabled={false}>
                  {t('resource_4:agent_nomination_to_carrier')}
                </Dropdown.Item>
                <Divider className='mt-0' />
                <Dropdown.Item
                  onClick={() => {
                    navigate(`/instruction/809`);
                  }}
                  disabled={false}>
                  {t('resource_4:agent_nomination_to_consignee')}
                </Dropdown.Item> */}
                  {/*  <Divider /> */}
                  {/*   <Dropdown.Item
                  onClick={() => {
                    navigate(`/instruction/810`);
                  }}
                  disabled={false}>
                  {t('resource_2:blanketDelegation')}
                </Dropdown.Item> */}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      {profileDetails?.partnerType !== "liner" &&
        rbac.rbacDetails.agentCreate === "Y" &&
        location.pathname.split("/")[1] === "standing" ? (
        <div className="d-flex justify-content-start align-items-between me-2">
          <Dropdown drop="down">
            <Dropdown.Toggle
              className="no-caret-dropdown-toggle cursor-pointer z-inxe-agent"
              as="div"
            >
              <Button
                variant="falcon-primary"
                size="sm"
                style={{ marginRight: "0.5rem", position: "relative" }}
              >
                <div className="d-flex">
                  <FontAwesomeIcon icon={faPlus} className="me-2 mt-1 " />
                  <span>Instruct</span>
                </div>
              </Button>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div>{standingInstructionItems}</div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default AddInstruction;
/* function dispatch(
  arg0: AsyncThunkAction<AxiosError<unknown, any> | undefined, any, {}>
) {
  throw new Error('Function not implemented.');
} */
