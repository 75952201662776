import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
// import { addAgentNnominate } from '../actions/Instruction';
import {
  addAgentNnominate,
  getAgentNnominate,
  getBlanketDeligation,
  getCodeList,
  addMultiAgentNnominate,
  addBlanketDeligation,
} from '../actions/Instruction';
import { fetchCompanyDetailsForAgentDelegation } from '../../../dashboard/actions/instruction';
import { string } from 'yup';

interface agentAccessList {
  agentAccessId: number | string | null;
  agentNominationId: number | string | null;
  type: string | null;
  allowAccess: string | null;
}

export interface AgentNnominateDetails {
  agentNominationId: any;
  blId: string;
  requestedPartnerId: string;
  agentPartnerId: any;
  taxId: string;
  companyName: string;
  emailId: string;
  allowFreightInvoice: string;
  blNo: string;
  fileName: string;
  byteArr: string;
  address: string;
  status: string;
  timePendingInvoice: string;
  blAccess: string;
  rejectRemark: string;
  customerRemarks: string;
  requestType: string;
}

interface IAgentNnominateList {
  list: any;
  next: string | null;
  previous: string | null;
}

export interface IAgentNnominate {
  data: IAgentNnominateList;
  dataLoading: boolean;
  emailId: string;
  companyName: string;
  address: string;
  agentPartnerId: any;
  isGrantFrtInvAccess: boolean;
  isBlAccess: boolean;
  isTimePendingInvoiceAccess: boolean;
  agentPartnerName: string;
  disableFields: boolean;
  invTypeList: any[];
  agentAccessList: any[];
  blanketData: any;
  tpoId:any;
}

const initialState: IAgentNnominate = {
  data: {
    list: [],
    next: null,
    previous: null,
  },
  dataLoading: true,
  emailId: '',
  companyName: '',
  address: '',
  agentPartnerId: null,
  isGrantFrtInvAccess: false,
  isBlAccess: false,
  isTimePendingInvoiceAccess: false,
  agentPartnerName: '',
  disableFields: false,
  invTypeList: [],
  agentAccessList: [],
  blanketData: {},
  tpoId:null
};

export const AgentNnominateSlice = createSlice({
  name: 'coreor',
  initialState,
  reducers: {
    updateAgentPartnerId: (state, action: PayloadAction<any>) => {
      state.emailId = action.payload.emailId;
      state.companyName = action.payload.partnerNm;
      state.address = action.payload.address;
      state.agentPartnerId = action.payload.mstPartnerId;
    },
    clearFields: (state, action: PayloadAction<any>) => {
      state.emailId = '';
      state.companyName = '';
      state.address = '';
      state.agentPartnerId = '';
    },
    toggleGrantFrtInvAccess: (state, action: PayloadAction<boolean>) => {
      state.isGrantFrtInvAccess = action.payload;
    },
    toggleBlAccess: (state, action: PayloadAction<boolean>) => {
      state.isBlAccess = action.payload;
    },
    toggleTimePendingInvoiceAccess: (state, action: PayloadAction<boolean>) => {
      state.isTimePendingInvoiceAccess = action.payload;
    },
    toggleDisableFields: (state, action: PayloadAction<boolean>) => {
      state.disableFields = action.payload;
    },
    updateAgentAccessList: (state, action: PayloadAction<any>) => {
      state.agentAccessList = action.payload;
    },
    resetBlanket: (state, action: PayloadAction<any>) => {
      state.blanketData = {};
    },
    tpoId: (state, action: PayloadAction<boolean>) => {
      state.tpoId = action.payload;
    },
    
  },
  extraReducers: (builder) => {
    builder.addCase(addAgentNnominate.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(addAgentNnominate.fulfilled, (state: any, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });

    builder.addCase(addMultiAgentNnominate.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(addMultiAgentNnominate.fulfilled, (state: any, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });

    builder.addCase(getAgentNnominate.pending, (state: any, action) => {
      state.dataLoading = true;
      state.data = action.payload;
    });
    builder.addCase(getAgentNnominate.fulfilled, (state: any, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getBlanketDeligation.pending, (state: any, action) => {
      state.dataLoading = true;
      //state.data = action.payload;
    });
    builder.addCase(getBlanketDeligation.fulfilled, (state: any, action) => {
      state.dataLoading = false;
      state.blanketData = action.payload;
    });
    builder.addCase(
      fetchCompanyDetailsForAgentDelegation.pending,
      (state: any, action) => {
        state.dataLoading = true;
        state.data = action.payload;
      }
    );
    builder.addCase(
      fetchCompanyDetailsForAgentDelegation.fulfilled,
      (state: any, action) => {
        state.dataLoading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(getCodeList.pending, (state: any, action) => {
      state.dataLoading = true;
    });
    builder.addCase(getCodeList.fulfilled, (state: any, action) => {
      state.dataLoading = false;
      state.invTypeList = action.payload;
    });
  },
});

export const {
  updateAgentPartnerId,
  toggleGrantFrtInvAccess,
  clearFields,
  toggleDisableFields,
  toggleBlAccess,
  toggleTimePendingInvoiceAccess,
  updateAgentAccessList,
  resetBlanket,
  tpoId
} = AgentNnominateSlice.actions;

export default AgentNnominateSlice.reducer;
