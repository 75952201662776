import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import tpa from '../../assets/images/tpalogo.png'
import { Image } from 'react-bootstrap';
import HapagLogo from '../../assets/images/Hapag.png';
import { useDispatch } from 'react-redux';
import { updateSearchInputValueNT } from '../../app/common/slices/navbarTop';
import { updateDataTableFilterState } from '../../app/common/components/DataTable/slices/DataTable';
import { useSelector } from 'react-redux';

const Logo = ({ at, width, className, textClass, tpaLogo, ...rest }) => {
  const dispatch = useDispatch();
  const profileDetails = useSelector((state) => state.userProfile.profile);

  return (
    <Link
      to='opc/home'
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
      onClick={() => {
        dispatch(updateDataTableFilterState({}));
        dispatch(updateSearchInputValueNT(''));
      }}
    >
      <div
        className={classNames(
          'd-flex',
          {
            'align-items-center py-3': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center fw-bolder fs-5 mb-4': at === 'auth',
          },
          className
        )}
      >
        {/* {profileDetails?.partnerType === 'liner' ? ( 
          <div className='crossfade-logo me-2 '> 
            <img className='bottom' src={logo} alt='Logo' width={width} /> 
            <div className='d-flex justify-content-center align-items-center top'>
              <Image src={HapagLogo} height={20} /> 
            </div> 
          </div> 
        ) : ( 
          <img className='bottom' src={logo} alt='Logo' width={width} /> 
        )}  */}
        {
          tpaLogo?
          <img className='bottom' src={tpa} alt='Logo' width={width} />:
          <img className='bottom' src={logo} alt='Logo' width={width} />
        }
        {/* new */}
      </div>
    </Link>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  className: PropTypes.string,
  textClass: PropTypes.string,
};

Logo.defaultProps = { at: 'auth', width: 58 };

export default Logo;
