import { Link } from 'react-router-dom';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import team3 from '../../../assets/img/team/avatar.png';
import Avatar from '../../common/Avatar';
import LinerLogo from '../../../assets/images/liner-logo.png';
import Flex from '../../common/Flex';
import { useSelector } from 'react-redux';
import axiosInstance from '../../../axiosSetup';
import { toast } from 'react-toastify';
import Divider from '../../common/Divider';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const ProfileDropdown = () => {
  const { t } = useTranslation();
  const profileDetails = useSelector((state) => state.userProfile.profile);

  
  const app = window.location.href.includes("tpdcs") ? '.tpdcs.com' : '.odexglobal.com';
  const authLink = window.location.href.includes("tpdcs") ? 'opc-auth':'auth'
  const windowLoc = window.location.href.indexOf(app);
  const windowurl = window.location.href.substring(0, windowLoc);

  const renderTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      {profileDetails?.partnerName}
    </Tooltip>
  );
  
  return (
    <Dropdown navbar={true} as='li'>
      {/*       <Dropdown.Toggle
        bsPrefix='toggle'
        as={Link}
        to='#!'
        className='pe-0 ps-2 nav-link '>
        <Flex direction={'row'} justifyContent='end' alignItems={'center'}>
          {/*  <Avatar
            src={profileDetails?.partnerType === 'liner' ? LinerLogo : team3}
            className='ms-2'
            size='xl'
          /> */}
      {/*   <div
            className={
              profileDetails?.partnerType === 'liner'
                ? 'linerProfileIcon'
                : 'customerProfile'
            }>
            {profileDetails?.partnerType === 'liner'
              ? profileDetails?.username?.substring(0, 1)
              : profileDetails?.username?.substring(0, 1)}
          </div>
        </Flex>
      </Dropdown.Toggle> */}
      <Dropdown.Toggle
        bsPrefix='toggle'
        as={Link}
        to='#!'
        className='pe-0 ps-2 nav-link'
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '5px 10px',
            borderRadius: '8px',
            border: '1px solid #ccc',
            backgroundColor: '#f8f9fa',
          }}
        >
          <Flex direction={'row'} justifyContent='end' alignItems={'center'}>
            {/* Username */}
            <OverlayTrigger
      placement={'bottom'}
      overlay={renderTooltip}
    >
            <div data-toggle='tooltip' style={{ marginRight: '8px', fontWeight: 'bold' }}>
              {profileDetails?.partnerName?.length>25?profileDetails?.partnerName?.slice(0,25)+'...':profileDetails?.partnerName}
            </div>
     </OverlayTrigger>
            {/* Avatar or Initial */}
            <div
              className={
                profileDetails?.partnerType === 'liner'
                  ? 'linerProfileIcon'
                  : 'customerProfile'
              }
            >
              {profileDetails?.partnerType === 'liner'
                ? profileDetails?.username?.substring(0, 1)
                : profileDetails?.username?.substring(0, 1)}
            </div>
          </Flex>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className='dropdown-caret dropdown-menu-card  dropdown-menu-end'>
        <div className='bg-white rounded-2 py-2 dark__bg-1000'>
          <Dropdown.Item disabled>
            <span className='text-secondary' style={{ fontSize: '16px' }}>
              {profileDetails.username}
            </span>
          </Dropdown.Item>
          <Divider></Divider>
          <Dropdown.Item as={Link} to='/opc/user/profile'>
            {t('resource_3:ProfileAccount')}
          </Dropdown.Item>
          {profileDetails?.partnerType === 'liner' && (
            <>
              <Divider></Divider>
              <Dropdown.Item as={Link} to='/settings'>
                Settings
              </Dropdown.Item>
            </>
          )}

          <Dropdown.Divider />
          <Dropdown.Item as={Link} to='/resetPassword'>
            {t('resource_2:changePassword')}
          </Dropdown.Item>
          <Dropdown.Divider />

          <Dropdown.Item
            as='div'
            onClick={async () => {
              try {
                await axios.get(
                  //  `${process.env.REACT_APP_AUTH_SERVER_HOST}/do/logout`
                  `${windowurl}${app}/drop`
                );
                window.location.href = `${windowurl}${app}/${authLink}/do/logout`;
              } catch (error) {
                toast.error('Unable to logout');
              }
            }}
            // href='/logout'
          >
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
