export const home = (data) => {
  return {
    label: "Dashboard",
    labelDisable: true,
    children: [
      /*     data?.rbacDetails?.blDashboard === "Y" && {
        name: "BL Dashboard",
        icon: "chart-pie",
        to: "opc/home",
        active: true,
      },
      data?.rbacDetails?.invDashboard === "Y" && {
        name: "Invoice Dashboard",
        to: "/payment",
        active: false,
        icon: "shopping-cart",
      }, */
      data?.rbacDetails?.invDashboard === "Y" && {
        name: "Invoice",
        to: "/opc/home",
        active: true,
        icon: "shopping-cart",
      },
      data?.rbacDetails?.instructionDashboard === "Y" && {
        name: "Requests",
        to: "/opc/instruction",
        active: true,
        icon: "book",
      },
      data?.rbacDetails?.payDetailsDashboard === "Y" && {
        name: "Payment Details",
        to: "/opc/paymentDashboard",
        active: false,
        icon: "pay",
      },
      data?.rbacDetails?.transctionDashboard === "Y" && {
        name: "Transactions",
        to: "opc/payment-fund",
        active: false,
        icon: "credit-card",
      },
      data?.rbacDetails?.permitDashboard === "Y" && {
        name: "Permit Tracking",
        to: "/opc/permit-tracking",
        active: true,
        icon: "truck",
      },
      data?.rbacDetails?.bestPickassignmentDashboard === "Y" && {
        name: "Best Pick Assign",
        to: "/opc/bestPick",
        active: false,
        icon: "file-pen",
      },
      data?.rbacDetails?.doDashboard === "Y" && {
        name: "DO Upload",
        active: false,
        icon: "report",
        to: "/opc/doupload",
      },
      data?.rbacDetails?.enquiryBlDashboard === "Y" && {
        name: "Invoiced BLs",
        active: false,
        icon: "chart-pie",
        to: "/opc/blinclusions",
      },
      data?.rbacDetails?.enquiryContainerDashboard === "Y" && {
        name: "Container Status",
        active: false,
        icon: "clipboard",
        to: "/opc/enqContainer",
      },
      [
        data?.rbacDetails?.userMgmtCreate,
        data?.rbacDetails?.userMgmtView,
        data?.rbacDetails?.vesselMasterDashboard,
        data?.rbacDetails?.roeDashboard,
      ].includes("Y") && {
        to: "/",
        name: "Masters",
        active: true,
        icon: "master",
        children: [
          (data?.rbacDetails?.userMgmtCreate === "Y" ||
            data?.rbacDetails?.userMgmtView === "Y") && {
            name: "Users",
            to: "/opc/users",
            active: true,
            icon: "user",
          },
          data?.rbacDetails?.vesselMasterDashboard === "Y" && {
            name: "Vessel Master",
            to: "/opc/vessel-master",
            active: true,
            icon: "ship",
          },
          data?.rbacDetails?.roeDashboard === "Y" && {
            name: "Rate Of Exchange",
            to: "/opc/roe",
            active: true,
            icon: "exchange",
          },
        ].filter((i) => i), // hidden routes will break the screen if not filtered
      },
      // data?.rbacDetails?.standingInstDashboard === "Y" && {
      //   name: "Standing Instruction",
      //   to: "/standing",
      //   active: false,
      //   icon: "list-ul",
      // },
      // data?.rbacDetails?.reportDashboard === "Y" && {
      //   name: "Report",
      //   to: "/report",
      //   active: false,
      //   icon: "report",
      // },
    ].filter((i) => i),
  };
};

const getRoutes = (data) => {
  return [home(data)];
};

export default getRoutes;
