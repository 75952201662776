import axios, { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../axiosSetup";

export const findCustomerByTaxId = createAsyncThunk(
    'dataTable/findCustomerByTaxId',
    async (requestPayload: any) => {
      try {
        const response = await axiosInstance.post(`api/user-management/v1/customer/findCustomerByTaxId?countryCode=TZ&taxId=${requestPayload.value}`);
  
        return response;
      } catch (e) {
        return e;      
      }
    }
  );
  