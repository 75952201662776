
import FilterView from '../../../common/components/FilterView';
import { matchPath, useLocation } from 'react-router-dom';
import { toggleColumnSettingsDashboard } from '../slices/dashboardSlice';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';

const DashboardRoot = () => {
  const location = useLocation();
  const dataLoading = useSelector(
    (state: RootState) => state.dashboard.dataLoading
  );
  const grids = useSelector((state: RootState) => state.dataTable.userGrids);

  // const data = useSelector((state: RootState) => state.instructionDashboard.data);
  const data = useSelector(
    (state: RootState) => state.dashboard.userDashboardList
  );

  const rbac = useSelector(
    (state: RootState) => state.userProfile.rbacDetails
  )

  return (
    <FilterView
      showColumnSettings
      toggleColumnSettings={toggleColumnSettingsDashboard}
      showExport
      columnSettingsPersistKey='@odex/maintable/v6'
      uniqueFilterKey='usersDetails'
      dataLoading={dataLoading}
      //  configViews={grids?.find?.((g) => g?.gridId === 1)?.configGroup || []}
      configViews={
        grids?.find?.((g: any) => g?.gridName === 'OPC_USER_DASHBOARD')
          ?.configGroup || []
      }
      dashboardName='OPC_USER_DASHBOARD'
      data={data}
      showRightDiv={location.pathname === '/opc/users'}
      gridDef={grids}
      showAddUserButton={matchPath("opc/users", location.pathname) &&
        rbac.userMgmtCreate == 'Y'}
    />
  );
};

export default DashboardRoot;
