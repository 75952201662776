import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
// import { BLDetailsList } from '../types';
//todo bllist
import {
  // getBlDashboardDefinition,
  getBlsList,
  getInstructionsListFilter,
  searchWithBlno,
  searchWithContainerno,
  searchWithInternalVoyageNo,
  searchWithInternalVoyageNoPaymentDashboard,
  searchWithShipmentNoBlDashboard,
  searchWithShipmentno,
  getInstructionListWithBlnumber,
  // getInstructionList,
} from "../../dashboard/actions/dashboard";
import { IDataTableColumn } from "../../../common/types/dataTable";
import { IConfigsDefinition } from "../../../utils/columnConfig";
import {
  bestPickCodes,
  bestPickGroupID,
  bestPickTotalContainers,
  bestPickView,
  getRefNo,
  ICDCode,
} from "../action/BestPickDetails";

interface addbestPickSlice {
  data: any;
  showPayment: any;
  registrationMasterDisable: any;
  showInstruction: any;
  showRelease: any;
  dataLoading: boolean;
  showColumnSettings: boolean;
  showBlExists: boolean;
  showAddAgent: boolean;
  showNonimationReq: boolean;
  enableAddAgentButton: boolean;
  blDashboardDefinition: any[];
  blDashboardDefinitionLoading: boolean;
  columns: IDataTableColumn[];
  configs: IConfigsDefinition;
  setDefaultViewBL: boolean;
  setDefaultViewInvoice: boolean;
  obldetails: boolean;
  // columnConfiguration: IDataTableColumn[];
  refnoVal: any;
  bestPickViewVal: any;
  bestPickContainerVal: any;
  ICDCodeVal: any;
  bestPickGroupIDval: any;
  bestPickCodesVal: any;
}

const initialState: addbestPickSlice = {
  data: {
    next: null,
    previous: null,
    list: [],
  },
  dataLoading: true,
  showPayment: "",
  showInstruction: "",
  showRelease: "",
  showColumnSettings: false,
  showAddAgent: false,
  showBlExists: false,
  enableAddAgentButton: false,
  registrationMasterDisable: false,
  showNonimationReq: false,
  blDashboardDefinition: [],
  blDashboardDefinitionLoading: false,
  columns: [],
  configs: {
    columns: [],
    staticHiddenCols: [],
    defaultHiddenCols: [],
  },
  setDefaultViewBL: true,
  setDefaultViewInvoice: true,
  obldetails: false,

  // columnConfiguration: columnConfig,
  refnoVal: "",
  bestPickViewVal: {},
  bestPickContainerVal: "",
  ICDCodeVal: "",
  bestPickGroupIDval: [],
  bestPickCodesVal: {},
};

export const addbestPickSlice = createSlice({
  name: "bestChoiceDashboard",
  initialState,
  reducers: {
    updateSetDefaultViewBL: (state, action: PayloadAction<boolean>) => {
      state.setDefaultViewBL = action.payload;
    },
    updateSetDefaultViewInvoice: (state, action: PayloadAction<boolean>) => {
      state.setDefaultViewInvoice = action.payload;
    },
    updateRegistrationMaster: (state, action: PayloadAction<boolean>) => {
      state.registrationMasterDisable = action.payload;
    },

    updateColumnDefinitionConfigs: (
      state,
      action: PayloadAction<IConfigsDefinition>
    ) => {
      state.configs = action.payload;
    },
    updateColumnDefinition: (
      state,
      action: PayloadAction<IDataTableColumn[]>
    ) => {
      state.columns = action.payload;
    },
    toggleColumnSettingsDashboard: (state, action: PayloadAction<boolean>) => {
      state.showColumnSettings = action.payload;
    },
    toggleAddAgent: (state, action: PayloadAction<boolean>) => {
      state.showAddAgent = action.payload;
    },
    toggleIsBlExists: (state, action: PayloadAction<boolean>) => {
      state.showBlExists = action.payload;
    },
    toggleAddAgentButton: (state, action: PayloadAction<boolean>) => {
      state.enableAddAgentButton = action.payload;
    },
    toggleNominationReq: (state, action: PayloadAction<boolean>) => {
      state.showNonimationReq = action.payload;
    },
    toggleObldetails: (state, action: PayloadAction<boolean>) => {
      state.obldetails = action.payload;
    },
    bestPickCodesValChange: (state, action: PayloadAction<any>) => {
      state.bestPickCodesVal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRefNo.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(getRefNo.fulfilled, (state, action) => {
      state.dataLoading = false;

      state.refnoVal = action.payload;
    });

    builder.addCase(bestPickTotalContainers.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(bestPickTotalContainers.fulfilled, (state, action) => {
      state.dataLoading = false;

      state.bestPickContainerVal = action.payload;
    });

    builder.addCase(ICDCode.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(ICDCode.fulfilled, (state, action) => {
      state.dataLoading = false;

      state.ICDCodeVal = action.payload;
    });

    builder.addCase(bestPickGroupID.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(bestPickGroupID.fulfilled, (state, action) => {
      state.dataLoading = false;

      state.bestPickGroupIDval = action.payload;
    });

    builder.addCase(bestPickCodes.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(bestPickCodes.fulfilled, (state, action) => {
      state.dataLoading = false;

      state.bestPickCodesVal = action.payload;
    });

    builder.addCase(bestPickView.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(bestPickView.fulfilled, (state, action) => {
      state.dataLoading = false;

      state.bestPickViewVal = action.payload;
    });
  },
});

export const {
  updateSetDefaultViewBL,
  updateColumnDefinitionConfigs,
  updateColumnDefinition,
  updateSetDefaultViewInvoice,
  toggleColumnSettingsDashboard,
  toggleAddAgent,
  toggleAddAgentButton,
  toggleNominationReq,
  toggleIsBlExists,
  toggleObldetails,
  bestPickCodesValChange,
  updateRegistrationMaster,
} = addbestPickSlice.actions;

export default addbestPickSlice.reducer;
