import { useTranslation } from 'react-i18next';
import { faArrowAltCircleLeft } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMailBulk, faRedo, faRocket, faSave, } from '@fortawesome/free-solid-svg-icons';
import { t } from "i18next";
import { Button, Card, Form } from "react-bootstrap";
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import useDataCountry from '../../../../hooks/useDataCountry';
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { getDateFormat } from '../../../../helpers/dateFormat';
import { sendQRDetailsReq } from '../../dashboard/actions/instruction';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { Item } from 'react-bootstrap/lib/Breadcrumb';
import { AESDecrypt, AESEncrypt, AESEncryptforDashboard, Base64Encode } from '../../../../encrypt-util';
import { useDispatch } from 'react-redux';
import { useBackNavigation } from '../../../../hooks/BackNavigation';
import { toast } from 'react-toastify';
import { clearData } from '../../dashboard/slices/snoozeSlice';
import { updateDashbaordRefresh } from '../../../common/components/DataTable/slices/DataTable';



const SendQrDetails = () => {

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { blEventId, blNum } = useParams();
  const bl_event_id = AESDecrypt(blEventId)
  const blnum = AESDecrypt(blNum)
  const dispatch = useDispatch<AppDispatch>();
  const userData = location.state;

  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );


  const permitDashboardData = useSelector(
    (state: RootState) => state.invoiceChange.permitDashboardData
  );

  const [sendData, setSendData] = useState<any>({})
  const permitTypeData = sendData.permitType;
  const appointmentNbrData = sendData.permitNbr;
  const ctrIdData = sendData.ctrId;
  const tpoId = useSelector((state: RootState) => state.agentDelegation.tpoId);

  const { permitNbr } = useParams()
  const permitNoDecrept = AESDecrypt(permitNbr);
  useEffect(() => {
    setSendData(permitDashboardData.find((item: any) => item.permitNbr == permitNoDecrept) ?? null)
  }, [permitNoDecrept, permitDashboardData])


  const selectedData: any = useSelector(
    (state: RootState) => state.dashboard.instructionDashboardList.result.filter((Item: any) => Item.bl_event_id == bl_event_id)
  );

  const blEventId_docHistory: any = useSelector(
    (state: RootState) => state.documentHistory.data?.[0]?.blEventId
  );

  const data = useSelector((state: RootState) => state.snooze.snoozeData)

  const demrageEmail = useSelector(
    (state: RootState) => state.demurrageViewSlice.demurrageForm.senderEmail)

  const demrageStatus = useSelector(
    (state: RootState) => state.demurrageViewSlice.demurrageForm?.status)

  const blNumber = useSelector(
    (state: RootState) => state.oblDetails.oblForm.number)

  const instructionType = useSelector(
    (state: RootState) => state.documentHistory.data.filter((item) => item.blEventId == bl_event_id ? item.document : '')
  );


  const onBack = useBackNavigation();
  const _onCloseForm = () => {
    if (location.pathname.split('/')[1] === 'instruction') {
      navigate('/instruction');
    } else {
      navigate(-1);
    }
  }

  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );
  const dataCountry = useDataCountry();




  type FormValues = {
    emailTo: string;
    remarks: string;
  };

  const validationSchema = Yup.object().shape({

    remarks: Yup.string().when('externalRemarks', {
      is: true, then: Yup.string().required(
        t('resource_3:isRequired', { field: t('resource_2:userRemark') })
      ), otherwise: Yup.string()
    }),
    emailTo: Yup.array()
      .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue ? originalValue.split(/[\s,]+/) : [];
      }).of(Yup.string().typeError('is not a valid email').email(({ value }) => `${value} is not a valid email`)),
  });


  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const disableFields = !![
    { path: 'instruction/viewSnooze' },
  ].some(({ path, condition }: { path: string; condition?: boolean }) => {
    return (
      (condition ?? true) &&
      matchPath({ path, caseSensitive: true, end: true }, location.pathname)
    );
  });

  const resetForm = () => {
    // setNoOfDays(null);

    setValue('emailTo', '')
    setValue('remarks', '')
  };

  const onSubmit = handleSubmit(async (payload) => {
    try {
      dispatch(
        sendQRDetailsReq({
          ...payload,
          permitType: Base64Encode(AESEncryptforDashboard(permitTypeData)),
          appointmentNbr: Base64Encode(AESEncryptforDashboard(appointmentNbrData)),
          ctrId: Base64Encode(AESEncryptforDashboard(ctrIdData)),
          tpoId: tpoId,
          countryCode: 'TZ',
        })
      ).unwrap()
        .then((response: any) => {
          toast.success("QR code send successfully", { toastId: '9b1507c' });
          dispatch(updateDashbaordRefresh(true))
        });
    } catch (err: any) {
      console.error(err.message)
    }
    onBack()
  });




  return (
    <Card className='h-100 overflow-auto'>
      <Card.Header className='d-flex align-items-center border-bottom sticky-top bg-white'>
        <FontAwesomeIcon
          icon={faArrowAltCircleLeft}
          className='text-warning cursor-pointer fa-lg mb-1 me-1'
          onClick={_onCloseForm}
        />

        <h2 className='fs-0 text-primary mb-1'>
          {t('resource_5:sendQrDetailsViaEmail')}
        </h2>
      </Card.Header>
      <Card.Body>
        <div className='container'>
          <Form onSubmit={onSubmit} autoComplete='off'>
            <div className='row'>
              {profileDetails.partnerType === 'customer' ? <></> : <div className='col-lg-12 mb-3'>
                <Form.Group controlId='invoiceChanges.emailTo'>
                  <Form.Label className='fs--1'>
                    {t('resource_2:emailTo')}&nbsp;
                  </Form.Label>
                  <Form.Control
                    {...register('emailTo')}
                    className={`fs--1 form-control ${errors.emailTo ? 'is-invalid' : ''
                      }`}
                    type='text'
                    maxLength={200}
                    onChange={({ target }) => {
                      setValue('emailTo', target.value);
                    }}
                    value={watch('emailTo')}
                    disabled={disableFields}
                  />
                  <div className='invalid-feedback'>
                    {(() => {
                      const errorList: any = errors.emailTo
                      return errorList?.reduce?.((a: any, b: any) => a + ((a.trim() == '' || (b?.message ?? '').trim() == '') ? '' : ', ') + (b?.message ?? ''), '') ?? ''
                    })()}
                  </div>
                </Form.Group>
              </div>}

              <div className='col-lg-12 mb-3'>
                <Form.Group controlId='pickUpInstruction.userSnoozeRemark'>
                  <Form.Label className='fs--1'>
                    {t('resource_1:remark')}
                  </Form.Label>
                  <Form.Control
                    {...register('remarks')}
                    className={`fs--1 form-control ${errors.remarks ? 'is-invalid' : ''
                      }`}
                    as='textarea'
                    onChange={(e) => setValue('remarks', e.target.value)}
                    maxLength={100}
                    value={watch('remarks')}
                    disabled={disableFields}
                  />
                  <div className='invalid-feedback'>
                    {errors.remarks?.message?.toString()}
                  </div>
                </Form.Group>
              </div>
              <div className='d-flex justify-content-center align-items-center'>
                <Button
                  className='me-2'
                  variant='falcon-primary'
                  size='sm'
                  type='submit'
                  onClick={onSubmit}
                  disabled={
                    watch('emailTo') != '' ? false : true
                  }
                >
                  <FontAwesomeIcon icon={faMailBulk} className='me-2' style={{ color: '#f5822a' }} />
                  {t('resource_1:send')}
                </Button>
                {/* 
                        {profileDetails.partnerType==='customer'? <></> : <Button
                          className='me-2'
                          variant='falcon-danger'
                          size='sm'
                          onClick={resetForm}
                          disabled={
                            location.pathname.includes('bl')? false:
                            selectedData[0]?.status==='Submitted' && selectedData[0]?.inst_type==='Container Release Request' ? false : true
                          }
                        >
                          <FontAwesomeIcon icon={faRedo} className='me-2' />
                          {t('resource_1:reset')}
                        </Button>} */}
              </div>


            </div>
          </Form>
        </div>
      </Card.Body>
    </Card>
  )
}

export default SendQrDetails;

