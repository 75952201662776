import { Link } from 'react-router-dom';
import { Dropdown, Button } from 'react-bootstrap';
import team3 from '../../../assets/img/team/3.jpg';
import Avatar from '../../common/Avatar';
import LinerLogo from '../../../assets/images/liner-logo.png';
import Flex from '../../common/Flex';
import { useSelector } from 'react-redux';
import axiosInstance from '../../../axiosSetup';
import { toast } from 'react-toastify';
import Divider from '../../common/Divider';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMailchimp,
  faYoutube,
  faYoutubeSquare,
} from '@fortawesome/free-brands-svg-icons';
import {
  faFileDownload,
  faGear,
  faHandsHelping,
  faMailBulk,
  faMailReplyAll,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCircleQuestion,
  faEnvelope,
  faQuestionCircle,
} from '@fortawesome/free-regular-svg-icons';

const HelpDocDropDown = () => {
  const { t } = useTranslation();
  const profileDetails = useSelector((state) => state.userProfile.profile);
  const windowLoc = window.location.href.indexOf('.tpdcs.com');
  const windowurl = window.location.href.substring(0, windowLoc);

  const blDashboardPDF = () => {
    window.open('/pdf/BLDashboardUpdated.pdf', '_blank');
  };

  const blDashboardYouTube = () => {
    window.open(
      'https://www.youtube.com/watch?v=cOcEfsZ7fIs&list=PLwjmkgX41C4voqajNCPhsjNx7IwPgWrMx&index=10',
      '_blank'
    );
  };

  const invoiceDashboardPDF = () => {
    window.open('/pdf/InvoiceDashboardUpdated.pdf', '_blank');
  };

  const invoiceDashboardYouTube = () => {
    window.open(
      'https://www.youtube.com/watch?v=oY6lrmN-9Zw&list=PLwjmkgX41C4voqajNCPhsjNx7IwPgWrMx&index=11',
      '_blank'
    );
  };

  const registrationPDF = () => {
    const newWindow = window.open('/pdf/ODeXRegistrationUpdated.pdf', '_blank');
    newWindow.document.title = 'Registration Manual';
  };

  const registrationYouTube = () => {
    window.open(
      'https://www.youtube.com/watch?v=BTOB5CzNryY&list=PLwjmkgX41C4voqajNCPhsjNx7IwPgWrMx&index=13',
      '_blank'
    );
  };

  const invoicePaymentPDF = () => {
    window.open('/pdf/InvoicePaymentsUpdated.pdf', '_blank');
  };

  const invoicePaymentYouTube = () => {
    window.open(
      'https://www.youtube.com/watch?v=ZCSROu6iM_E&list=PLwjmkgX41C4voqajNCPhsjNx7IwPgWrMx&index=9',
      '_blank'
    );
  };

  const deliveryInstruction = () => {
    window.open(
      '/pdf/instructionDeliveryOrderEntryRequestUpdated.pdf',
      '_blank'
    );
  };

  const deliveryInstructionYouTube = () => {
    window.open(
      'https://www.youtube.com/watch?v=BnB8Ahtq7zU&list=PLwjmkgX41C4voqajNCPhsjNx7IwPgWrMx&index=8',
      '_blank'
    );
  };

  const logisticsPartnerDelegationInstruction = () => {
    window.open(
      '/pdf/InstructionLogisticsPartnerDelegationUpdated.pdf',
      '_blank'
    );
  };

  const logisticsPartnerDelegationInstructionYouTube = () => {
    window.open(
      ' https://www.youtube.com/watch?v=67fKiZRo_lY&list=PLwjmkgX41C4voqajNCPhsjNx7IwPgWrMx&index=7',
      '_blank'
    );
  };

  const demurrageInvoiceRequestInstruction = () => {
    window.open('/pdf/InstructionDemurrageInvoiceRequestUpdated.pdf', '_blank');
  };

  const demurrageInvoiceRequestInstructionYouTube = () => {
    window.open(
      'https://www.youtube.com/watch?v=qxxqYUHP19k&list=PLwjmkgX41C4voqajNCPhsjNx7IwPgWrMx&index=12',
      '_blank'
    );
  };

  const blanketDelegationUS = () => {
    window.open('/pdf/blanketDelegationUS.pdf', '_blank');
  };

  const reverseDelegationforConsigneeApprovalUs = () => {
    window.open('/pdf/reverseDelegationforConsigneeApprovalUs.pdf', '_blank');
  };

  const delegationRequestConsigneeAgentUs = () => {
    window.open('/pdf/delegationRequestConsigneeAgentUs.pdf', '_blank');
  };

  const paycargoUserManualUs = () => {
    window.open('/pdf/paycargoUserManualUs.pdf', '_blank');
  };

  /* SG Help Document */
  const customerInvoicePaymentsSingapore = () => {
    const newWindow = window.open(
      '/pdf/SG/customerInvoicePaymentsSingapore.pdf',
      '_blank'
    );
    newWindow.document.title = 'Customer Invoice Payments Singapore';
  };

  const customerLogisticsPartnerDelegationSingapore = () => {
    const newWindow = window.open(
      '/pdf/SG/customerLogisticsPartnerDelegationSingapore.pdf',
      '_blank'
    );
    newWindow.document.title = 'Customer Invoice Payments Singapore';
  };

  const customerBlanketDelegationSingapore = () => {
    const newWindow = window.open(
      '/pdf/SG/customerBlanketDelegationSingapore.pdf',
      '_blank'
    );
    newWindow.document.title = 'Customer Invoice Payments Singapore';
  };

  const customerContainerReleaseRequestInstructionSingapore = () => {
    const newWindow = window.open(
      '/pdf/SG/customerContainerReleaseRequestInstructionSingapore.pdf',
      '_blank'
    );
    newWindow.document.title = 'Customer Invoice Payments Singapore';
  };

  const customerBLDashboardSingapore = () => {
    const newWindow = window.open(
      '/pdf/SG/customerBLDashboardSingapore.pdf',
      '_blank'
    );
    newWindow.document.title = 'BL Dashboard Singapore';
  };

  const usersManagementManualSingapore = () => {
    const newWindow = window.open(
      '/pdf/SG/usersManagementManualSingapore.pdf',
      '_blank'
    );
    newWindow.document.title = 'Users Management Manual Singapore';
  };

  const customerInvoiceDashboardSingapore = () => {
    const newWindow = window.open(
      '/pdf/SG/customerInvoiceDashboardSingapore.pdf',
      '_blank'
    );
    newWindow.document.title = ' Invoice Dashboard Singapore';
  };

  /* ZA Help Document */

  const zaRegistrationPDF = () => {
    const newWindow = window.open('/pdf/ZA/zaODeXRegistration.pdf', '_blank');
    newWindow.document.title = 'Registration Manual';
  };

  const zaUserDashboard = () => {
    const newWindow = window.open(
      '/pdf/ZA/zaUserDashboardManual.pdf',
      '_blank'
    );
    newWindow.document.title = 'User Dashboard Manual';
  };

  const zaBLDashboardCustomer = () => {
    const newWindow = window.open(
      '/pdf/ZA/zaBLDashboardCustomer.pdf',
      '_blank'
    );
    newWindow.document.title = 'BL Dashboard ';
  };

  const zaInvoiceDashboardCustomer = () => {
    const newWindow = window.open(
      '/pdf/ZA/zaInvoiceDashboardCustomer.pdf',
      '_blank'
    );
    newWindow.document.title = 'Invoice Dashboard ';
  };

  const zaCarrierHaulageInstructionCustomer = () => {
    const newWindow = window.open(
      '/pdf/ZA/zaCarrierHaulageInstructionCustomer.pdf',
      '_blank'
    );
    newWindow.document.title = 'Carrier Haulage Instruction ';
  };

  const zaContainerReleaseRequestInstructionCustomer = () => {
    const newWindow = window.open(
      '/pdf/ZA/zaContainerReleaseRequestInstructionCustomer.pdf',
      '_blank'
    );
    newWindow.document.title = 'Container Release Request Instruction';
  };

  const zaBlanketDelegation = () => {
    const newWindow = window.open('/pdf/ZA/zaBlanketDelegation.pdf', '_blank');
    newWindow.document.title = 'Blanket Delegation Manual';
  };

  const zaDelegationRequesttoCarrierbyAgent = () => {
    const newWindow = window.open(
      '/pdf/ZA/zaDelegationRequesttoCarrierbyAgent.pdf',
      '_blank'
    );
    newWindow.document.title = 'Delegation Request to Carrier by Agent';
  };

  const zaDelegationRequestToConsigneeByAgent = () => {
    const newWindow = window.open(
      '/pdf/ZA/zaDelegationRequestToConsigneeByAgent.pdf',
      '_blank'
    );
    newWindow.document.title = 'Delegation Request To Consignee By Agent';
  };

  const zaInvoiceInstructionCustomer = () => {
    const newWindow = window.open(
      '/pdf/ZA/zaInvoiceInstructionCustomer.pdf',
      '_blank'
    );
    newWindow.document.title = 'Invoice Instruction Customer';
  };

  const zaInvoicePaymentsCustomer = () => {
    const newWindow = window.open(
      '/pdf/ZA/zaInvoicePaymentsCustomer.pdf',
      '_blank'
    );
    newWindow.document.title = 'Invoice Instruction Customer';
  };

  const zaLogisticsPartnerInstructionCustomer = () => {
    const newWindow = window.open(
      '/pdf/ZA/zaLogisticsPartnerInstructionCustomer.pdf',
      '_blank'
    );
    newWindow.document.title = 'Invoice Instruction Customer';
  };

  const zaPickupInstructionCustomer = () => {
    const newWindow = window.open(
      '/pdf/ZA/zaPickupInstructionCustomer.pdf',
      '_blank'
    );
    newWindow.document.title = 'Invoice Instruction Customer';
  };

  const zaReverseDelegationForConsigneeApproval = () => {
    const newWindow = window.open(
      '/pdf/ZA/zaReverseDelegationForConsigneeApproval.pdf',
      '_blank'
    );
    newWindow.document.title = 'Invoice Instruction Customer';
  };

  return (
    <Dropdown navbar={true} as='li'>
      {(profileDetails?.partnerType === 'customer' &&
        profileDetails?.partnerCountry === 'US') ||
      profileDetails?.partnerCountry === 'SG' ||
      profileDetails?.partnerCountry === 'ZA' ? (
        <Dropdown.Toggle
          bsPrefix='toggle'
          as={Link}
          to='#!'
          className='pe-0 ps-2 nav-link '>
          <Flex
            direction={'row'}
            justifyContent='end'
            alignItems={'center'}
            alignContent=''
            inline=''
            wrap=''
            className=''
            children=''
            breakpoint=''>
            {profileDetails?.partnerType === 'customer' ? (
              <Button
                variant='ms-3 btn btn-falcon-primary z-inxe-agent btn-sm'
                size='sm'
                title='Help Document'
                className='ms-3'>
                <FontAwesomeIcon
                  icon={faCircleQuestion}
                  className='me-2 '
                  style={{ fontSize: '14px', color: '#f5822a' }}
                  title='Help Document'
                />
                <span>Help</span>
              </Button>
            ) : (
              ''
            )}{' '}
            {
              /*<Avatar
            src={
              profileDetails?.partnerType === 'liner'
                ? LinerLogo
                : faFileDownload
            }
            className='ms-2'
            size='xl'
        /> */
            }
          </Flex>
        </Dropdown.Toggle>
      ) : (
        ''
      )}

      <Dropdown.Menu className='dropdown-caret dropdown-menu-card  dropdown-menu-end'>
        {profileDetails?.partnerType === 'customer' &&
        profileDetails?.partnerCountry === 'US' ? (
          <div className='bg-white rounded-2 py-2 dark__bg-1000'>
            <Dropdown.Item>
              <div class='row g-2 align-items-sm-center'>
                <div class='col-auto'> Support Mail</div>
                <div class='col'>
                  <div class='row align-items-center'>
                    <div class='col col-lg-8'>
                      <span style={{ fontSize: '16px !important' }}>
                        {' '}
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          className='me-2 '
                          style={{
                            fontSize: '16px !important',
                            color: '#f5822a',
                          }}
                          title='Support Mail'
                        />{' '}
                      </span>
                      <span class='fs-0 mb-3 mb-sm-0 text-primary'>
                        us.support@odexglobal.com
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-5'>Registration</div>
                <div>
                  <span onClick={registrationPDF} to={''}>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>{' '}
                  <span onClick={registrationYouTube} to={''}>
                    <FontAwesomeIcon icon={faYoutube} className='me-2 ' />
                  </span>
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-5'>BL Dashboard</div>
                <div>
                  <span onClick={blDashboardPDF} to={''}>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>
                  <span onClick={blDashboardYouTube} to={''}>
                    <FontAwesomeIcon icon={faYoutube} className='me-2  ' />
                  </span>
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-3'>Invoice Dashboard</div>
                <div>
                  <span onClick={invoiceDashboardPDF} to={''}>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>{' '}
                  <span onClick={invoiceDashboardYouTube} to={''}>
                    <FontAwesomeIcon icon={faYoutube} className='me-2 ' />
                  </span>
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-3'>Invoice Payment</div>
                <div>
                  <span onClick={invoicePaymentPDF} to={''}>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>{' '}
                  <span onClick={invoicePaymentYouTube} to={''}>
                    <FontAwesomeIcon icon={faYoutube} className='me-2 ' />
                  </span>
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div>Instruction Delivery</div>
                <div>
                  <span onClick={deliveryInstruction} to={''}>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>
                  <span onClick={deliveryInstructionYouTube} to={''}>
                    <FontAwesomeIcon icon={faYoutube} className='me-2 ' />
                  </span>
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-3'>Instruction Logistics</div>
                <div>
                  <span onClick={logisticsPartnerDelegationInstruction} to={''}>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2  ' />
                  </span>
                  <span
                    onClick={logisticsPartnerDelegationInstructionYouTube}
                    to={''}>
                    <FontAwesomeIcon icon={faYoutube} className='me-2 ' />
                  </span>
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-3'>Instruction Demurrage </div>
                <div>
                  <span onClick={demurrageInvoiceRequestInstruction} to={''}>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>{' '}
                  <span
                    onClick={demurrageInvoiceRequestInstructionYouTube}
                    to={''}>
                    <FontAwesomeIcon icon={faYoutube} className='me-2 ' />
                  </span>
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-3'> Blanket Delegation</div>
                <div>
                  <span onClick={blanketDelegationUS} to={''}>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>{' '}
                  <span>&nbsp; &nbsp; &nbsp; &nbsp;</span>
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-3'>
                  Reverse Delegation for Consignee Approval
                </div>
                <div>
                  <span
                    onClick={reverseDelegationforConsigneeApprovalUs}
                    to={''}>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>{' '}
                  <span>&nbsp; &nbsp; &nbsp; &nbsp;</span>
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-3'>Delegation Request Consignee Agent </div>
                <div>
                  <span onClick={delegationRequestConsigneeAgentUs} to={''}>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>{' '}
                  <span>&nbsp; &nbsp; &nbsp; &nbsp;</span>
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-3'>Pay Cargo User Manual </div>
                <div>
                  <span onClick={paycargoUserManualUs} to={''}>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>{' '}
                  <span>&nbsp; &nbsp; &nbsp; &nbsp;</span>
                </div>
              </div>
            </Dropdown.Item>
          </div>
        ) : profileDetails?.partnerType === 'customer' &&
          profileDetails?.partnerCountry === 'SG' ? (
          <div className='bg-white rounded-2 py-2 dark__bg-1000'>
            <Dropdown.Item className='d-none'>
              <div class='row g-2 align-items-sm-center'>
                <div class='col-auto'> Support Mail</div>
                <div class='col'>
                  <div class='row align-items-center'>
                    <div class='col col-lg-8'>
                      <span style={{ fontSize: '16px !important' }}>
                        {' '}
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          className='me-2 '
                          style={{
                            fontSize: '16px !important',
                            color: '#f5822a',
                          }}
                          title='Support Mail'
                        />{' '}
                      </span>
                      <span class='fs-0 mb-3 mb-sm-0 text-primary'>
                        us.support@odexglobal.com
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Item onClick={usersManagementManualSingapore}>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-5 '>Users Management </div>
                <div>
                  <span>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>{' '}
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={customerBLDashboardSingapore} to={''}>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-5 pointerEventNone'>BL Dashboard</div>
                <div>
                  <span>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>{' '}
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={customerInvoiceDashboardSingapore} to={''}>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-5 pointerEventNone'>Invoice Dashboard </div>
                <div>
                  <span>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>{' '}
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={customerInvoicePaymentsSingapore} to={''}>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-5 pointerEventNone'>Invoice Payments</div>
                <div>
                  <span>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>{' '}
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              onClick={customerContainerReleaseRequestInstructionSingapore}>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-5 pointerEventNone'>
                  Container Release Request{' '}
                </div>
                <div>
                  <span to={''}>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>{' '}
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              onClick={customerLogisticsPartnerDelegationSingapore}>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-5 pointerEventNone'>
                  Logistics Partner Delegation{' '}
                </div>
                <div>
                  <span to={''}>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>{' '}
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={customerBlanketDelegationSingapore}>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-5 pointerEventNone'>Blanket Delegation </div>
                <div>
                  <span to={''}>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>{' '}
                </div>
              </div>
            </Dropdown.Item>
          </div>
        ) : profileDetails?.partnerType === 'customer' &&
          profileDetails?.partnerCountry === 'ZA' ? (
          <div className='bg-white rounded-2 py-2 dark__bg-1000'>
            <Dropdown.Item className='d-none'>
              <div class='row g-2 align-items-sm-center'>
                <div class='col-auto'> Support Mail</div>
                <div class='col'>
                  <div class='row align-items-center'>
                    <div class='col col-lg-8'>
                      <span style={{ fontSize: '16px !important' }}>
                        {' '}
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          className='me-2 '
                          style={{
                            fontSize: '16px !important',
                            color: '#f5822a',
                          }}
                          title='Support Mail'
                        />{' '}
                      </span>
                      <span class='fs-0 mb-3 mb-sm-0 text-primary'>
                        us.support@odexglobal.com
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Dropdown.Item>

            <Dropdown.Item onClick={zaUserDashboard} to={''}>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-5 pointerEventNone'>User Dashboard</div>
                <div>
                  <span>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>{' '}
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={zaBLDashboardCustomer} to={''}>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-5 pointerEventNone'>BL Dashboard </div>
                <div>
                  <span>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>{' '}
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={zaInvoiceDashboardCustomer} to={''}>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-5 pointerEventNone'>Invoice Dashboard</div>
                <div>
                  <span>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>{' '}
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={zaInvoicePaymentsCustomer}>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-5 pointerEventNone'>Invoice Payments </div>
                <div>
                  <span to={''}>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>{' '}
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={zaLogisticsPartnerInstructionCustomer}>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-5 pointerEventNone'>
                  {' '}
                  Logistics Partner Delegation{' '}
                </div>
                <div>
                  <span to={''}>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>{' '}
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={zaBlanketDelegation}>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-5 pointerEventNone'>Blanket Delegation </div>
                <div>
                  <span to={''}>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>{' '}
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={zaDelegationRequesttoCarrierbyAgent}>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-5 pointerEventNone'>
                  Delegation Request to Carrier by Agent{' '}
                </div>
                <div>
                  <span to={''}>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>{' '}
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={zaDelegationRequestToConsigneeByAgent}>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-5 pointerEventNone'>
                  Delegation Request To Consignee By Agent{' '}
                </div>
                <div>
                  <span to={''}>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>{' '}
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={zaReverseDelegationForConsigneeApproval}>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-5 pointerEventNone'>
                  {' '}
                  Reverse Delegation For Consignee Approval{' '}
                </div>
                <div>
                  <span to={''}>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>{' '}
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={zaInvoiceInstructionCustomer}>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-5 pointerEventNone'>
                  Invoice Instruction{' '}
                </div>
                <div>
                  <span to={''}>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>{' '}
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={zaPickupInstructionCustomer}>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-5 pointerEventNone'>
                  {' '}
                  Pickup Instruction{' '}
                </div>
                <div>
                  <span to={''}>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>{' '}
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={zaCarrierHaulageInstructionCustomer}>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-5 pointerEventNone'>
                  Carrier Haulage Instruction{' '}
                </div>
                <div>
                  <span to={''}>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>{' '}
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              onClick={zaContainerReleaseRequestInstructionCustomer}>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-5 pointerEventNone'>
                  Container Release Request{' '}
                </div>
                <div>
                  <span to={''}>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>{' '}
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={zaRegistrationPDF}>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-5 '>Registration Manual </div>
                <div>
                  <span>
                    <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                  </span>{' '}
                </div>
              </div>
            </Dropdown.Item>
          </div>
        ) : (
          ''
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default HelpDocDropDown;
