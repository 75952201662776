import React, { useEffect, useState } from 'react';

import * as Yup from 'yup';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCancel, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';

import { AESDecrypt } from '../../../encrypt-util';
import { AppDispatch, RootState } from '../../store';
import DataTable from '../../common/components/DataTable';
import { IDataTableColumn } from '../../common/types/dataTable';
import { updateDashbaordRefresh } from '../../common/components/DataTable/slices/DataTable';
import { getVesselMasterData, saveVesselMasterData, updateVesselQuota } from '../dashboard/actions/dashboard';

interface FormValues {
  vesselKey: string;
  vesselName: string;
  service1: string;
  service2: string;
  ibVoyage: string;
  obVoyage: string;
  // eta: string;
  // etd: string;
  // ata: string;
  atd: string;
  etb: string;
  invoiceCutOff: string;
  ebsStart: string;
  ebsEnd: string;
  gateOpen: string;
  gateClose: string;
  status: string;
  remark: string;
  createDate: string;
  updatedBy: string;
  updatedOn: string;
}

const validationSchema = Yup.object().shape({
  vesselKey: Yup.string(),
  vesselName: Yup.string(),
  service1: Yup.string(),
  service2: Yup.string(),
  ibVoyage: Yup.string(),
  obVoyage: Yup.string(),
  // eta: Yup.date(),
  // etd: Yup.date(),
  // ata: Yup.date(),
  atd: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  etb: Yup.date(),
  invoiceCutOff: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Invoice Cut off is required'),
  ebsStart: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('EBS Start is required'),
  ebsEnd: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('EBS End is required'),
  gateOpen: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Gate Open is required'),
  gateClose: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Gate Close is required'),
  status: Yup.string().required('Status is required'),
  remark: Yup.string(),
  updatedBy: Yup.string(),
  updatedOn: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
});

const VesselMaster: React.FC = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
    getValues,
    clearErrors,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  });
  const { showUpdateVesselQuotaBtn, showEditCuttOffBtn } = useSelector((state: RootState) => state.userProfile.rbacDetails) ?? {
    showUpdateVesselQuotaBtn: '',
    showEditCuttOffBtn: ''
  };
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const userDetails = useSelector((state: RootState) => state.userList);
  const profileDetails = useSelector((state: RootState) => state.userProfile.profile);
  const { navisKey } = useParams();
  const { userID } = useParams();
  const navigate = useNavigate();
  const [isUpdateQuota, setIsUpdateQuota] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [additionalInputs, setAdditionalInputs] = useState<any>([]);
  const [additionalInputsTemp, setAdditionalInputsTemp] = useState<any>([]);
  const [visitKeyVal, setvisitKeyVal] = useState("");
  const [vesselIdVal, setvesselIdVal] = useState<any>(null);
  const [partnerIdVal, setpartnerIdVal] = useState<any>(null);
  const [vesselData, setVesselData] = useState<FormValues | null>(null);
  const [lineVal, setlineVal] = useState("");
  useEffect(() => {
    if (navisKey) {
      setAdditionalInputs([]);
      setAdditionalInputsTemp([]);
      dispatch(getVesselMasterData(AESDecrypt(navisKey)))
        .then((response: any) => {
          const data = response.payload;
          setVesselData(data);
          populateFormFields(data);
          setvisitKeyVal(data.visitKey);
          setvesselIdVal(data.vesselId);
          setpartnerIdVal(data.partnerId);
          setlineVal(data.line);
          let quotaList: any;
          if (data?.quotaList?.length > 0) {
            quotaList = data?.quotaList?.map((data: any) => ({
              startDate: data?.startDate,
              endDate: data?.endDate,
              maxPermits: data?.maxPermit,
              usedQuota: data?.totalPermitReq,
              updatedBy: data?.updatedBy,
              updatedOn: data?.updatedOn,
              quotaId: data?.vesselMasterQuotaId,
              rowNumber: crypto.randomUUID(),
              isUpdated: false,
            }))
          }
          setAdditionalInputs(
            quotaList.map((i: any) => ({
              ...i
            })).sort((a: any, b: any) => {
              let startDateA = new Date(a.startDate);
              let startDateB = new Date(b.startDate);
              if (isNaN(startDateA.getTime()) || isNaN(startDateB.getTime())) {
                return 0;
              }

              return startDateB.getTime() - startDateA.getTime();
            })
          );
          setAdditionalInputsTemp(quotaList.map((i: any) => ({
            ...i
          }))
            .sort((a: any, b: any) => {
              let startDateA = new Date(a.startDate);
              let startDateB = new Date(b.startDate);
              if (isNaN(startDateA.getTime()) || isNaN(startDateB.getTime())) {
                return 0;
              }

              return startDateB.getTime() - startDateA.getTime();
            })
          );
        })
        .catch((error) => {
          console.error('Error fetching vessel data:', error);
        });
    }
  }, [navisKey]);


  useEffect(() => {
    setIsEditable(false);
    setIsUpdateQuota(false);
  }, [navisKey]);

  const onClickNewDate = () => {
    const defaultDate = new Date();
    defaultDate.setDate(defaultDate.getDate() - 1);
    const greatestDate = additionalInputs.reduce((maxDate: any, item: any) => {
      const currentDate = new Date(item.startDate);
      return currentDate > maxDate ? currentDate : maxDate;
    }, defaultDate);
    const currentDate = greatestDate;
    const today = new Date();

    const previousDate = new Date(today);

    previousDate.setDate(today.getDate());
    const nextDate = new Date(currentDate);
    nextDate.setDate(nextDate.getDate() + 1);

    let startDate = nextDate.setHours(0, 0, 0, 0);
    let endDateTemp = new Date(nextDate);
    endDateTemp.setHours(23, 59, 59, 0);
    let endDate: any = new Date(endDateTemp) > new Date(getValues('gateClose')) ? getValues('gateClose') : endDateTemp;
    if ((additionalInputsTemp || additionalInputsTemp?.length > 0) &&
      additionalInputsTemp?.some((data: any) => new Date(data?.startDate).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0))
    ) {
      startDate = new Date(previousDate).setHours(0, 0, 0, 0);
      endDate = new Date(previousDate).setHours(23, 59, 59, 0);
    }

    const newVesselQyotaObj = {
      startDate,
      endDate,
      maxPermits: "",
      updatedBy: "",
      usedQuota: '',
      updatedOn: "",
      rowNumber: crypto.randomUUID()
    };

    setAdditionalInputs((prevState: any) => ([
      ...prevState,
      newVesselQyotaObj
    ]))
  }


  const populateFormFields = (data: any) => {
    if (!data) return;

    setValue('vesselKey', data.navisKey?.toString() || '');
    setValue('vesselName', data.vesselName || '');
    setValue('service1', data.service || '');
    setValue('service2', data.service || '');
    setValue('ibVoyage', data.inboundVoyage || '');
    setValue('obVoyage', data.outboundVoyage || '');
    setValue('atd', data.atd ? formatDateTime(data.atd) : '');
    setValue('etb', data.etb ? formatDateTime(data.etb) : '');
    setValue('invoiceCutOff', data.invoiceCutOff ? formatDateTime(data.invoiceCutOff) : '');
    setValue('ebsStart', data.ebsStart ? formatDateTime(data.ebsStart) : '');
    setValue('ebsEnd', data.ebsEnd ? formatDateTime(data.ebsEnd) : '');
    setValue('gateOpen', data.gateOpen ? formatDateTime(data.gateOpen) : '');
    setValue('gateClose', data.gateClose ? formatDateTime(data.gateClose) : '');
    setValue('status', data.phase || '');
    setValue('remark', data.remark || '');
    setValue('updatedBy', data.updatedBy || '');
    setValue('updatedOn', data.updatedOn ? formatDateTime(data.updatedOn) : '');
  };


  const formatDateTime = (dateString: string) => {
    const date = new Date(dateString);
    const localISOTime = date.getFullYear() +
      '-' + String(date.getMonth() + 1).padStart(2, '0') +
      '-' + String(date.getDate()).padStart(2, '0') +
      'T' + String(date.getHours()).padStart(2, '0') +
      ':' + String(date.getMinutes()).padStart(2, '0');
    return localISOTime;
  };

  const toUTCString = (localDateTime: string) => {
    const localDate = new Date(localDateTime);
    return new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000).toISOString();
  };

  const onClickUpdateQuota = () => {
    setIsUpdateQuota(true);
    if (additionalInputs?.length == 0 || !additionalInputs) {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      const newInputs = [
        {
          startDate: date.setHours(0, 0, 0, 0),
          endDate: date.setHours(23, 59, 59, 0),
          maxPermits: "",
          usedQuota: '',
          updatedBy: "",
          updatedOn: "",
          rowNumber: crypto.randomUUID(),
          quotaId: null,
          isUpdated: false,
        }
      ];
      setAdditionalInputs(newInputs);
    }
  }


  const handleInputChangeValue = (rowNumber: string, value: string, type: string) => {
    const newInputs = [...additionalInputs];
    const prevInputs = [...additionalInputsTemp];
    const prevRow = prevInputs.find((i: any) => i.rowNumber === rowNumber) ?? { maxPermits: 0 };
    const row = newInputs.find((i: any) => i.rowNumber === rowNumber);


    if (!row) return;


    if (type === 'maxPermits') {
      if (value) {
        const numVal = value.replace(/[^0-9]/g, '')
        const numericValue = +numVal?.toString().slice(0, 5); // Convert to number and limit length
        if (numericValue > prevRow.maxPermits) {
          row[type] = numericValue;
          row.isUpdated = true;
        } else {
          // Uncomment if you want to reset to previous value if the new value is not greater
          row[type] = prevRow.maxPermits;
          row.isUpdated = false;
        }
      }
    }
    else if (type === 'startDate') {
      if (value) {
        if (
          new Date(row?.startDate).getMonth() !== new Date(value).getMonth() ||
          new Date(row?.startDate).getDate() !== new Date(value).getDate() || // Changed to getDate()
          new Date(row?.startDate).getFullYear() !== new Date(value).getFullYear()
        ) {
          const endDate = new Date(value).setHours(23, 59, 59, 0);
          row[type] = new Date(value).setHours(0, 0, 0, 0);
          row.endDate = new Date(endDate) > new Date(getValues('gateClose')) ? new Date(getValues('gateClose')) : endDate;
        }
        else if (new Date(row?.endDate) < new Date(value)) {
          {
            toast.error('Start Date should not be greater than End Date', {
              toastId: 'd8f87a8'
            })
            const endDate = new Date(value).setHours(23, 59, 59, 0);
            row[type] = new Date(value).setHours(0, 0, 0, 0);
            row.endDate = new Date(endDate) > new Date(getValues('gateClose')) ? new Date(getValues('gateClose')) : endDate;
          }
        }
        else if (new Date(value) > new Date(getValues('gateClose'))) {
          toast.error('Start Date should not exceed gate close date ', {
            toastId: 'dba46d3'
          })
          const endDate = new Date(value).setHours(23, 59, 59, 0);
          row[type] = new Date(value).setHours(0, 0, 0, 0);
          row.endDate = new Date(endDate) > new Date(getValues('gateClose')) ? new Date(getValues('gateClose')) : endDate;
        }
        else {
          row[type] = value;
        }
        row.isUpdated = true;
      } else {
        row[type] = null;
        row.isUpdated = false;
      }
    }
    else if (type === 'endDate') {
      if (value) {
        if (new Date(value) < new Date(row?.startDate)) {
          toast.error('Start Date should not be greater than End Date', {
            toastId: 'a215798'
          })
          const endDate = new Date(value).setHours(23, 59, 59, 0);
          row.startDate = new Date(value).setHours(0, 0, 0, 0);
          row[type] = new Date(endDate) > new Date(getValues('gateClose')) ? new Date(getValues('gateClose')) : endDate;
        }
        else {
          row[type] = value;
        }
        row.isUpdated = true;
      } else {
        row[type] = null;
        row.isUpdated = false;
      }
    }
    else {
      row[type] = value;
      row.isUpdated = true;
    }

    row.error = null; // Reset error state
    setAdditionalInputs(newInputs); // Update state
  };


  const onSubmit: SubmitHandler<FormValues> = (data) => {
    if (isUpdateQuota) {
      let errorFound = false;
      additionalInputs.forEach((item: any) => {
        // blank or null error  
        if (!item?.maxPermits) {
          errorFound = true;
          toast.error('Max permit value is required', {
            toastId: 'c8a7b81'
          });
          // cfaTinListFieldError(rowNumber, "CFA TIN is required");
          return;
        }
      });

      const deletedIds = additionalInputsTemp?.filter((data: any) =>
        !additionalInputs.some((temp: any) => temp.quotaId === data?.quotaId)
      )?.map(({ quotaId }: any) => quotaId)

      const quotaList = additionalInputs?.filter((data: any) => data?.isUpdated === true).map((data: any) => (
        {
          vesselMasterQuotaId: data?.quotaId ?? null,
          startDate: toUTCString(data?.startDate),
          endDate: toUTCString(data?.endDate),
          maxPermit: data?.maxPermits,
        }
      ))

      if (errorFound) return;

      setIsUpdateQuota(false);

      const payload = {
        vesselId: vesselIdVal,
        quotaList: quotaList ?? [],
        deletedIds: deletedIds ?? []
      };

      if (payload?.quotaList?.length > 0 || deletedIds?.length > 0) {
        dispatch(updateVesselQuota(payload))
          .unwrap()
          .then((response: any) => {
            if (response.data.responseCode === "SUCCESS") {
              toast.success(response.data.responseMsg, {
                toastId: 'f86d43f'
              });
              navigate("/opc/vessel-master");
              setTimeout(() => dispatch(updateDashbaordRefresh(true)), 1000)
            } else {
              navigate("/opc/vessel-master");
              toast.error(response.data.responseMsg, {
                toastId: '3fb11f4'
              });
            }
          })
          .catch((error) => {
            navigate("/opc/vessel-master");
            console.error('Error saving update vessel quota details:', error);
          });
      }
      else {
        navigate("/opc/vessel-master");
        toast.error('No quata has been updated', {
          toastId: '4992215'
        })
      }
    }
    else {
      const payload = {
        vesselId: vesselIdVal,
        navisKey: AESDecrypt(navisKey),
        partnerId: partnerIdVal,
        visitKey: visitKeyVal,
        vesselName: data.vesselName,
        service: data.service1,
        line: lineVal,
        inboundVoyage: data.ibVoyage,
        outboundVoyage: data.obVoyage,
        phase: data.status,
        // eta: toUTCString(data.eta),
        // etd: toUTCString(data.etd),
        // ata: toUTCString(data.ata),
        etb: toUTCString(data.etb),
        invoiceCutOff: toUTCString(data.invoiceCutOff),
        ebsStart: toUTCString(data.ebsStart),
        ebsEnd: toUTCString(data.ebsEnd),
        gateOpen: toUTCString(data.gateOpen),
        gateClose: toUTCString(data.gateClose),
        remark: data.remark,
      };

      dispatch(saveVesselMasterData(payload))
        .then((response: any) => {
          if (response.payload.responseCode === "SUCCESS") {
            toast.success(response.payload.responseMsg, {
              toastId: '0ae4096'
            });
            navigate("/opc/vessel-master");
            setTimeout(() => dispatch(updateDashbaordRefresh(true)), 1000)
          } else {
            toast.error(response.payload.responseMsg, {
              toastId: 'ccd4eea'
            });
          }
        })
        .catch((error) => {
          console.error('Error saving vessel data:', error);
          // toast.error(t('error_occurred'));
        });
    }
  };

  const removeAttachment = (rowData: any) => {
    const { rowNumber, usedQuota, startDate } = rowData.row.original;
    if (
      isUpdateQuota && (usedQuota === 0 || !usedQuota) &&
      (
        !additionalInputsTemp.some((data: any) => data?.rowNumber === rowNumber)
        ||
        new Date().setHours(0, 0, 0, 0) < new Date(startDate).setHours(0, 0, 0, 0)
      )
    ) {
      return (
        <>
          <Button
            variant="falcon-warning"
            style={{
              background: "transparent",
              border: "none",
              boxShadow: "none",
              padding: "0px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              height: "100%",
            }}
            onClick={() => {
              setAdditionalInputs((prevState: any) => prevState?.filter((data: any) => data?.rowNumber != rowNumber))
            }}
          >
            <FontAwesomeIcon icon={faTrash} size="sm" />
          </Button>
        </>
      );
    }
  };

  const nextDateDisabled = () => {
    let isDisabled = false;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    const gateCloseDate = new Date(getValues('gateClose'));
    gateCloseDate.setHours(0, 0, 0, 0);

    const countPastDates = additionalInputsTemp
      .map((data: any) => new Date(data?.startDate).setHours(0, 0, 0, 0))
      .filter((date: any) => date < now.getTime()).length || 0;

    const todayTime = today.getTime();
    const gateCloseTime = gateCloseDate.getTime();

    const isTodayPastGateClose = todayTime >= gateCloseTime;

    const isTodayInAdditionalInputs = additionalInputs.some((data: any) => {
      const startDate = new Date(data?.startDate);
      startDate.setHours(0, 0, 0, 0);
      return startDate.getTime() === todayTime;
    });

    if (isTodayPastGateClose && isTodayInAdditionalInputs) {
      isDisabled = true;
    } else if (additionalInputs?.length >= countPastDates + 2 || (
      additionalInputs.some((data: any) => {
        const startDate: any = new Date(data?.startDate);
        startDate.setHours(0, 0, 0, 0);
        return startDate > now.getTime();
      }) && (!additionalInputsTemp || additionalInputsTemp.length < 1)
    )) {
      isDisabled = true;
    }

    return isDisabled;
  };


  const vesselQuotaCols: IDataTableColumn[] = [
    {
      accessor: "startDate",
      Header: "Quota Start Date",
      Cell: (rowData: any) => {
        const { rowNumber, startDate } = rowData.row.original;
        const { index } = rowData.row
        const maxDate = () => {
          let date = new Date();
          if ((additionalInputsTemp || additionalInputsTemp?.length > 0) &&
            additionalInputs?.some((data: any) => new Date(data?.startDate) > new Date())) {
            date = new Date();
          }
          else if (index === 0) {
            date.setDate(date.getDate() + 1);
          } else if (additionalInputs[index - 1]?.startDate) {
            date.setDate(new Date(additionalInputs[index - 1]?.startDate)?.getDate() + 1);
          }

          const minDate = (d1: any, d2: any) => (d1 < d2 ? d1 : d2);

          const earliestDate = minDate(new Date(date), new Date(getValues('gateClose')));
          return earliestDate;
        };

        const minDate = () => {
          const date = new Date();

          if (index !== 0 && additionalInputs[index - 1]?.startDate) {
            date.setDate(new Date(additionalInputs[index - 1].startDate).getDate() + 1);
          }

          return date;
        };
        return (
          <DatePicker
            maxDate={maxDate()}
            timeIntervals={15}
            minDate={minDate()}
            disabled={!isUpdateQuota || additionalInputsTemp?.some((data: any) => data?.rowNumber == rowNumber)}
            selected={startDate ? new Date(startDate) : null}
            onChange={(date: any) => {
              handleInputChangeValue(rowNumber, date, 'startDate');
            }}
            showTimeSelect
            dateFormat="dd/MM/yyyy HH:mm:ss"
            className={`fs--1 form-control`}
            portalId='rootId'
          />
        )
      },
      width: 150,
      maxWidth: 150,
    },
    {
      accessor: "endDate",
      Header: 'Quota End Date',
      Cell: (rowData: any) => {
        const { rowNumber, endDate, startDate } = rowData.row.original;
        return (
          <DatePicker
            selected={endDate ? new Date(endDate) : startDate}
            portalId='rootId'
            timeIntervals={15}
            minDate={startDate}
            maxDate={startDate}
            disabled={!isUpdateQuota || additionalInputsTemp?.some((data: any) => data?.rowNumber == rowNumber)}
            onChange={(date: any) => {
              if (new Date(date) > new Date(getValues('gateClose'))) {
                toast.error("End date should not exceed gate close date", {
                  toastId: 'c004e58'
                });
                handleInputChangeValue(rowNumber, getValues('gateClose'), 'endDate');
              } else {
                handleInputChangeValue(rowNumber, date, 'endDate');
              }
            }}
            showTimeSelect
            dateFormat="dd/MM/yyyy HH:mm:ss"
            className={`fs--1 form-control z-3`}
          />
        )
      },
      width: 150,
      maxWidth: 150,
    },
    {
      accessor: "maxPermits",
      Header: 'Max Permits',
      Cell: (rowData: any) => {
        const { rowNumber, maxPermits, startDate } = rowData.row.original;
        const today = new Date()
        today.setHours(0, 0, 0, 0);
        return (
          <Form.Control
            type="text"
            defaultValue={maxPermits}
            disabled={!isUpdateQuota || new Date(startDate) < today}
            className='fs--1 form-control'
            onBlur={(e) => {
              const value = e.target.value;
              if (value.toString?.length < 5) {
                handleInputChangeValue(rowNumber, value, 'maxPermits');
              }
            }}
          />
        )
      },
      width: 140,
      maxWidth: 140,
    },
    {
      accessor: "usedQuota",
      Header: 'Used Quota',
      width: 140,
      Cell: (rowData: any) => {
        const { usedQuota } = rowData.row.original;
        return (
          <div className='h-100 d-flex align-items-center'>
            <span>{usedQuota}</span>
          </div>
        )
      },
      maxWidth: 140,
    },
    {
      accessor: "updatedBy",
      Header: "Updated By",
      width: 200,
      maxWidth: 200,
      Cell: (rowData: any) => {
        const { updatedBy } = rowData.row.original;
        return (
          <div className='h-100 d-flex align-items-center'>
            <span>{updatedBy}</span>
          </div>
        )
      },
    },
    {
      accessor: "updatedOn",
      Header: "Updated On",
      Cell: (rowData: any) => {
        const { updatedOn } = rowData.row.original;
        return (
          <div className='h-100 d-flex align-items-center'>
            <span>
              {updatedOn ? new Date(updatedOn).toLocaleString() : ''}
            </span>
          </div>
        )
      },
      width: 200,
      maxWidth: 200
    },
    {
      accessor: "delete",
      Header: "",
      width: 100,
      maxWidth: 100,
      Cell: removeAttachment,
    }
  ];


  const handleEdit = () => {
    setIsEditable(true);
  };


  const handleCancel = () => {
    if (isEditable) {
      setIsEditable(false);
      if (vesselData) {
        populateFormFields(vesselData);
      }
    }
    if (isUpdateQuota) {
      setAdditionalInputs(additionalInputsTemp?.map((data: any) => ({
        ...data
      })));
      setTimeout(() => {
        setIsUpdateQuota(false);
      });
    }
  };


  return (
    <Form>
      <div className="row">
        {/* <div className="col-lg-6">
          <Form.Group controlId="form.vesselKey">
            <Form.Label className="fs--1">
              <span className="mandatory">*</span>Vessel Key
            </Form.Label>
            <Form.Control
              type="text"
              {...register('vesselKey')}
              className={`fs--1 form-control ${errors.vesselKey ? 'is-invalid' : ''}`}
              value={watch('vesselKey')}
              onChange={(e) => {
                setValue('vesselKey', e.target.value);
                clearErrors('vesselKey');
              }}
              disabled={!isEditable}
            />
            <div className="invalid-feedback">{errors.vesselKey?.message}</div>
          </Form.Group>
        </div> */}

        <div className="col-lg-6">
          <Form.Group controlId="form.vesselName">
            <Form.Label className="fs--1">
              <span className="mandatory">*</span>Vessel Name
            </Form.Label>
            <Form.Control
              type="text"
              {...register('vesselName')}
              className={`fs--1 form-control ${errors.vesselName ? 'is-invalid' : ''}`}
              value={watch('vesselName')}
              onChange={(e) => {
                setValue('vesselName', e.target.value);
                clearErrors('vesselName');
              }}
              disabled={true}
            />
            <div className="invalid-feedback">{errors.vesselName?.message}</div>
          </Form.Group>
        </div>

        {/* <div className="col-lg-6">
          <Form.Group controlId="form.service1">
            <Form.Label className="fs--1">
              <span className="mandatory">*</span>Service 1
            </Form.Label>
            <Form.Control
              type="text"
              {...register('service1')}
              className={`fs--1 form-control ${errors.service1 ? 'is-invalid' : ''}`}
              value={watch('service1')}
              onChange={(e) => {
                setValue('service1', e.target.value);
                clearErrors('service1');
              }}
              disabled={true}
            />
            <div className="invalid-feedback">{errors.service1?.message}</div>
          </Form.Group>
        </div> */}

        {/* <div className="col-lg-6">
          <Form.Group controlId="form.service2">
            <Form.Label className="fs--1">
              <span className="mandatory">*</span>Service 2
            </Form.Label>
            <Form.Control
              type="text"
              {...register('service2')}
              className={`fs--1 form-control ${errors.service2 ? 'is-invalid' : ''}`}
              value={watch('service2')}
              onChange={(e) => {
                setValue('service2', e.target.value);
                clearErrors('service2');
              }}
              disabled={true}
            />
            <div className="invalid-feedback">{errors.service2?.message}</div>
          </Form.Group>
        </div> */}

        {/* <div className="col-lg-6">
          <Form.Group controlId="form.ibVoyage">
            <Form.Label className="fs--1">
              <span className="mandatory">*</span>I/B Voyage
            </Form.Label>
            <Form.Control
              type="text"
              {...register('ibVoyage')}
              className={`fs--1 form-control ${errors.ibVoyage ? 'is-invalid' : ''}`}
              value={watch('ibVoyage')}
              onChange={(e) => {
                setValue('ibVoyage', e.target.value);
                clearErrors('ibVoyage');
              }}
              disabled={true}
            />
            <div className="invalid-feedback">{errors.ibVoyage?.message}</div>
          </Form.Group>
        </div> */}

        {/* <div className="col-lg-6">
          <Form.Group controlId="form.obVoyage">
            <Form.Label className="fs--1">
              <span className="mandatory">*</span>O/B Voyage
            </Form.Label>
            <Form.Control
              type="text"
              {...register('obVoyage')}
              className={`fs--1 form-control ${errors.obVoyage ? 'is-invalid' : ''}`}
              value={watch('obVoyage')}
              onChange={(e) => {
                setValue('obVoyage', e.target.value);
                clearErrors('obVoyage');
              }}
              disabled={true}
            />
            <div className="invalid-feedback">{errors.obVoyage?.message}</div>
          </Form.Group>
        </div> */}

        {/* <div className="col-lg-6">
          <Form.Group controlId="form.eta">
            <Form.Label className="fs--1">
              <span className="mandatory">*</span>ETA
            </Form.Label>
            <Form.Control
              type="datetime-local"
              {...register('eta')}
              className={`fs--1 form-control ${errors.eta ? 'is-invalid' : ''}`}
              value={watch('eta')}
              onChange={(e) => {
                setValue('eta', e.target.value);
                clearErrors('eta');
              }}
              disabled={true}
            />
            <div className="invalid-feedback">{errors.eta?.message}</div>
          </Form.Group>
        </div> */}

        {/* <div className="col-lg-6">
          <Form.Group controlId="form.etd">
            <Form.Label className="fs--1">
              <span className="mandatory">*</span>ETD
            </Form.Label>
            <Form.Control
              type="datetime-local"
              {...register('etd')}
              className={`fs--1 form-control ${errors.etd ? 'is-invalid' : ''}`}
              value={watch('etd')}
              onChange={(e) => {
                setValue('etd', e.target.value);
                clearErrors('etd');
              }}
              disabled={true}
            />
            <div className="invalid-feedback">{errors.etd?.message}</div>
          </Form.Group>
        </div> */}

        {/* <div className="col-lg-6">
          <Form.Group controlId="form.ata">
            <Form.Label className="fs--1">
              <span className="mandatory">*</span>ATA
            </Form.Label>
            <Form.Control
              type="datetime-local"
              {...register('ata')}
              className={`fs--1 form-control ${errors.ata ? 'is-invalid' : ''}`}
              value={watch('ata')}
              onChange={(e) => {
                setValue('ata', e.target.value);
                clearErrors('ata');
              }}
              disabled={true}
            />
            <div className="invalid-feedback">{errors.ata?.message}</div>
          </Form.Group>
        </div> */}

        {/* <div className="col-lg-6">
          <Form.Group controlId="form.atd">
            <Form.Label className="fs--1">
              <span className="mandatory">*</span>ATD
            </Form.Label>
            <Form.Control
              type="datetime-local"
              {...register('atd')}
              className={`fs--1 form-control ${errors.atd ? 'is-invalid' : ''}`}
              value={watch('atd')}
              onChange={(e) => {
                setValue('atd', e.target.value);
                clearErrors('atd');
              }}
              disabled={true}
            />
            <div className="invalid-feedback">{errors.atd?.message}</div>
          </Form.Group>
        </div> */}

        <div className="col-lg-6">
          <Form.Group controlId="form.etb">
            <Form.Label className="fs--1">
              <span className="mandatory">*</span>ETB
            </Form.Label>
            <div>
              <Controller
                control={control}
                name="etb"

                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    selected={value ? new Date(value) : null}
                    onChange={(date) => {
                      onChange(date);
                      if (date && !isNaN(date.getTime())) {
                        clearErrors('etb');
                      }
                    }}
                    timeIntervals={15}
                    showTimeSelect
                    dateFormat="dd/MM/yyyy h:mm aa"
                    className={`fs--1 form-control ${errors.etb ? 'is-invalid' : ''}`}
                    disabled={!isEditable}
                  />
                )}
              />
              <div className="invalid-feedback">{errors.etb?.message}</div>
            </div>

          </Form.Group>
        </div>

        <div className="col-lg-6">
          <Form.Group controlId="form.invoiceCutOff">
            <Form.Label className="fs--1">
              <span className="mandatory">*</span>Invoice Cut Off
            </Form.Label>
            <div>
              <Controller
                control={control}
                name="invoiceCutOff"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    selected={value ? new Date(value) : null}
                    timeIntervals={15}
                    onChange={(date) => {
                      onChange(date);
                      if (date && !isNaN(date.getTime())) {
                        clearErrors('invoiceCutOff');
                      }
                    }}
                    showTimeSelect
                    dateFormat="dd/MM/yyyy h:mm aa"
                    className={`fs--1 form-control ${errors.invoiceCutOff ? 'is-invalid' : ''}`}
                    disabled={!isEditable}
                  />
                )}
              />
              <div className="invalid-feedback">{errors.invoiceCutOff?.message}</div>
            </div>

          </Form.Group>
        </div>

        <div className="col-lg-6">
          <Form.Group controlId="form.ebsStart">
            <Form.Label className="fs--1">
              <span className="mandatory">*</span>EBS Start
            </Form.Label>
            <div>
              <Controller
                control={control}
                name="ebsStart"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    selected={value ? new Date(value) : null}
                    timeIntervals={15}
                    onChange={(date) => {
                      onChange(date);
                      if (date && !isNaN(date.getTime())) {
                        clearErrors('ebsStart');
                      }
                    }}
                    showTimeSelect
                    dateFormat="dd/MM/yyyy h:mm aa"
                    className={`fs--1 form-control ${errors.ebsStart ? 'is-invalid' : ''}`}
                    disabled={!isEditable}
                  />
                )}
              />
              <div className="invalid-feedback">{errors.ebsStart?.message}</div>
            </div>

          </Form.Group>

        </div>

        <div className="col-lg-6">
          <Form.Group controlId="form.ebsEnd">
            <Form.Label className="fs--1">
              <span className="mandatory">*</span>EBS End
            </Form.Label>
            <div>
              <Controller
                control={control}
                name="ebsEnd"

                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    timeIntervals={15}
                    selected={value ? new Date(value) : null}
                    onChange={(date) => {
                      onChange(date);
                      if (date && !isNaN(date.getTime())) {
                        clearErrors('ebsEnd');
                      }
                    }}
                    showTimeSelect
                    dateFormat="dd/MM/yyyy h:mm aa"
                    className={`fs--1 form-control ${errors.ebsEnd ? 'is-invalid' : ''}`}
                    disabled={!isEditable}
                  />
                )}
              />
              <div className="invalid-feedback">{errors.ebsEnd?.message}</div>
            </div>

          </Form.Group>
        </div>

        <div className="col-lg-6">
          <Form.Group controlId="form.gateOpen">
            <Form.Label className="fs--1">
              <span className="mandatory">*</span>Gate Open
            </Form.Label>
            <div>
              <Controller
                control={control}
                name="gateOpen"

                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    timeIntervals={15}
                    selected={value ? new Date(value) : null}
                    onChange={(date) => {
                      onChange(date);
                      if (date && !isNaN(date.getTime())) {
                        clearErrors('gateOpen');
                      }
                    }}
                    showTimeSelect
                    dateFormat="dd/MM/yyyy h:mm aa"
                    className={`fs--1 form-control ${errors.gateOpen ? 'is-invalid' : ''}`}
                    disabled={!isEditable}
                  />
                )}
              />
              <div className="invalid-feedback">{errors.gateOpen?.message}</div>
            </div>

          </Form.Group>
        </div>

        <div className="col-lg-6">
          <Form.Group controlId="form.gateClose">
            <Form.Label className="fs--1">
              <span className="mandatory">*</span>Gate Close
            </Form.Label>
            <div>
              <Controller
                control={control}
                name="gateClose"

                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    timeIntervals={15}
                    selected={value ? new Date(value) : null}
                    onChange={(date) => {
                      onChange(date);
                      if (date && !isNaN(date.getTime())) {
                        clearErrors('gateClose');
                      }
                    }}
                    showTimeSelect
                    dateFormat="dd/MM/yyyy h:mm aa"
                    className={`fs--1 form-control ${errors.gateClose ? 'is-invalid' : ''}`}
                    disabled={!isEditable}
                  />
                )}
              />
              <div className="invalid-feedback">{errors.gateClose?.message}</div>
            </div>

          </Form.Group>
        </div>

        <div className="col-lg-6">
          <Form.Group controlId="form.status">
            <Form.Label className="fs--1">
              <span className="mandatory">*</span>Status
            </Form.Label>
            <Form.Control
              type="text"
              {...register('status')}
              className={`fs--1 form-control ${errors.status ? 'is-invalid' : ''}`}
              value={watch('status')}
              onChange={(e) => {
                setValue('status', e.target.value);
                clearErrors('status');
              }}
              disabled={true}
            />
            <div className="invalid-feedback">{errors.status?.message}</div>
          </Form.Group>
        </div>

        <div className="col-lg-6 mb-4">
          <Form.Group controlId="form.remark">
            <Form.Label className="fs--1">
              Remark
            </Form.Label>
            <Form.Control
              type="text"
              as='textarea'
              {...register('remark')}
              className={`fs--1 form-control ${errors.remark ? 'is-invalid' : ''}`}
              value={watch('remark')}
              onChange={(e) => {
                setValue('remark', e.target.value);
                clearErrors('remark');
              }}
              disabled={!isEditable}
            />
            <div className="invalid-feedback">{errors.remark?.message}</div>
          </Form.Group>
        </div>
        <div className='d-flex flex-column gap-2 '>
          {
            isUpdateQuota &&
            <>
              <Button
                variant='falcon-primary'
                size='sm'
                style={{ width: 'max-content' }}
                className='me-2 '
                onClick={() => {
                  onClickNewDate();
                }}
                disabled={nextDateDisabled()}
                type='button'>
                Next Date
              </Button>
            </>
          }
          {
            (additionalInputs?.length > 0 || isUpdateQuota) &&
            <DataTable
              data={additionalInputs}
              gridName="VESSEL_QUOTA"
              columns={vesselQuotaCols}
              sortable={false}
              searchable={false}
              showColumnFilter={false}
              selection={false}
              perPage={30}
              pagination={false}
              showExport={false}
              uniqueFilterKey="vesselQuota"
              autoHeight={true}
              staticHiddenCols={[]}
            />
          }
        </div>
        {/*<div className="col-lg-6">
        <Form.Group controlId="form.createDate">
        <Form.Label className="fs--1">
          <span className="mandatory">*</span>Create Date
        </Form.Label>
        <div>
        <Controller
          control={control}
          name="createDate"
          
          render={({ field: { onChange, value } }) => (
            <DatePicker
              selected={value ? new Date(value) : null}
              onChange={(date) => {
                onChange(date);
                if (date && !isNaN(date.getTime())) {
                  clearErrors('createDate');
                }
              }}
              showTimeSelect
              dateFormat="dd/MM/yyyy h:mm aa"
              className={`fs--1 form-control ${errors.createDate ? 'is-invalid' : ''}`}
              disabled={true}  
            />
          )}
        />
        <div className="invalid-feedback">{errors.createDate?.message}</div>
        </div>

      </Form.Group>
        </div>*/}

        {/* <div className="col-lg-6">
          <Form.Group controlId="form.updatedBy">
            <Form.Label className="fs--1">
              <span className="mandatory">*</span>Updated By
            </Form.Label>
            <Form.Control
              type="text"
              {...register('updatedBy')}
              className={`fs--1 form-control ${errors.updatedBy ? 'is-invalid' : ''}`}
              value={watch('updatedBy')}
              onChange={(e) => {
                setValue('updatedBy', e.target.value);
                clearErrors('updatedBy');
              }}
              disabled={!isEditable}
            />
            <div className="invalid-feedback">{errors.updatedBy?.message}</div>
          </Form.Group>
        </div>

        <div className="col-lg-6">
          <Form.Group controlId="form.updatedOn">
            <Form.Label className="fs--1">
              <span className="mandatory">*</span>Updated On
            </Form.Label>
            <Form.Control
              type="datetime-local"
              {...register('updatedOn')}
              className={`fs--1 form-control ${errors.updatedOn ? 'is-invalid' : ''}`}
              value={watch('updatedOn')}
              onChange={(e) => {
                setValue('updatedOn', e.target.value);
                clearErrors('updatedOn');
              }}
              disabled={!isEditable}
            />
            <div className="invalid-feedback">{errors.updatedOn?.message}</div>
          </Form.Group>
        </div> */}

        {
          <div className='d-flex justify-content-center align-items-center mt-4'>
            {(!isEditable && !isUpdateQuota) &&
              <>
                {
                  showEditCuttOffBtn == 'Y' &&
                  <Button
                    // type='submit'
                    onClick={handleEdit}
                    className='me-2'
                    variant='falcon-primary me-2'
                    size='sm'
                  >
                    Edit Cut Off
                  </Button>
                }
                {(showUpdateVesselQuotaBtn == 'Y') &&
                  <Button
                    disabled={new Date().setHours(0, 0, 0, 0) > new Date(getValues('gateClose')).setHours(0, 0, 0, 0)}
                    onClick={() => onClickUpdateQuota()}
                    className='me-2'
                    variant='falcon-primary me-2'
                    size='sm'
                  >
                    Update Quota
                  </Button>
                }
              </>
            }

            {(isEditable || isUpdateQuota) && (
              <Button
                className='me-2'
                variant='falcon-primary me-2'
                size='sm'
                type='button'
                onClick={handleSubmit(onSubmit)}
                style={{ zIndex: 1 }}
              >
                <FontAwesomeIcon icon={faSave} className='me-2' />
                Save
              </Button>
            )}
            {(isEditable || isUpdateQuota) && (
              <Button
                className='me-2'
                variant='falcon-danger'
                size='sm'
                onClick={handleCancel}
                style={{ zIndex: 1 }}
              >
                <FontAwesomeIcon icon={faCancel} className='me-2' />
                Cancel
              </Button>
            )}
          </div>
        }
      </div>
    </Form>
  );
};

export default VesselMaster;
