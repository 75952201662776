import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  getDemurrage,
  addDemurrage,
  getPickupList,
  getDefoultPickuLocation,
  getAttachmentsOnly,
  getSsrDocList,
  getSsrLateGateInDocList,
  getInvoiceAttachmentListExport,
  getInvoiceAttachmentListImport,
  getSsrLateGateInDocName,
} from "../../../dashboard/actions/instruction";
import { randomUUID } from "crypto";

interface IDemurrageForm {
  senderEmail: string | null;
  intendedDate: string | null;
  truckerCode: string | null;
  country: string | null;
  status: string | null;
  requestedPartnerName: string | null;
}

interface IDemurrageContainerDetails {
  //vesselType: any;
  containerNo: string | null;
  IntendedDate: string | null;
  truckerCode: string | null;
  pickupLocation: string | null;
  truckerName: string | null;
  truckerId: string | null;
  internalCode: string | null;
}

interface IDemurrageDocAttachmentDetails {
  //vesselType: any;
  docId: string | null;
  fileName: any | null;
  byteArray: string | null;
}

interface IDemurrageList {
  list: any[];
  next: string | null;
  previous: string | null;
}


export interface IDemurrageDetails {
  selectedTruckerCode: any;
  data: IDemurrageList;
  attData: any;
  attDataExport: any;
  attSSR: any;
  attSSRGate: any;
  isDemurrageDetailsLoading: boolean;
  dataLoading: boolean;
  demurrageForm: IDemurrageForm;
  selectedDate: any;
  docList: any[];
  ssrDoc: any[];
  ssrDocLategateIn: any[];
  docExport: any[];
  pickUpLocation: any[];
  truckerList: any[];
  selectedAttachmentName: any;
  selectedAttachmentBytrr: any;
  selectedAttachmentFilePath: any;
  selectedDocId: any;
  selectedPickupLocation: any;
  selectedTruckerName: any;
  selectedtruckerId: any;
  selectedinternalCode: any;
  selectedDefaultPickupLocation: any;
  selectedFileServerPath: any;
}

const initialState: IDemurrageDetails = {
  data: {
    list: [],
    next: null,
    previous: null,
  },
  attData: {
    list: [],
    next: null,
    previous: null,
  },

  attDataExport: {
    list: [],
    next: null,
    previous: null,
  },
  attSSR: {
    list: [],
    next: null,
    previous: null,
  },
  attSSRGate: {
    list: [],
    next: null,
    previous: null,
  },
  docList: [],

  docExport: [],
  ssrDoc: [],
  ssrDocLategateIn: [],
  pickUpLocation: [],

  dataLoading: true,
  isDemurrageDetailsLoading: false,
  demurrageForm: {
    senderEmail: "",
    intendedDate: "",
    truckerCode: "",
    country: "",
    status: "",
    requestedPartnerName: "",
  },
  selectedDate: {},
  selectedTruckerCode: {},
  selectedAttachmentName: {},
  selectedAttachmentBytrr: {},
  selectedDocId: {},
  selectedPickupLocation: {},
  selectedTruckerName: {},
  selectedtruckerId: {},
  truckerList: [],
  selectedinternalCode: {},
  selectedAttachmentFilePath: {},
  selectedDefaultPickupLocation: "",
  selectedFileServerPath: {},
};

export const DemurrageSlice = createSlice({
  name: "demurrage",
  initialState,
  reducers: {
    updateDemurrageList: (state, action: PayloadAction<IDemurrageList>) => {
      state.data = action.payload;
    },
    clearDocumentList: (state) => {
      state.attSSR = initialState.attSSR;
    },
    updateSSRDocumentList: (state, action) => {
      if (state.attSSR) {
        const row = state.attSSR?.list?.find(
          (x: any) => x?.rowNumber == action.payload?.rowNumber
        );
        if (!row) return;
        Object.entries(action.payload)?.forEach(([key, val]) => {
          row[key] = val;
        });
      }
    },
    updateSelectedDateDV: (state, action: PayloadAction<any>) => {
      state.selectedDate = action.payload;
    },
    updateSelectedtruckerCode: (state, action: PayloadAction<any>) => {
      state.selectedTruckerCode = action.payload;
    },
    updateSelectedAttachmentName: (state, action: PayloadAction<any>) => {
      state.selectedAttachmentName = action.payload;
    },
    updateSelectedAttachmentBytrr: (state, action: PayloadAction<any>) => {
      state.selectedAttachmentBytrr = action.payload;
    },
    updateSelectedDocId: (state, action: PayloadAction<any>) => {
      state.selectedDocId = action.payload;
    },
    updateAdttamentData: (state, action: PayloadAction<any>) => {
      state.attData.list = action.payload;
    },
    updateTruckerList: (state, action: PayloadAction<any>) => {
      state.truckerList = action.payload;
    },
    updateSelectedPickupLocation: (state, action: PayloadAction<any>) => {
      state.selectedPickupLocation = action.payload;
    },
    updateSelectedTruckerName: (state, action: PayloadAction<any>) => {
      state.selectedTruckerName = action.payload;
    },
    updateSelectedtruckerId: (state, action: PayloadAction<any>) => {
      state.selectedtruckerId = action.payload;
    },
    updateselectedInternalCode: (state, action: PayloadAction<any>) => {
      state.selectedinternalCode = action.payload;
    },
    updatePickupLocation: (state, action: PayloadAction<any>) => {
      state.pickUpLocation = action.payload;
    },
    updateSelectedAttachmentFilePath: (state, action: PayloadAction<any>) => {
      state.selectedAttachmentFilePath = action.payload;
    },
    clearSelectedFileServerPath: (state) => {
      state.selectedFileServerPath = {};
    },
    updateSelectedFileServerPath: (state, action: PayloadAction<any>) => {
      state.selectedFileServerPath = action.payload;
    },
    updateSSRDocLateGateIn: (state, action: PayloadAction<any>) => {
      state.ssrDocLategateIn = action.payload;
    },
    resetSSRDocLateGateIn: (state) => {
      state.attSSRGate = {
        list: [
          {
            dndInstructionAttId: null,
            docId: 11,
            fileName: "",
            byteArray: "hdavdddjhcvhjvdj",
            downloadBtn: true,
            m: true,
            rowNumber: crypto.randomUUID(),
          },
          {
            dndInstructionAttId: null,
            docId: 12,
            fileName: "",
            byteArray: "hdavjhcvddddhjvdj",
            downloadBtn: true,
            rowNumber: crypto.randomUUID(),
            m: true,
          },
        ],
        next: null,
        previous: null,
      };
      state.ssrDocLategateIn = [
        { id: 11, name: "Loading permission", m: true, rowNumber: 11 },
        { id: 12, name: "Others", m: false, rowNumber: 12 },
      ];
    },
    updateAttSSRGate: (state, action: PayloadAction<any>) => {
      state.attSSRGate = { ...state.attSSRGate, list: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addDemurrage.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(addDemurrage.fulfilled, (state, action) => {
      state.dataLoading = false;
    });
    builder.addCase(getPickupList.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(getPickupList.fulfilled, (state, action) => {
      state.pickUpLocation = action.payload;
    });

    builder.addCase(getDefoultPickuLocation.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(getDefoultPickuLocation.fulfilled, (state, action) => {
      state.selectedDefaultPickupLocation = action?.payload?.toString() || "";
    });

    builder.addCase(getDemurrage.pending, (state, action) => {
      state.data = {
        list: [],
        next: null,
        previous: null,
      };
      state.dataLoading = true;
    });
    builder.addCase(getDemurrage.fulfilled, (state: any, action) => {
      state.dataLoading = false;

      state.data = action.payload;
    });
    builder.addCase(getAttachmentsOnly.fulfilled, (state, action) => {
      // resetting attData.list to initial value
      state.attData.list = [
        {
          dndInstructionAttId: null,
          docId: 101,
          fileName: "",
          byteArray: "hdavjhcvhjvdj",
          downloadBtn: true,
          m: true,
        },
        {
          dndInstructionAttId: null,
          docId: 102,
          fileName: "",
          byteArray: "hdavjhcvhjvdj",
          downloadBtn: true,
          m: true,
        },
        {
          dndInstructionAttId: null,
          docId: 103,
          fileName: "",
          byteArray: "hdavjhcvhjvdj",
          downloadBtn: true,
          m: true,
        },
        {
          dndInstructionAttId: null,
          docId: 104,
          fileName: "",
          byteArray: "hdavjhcvhjvdj",
          downloadBtn: true,
          m: false,
        },
        {
          dndInstructionAttId: null,
          docId: 105,
          fileName: "",
          byteArray: "hdavjhcvhjvdj",
          downloadBtn: true,
          m: false,
        },
        {
          dndInstructionAttId: null,
          docId: 106,
          fileName: "",
          byteArray: "hdavjhcvhjvdj",
          downloadBtn: true,
          m: false,
        },
        {
          dndInstructionAttId: null,
          docId: 107,
          fileName: "",
          byteArray: "hdavjhcvhjvdj",
          downloadBtn: true,
          m: false,
        },
      ];
      if (!action.payload) return;
      action.payload?.forEach((data: any, i: any) => {
        state.attData.list[i].fileName = data.fileName;
        state.attData.list[i].dndInstructionAttId = data.dndInstructionAttId;
        state.attData.list[i].filePath = data.filePath;

        state.selectedAttachmentFilePath[data.docId] = data.filePath;
        state.selectedAttachmentName[data.docId] = { name: data.fileName };
      });
    });
    builder.addCase(getSsrDocList.fulfilled, (state, action: any) => {
      state.attSSR = action.payload.attSSR;
      state.ssrDoc = action.payload.ssrDoc;
    });
    builder.addCase(getSsrLateGateInDocList.fulfilled, (state, action: any) => {
      state.attSSRGate = action.payload.attSSRGate;
      state.ssrDocLategateIn = action.payload.ssrDocLategateIn;
    });
    builder.addCase(getSsrLateGateInDocName.fulfilled, (state, action: any) => {
      //state.attSSRGate = action.payload.attSSRGate;
      state.ssrDocLategateIn = action.payload.ssrDocLategateIn;
    });
    builder.addCase(getInvoiceAttachmentListExport.fulfilled, (state, action: any) => {
      state.attDataExport = action.payload.attachmentExportList;
      state.docExport = action.payload.docExport
    })
    builder.addCase(getInvoiceAttachmentListImport.fulfilled, (state, action: any) => {
      state.attData = action.payload.attachmentExportList;
      state.docList = action.payload.docImport;
    })
  },
});
export const {
  updateDemurrageList,
  updateSelectedDateDV,
  updateSelectedtruckerCode,
  updateSelectedAttachmentBytrr,
  updateSelectedAttachmentName,
  updateSelectedDocId,
  updateAdttamentData,
  updateSelectedPickupLocation,
  updateSelectedTruckerName,
  updateSelectedtruckerId,
  updateselectedInternalCode,
  updatePickupLocation,
  updateSelectedAttachmentFilePath,
  updateSelectedFileServerPath,
  clearSelectedFileServerPath,
  clearDocumentList,
  updateSSRDocLateGateIn,
  updateAttSSRGate,
  resetSSRDocLateGateIn,
  updateSSRDocumentList
} = DemurrageSlice.actions;

export default DemurrageSlice.reducer;
