import React from "react";
import {
  Button,
  Card,
  Carousel,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
// import axiosInstance from '../../../../axiosSetup';
import axiosInstance from "../../../axiosSetup";
// import { checkLoginAuth } from '../../../loginSlice';
import { checkLoginAuth } from "./loginSlice";
import { useDispatch, useSelector } from "react-redux";
// import { AppDispatch,RootState } from '../../store';
// import { RootState } from '../../../store';
import { AppDispatch, RootState } from "../../store";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import { faCheck, faSearchPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LogoOne from "../../../assets/images/logo.png";
import axios from "axios";

interface IProps {
  hasLabel: any;
}

const LoginForm = ({ hasLabel }: IProps) => {
  var resval = "no";
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const selectedBls = useSelector((state: RootState) => state);

  const getToken = () => {
    const querySelector: (Element & { content: string }) | null =
      document.head.querySelector("[name=_csrf]");
    return querySelector ? querySelector.content : "";
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await axiosInstance.post(
        "/api/user-management/v1/users/getLoginUserDetails",
        {}
      );

      dispatch(checkLoginAuth({ success: "true" }));
    } catch (error) {
      dispatch(checkLoginAuth({ success: "false" }));

      console.error(error);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col style={{ position: "relative" }} className="p-0 col-4 form-login">
          {/* <SignUpBox /> */}
          <div className="login-wrap">
            <Card style={{ boxShadow: "none" }} className="h-100 pt-5 pb-5 p-4">
              <img src={LogoOne} className="logoWidth pb-5" />
              <Card.Header className="cardHeader">LOGIN</Card.Header>
              <Card.Body className="p-4 bodyHeight">
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    {hasLabel && (
                      <Form.Label htmlFor="floatingInput">
                        Email address
                      </Form.Label>
                    )}
                    <Form.Control
                      placeholder={!hasLabel ? "Email address" : ""}
                      name="username"
                      type="email"
                      className="fs--1 inputBorder"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    {hasLabel && <Form.Label>Password</Form.Label>}
                    <Form.Control
                      placeholder={!hasLabel ? "Password" : ""}
                      name="password"
                      type="password"
                      className="fs--1 inputBorder"
                    />
                  </Form.Group>
                  <input type="hidden" name="_csrf" value={getToken()} />
                  <div className="row">
                    <div className="col-12">
                      <Form.Group className="d-flex justify-content-center align-items-center">
                        <Button
                          type="submit"
                          color="primary"
                          className="mt-2 me-2 w-100 btn-info"
                        >
                          Log in
                        </Button>
                        <Button
                          type="submit"
                          color="danger"
                          className="mt-2 w-100 btn-danger"
                        >
                          Reset
                        </Button>
                      </Form.Group>
                    </div>
                  </div>
                </Form>
              </Card.Body>
              <Card.Footer className="form-footer mb-3">
                <Row className="justify-content-between align-items-center">
                  {/* <Col xs='auto'> */}
                  {/*<Form.Check
                    type='checkbox'
                    id='rememberMe'
                    className='mb-0'>
                    <Form.Check.Input type='checkbox' name='remember' />
                    <Form.Check.Label className='mb-0 text-700'>
                      Remember me
                    </Form.Check.Label>
</Form.Check>*/}
                  {/* <Link className='fs--1 mb-0 link' to='/forgotpassword'>
                    <span className='iconBody'>
                      <FontAwesomeIcon
                        className='icon fs--1'
                        icon={faLock}
                        size='lg'
                      />
                    </span>
                    Forgot Password?
                  </Link> */}
                  {/* </Col> */}

                  <Col xs="auto">
                    <Link className="fs--1 mb-0 link" to="/register">
                      <span className="iconBody">
                        <FontAwesomeIcon
                          className="icon fs--1"
                          icon={faCheck}
                          size="lg"
                        />
                      </span>
                      Register Now
                    </Link>
                  </Col>
                  <Col xs="auto">
                    <Link
                      className="fs--1 mb-0 link"
                      to=""
                      // onClick={handleShow}
                    >
                      <span className="iconBody">
                        <FontAwesomeIcon
                          className="icon fs--1"
                          icon={faFile}
                          size="lg"
                        />
                      </span>
                      Terms & Conditions
                    </Link>
                  </Col>
                  <Col xs="auto">
                    <Link
                      className="fs--1 mb-0 link"
                      to=""
                      // onClick={handleShowPolicy}
                    >
                      <span className="iconBody">
                        <FontAwesomeIcon
                          className="icon fs--1"
                          icon={faSearchPlus}
                          size="lg"
                        />
                      </span>
                      Privacy Policy
                    </Link>
                  </Col>
                </Row>
              </Card.Footer>
              {/* <div className='col-12 fs--1 copyWriteText'>
              Copyright © EA Technologies 2018. All rights reserved
            </div> */}
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginForm;
