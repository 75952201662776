/* eslint-disable react-hooks/exhaustive-deps */
import DataTable from '../../../common/components/DataTable';
import { useDispatch } from 'react-redux';

import {
  toggleAddAgent,
  toggleAddAgentButton,
  toggleColumnSettingsDashboard,
  updateColumnDefinition,
  updateColumnDefinitionConfigs,
} from '../slices/dashboardSlice';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';

import NominationReq from '../../nominationReq/NominationReq';
import {
  Link,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OdexSpinner from '../../../common/components/OdexSpinner';
import {
  IConfigsDefinition,
  generateColumnConfig,
} from '../../../utils/columnConfig';
import React, { useEffect, useRef, useState } from 'react';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AESDecrypt, AESEncrypt } from '../../../../encrypt-util';
import { IDataTableColumn } from '../../../common/types/dataTable';
import DashboardRoot from './DashboardRoot';
import { graphql } from 'msw';
import {
  updateCurrentPageInstructionDashboard,
  updateSelectedTableData,
} from '../../dashboard/slices/dashboardSlice';
import { getOBLDetails } from '../../dashboard/actions/instruction';
import { updateInstructionID } from '../../blDetails/slices/documentHistorySlice';
import { Card } from 'react-bootstrap';
import { updateSelectedInstRow } from '../../dashboard/slices/dashboardSlice';

const TableComponent = () => {
  const tableRef = useRef<any>(null);
  const { t } = useTranslation();
  const { encryptdoc, blNum, encrypt, doc_id } = useParams();
  const blDocId = encryptdoc;
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const grids = useSelector(
    (state: RootState) => state.dataTable.instructionsGrids
  );
  const currentPage = useSelector(
    (state: RootState) => state.dashboard.currentPageInstruction
  );

  const rbac = useSelector((state: RootState) => state.userProfile);
  const selectionInitialState = useSelector(
    (state: RootState) => state.dashboard.selectedInstDataRows
  );
  // const configs = useSelector(
  //   (state: RootState) => state.instructionDashboard.configs
  // );
  const obldetails = useSelector(
    (state: RootState) => state.instructionDashboard.obldetails
  );
  // const columns = useSelector((state: RootState) => state.dashboard.columns);
  const [columns, setColumns] = useState<IDataTableColumn[]>([]);
  const [configs, setConfigs] = useState<IConfigsDefinition>({
    columns: [],
    staticHiddenCols: [],
    defaultHiddenCols: [],
  });

  const data = useSelector(
    (state: RootState) => state.dashboard.instructionDashboardList
  );
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );
  // const dataCountry = linerDataCountry || profileDetails.partnerCountry || '';
  const storedUserCountry = JSON.parse(
    window.localStorage.getItem('@user-selected-datacountry') || '{}'
  );
  const storedCountry =
    storedUserCountry?.[0]?.loginId === profileDetails.email
      ? storedUserCountry?.[0]?.country
      : null;
  const dataCountry: any = storedCountry
    ? storedCountry
    : linerDataCountry
      ? linerDataCountry
      : profileDetails.partnerCountry || null;
  const dataLoading = useSelector(
    (state: RootState) => state.dashboard.dataLoading
  );
  const selectedLinerId = useSelector(
    (state: RootState) => state.userProfile.selectedLinerId
  );
  useEffect(() => {
    if (blNum) {
      dispatch(
        getOBLDetails({
          blNumber: AESDecrypt(blNum) || null,
          selectedLinerId:
            profileDetails.partnerType === 'liner'
              ? profileDetails?.partnerId
              : selectedLinerId,
        })
      );
    }
  }, [blNum]);
  const instructionIdurl = useSelector(
    (state: RootState) => state.documentHistory.instructionIdurl
  );

  const getParams = () => {
    dispatch(
      updateInstructionID(AESDecrypt(location?.pathname?.split?.('/')?.[3]))
    );
    return {
      bl_id: blNum ? AESDecrypt(blNum) : '',
      doc_id: encryptdoc ? AESDecrypt(encryptdoc) : '',
      // inst_id: parseInt(location?.pathname?.split?.('/')?.[3]) || '',
      inst_id: encrypt ? AESDecrypt(encrypt) : '',
    };
  };

  /*  const userGridDefinitions = useSelector(
    (state: RootState) => state.userProfile.userGridDefinitions
  ); */
  const userGridDefinitions = useSelector(
    (state: RootState) => state.dataTable.grids
  );

  const showColumnSettings = useSelector(
    (state: RootState) => state.instructionDashboard.showColumnSettings
  );

  const showAddAgent = useSelector(
    (state: RootState) => state.instructionDashboard.showAddAgent
  );

  const showNominaationReq = useSelector(
    (state: RootState) => state.instructionDashboard.showNonimationReq
  );

  const rowColumns = useSelector(
    (state: RootState) => state.instructionTable.rowColumns
  );

  const onHideColumnSettings = () => {
    dispatch(toggleColumnSettingsDashboard(false));
  };
  const loadingGridConfigs = useSelector(
    (state: RootState) => state.dataTable.loadingGridConfigs
  );

  const accessorEtd = () => (rowData: any) => {
    const { etd } = rowData.row.original;

    try {
      const value = etd
        .split('-')
        .map((v: string, index: number) => (index === 0 ? v.slice(2, 4) : v))
        .join('-');

      return (
        <div className='text-truncate' data-toggle='tooltip' title={value}>
          {value}
        </div>
      );
    } catch (e) {
      return (
        <div className='text-truncate' data-toggle='tooltip' title={etd}>
          {etd}
        </div>
      );
    }
  };

  const accessorEta = () => (rowData: any) => {
    const { eta } = rowData.row.original;

    try {
      const value = eta
        .split('-')
        .map((v: string, index: number) => (index === 0 ? v.slice(2, 4) : v))
        .join('-');

      return (
        <div className='text-truncate' data-toggle='tooltip' title={value}>
          {value}
        </div>
      );
    } catch (e) {
      return (
        <div className='text-truncate' data-toggle='tooltip' title={eta}>
          {eta}
        </div>
      );
    }
  };
  const selectedData = useSelector(
    (state: RootState) => state.dashboard.selectedData
  );
  useEffect(() => {
    let selections: any = {};
    data.result?.forEach((d: any, i: any) => {
      if (
        selectedData?.map((v: any) => v?.bl_event_id).includes(d?.bl_event_id)
      )
        selections[i] = true;
    });

    dispatch(updateSelectedInstRow(selections));
  }, [selectedData, data]);

  // useEffect(()=>{
  //   dispatch(updateSelectedTableData([]));
  // },[dataCountry])

  // const columns = columnConfig(AESDecrypt(blNum) || null);

  // useEffect(() => {
  //   const blDashboardDefinition = grids?.[0]?.columnConfigGrid || '';

  //   if (!blDashboardDefinition) return;

  //   const configs = generateColumnConfig(JSON.parse(blDashboardDefinition));

  //   const columns = configs?.columns?.map((c) =>
  //     c.accessor === 'eta'
  //       ? { ...c, Cell: accessorEta() }
  //       : c.accessor === 'etd'
  //       ? { ...c, Cell: accessorEtd() }
  //       : c
  //   );

  //   dispatch(updateColumnDefinitionConfigs(configs));
  //   dispatch(updateColumnDefinition(columns));
  // }, [grids, location]);

  useEffect(() => {
    const blDashboardDefinition = grids?.[0]?.columnConfigGrid || '';

    if (!blDashboardDefinition) return;

    try {
      const configs = generateColumnConfig(
        JSON.parse(JSON.parse(blDashboardDefinition)),
        getParams,
        navigate,
        selectedData,
        dispatch
      );

      const columns = configs?.columns?.map((c) => c);
      setConfigs(configs);
      setColumns(configs?.columns);
    } catch (e) {
      console.error('Column Error: ', e);
    }
  }, [grids, location, selectedData]);
  const setCurrentPage = (page: number) => {
    dispatch(updateCurrentPageInstructionDashboard(page));
  };

  useEffect(() => {
    const sliderOpened = showAddAgent || showNominaationReq;

    if (sliderOpened && tableRef.current) {
      const tableElement = tableRef.current;
      const rowIndexToShow = 0;

      const rows = tableElement.getElementsByTagName('tr');
      const rowToScroll = rows[rowIndexToShow];

      if (rowToScroll) {
        const tableBounds = tableElement.getBoundingClientRect();
        const rowBounds = rowToScroll.getBoundingClientRect();

        if (
          rowBounds.bottom > tableBounds.bottom ||
          rowBounds.top < tableBounds.top
        ) {
          // Scroll the row into view smoothly
          rowToScroll.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }
  }, [showAddAgent, showNominaationReq]);
  return (
    <div
      className={`row g-0 col-transition-animation h-100 w-100 ${rowColumns.mainTableClass === 'col-lg-12'
          ? 'w-100 '
          : 'odex-width-14rem '
        }`}
    >
      <div
        className={`d-flex flex-column ${showAddAgent
            ? 'col-sm-0 col-md-7 col-lg-7'
            : showNominaationReq
              ? 'col-sm-0 col-md-6 col-lg-6'
              : 'col-md-12 col-lg-12'
          }`}
      >
        {columns?.length > 0 ? (
          <>
            {location.pathname !== '/opc/instruction' ? (
              <Card.Header className='p-0 ps-2 pe-2 d-flex justify-content-start align-items-center odex-mh-60px' />
            ) : (
              <DashboardRoot />
            )}
            <DataTable
              data={data?.result || []}
              columns={columns}
              sortable={false}
              searchable={false}
              showColumnFilter={false}
              selection={rbac.rbacDetails.allowInstructionAssignment == 'Y'}
              selectionInitialState={selectionInitialState}
              perPage={20}
              pagination
              configGroup={
                //  grids?.find?.((g) => g?.gridId === 2)?.configGroup || []
                grids?.find?.((g) => g?.gridName === 'OPC_INST_DASHBOARD')
                  ?.configGroup || []
              }
              onChangePageNumber={(pageNumber) => setCurrentPage(pageNumber)}
              numberPagination
              maxPage={parseInt(data?.maxPage || '100')}
              hasNext={data?.hasNext}
              showExport={false}
              currentPage={currentPage}
              localFilter={true}
              showColumnSettings={showColumnSettings}
              gridName='OPC_INST_DASHBOARD'
              onHide={onHideColumnSettings}
              columnSettingsPersistKey='@odex/maintable/v6'
              onCustomSelectChange={(d) => {
                if (d?.list?.length > 0) {
                  dispatch(toggleAddAgentButton(true));
                } else {
                  dispatch(toggleAddAgentButton(false));
                  dispatch(toggleAddAgent(false));
                }
              }}
              customSort={true}
              shortPrepend='bl_'
              uniqueFilterKey='instDashboard'
              staticHiddenCols={configs?.staticHiddenCols || []}
              defaultHiddenCols={configs?.defaultHiddenCols || []}
              autoHeight={false}
              tableMinHeight={400}
              dataCountry={dataCountry}
              gridId={grids?.[0]?.gridId || 0}
              dataLoading={false}
            />
          </>
        ) : (
          ''
        )}
      </div>
      <div
        className={`${showAddAgent
            ? 'col-sm-12 col-md-5 col-lg-5 odex-overlap-card h-100'
            : showNominaationReq
              ? 'col-sm-12 col-md-6 col-lg-6 odex-overlap-card h-100'
              : 'd-none'
          }`}
      ></div>
    </div>
  );
};

export default TableComponent;
