/* eslint-disable react-hooks/exhaustive-deps */
import {
  faGear,
  faFileCsv,
  faPlus,
  faTrash,
  faAdd,
  faFileInvoice,
  faMailBulk,
  faDownload,
  faUpload,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Button, InputGroup, Form, Dropdown } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.min.css";
import { matchPath, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { AnyAction } from "@reduxjs/toolkit";
import Papa from "papaparse";
import { BLDetailsList } from "../../../features/dashboard/types";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  toggleAddAgent,
  toggleNominationReq,
  updateCurrentPageInstructionDashboard,
  updatePrveDependenciesBlDashboard,
} from "../../../features/dashboard/slices/dashboardSlice";
import { updateSelectedPayee } from "../../../features/dashboard/slices/dashboardSlice";
import {
  useEffect,
  useState,
  useMemo,
  useRef,
  ChangeEvent,
  Children,
} from "react";
import {
  updateDashbaordRefresh,
  updateDataTableFilterState,
  updateParams,
  updatePermitTrackingState,
  updateSelectedConfigId,
  updateSortStateDataTable,
} from "../DataTable/slices/DataTable";
import {
  exportDashboardData,
  getBlsListExportCSV,
  getDashboardData,
  getEbsPermitsData,
  getEirInPermitsData,
  getInvoiceListExportCSV,
  getInvoiceTableData,
  getPermitTracking,
  getPermitTrackingData,
  getVesselTableData,
} from "../../../features/dashboard/actions/dashboard";
import {
  faCheckCircle,
  faCircleQuestion,
  faCreditCard,
  faEnvelope,
  faHandPointRight,
} from "@fortawesome/free-regular-svg-icons";
import { generateActiveColuns } from "../../../utils/columnConfig";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";
import { updateInitialLoadLocalStorage } from "../../slices/filterView";
import AddInstruction from "../../../features/instructionDashboard/components/AddInstruction";
import {
  updateSelectedInvoiceType,
  updateSelectedRowsInvoice,
} from "../../../features/multiBlPayment/slices/multiBlPaymentSlice";
import { Link } from "react-router-dom";
import { Divider } from "semantic-ui-react";
import queryString from "query-string";
import { AESEncrypt } from "../../../../encrypt-util";
import Flex from "../../../../components/common/Flex";
import UploadIndicator from "../../../../components/common/UploadIndicator";
import axiosInstance from "../../../../axiosSetup";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import AssignedToButton from "../../../features/instructionDashboard/components/AssignedTo";
import { selectReportInstruction } from "../../../features/reportDashboard/reportSlice";
import useReportGridName from "../../../features/reportDashboard/useReportGridName";
import SnoozedToButton from "../../../features/instructionDashboard/components/SnoozedTo";
import { Console } from "console";
import Select from "react-select";
import { getExchangeRateRefresh } from "../../../features/blPayment/actions/payments";
import "../../../../assets/scss/filterView.scss";
import { c } from "msw/lib/glossary-de6278a9";

interface IProps {
  toggleColumnSettings?: (v: boolean) => AnyAction;
  showColumnSettings?: boolean;
  showPayButton?: boolean;
  showUploadButton?: boolean;
  showAddFundButton?: boolean;
  showExport?: boolean;
  dashboardName?: string;
  showNomination?: boolean;
  showRefresh?: boolean;
  data?: any;
  columns?: any;
  showInstruction?: boolean;
  columnSettingsPersistKey?: string;
  dataLoading?: boolean;
  configViews?: any[];
  uniqueFilterKey?: string;
  showRightDiv?: boolean;
  loadList?: (pageUpdate: boolean) => void;
  gridDef?: any[];
  showAddUserButton?: any;
  showAddInstruct?: boolean;
  showAmount?: boolean;
  showReportDropdown?: boolean;
  showAddDoButton?: any;
  csvByMail?: boolean;
  additionalChildren?: any;
  manualFilter?: boolean;
  hideAll?: boolean
}

const FilterView = ({
  loadList,
  toggleColumnSettings,
  showColumnSettings,
  showPayButton,
  dashboardName,
  showAddFundButton,
  showExport,
  showNomination,
  showInstruction,
  dataLoading,
  configViews,
  uniqueFilterKey = '',
  showRightDiv = true,
  data = { result: [] },
  gridDef,
  showAddUserButton,
  showRefresh,
  showAddInstruct = false,
  showAmount = false,
  showReportDropdown = false,
  showAddDoButton,
  csvByMail = true,
  additionalChildren,
  manualFilter,
  hideAll = false
}: IProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const activeButton = useSelector(
    (state: RootState) => state.dataTable.permitTrackingState
  );
  const [activeButtonInvoice, setActiveButtonInvoice] =
    useState<any>("CWPDRAFTINVOICES");
  const [loadingFileState, setLoadingFileState] = useState<any>();
  const [fileName, setFileName] = useState("Upload File");
  const [uploadStatus, setuploadStatus] = useState(false);
  const [payeeName, setpayeeName] = useState([]);
  const [tempFile, setTempFileName] = useState<any>("");
  const [payeeList, setpayeeList] = useState<any>("");

  const [previousState, setPreviousState] = useState<{
    filterState: string | null;
    search: string | null;
    grids: any[] | null;
  }>({
    filterState: null,
    search: null,
    grids: null,
  });

  const exchangeRate = useSelector((state: any) => state.userProfile.exchangeRate);

  const filtrState = useSelector((state: any) => state.dataTable.filterState);
  const { t } = useTranslation();
  const grids = useSelector((state: RootState) => state.dataTable.grids);
  const paymentGrids = useSelector(
    (state: RootState) => state.dataTable.paymentDashboardGrids
  );
  const enquiryDashboardContainerGrids = useSelector(
    (state: RootState) => state.dataTable.enquiryDashboardContainerGrids
  );
  const enqGrids = useSelector(
    (state: RootState) => state.dataTable.enquiryDashboardGrids
  );
  const vesselGrids = useSelector(
    (state: RootState) => state.dataTable.vesselGrids
  );
  const permitGrids = useSelector(
    (state: RootState) => state.dataTable.permitGrids
  );

  // const currentGridsName = useSelector(
  //   (state: RootState) => state.dashboard.currentGridName
  // );
  const selectedInst = useSelector(
    (state: RootState) => state.reportDashboard.selectedInstruct
  );
  const invoiceGrids = useSelector(
    (state: RootState) => state.dataTable.invoiceGrids
  );
  const invoiceDataGridsgrids = useSelector(
    (state: RootState) => state.dataTable.invoiceDataGrids
  );
  const standingInstructionGrids = useSelector(
    (state: RootState) => state.dataTable.standingInstructionGrids
  );
  const doGrids = useSelector((state: RootState) => state.dataTable.doGrids);
  const registrationMasterGrid = useSelector(
    (state: RootState) => state.dataTable.registrationMasterGrid
  );
  const paymentFundGrids = useSelector(
    (state: RootState) => state.dataTable.paymentFundGrids
  );

  const bestPickGrids = useSelector(
    (state: RootState) => state.dataTable.bestPickGrids
  );
  const reportList = useSelector(
    (state: RootState) => state.reportDashboard.reportList
  );
  const instructionsGrids = useSelector(
    (state: RootState) => state.dataTable.instructionsGrids
  );

  const reportGrids = useSelector(
    (state: RootState) => state.dataTable.reportGrids
  );
  const { reportGridName, reportGridFilterKey } = useReportGridName();
  const fileInputRef = useRef(null);
  const userGrids = useSelector(
    (state: RootState) => state.dataTable.userGrids
  );

  const selectedPayee = useSelector(
    (state: RootState) => state.dashboard.selectedPayee
  );

  const tpoId = useSelector((state: RootState) => state.agentDelegation.tpoId);
  const savedViewValue: any = localStorage.getItem(
    `selectedConfigId_${dashboardName}`
  );

  const [csvLabels, setCsvLabels] = useState<
    { accessor: string; label: string; timeFormat: string; dataType?: string }[]
  >([]);
  const [exportCsvLabels, setExportCsvLabels] = useState<
    { accessor: string; label: string; dataType?: string }[]
  >([]);

  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const userGridDefinitions = useSelector(
    (state: RootState) => state.userProfile.userGridDefinitions
  );

  const selectedTopSearchFilter = useSelector(
    (state: RootState) => state.navbarTop.selectedTopSearchFilter
  );

  const currentGridName = useSelector(
    (state: RootState) => state.dashboard.currentGridName
  );
  const setCurrentPage = (page: number) => {
    dispatch(updateCurrentPageInstructionDashboard(page));
  };

  const filterState = useSelector(
    (state: RootState) => state.dataTable.filterState
  );

  const sort = (queryString.parse(location.search)?.sort as string)
    ?.split?.(",")
    ?.join?.(" ");

  const [isUnderlined, setIsUnderlined] = useState(false);

  const linkStyle = {
    cursor: "pointer",
    fontSize: "13px",
    fontStyle: "italic",
    textDecoration: isUnderlined ? "underline" : "none",
  };

  const searchInputValue = useSelector(
    (state: RootState) => state.navbarTop.searchInputValue
  );
  const [dropdownDownVisible, setdropdownDownVisible] = useState(false);
  const instructionTopSearchState = useSelector(
    (state: RootState) => state.navbarTop.instructionTopSearchState
  );

  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );
  const storedUserCountry = JSON.parse(
    window.localStorage.getItem("@user-selected-datacountry") || "{}"
  );
  const storedCountry =
    storedUserCountry?.[0]?.loginId === profileDetails.email
      ? storedUserCountry?.[0]?.country
      : null;
  const dataCountry = storedCountry
    ? storedCountry
    : linerDataCountry
      ? linerDataCountry
      : profileDetails.partnerCountry || null;

  const currentPage = useSelector(
    (state: RootState) => state.dashboard.currentPageInstruction
  );
  const currentPageRegistration = useSelector(
    (state: RootState) => state.dashboard.currentPageRegistration
  );

  /* const filterState = useSelector(
    (state: RootState) => state.dataTable.filterState
  ); */
  const selectedConfigId = useSelector(
    (state: RootState) => state.dataTable.selectedConfigId
  );
  const selectedData = useSelector(
    (state: RootState) => state.dashboard.selectedData
  );

  const selectedRowsInvoice: any[] = useSelector(
    (state: RootState) => Object.values(state.multiBlPayment.selectedRowsInvoice).filter(x => x != undefined)
  );
  const selectedInvoiceType = useSelector(
    (state: RootState) => state.multiBlPayment.selectedInvoiceType
  );

  const selectedinstIdList: any = selectedData.map((item: { inst_id: any }) => {
    return item.inst_id;
  });

  const userGridDefinitionConfig = userGridDefinitions?.find(
    (item: any) => item.gridName === dashboardName
  )?.columnConfigGrid;

  const rbac: any = useSelector((state: RootState) => state.userProfile);

  const includesPropertyValue = (array: any, propertyName: any, value: any) => {
    return array.some(
      (item: { [x: string]: any }) => item[propertyName] === value
    );
  };

  console.log(
    //selectedRowsMultiPayTable,
    //selectedAmount,
    selectedRowsInvoice,
    'dattttt'
  );

  const enableAddAgentButton = useSelector(
    (state: RootState) => state.dashboard.enableAddAgentButton
  );

  const configViewDependency = JSON.stringify(configViews) || "";
  const prevselectedConfigId = useSelector(
    (state: RootState) => state.dashboard.prevDependencies.prevselectedConfigId
  );
  const setPrevPageInstructionDashbaord = (state: string) => {
    dispatch(
      updatePrveDependenciesBlDashboard({
        key: "prevPageInstructionDashbaord",
        value: state,
      })
    );
  };
  const setPrevPageRegistrationDashbaord = (state: string) => {
    dispatch(
      updatePrveDependenciesBlDashboard({
        key: "prevPageRegistrationDashbaord",
        value: state,
      })
    );
  };

  const prevPageInstructionDashbaord = useSelector(
    (state: RootState) =>
      state.dashboard.prevDependencies.prevPageInstructionDashbaord
  );
  const roeGrids = useSelector((state: RootState) => state.dataTable.roeGrids);
  const prevPageRegistrationDashbaord = useSelector(
    (state: RootState) =>
      state.dashboard.prevDependencies.prevPageRegistrationDashbaord
  );
  const setPrevselectedConfigId = (loc: string) => {
    dispatch(
      updatePrveDependenciesBlDashboard({
        key: "prevselectedConfigId",
        value: loc,
      })
    );
  };

  const handleFileChange = (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      if (!file.name.endsWith(".xlsx")) {
        toast.error("Please select a valid XLSX file.", { toastId: 'handleFileChangeToastFailedSuccess4' });
        event.target.value = null;
        return;
      } else {
        if (file.size > 10 * 1024 * 1024) {
          toast.error(
            "File size is greater than 10MB. Please upload file below 10MB"
            , { toastId: 'handleFileChangeToastFailed2' }
          );
          event.target.value = null;
          return;
        } else {
          if (event.target.files) {
            const file = event?.target?.files[0];
            if (file) {
              setFileName(file.name);
            }
          }

          const formData = new FormData();
          formData.append("file", file);

          try {
            setLoadingFileState(0);

            axiosInstance
              .post("/api/invoice-management/v1/view/file", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                onUploadProgress(progressEvent: any) {
                  const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / (progressEvent.total ?? 1)
                  );
                  setLoadingFileState(percentCompleted);
                  // setLoadingFileState((state: any) => {
                  //   return { ...state, [fileDocId]: percentCompleted };
                  // });
                },
              })
              .then((response: any) => {
                setLoadingFileState(100);
                setTempFileName(response?.data?.tempFileName);
                // setLoadingFileState((state: any) => {
                //   return { ...state, [fileDocId]: 100 };
                // });
                // dispatch(
                //   updateSelectedFileServerPath({
                //     ...filePaths,
                //     [fileDocId]: [response.data.path, response.data.tempFileName],
                //   })
                // );
              })
              .catch(() => {
                setLoadingFileState(-1);
                // setLoadingFileState((state: any) => {
                //   return { ...state, [fileDocId]: -1 };
                // });
              });
            event.target.value = null;
            // add server path to redux
          } catch (error) {
            console.error("File Upload Error: ", error);
            console.error("File Upload Error: ", error);
          }
        }
      }

      setSelectedFile(event.target.files[0]);
    }
  };
  const handleToggleClick = () => {
    setdropdownDownVisible(true);
  };

  const handleClick = (actionCreator: any, buttonId: any) => {
    dispatch(updatePermitTrackingState(buttonId));
    actionCreator && dispatch(actionCreator);
  };

  const handleClickInvoice = (actionCreator: any, buttonId: any) => {
    // setActiveButtonInvoice(buttonId);
    dispatch(actionCreator());
    dispatch(buttonId());
  };

  const handleUpload = async (e: any) => {
    setLoadingFileState(null);
    setFileName("Upload File");
    setSelectedFile(null);
    setuploadStatus(false);
    e.target.value = null;
    try {
      const response = await axiosInstance.post(
        `api/invoice-management/v1/payment/uploadFile?filePath=odex-warehouse-documents/temp/uploads/${tempFile}&linerId=1`,
        {
          // Include any request data if necessary
        },
        {
          responseType: "blob", // Specify the responseType as blob
        }
      );

      if (response.status === 200) {
        const excelBlob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const url = window.URL.createObjectURL(excelBlob);

        const link = document.createElement("a");
        link.href = url;
        link.download =
          "Bulk payment upload- Bank transfer - Response file.xlsx";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        toast.success("File downloaded successfully", {
          theme: "colored",
          toastId: 'handleUploadToastSuccess'
        });
        setTimeout(() => {
          navigate("/payment");
        }, 3000);
      } else {
        toast.error("Error downloading file", {
          toastId: 'handleUploadToastFailed'
        });
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.error("Error downloading file", {
        toastId: 'handleUploadToastFailed2'
      });
    }
  };

  useEffect(() => {
    const savedViewValueNew: any = localStorage.getItem(
      `selectedConfigId_${dashboardName}`
    );

    const newValue = savedViewValueNew;
    const newdata = location.pathname.includes("opc/instruction")
      ? instructionsGrids[0]?.configGroup?.find(
        (c: any) => c.userGridId == newValue
      )?.columnConfigGrid
      : location.pathname.includes("opc/doupload")
        ? doGrids[0]?.configGroup?.find((c: any) => c.userGridId == newValue)
          ?.columnConfigGrid
        : location.pathname.includes("paymentDashboard")
          ? paymentGrids[0]?.configGroup?.find((c: any) => c.userGridId == newValue)
            ?.columnConfigGrid
          : location.pathname.includes("payment")
            ? invoiceGrids[0]?.configGroup?.find((c: any) => c.userGridId == newValue)
              ?.columnConfigGrid
            : location.pathname.includes("standing")
              ? standingInstructionGrids[0]?.configGroup?.find(
                (c: any) => c.userGridId == newValue
              )?.columnConfigGrid
              : location.pathname.includes("registrationmaster")
                ? registrationMasterGrid[0]?.configGroup?.find(
                  (c: any) => c.userGridId == newValue
                )?.columnConfigGrid
                : location.pathname.includes("users")
                  ? userGrids[0]?.configGroup?.find((c: any) => c.userGridId == newValue)
                    ?.columnConfigGrid
                  : grids[0]?.configGroup?.find((c: any) => c.userGridId == newValue)
                    ?.columnConfigGrid;

    if (newdata) {
      if (JSON.parse(newdata)[0].sorts) {
        const input = `${JSON.parse(newdata)[0].sorts}`;
        const [firstWord, lastWord]: any = input.match(/\w+/g) || [];
        dispatch(
          updateSortStateDataTable({
            key: firstWord,
            value: lastWord,
          })
        );
        dispatch(
          updateParams({
            key: firstWord,
            value: lastWord,
          })
        );
        const queryParams = queryString.parse(location.search);
        const sortString = {
          sort: `${firstWord},${lastWord}`,
          seek: undefined,
          direction: undefined,
        };
        setTimeout(() => {
          navigate(
            `${location.pathname}?${queryString.stringify({
              ...queryParams,
              ...sortString,
            })}`
          );
        }, 100);
      } else {
        // dispatch(
        //   updateSortStateDataTable({
        //     key: null,
        //   })
        // )
        // navigate(
        //   `${location.pathname}`
        // );
      }
    }
  }, [
    grids,
    invoiceGrids,
    paymentGrids,
    doGrids,
    instructionsGrids,
    userGrids,
    standingInstructionGrids,
    registrationMasterGrid,
  ]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/v1/customer/getCustomerMap?ptoId=${tpoId}`
        );
        setpayeeName(
          response.data.sort((a: any, b: any) =>
            a.partnerNm.localeCompare(b.partnerNm)
          )
        );
      } catch (error) {
        console.error(error);
      }
    };

    if (
      profileDetails.opcPartnerType === "PTO" &&
      (window.location.pathname.includes("permit-tracking") ||
        window.location.pathname.includes("home") ||
        window.location.pathname.includes("invoice"))
    ) {
      fetchData();
    }

    if (
      (window.location.pathname.includes("home") ||
        window.location.pathname.includes("invoice"))
    ) {
      if (profileDetails.opcPartnerType === "PTO") {
        if (selectedInvoiceType) {
          dispatch(updateSelectedPayee(null));
          setpayeeList("");
          dispatch(
            getInvoiceTableData(null)
          );
        }
      }
      else {
        dispatch(updateDashbaordRefresh(true));
        dispatch(updateSelectedRowsInvoice({}));
        dispatch(
          getInvoiceTableData({
            tpoId: tpoId,
            invoiceType: "CWPDRAFTINVOICES",
            payeeId: selectedPayee?.value || "",
          })
        );
      }
    }
  }, []);
  useEffect(() => {
    const defaultViewId =
      /*    configViews?.find?.((v: any) =>
        v?.columnConfigName?.toLowerCase?.().includes('default')
      )?.userGridId || null; */
      configViews?.find?.(
        (v: any) => v?.type === "system" && v?.columnConfigName[0]
      )?.userGridId || null;

    const configViewIds = configViews?.map?.((i: any) => i.userGridId) || [];

    if (
      typeof uniqueFilterKey === "string" ||
      typeof uniqueFilterKey === "number"
    ) {
      dispatch(updateInitialLoadLocalStorage(false));
      dispatch(
        updateSelectedConfigId({
          ...selectedConfigId,
          [uniqueFilterKey]:
            savedViewValue &&
              configViewIds?.includes?.(parseInt(savedViewValue))
              ? savedViewValue
              : defaultViewId,
        })
      );
    }
  }, [configViewDependency, savedViewValue, location.pathname]);

  useEffect(() => {
    const selectedConfigVal = configViews?.find?.(
      (c) => c.userGridId == selectedConfigId?.[uniqueFilterKey || ""]
    );
    let filterValue: any = {};

    if (
      selectedConfigId &&
      JSON.stringify(selectedConfigId) !== prevselectedConfigId
    ) {
      setPrevselectedConfigId(JSON.stringify(selectedConfigId));
      if (configViews && selectedConfigId) {
        if (selectedConfigVal?.columnConfigGrid) {
          for (let c of JSON.parse(selectedConfigVal?.columnConfigGrid)) {
            if (c?.filterValues?.[0]?.label && c?.filterValues?.[0]?.accessor) {
              filterValue[c.filterValues[0].accessor] = JSON.parse(
                c.filterValues[0].label
              );
            } else {
              filterValue[c.accessor] = "";
            }
          }
        }

        if (selectedConfigId?.[uniqueFilterKey || ""] && selectedConfigVal) {
          // Todo: Invoice Dashboard reload issue can be resolved here
          if (uniqueFilterKey) {
            setTimeout(() => {
              dispatch(
                updateDataTableFilterState({
                  ...filterState,
                  [uniqueFilterKey]: filterValue,
                })
              );
            }, 500);
          }
        } else if (
          uniqueFilterKey &&
          selectedConfigId?.[uniqueFilterKey] === null
        ) {
          if (uniqueFilterKey) {
            //
            dispatch(
              updateDataTableFilterState({
                ...filterState,
                [uniqueFilterKey]: {},
              })
            );
          }
        }
      }
    }
  }, [selectedConfigId]);

  const _toggleAddAgent = () => {
    dispatch(toggleNominationReq(false));
    dispatch(toggleAddAgent(true));
  };

  const formatNumber = (stringValue: string): string => {
    const numericValue = parseFloat(stringValue);

    if (isNaN(numericValue)) {
      return "Invalid Number";
    }

    const trimmedValue = numericValue.toFixed(2);

    const numberWithCommas = trimmedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return numberWithCommas;
  };

  const navigate = useNavigate();

  const onShowColumnSettings = () => {
    if (toggleColumnSettings) dispatch(toggleColumnSettings(true));
  };

  // Function to export data as a file
  const exportData = (data: any, fileName: string, type: string) => {
    try {
      // Create a link and download the file
      const blob = new Blob([data], { type });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      toast.success("File downloaded successfully", {
        theme: "colored",
        toastId: 'exportDataToastSuccess'
      });
    } catch (error) {
      console.error(error);
      toast.error("something went wrong", {
        theme: "colored",
        toastId: 'exportDataToastFailded'
      });
    }
  };

  useEffect(() => {
    const selectedConfigVal = configViews?.find?.(
      (c) => c.userGridId == selectedConfigId?.[uniqueFilterKey || ""]
    )?.columnConfigGrid;
    if (selectedConfigVal) {
      const generateHeaderLabel = (s: any) => {
        let count = 1;
        for (let i in s) {
          if (s.charAt(i) === s.charAt(i).toUpperCase()) {
            count++;
          }
        }
        return count ? t(`resource_${count}:${s}`) : s;
      };

      // if (dashboardName == "INV_DASHBOARD")
      //   if (invoiceGrids[0]) {
      //     const finalArray: any = [];
      //     JSON.parse(selectedConfigVal).forEach((obj1: any) => {
      //       const obj2 = JSON.parse(
      //         JSON.parse(invoiceGrids?.[0]?.columnConfigGrid)
      //       ).find((item: any) => item.accessor === obj1.accessor);
      //       if (obj2) {
      //         finalArray.push({ ...obj1, dataType: obj2.dataType });
      //       }
      //     });

      //     if (finalArray.length > 0) {
      //       const labels = finalArray
      //         .filter((v: any) => v.visible)
      //         .map((d: any) => ({
      //           accessor: d.accessor,
      //           label: d?.accessor ? generateHeaderLabel(d.accessor) : "",
      //           dataType: d?.dataType,
      //         }));
      //       setCsvLabels(labels);
      //     }
      //   }

      [
        { name: "OPC_USER_DASHBOARD", grid: userGrids },
        { name: "OPC_VESSEL_DASHBOARD", grid: vesselGrids },
        { name: "OPC_PERMIT_DASHBOARD", grid: permitGrids },
        { name: "INV_DASHBOARD", grid: invoiceGrids },
        { name: "BL_DASHBOARD", grid: grids },
        { name: "OPC_PAYMENT_DASHBOARD", grid: paymentGrids },
        { name: "OPC_ENQ_DASHBOARD", grid: enqGrids },
        { name: "OPC_CONTAINER_ENQUIRY", grid: enquiryDashboardContainerGrids },
        { name: "OPC_INSTRUCTION_DASHBOARD", grid: instructionsGrids },
        { name: "OPC_INV_DASHBOARD", grid: invoiceDataGridsgrids },
        { name: "OPC_DO_DASHBOARD", grid: doGrids },
        { name: "REGISTRATION_DASHBOARD", grid: registrationMasterGrid },
        { name: "STANDING_INSTRUCTION", grid: standingInstructionGrids },
        { name: "OPC_PAYMENT_FUNDING", grid: paymentFundGrids },
        { name: "OPC_BESTPICK_DASHBOARD", grid: bestPickGrids },
        { name: "OPC_ROE_DASHBOARD", grid: roeGrids },
      ].forEach(({ name, grid }) => {
        if (dashboardName == name) {
          if (grid[0]) {
            const finalArray: any = [];
            JSON.parse(selectedConfigVal).forEach((obj1: any) => {
              const obj2 = JSON.parse(
                JSON.parse(grid?.[0]?.columnConfigGrid)
              ).find((item: any) => item.accessor === obj1.accessor);
              if (obj2) {
                finalArray.push({ ...obj1, dataType: obj2.dataType });
              }
            });

            const csvHide = JSON.parse(JSON.parse(grid[0]?.columnConfigGrid))
              ?.filter?.((x: any) => x?.csv == false)
              .map((x: any) => x.accessor);

            if (finalArray.length > 0) {
              const labels = finalArray
                .filter((v: any) => v.visible && !csvHide?.includes(v.accessor))
                .map((d: any) => ({
                  accessor: d.accessor,
                  label: d?.accessor ? generateHeaderLabel(d.accessor) : "",
                  dataType: d?.dataType,
                }));
              setCsvLabels(labels);
            }
          }
        }
      });
      // if (dashboardName == "BL_DASHBOARD")
      //   if (grids[0]) {
      //     const finalArray: any = [];
      //     JSON.parse(selectedConfigVal).forEach((obj1: any) => {
      //       const obj2 = JSON.parse(
      //         JSON.parse(grids?.[0]?.columnConfigGrid)
      //       ).find((item: any) => item.accessor === obj1.accessor);
      //       if (obj2) {
      //         finalArray.push({ ...obj1, dataType: obj2.dataType });
      //       }
      //     });
      //     if (finalArray.length > 0) {
      //       const labels = finalArray
      //         .filter((v: any) => v.visible)
      //         .map((d: any) => ({
      //           accessor: d.accessor,
      //           label: d?.accessor ? generateHeaderLabel(d.accessor) : "",
      //           dataType: d?.dataType,
      //         }));
      //       setCsvLabels(labels);
      //     }
      //   }
      // if (dashboardName == "OPC_PAYMENT_DASHBOARD")
      //   if (paymentGrids[0]) {
      //     const finalArray: any = [];
      //     JSON.parse(selectedConfigVal).forEach((obj1: any) => {
      //       const obj2 = JSON.parse(
      //         JSON.parse(paymentGrids?.[0]?.columnConfigGrid)
      //       ).find((item: any) => item.accessor === obj1.accessor);
      //       if (obj2) {
      //         finalArray.push({ ...obj1, dataType: obj2.dataType });
      //       }
      //     });
      //     if (finalArray.length > 0) {
      //       const labels = finalArray
      //         .filter((v: any) => v.visible)
      //         .map((d: any) => ({
      //           accessor: d.accessor,
      //           label: d?.accessor ? generateHeaderLabel(d.accessor) : "",
      //           dataType: d?.dataType,
      //         }));
      //       setCsvLabels(labels);
      //     }
      //   }
      // if (dashboardName == "OPC_INSTRUCTION_DASHBOARD")
      //   if (instructionsGrids[0]) {
      //     console.log("instructiongrids");
      //     const finalArray: any = [];
      //     JSON.parse(selectedConfigVal).forEach((obj1: any) => {
      //       const obj2 = JSON.parse(
      //         JSON.parse(instructionsGrids?.[0]?.columnConfigGrid)
      //       ).find((item: any) => item.accessor === obj1.accessor);
      //       if (obj2) {
      //         finalArray.push({ ...obj1, dataType: obj2.dataType });
      //       }
      //     });
      //     if (finalArray.length > 0) {
      //       const labels = finalArray
      //         .filter((v: any) => v.visible)
      //         .map((d: any) => ({
      //           accessor: d.accessor,
      //           label: d?.accessor ? generateHeaderLabel(d.accessor) : "",
      //           dataType: d?.dataType,
      //         }));
      //       setCsvLabels(labels);
      //     }
      //   }
      // if (dashboardName?.includes("OPC_INV_DASHBOARD"))
      //   if (invoiceDataGridsgrids[0]) {
      //     console.log("instruciontgrids");
      //     const finalArray: any = [];

      //     JSON.parse(selectedConfigVal).forEach((obj1: any) => {
      //       const obj2 = JSON.parse(
      //         JSON.parse(invoiceDataGridsgrids?.[0]?.columnConfigGrid)
      //       ).find((item: any) => item.accessor === obj1.accessor);
      //       if (obj2) {
      //         finalArray.push({ ...obj1, dataType: obj2.dataType });
      //       }
      //     });
      //     if (finalArray.length > 0) {
      //       const labels = finalArray
      //         .filter((v: any) => v.visible)
      //         .map((d: any) => ({
      //           accessor: d.accessor,
      //           label: d?.accessor ? generateHeaderLabel(d.accessor) : "",
      //           dataType: d?.dataType,
      //         }));
      //       console.log("myLables", labels);
      //       setCsvLabels(labels);
      //     }
      //   }
      // if (dashboardName == "OPC_DO_DASHBOARD")
      //   if (doGrids[0]) {
      //     console.log("DOgrids");
      //     const finalArray: any = [];
      //     JSON.parse(selectedConfigVal).forEach((obj1: any) => {
      //       const obj2 = JSON.parse(
      //         JSON.parse(doGrids?.[0]?.columnConfigGrid)
      //       ).find((item: any) => item.accessor === obj1.accessor);
      //       if (obj2) {
      //         finalArray.push({ ...obj1, dataType: obj2.dataType });
      //       }
      //     });
      //     if (finalArray.length > 0) {
      //       const labels = finalArray
      //         .filter((v: any) => v.visible)
      //         .map((d: any) => ({
      //           accessor: d.accessor,
      //           label: d?.accessor ? generateHeaderLabel(d.accessor) : "",
      //           dataType: d?.dataType,
      //         }));
      //       setCsvLabels(labels);
      //     }
      //   }
      // if (dashboardName == "PICKUP_LOC_INSTRACT_REPORT")
      //   if (reportGrids[0]) {
      //     const finalArray: any = [];
      //     JSON.parse(selectedConfigVal).forEach((obj1: any) => {
      //       const obj2 = JSON.parse(
      //         JSON.parse(reportGrids?.[0]?.columnConfigGrid)
      //       ).find((item: any) => item.accessor === obj1.accessor);
      //       if (obj2) {
      //         finalArray.push({ ...obj1, dataType: obj2.dataType });
      //       }
      //     });
      //     if (finalArray.length > 0) {
      //       const labels = finalArray
      //         .filter((v: any) => v.visible)
      //         .map((d: any) => ({
      //           accessor: d.accessor,
      //           label: d?.accessor ? generateHeaderLabel(d.accessor) : "",
      //           dataType: d?.dataType,
      //         }));
      //       setCsvLabels(labels);
      //     }
      //   }
      // if (dashboardName == "REGISTRATION_DASHBOARD")
      //   if (registrationMasterGrid[0]) {
      //     const finalArray: any = [];
      //     JSON.parse(selectedConfigVal).forEach((obj1: any) => {
      //       const obj2 = JSON.parse(
      //         JSON.parse(registrationMasterGrid?.[0]?.columnConfigGrid)
      //       ).find((item: any) => item.accessor === obj1.accessor);
      //       if (obj2) {
      //         finalArray.push({ ...obj1, dataType: obj2.dataType });
      //       }
      //     });
      //     if (finalArray.length > 0) {
      //       const labels = finalArray
      //         .filter((v: any) => v.visible)
      //         .map((d: any) => ({
      //           accessor: d.accessor,
      //           label: d?.accessor ? generateHeaderLabel(d.accessor) : "",
      //           dataType: d?.dataType,
      //         }));
      //       setCsvLabels(labels);
      //     }
      //   }
      // if (dashboardName == "STANDING_INSTRUCTION")
      //   if (standingInstructionGrids[0]) {
      //     const finalArray: any = [];
      //     JSON.parse(selectedConfigVal).forEach((obj1: any) => {
      //       const obj2 = JSON.parse(
      //         JSON.parse(standingInstructionGrids?.[0]?.columnConfigGrid)
      //       ).find((item: any) => item.accessor === obj1.accessor);
      //       if (obj2) {
      //         finalArray.push({ ...obj1, dataType: obj2.dataType });
      //       }
      //     });
      //     if (finalArray.length > 0) {
      //       const labels = finalArray
      //         .filter((v: any) => v.visible)
      //         .map((d: any) => ({
      //           accessor: d.accessor,
      //           label: d?.accessor ? generateHeaderLabel(d.accessor) : "",
      //           dataType: d?.dataType,
      //         }));
      //       setCsvLabels(labels);
      //     }
      //   }
      // if (dashboardName == "PAYMENT_FUNDING")
      //   if (paymentFundGrids[0]) {
      //     const finalArray: any = [];
      //     JSON.parse(selectedConfigVal).forEach((obj1: any) => {
      //       const obj2 = JSON.parse(
      //         JSON.parse(paymentFundGrids?.[0]?.columnConfigGrid)
      //       ).find((item: any) => item.accessor === obj1.accessor);
      //       if (obj2) {
      //         finalArray.push({ ...obj1, dataType: obj2.dataType });
      //       }
      //     });
      //     if (finalArray.length > 0) {
      //       const labels = finalArray
      //         .filter((v: any) => v.visible)
      //         .map((d: any) => ({
      //           accessor: d.accessor,
      //           label: d?.accessor ? generateHeaderLabel(d.accessor) : "",
      //           dataType: d?.dataType,
      //         }));
      //       setCsvLabels(labels);
      //     }
      //   }

      // const labels = JSON.parse(selectedConfigVal)
      //   .filter((v: any) => v.visible)
      //   .map((d: any) => ({
      //     accessor: d.accessor,
      //     label: d?.accessor ? generateHeaderLabel(d.accessor) : '',
      //     dataType: d?.dataType,
      //   }));
      // setCsvLabels(labels);
    } else {
      if (userGridDefinitionConfig)
        setCsvLabels(
          generateActiveColuns(JSON.parse(JSON.parse(userGridDefinitionConfig)))
        );
    }
  }, [configViews, selectedConfigId]);

  const openLink = () => {
    {
      window.open(
        "https://www.transnetportterminals.net/Ports/Pages/Terminal%20Updates.aspx "
      );
    }
  };

  const getRowValues = (page: any) => {
    try {
      if (manualFilter && filtrState?.[uniqueFilterKey]) {
        let finalPage = page;

        for (let key of Object.keys(filtrState?.[uniqueFilterKey])) {
          if (typeof filtrState?.[uniqueFilterKey]?.[key] === 'string')
            finalPage = finalPage?.filter?.((i: any) => {
              return filtrState?.[uniqueFilterKey]?.[key]
                ? i?.[key]
                  ?.toLowerCase()
                  .includes?.(
                    filtrState?.[uniqueFilterKey]?.[key].toLowerCase()
                  )
                : true;
            });
          else if (
            typeof filtrState?.[uniqueFilterKey]?.[key] === 'object' &&
            filtrState?.[uniqueFilterKey]?.[key]?.length > 0
          ) {
            finalPage = finalPage.filter((i: any) =>
              filtrState?.[uniqueFilterKey]?.[key]?.includes?.(
                i?.[key]
              )
            );
          } else if (
            typeof filtrState?.[uniqueFilterKey]?.[key] === 'object'
            && manualFilter
          ) {
            const from = filtrState?.[uniqueFilterKey]?.[key]?.from ? new Date(
              filtrState?.[uniqueFilterKey]?.[key]?.from
            ) : null

            const to = filtrState?.[uniqueFilterKey]?.[key]?.to ? new Date(
              filtrState?.[uniqueFilterKey]?.[key]?.to
            ) : null

            finalPage = finalPage?.filter?.(
              (i: any) => {
                const dateValue = i?.[key] ? new Date(i?.[key]) : '';
                if ((from || to) && !dateValue) return false

                if (from && from > dateValue) return false
                if (to && to < dateValue) return false
                return true
              })
          }
        }

        return finalPage;
      }
      return page;
    } catch (error) {
      console.error('Get Rows Error: ', error);
      return page;
    }
  };


  const exportDataAsCSV = () => {
    let csvData;
    let fileName = `${dashboardName}.csv`;
    let result = getRowValues(data?.result);
    if (data.result) {
      if (csvLabels?.length > 0) {
        csvData = Papa.unparse(
          result.map((v: any) => {
            let finalOut: any = {};

            for (let i of csvLabels) {
              finalOut[i.label] =
                i?.timeFormat && i?.timeFormat !== "null"
                  ? moment(v?.[i.accessor] + " UTC").format(i.timeFormat)
                  : v?.[i.accessor];
            }
            return finalOut;
          }),
          { columns: csvLabels.map((m) => m.label) }
        );
        exportData(csvData, fileName, "text/csv;");
      }
    } else {
      if (csvLabels?.length > 0) {
        csvData = Papa.unparse(
          result.map((v: any) => {
            let finalOut: any = {};

            for (let i of csvLabels) {
              finalOut[i.label] =
                i?.timeFormat && i?.timeFormat !== "null"
                  ? moment(v?.[i.accessor] + " UTC").format(i.timeFormat)
                  : v?.[i.accessor];
            }

            return finalOut;
          }),
          { columns: csvLabels.map((m) => m.label) }
        );
        exportData(csvData, fileName, "text/csv;");
      }
    }
  };
  // const exportDataAsCSV2 = () => {
  //   const jsonData = {
  //     bulkPayment: {
  //       bulkPayment: [
  //         {
  //           'Invoice No': ' ',
  //           'Inv Amount': '',
  //           'Payment Reference No': '',
  //         },
  //       ],
  //     },
  //   };

  //   const workbook = XLSX.utils.book_new();

  //   const worksheet = XLSX.utils.json_to_sheet(
  //    jsonData.bulkPayment.bulkPayment
  //  );

  //   XLSX.utils.book_append_sheet(workbook, worksheet, 'Employees');

  //   const excelBuffer = XLSX.write(workbook, {
  //     bookType: 'xlsx',
  //     type: 'array',
  //   });

  //   const excelBlob = new Blob([excelBuffer], {
  //     type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  //   });

  //   const link = document.createElement('a');
  //   link.href = URL.createObjectURL(excelBlob);
  //   link.download = 'BULK_Payment.xlsx';

  //   document.body.appendChild(link);
  //   link.click();

  //   document.body.removeChild(link);
  // };

  const showChooseConfigView = () => {
    const types = configViews?.map((v) => v.type);
    const systemConfigsLength =
      configViews &&
        configViews?.filter?.((v) => v?.type === "system")?.length > 1
        ? true
        : false;
    return (
      configViews &&
      configViews?.length > 0 &&
      (types?.includes("shared") ||
        types?.includes("private") ||
        (types?.includes("system") && systemConfigsLength))
    );
  };
  //const filterStateLocal = filterState?.['instDashboard'];

  const filterStateLocal =
    gridDef?.[0]?.gridName === "BL_DASHBOARD"
      ? filterState?.["mainDashboard"]
      : gridDef?.[0]?.gridName === "INV_DASHBOARD"
        ? filterState?.["paymentOne"]
        : gridDef?.[0]?.gridName === "OPC_INST_DASHBOARD"
          ? filterState?.["instDashboard"]
          : gridDef?.[0]?.gridName === "OPC_INST_DASHBOARD"
            ? filterState?.["enqDashboard"]
            : gridDef?.[0]?.gridName === "OPC_CONTAINER_ENQUIRY"
              ? filterState?.["invDashboard"]
              : gridDef?.[0]?.gridName === "OPC_INV_DASHBOARD_TAX"
                ? filterState?.["invDashboard"]
                : gridDef?.[0]?.gridName === "OPC_VESSEL_DASHBOARD"
                  ? filterState?.["vesselDetails"]
                  : gridDef?.[0]?.gridName === "OPC_DO_DASHBOARD"
                    ? filterState?.["doDashboard"]
                    : gridDef?.[0]?.gridName === "OPC_USER_DASHBOARD"
                      ? filterState?.["usersDetails"]
                      : gridDef?.[0]?.gridName === "OPC_PAYMENT_DASHBOARD"
                        ? filterState?.["paymentTwo"]
                        : gridDef?.[0]?.gridName === reportGridName
                          ? filterState?.[reportGridFilterKey]
                          : gridDef?.[0]?.gridName === "REGISTRATION_DASHBOARD"
                            ? filterState?.["adminDashboard"]
                            : gridDef?.[0]?.gridName === "STANDING_INSTRUCTION"
                              ? filterState?.["standingInst"]
                              : gridDef?.[0]?.gridName === "PAYMENT_FUNDING"
                                ? filterState?.["paymentFund"]
                                : "";

  useEffect(() => {
    const currentStateValue = {
      filterState: JSON.stringify(filterStateLocal),
      search: location.search,
      grids: grids,
    };
    if (JSON.stringify(previousState) !== JSON.stringify(currentStateValue)) {
      const currentState = {
        filterState: JSON.stringify(filterStateLocal),
        search: location.search,
        grids: grids,
      };
      setPreviousState(currentState);
    }
  }, [filterStateLocal, location.search, grids]);

  useEffect(() => {
    if (currentPage && currentPage !== prevPageInstructionDashbaord) {
      setPrevPageInstructionDashbaord(currentPage);
    }
  }, [currentPage]);
  useEffect(() => {
    if (
      currentPageRegistration &&
      currentPageRegistration !== prevPageRegistrationDashbaord
    ) {
      setPrevPageRegistrationDashbaord(currentPage);
    }
  }, [currentPageRegistration]);

  useEffect(() => {
    const lables = csvLabels.map((d: any) => ({
      accessor:
        d?.dataType === "date" ||
          d?.dataType === "datetime" ||
          d?.dataType === "number"
          ? `${d?.accessor}_string`
          : d?.accessor,
      label: d?.label,
      dataType: d?.dataType,
    }));
    setExportCsvLabels(lables);
  }, [csvLabels]);

  const charLimit = (name: string, limit: number) =>
    name && name?.length >= limit ? name?.substring(0, limit) + "..." : name;

  const loadDashboardData = (filterUpdated: boolean) => {
    if (filterStateLocal) {
      if (Object.keys(filterStateLocal)?.length > 0) {
        const filterApiState: any = {};

        const filterKeys = Object.keys(filterStateLocal);

        for (let k of filterKeys) {
          if (filterStateLocal?.[k]?.from) {
            filterApiState[k] = [
              filterStateLocal?.[k]?.from || "",
              filterStateLocal?.[k]?.to || "",
            ].filter((i) => i);
          } else if (filterStateLocal?.[k]?.from !== undefined) {
            filterApiState[k] = undefined;
          } else {
            filterApiState[k] = filterStateLocal?.[k]
              ? typeof filterStateLocal?.[k] === "string"
                ? [filterStateLocal?.[k]]
                : filterStateLocal?.[k]
              : undefined;
          }
        }
        if (Object.keys(filterApiState)?.length > 0) {
          const finalFilterApiState: any = {};

          for (let i of Object.keys(filterApiState)) {
            if (filterApiState[i]) {
              finalFilterApiState[i] = filterApiState[i];
            }
          }

          if (true) {
            dispatch(
              exportDashboardData({
                gridName: currentGridName,
                payload: {
                  fields: exportCsvLabels.map((c) => c?.accessor),
                  labels: csvLabels.map((c) => c?.label),
                  filters: !selectedTopSearchFilter?.where
                    ? finalFilterApiState
                    : {},

                  size: 20,
                  sorts: sort
                    ? [sort]
                    : currentGridName === "BL_DASHBOARD"
                      ? ["lastupdateddate desc"]
                      : currentGridName === "INVOICE_DASHBOARD"
                        ? ["invid desc"]
                        : currentGridName === "OPC_INSTRUCTION_DASHBOARD"
                          ? ["requested_on desc"]
                          : currentGridName === "OPC_VESSEL_MASTER"
                            ? ["'filter_status asc'"]
                            : currentGridName === "OPC_INV_DASHBOARD"
                              ? ["requested_on desc"]
                              : currentGridName === "OPC_INV_DASHBOARD_TAX"
                                ? ["requested_on desc"]
                                : currentGridName === "OPC_DO_DASHBOARD"
                                  ? ["requested_on desc"]
                                  : currentGridName === "OPC_USER_DASHBOARD"
                                    ? ["user_id desc"]
                                    : currentGridName === "REGISTRATION_DASHBOARD"
                                      ? ["submissiondate desc"]
                                      : currentGridName === "OPC_PAYMENT_DASHBOARD"
                                        ? ["pmtmode desc"]
                                        : currentGridName === "PICKUP_LOC_INSTRACT_REPORT"
                                          ? ["bl_no desc"]
                                          : currentGridName === "STANDING_INSTRUCTION"
                                            ? ["standing_instruction_id desc"]
                                            : currentGridName === "PAYMENT_FUNDING"
                                              ? ["payment_funding_id desc"]
                                              : "",
                  page: filterUpdated ? 1 : currentPage,
                },
                asCsv: true,

                partnerId: profileDetails.partnerId,
                where: gridDef?.[0]?.whereClause
                  ? AESEncrypt(gridDef?.[0]?.whereClause)
                  : null,
              })
            )
              .unwrap()
              .then((response: any) => {
                if (response === "success") {
                  toast.success(
                    "CSV file has been shared successfully to your email ID.",
                    { toastId: 'loadDashboardDataToastSuccess' }
                  );
                }
              });
          }
        }
      } else {
        if (
          profileDetails &&
          ((profileDetails.partnerType === "customer" &&
            profileDetails.partnerId) ||
            profileDetails?.partnerType === "liner")
        ) {
          dispatch(
            exportDashboardData({
              gridName: currentGridName,
              payload: {
                fields: exportCsvLabels.map((c) => c?.accessor),
                labels: csvLabels.map((c) => c?.label),
                filters:
                  selectedTopSearchFilter?.where == ""
                    ? instructionTopSearchState
                    : {},

                size: 20,
                sorts: sort
                  ? [sort]
                  : currentGridName === "BL_DASHBOARD"
                    ? ["lastupdateddate desc"]
                    : currentGridName === "INVOICE_DASHBOARD"
                      ? ["invid desc"]
                      : currentGridName === "INSTRUCTION_DASHBOARD"
                        ? ["timestamp desc"]
                        : currentGridName === "OPC_USER_DASHBOARD"
                          ? ["user_id desc"]
                          : currentGridName === "OPC_PAYMENT_DASHBOARD"
                            ? ["pmtmode desc"]
                            : currentGridName === "PICKUP_LOC_INSTRACT_REPORT"
                              ? ["bl_no desc"]
                              : currentGridName === "REGISTRATION_DASHBOARD"
                                ? ["submissiondate desc"]
                                : currentGridName === "STANDING_INSTRUCTION"
                                  ? ["standing_instruction_id desc"]
                                  : currentGridName === "PAYMENT_FUNDING"
                                    ? ["payment_funding_id desc"]
                                    : "",
                page: filterUpdated ? 1 : currentPage,
              },
              asCsv: true,
              dataCountry: dataCountry,
              partnerId:
                profileDetails?.partnerType === "customer"
                  ? profileDetails?.partnerId
                  : null,
              where: gridDef?.[0]?.whereClause
                ? AESEncrypt(gridDef?.[0]?.whereClause)
                : null,
              parameter:
                selectedTopSearchFilter?.where !== ""
                  ? {
                    searchParameter: searchInputValue,
                    searchWhere: selectedTopSearchFilter?.where,
                  }
                  : {},
            })
          )
            .unwrap()
            .then((response: any) => {
              if (response === "success") {
                toast.success(
                  "CSV file has been shared successfully to your email ID.", {
                  toastId: 'loadDashboardDataToastSuccess2'
                }
                );
              }
            });
        }
      }
    } else {
      if (
        !location.pathname.includes("blsliderinstruction/") &&
        location.pathname.includes("opc/home")
      ) {
        if (
          profileDetails &&
          ((profileDetails.partnerType === "customer" &&
            profileDetails.partnerId) ||
            profileDetails?.partnerType === "liner")
        ) {
          dispatch(
            exportDashboardData({
              gridName: currentGridName,
              payload: {
                fields: exportCsvLabels.map((c) => c?.accessor),
                labels: csvLabels.map((c) => c?.label),
                filters:
                  selectedTopSearchFilter?.where === ""
                    ? instructionTopSearchState
                    : {},
                size: 20,
                sorts: sort
                  ? [sort]
                  : currentGridName === "BL_DASHBOARD"
                    ? ["lastupdateddate desc"]
                    : currentGridName === "INVOICE_DASHBOARD"
                      ? ["invid desc"]
                      : currentGridName === "INSTRUCTION_DASHBOARD"
                        ? ["timestamp desc"]
                        : currentGridName === "OPC_USER_DASHBOARD"
                          ? ["user_id desc"]
                          : currentGridName === "OPC_PAYMENT_DASHBOARD"
                            ? ["pmtmode desc"]
                            : currentGridName === "PICKUP_LOC_INSTRACT_REPORT"
                              ? ["bl_no desc"]
                              : currentGridName === "REGISTRATION_DASHBOARD"
                                ? ["submissiondate desc"]
                                : currentGridName === "STANDING_INSTRUCTION"
                                  ? ["standing_instruction_id desc"]
                                  : currentGridName === "PAYMENT_FUNDING"
                                    ? ["payment_funding_id desc"]
                                    : "",
                page: filterUpdated ? 1 : currentPage,
              },
              asCsv: true,
              dataCountry: dataCountry,
              partnerId:
                profileDetails?.partnerType === "customer"
                  ? profileDetails?.partnerId
                  : null,
              where: gridDef?.[0]?.whereClause
                ? AESEncrypt(gridDef?.[0]?.whereClause)
                : null,
              parameter:
                selectedTopSearchFilter?.where !== ""
                  ? {
                    searchParameter: searchInputValue,
                    searchWhere: selectedTopSearchFilter?.where,
                  }
                  : {},
            })
          )
            .unwrap()
            .then((response: any) => {
              if (response === "success") {
                toast.success(
                  "CSV file has been shared successfully to your email ID."
                  ,
                  {
                    toastId: "loadDashboardDataToastSuccess3"
                  });
              }
            });
        }
      }
    }
  };

  if (hideAll) return <Card.Header className='p-0 ps-2 pe-2 d-flex justify-content-start align-items-center odex-mh-60px' />
  return (
    <Card.Header className="filterView p-0 ps-2 pe-2 d-flex justify-content-between gap-2 align-items-center odex-mh-60px">
      <div className="d-flex minWidthFilterView" >
        {/*    {location.pathname.split('/')[1] === 'instruction' && dataCountry === 'SG' &&  
           <SnoozedToButton  
              isDisabled={selectedData?.length <= 0 || selectedData?.length > 1 || selectedinstIdList.some((item:any)=>item !=804 )}
              selectedRows={selectedData} 
           />
          }   */}

        {additionalChildren ? additionalChildren : ""}

        {location.pathname === "/opc/instruction" &&
          rbac.rbacDetails.allowInstructionAssignment === "Y" && (
            <AssignedToButton
              isDisabled={!(selectedData?.length > 0)}
              selectedRows={selectedData}
            />
          )}

        {location.pathname === "/opc/home" && (
          <AddInstruction
            isDisabled={!(selectedData?.length > 0)}
            selectedRows={selectedData}
          />
        )}
        {location.pathname == "/opc/instruction" &&
          rbac?.profile?.opcPartnerType?.toLowerCase() !== "pto" ? (
          <AddInstruction />
        ) : (
          ""
        )}

        {showAddInstruct && <AddInstruction />}
        {profileDetails?.partnerType !== "liner" && showNomination ? (
          <Button
            variant="falcon-primary z-inxe-agent"
            size="sm"
            onClick={_toggleAddAgent}
            disabled={!enableAddAgentButton}
            style={{
              position: "relative",
            }}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Agent
          </Button>
        ) : (
          ""
        )}

        {showInstruction ? (
          <Button
            variant="falcon-primary z-inxe-agent"
            size="sm"
            onClick={_toggleAddAgent}
            disabled={!enableAddAgentButton}
            style={{
              position: "relative",
            }}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            New
          </Button>
        ) : (
          ""
        )}

        {(location.pathname.includes("invoice") &&
          profileDetails?.opcPartnerType !== "PTO") ||
          (location.pathname.includes("home") &&
            profileDetails?.opcPartnerType !== "PTO" &&
            selectedInvoiceType === "CWPDRAFTINVOICES") ? (
          <Button
            variant="falcon-primary"
            onClick={() => navigate(`/opc/invoice/payment${location.search}`)}
            //disabled={!(selectedBls ?.length > 0)}
            disabled={selectedRowsInvoice.length <= 0}
            size="sm"
            style={{
              marginRight: "0.5rem",
              position: "relative",
              float: "left",
            }}
          //   disabled={totalAmount.toFixed(2) > 0 ? false : true}
          >
            <div className="d-flex">
              <FontAwesomeIcon icon={faCreditCard} className="me-2 mt-1 " />
              <span>Pay</span>
            </div>
          </Button>
        ) : (
          ""
        )}
        {/* {location.pathname.includes('permit-tracking') &&
        profileDetails.opcPartnerType === 'PTO' ? (
          <div>
            <InputGroup>
              <InputGroup.Text
                className='rounded-pill-l bg-100 fs--1'
                style={{ fontWeight: 450 }}
              >
                Payee Name
              </InputGroup.Text>

              <Form.Select
                aria-label='Carrier'
                className='rounded-pill-r text-primary fs--1'
                size='sm'
                //value={}
              >
                <option>All</option>
                {payeeName.map((item: any, index: any) => (
                  <option key={index} value={item.mapCode}>
                    {item.partnerNm}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>
          </div>
        ) : (
          ''
        )} */}
        {location.pathname.includes("permit-tracking") ? (
          <div style={{ display: "flex" }}>
            <Button
              variant="falcon-primary"
              onClick={() => {
                handleClick(
                  (profileDetails?.opcPartnerType !== "PTO" || payeeList) ?
                    getPermitTracking({
                      tpoId: tpoId,
                      payeeId: payeeList,
                      permitType: "EIROUTPERMITS",
                    }) : null,
                  "EIROUTPERMITS"
                )
              }
              }
              size="sm"
              style={{
                border:
                  activeButton === "EIROUTPERMITS"
                    ? "2px solid #007bff"
                    : "none",
              }}
              className="permitTrackingBtns"
            >
              <div className="d-flex" style={{ justifyContent: "center" }}>
                <span>EIR OUT</span>
              </div>
            </Button>

            <Button
              variant="falcon-primary"
              onClick={() => {
                handleClick(
                  (profileDetails?.opcPartnerType !== "PTO" || payeeList) ?
                    getPermitTracking({
                      tpoId: tpoId,
                      payeeId: payeeList,
                      permitType: "EIRINPERMITS",
                    }) : null
                  ,
                  "EIRINPERMITS"
                )
              }
              }
              size="sm"
              style={{
                border:
                  activeButton === "EIRINPERMITS"
                    ? "2px solid #007bff"
                    : "none",
              }}
              className="permitTrackingBtns"
            >
              <div className="d-flex" style={{ justifyContent: "center" }}>
                <span>EIR IN</span>
              </div>
            </Button>
            {profileDetails.opcPartnerType === "CFA" && (
              <Button
                variant="falcon-primary"
                onClick={() => {
                  handleClick(
                    (profileDetails?.opcPartnerType !== "PTO" || payeeList) ?
                      getPermitTracking({
                        tpoId: tpoId,
                        payeeId: payeeList,
                        permitType: "EBSPERMITS",
                      }) : null
                    ,
                    "EBSPERMITS"
                  )
                }
                }
                size="sm"
                style={{
                  border:
                    activeButton === "EBSPERMITS"
                      ? "2px solid #007bff"
                      : "none",
                }}
                className="permitTrackingBtns"
              >
                <div className="d-flex" style={{ justifyContent: "center" }}>
                  <span>EBS</span>
                </div>
              </Button>
            )}
            {profileDetails.opcPartnerType === "PTO" && (
              <Button
                variant="falcon-primary"
                onClick={() => {
                  handleClick(
                    (profileDetails?.opcPartnerType !== "PTO" || payeeList) ?
                      getPermitTracking({
                        tpoId: tpoId,
                        payeeId: payeeList,
                        permitType: "EBSPERMITS",
                      }) : null
                    ,
                    "EBSPERMITS"
                  )
                }
                }
                className="permitTrackingBtns"
                size="sm"
                style={{
                  border:
                    activeButton === "EBSPERMITS"
                      ? "2px solid #007bff"
                      : "none",
                }}
              >
                <div className="d-flex" style={{ justifyContent: "center" }}>
                  <span>EBS</span>
                </div>
              </Button>
            )}
            {/*      {profileDetails.opcPartnerType === 'PTO' && (
              <div style={{ width: '300px' }}>
                <InputGroup>
                  <InputGroup.Text
                    className='rounded-pill-l bg-100 fs--1'
                    style={{ fontWeight: 450 }}
                  >
                    Payee Name
                  </InputGroup.Text>

                  <Form.Select
                    aria-label='Carrier'
                    className='rounded-pill-r text-primary fs--1'
                    size='sm'
                    onChange={(e) => {
                      if (e.target.value == 'All') {
                        setpayeeList('');
                        dispatch(
                          getPermitTracking({
                            tpoId: tpoId,
                            payeeId: '',
                            permitType: activeButton,
                          })
                        );
                      } else {
                        setpayeeList(e.target.value);
                        dispatch(
                          getPermitTracking({
                            tpoId: tpoId,
                            payeeId: e.target.value,
                            permitType: activeButton,
                          })
                        );
                      }
                    }}
                    //value={}
                  >
                    <option>All</option>

                    {payeeName.map((item: any, index: any) => (
                      <option key={index} value={item.mapCode}>
                        {item.partnerNm}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>
              </div>
            )} */}
            {profileDetails.opcPartnerType === "PTO" && (
              <div className="payeeDropdown" style={{ width: "max-content" }}>
                <InputGroup>
                  <Select
                    className="rounded-pill-r payeeDropdown payeeName text-primary fs--1"
                    /*  options={[
                      { value: 'All', label: 'All' },
                      ...payeeName.map((item: any) => ({
                        value: item.mapCode,
                        label: item.partnerNm,
                      })),
                    ]} */
                    options={payeeName.map((item: any) => ({
                      value: item.mapCode,
                      label: item.partnerNm,
                    }))}
                    isClearable
                    onChange={(selectedOption: any) => {
                      if (selectedOption?.value === "All") {
                        setpayeeList("");
                        if (activeButton) {
                          dispatch(
                            getPermitTracking({
                              tpoId: tpoId,
                              payeeId: "",
                              permitType: activeButton,
                            })
                          );
                        }
                      } else {
                        setpayeeList(selectedOption?.value);
                        if (activeButton) {
                          dispatch(
                            getPermitTracking({
                              tpoId: tpoId,
                              payeeId: selectedOption?.value,
                              permitType: activeButton,
                            })
                          );
                        }
                      }
                    }}
                    placeholder="Select Payee Name"
                    isSearchable={true}
                    styles={{
                      menu: (provided: any) => ({
                        ...provided,
                        width: "300px", // Adjust the width as needed
                      }),
                      option: (provided: any) => ({
                        ...provided,
                        whiteSpace: "nowrap", // Ensures options don't wrap
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "300px", // Adjust the width of each option if needed
                      }),
                      singleValue: (provided: any) => ({
                        ...provided,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "150px",
                      }),
                    }}
                    formatOptionLabel={(data) => (
                      <div title={data.label}>{data.label}</div>
                    )}
                  />
                </InputGroup>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
        {location.pathname.includes("home") ||
          location.pathname.includes("invoice") ? (
          <div style={{ display: "flex" }}>
            {profileDetails.opcPartnerType === "PTO" && (
              <div style={{ width: "max-content" }}>
                <InputGroup>
                  <InputGroup.Text
                    className="rounded-pill-l bg-100 fs--1"
                    style={{ fontWeight: 450 }}
                  >
                    Payee Name
                  </InputGroup.Text>

                  <Select
                    className="rounded-pill-r text-primary fs--1"
                    options={[
                      ...payeeName.map((item: any) => ({
                        value: item.mapCode,
                        label: item.partnerNm,
                      })),
                    ]}
                    isClearable
                    onChange={(selectedOption: any) => {
                      dispatch(updateSelectedPayee(selectedOption));
                      setpayeeList(selectedOption?.value || "");
                      if (selectedOption?.value && selectedInvoiceType) {
                        dispatch(
                          getInvoiceTableData({
                            tpoId: tpoId,
                            invoiceType: selectedInvoiceType,
                            payeeId: selectedOption?.value || "",
                          })
                        );
                      }
                      else {
                        dispatch(updateSelectedPayee(null));
                        dispatch(getInvoiceTableData(null));
                      }
                    }}
                    placeholder="Select Payee Name"
                    isSearchable={true}
                    styles={{
                      menu: (provided: any) => ({
                        ...provided,
                        width: "500px", // Adjust the width as needed
                      }),
                      option: (provided: any) => ({
                        ...provided,
                        whiteSpace: "nowrap", // Ensures options don't wrap
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "500px", // Adjust the width of each option if needed
                      }),
                      singleValue: (provided: any) => ({
                        ...provided,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "150px",
                      }),
                    }}
                    formatOptionLabel={(data) => (
                      <div title={data.label}>{data.label}</div>
                    )}
                  />
                </InputGroup>
              </div>
            )}
          </div>
        ) : (
          ""
        )}


        {showPayButton && rbac.rbacDetails.bulkPymtCUD === "Y" ? (
          <Dropdown
            navbar={true}
            style={{
              marginRight: "0.5rem",
              position: "relative",
              float: "left",
            }}
            onClick={handleToggleClick}
          >
            {profileDetails?.partnerType === "customer" ? (
              <Dropdown.Toggle bsPrefix="toggle" as={Link} to="">
                <div>
                  <Button
                    variant="ms-3 btn btn-falcon-primary z-inxe-agent btn-sm"
                    size="sm"
                    title="Help Document"
                    onClick={() => {
                      setFileName("Upload File");
                      setSelectedFile(null);
                      setuploadStatus(false);
                      setLoadingFileState(null);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      style={{
                        fontSize: "14px",
                        color: "#f5822a",
                        marginRight: "0.5rem",
                      }}
                      title="Bulk Payment"
                    />
                    <span>Bulk</span>
                  </Button>

                  {/*<Avatar
                src={
                  profileDetails?.partnerType === 'liner'
                    ? LinerLogo
                    : faFileDownload
                }
                className='ms-2'
                size='xl'
            /> */}
                </div>
              </Dropdown.Toggle>
            ) : (
              ""
            )}

            {dropdownDownVisible && (
              <Dropdown.Menu
                className="dropdown-caret dropdown-menu-card  dropdown-menu-end"
                style={{ height: "90px" }}
              >
                {
                  <div>
                    <div className="row g-2 align-items-sm-center">
                      <div className="col">
                        <div className="row align-items-center">
                          <div className="col ">
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              className="fs-0  text-primary"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <label
                                  className="btn btn-sm btn-falcon-primary"
                                  style={{
                                    marginBottom: "0rem",
                                    margin: "0.5rem",
                                  }}
                                  htmlFor="fileInput"
                                >
                                  <FontAwesomeIcon
                                    icon={faUpload}
                                    className="me-2 "
                                    style={{
                                      fontSize: "16px !important",
                                      color: "#f5822a",
                                      marginLeft: "0.5rem",
                                      cursor: "pointer",
                                    }}
                                    title="Upload"
                                  />
                                </label>
                                <input
                                  type="file"
                                  id="fileInput"
                                  accept=".xlsx"
                                  onClick={(event: any) => {
                                    event.target.value = "";
                                    setFileName("Upload File");
                                    setSelectedFile(null);
                                    setuploadStatus(false);
                                    setLoadingFileState(null);
                                  }}
                                  onChange={handleFileChange}
                                  style={{ display: "none" }}
                                />
                                <>
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      color: "#2c7be5",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {charLimit(fileName, 15)}
                                  </span>

                                  {loadingFileState != null && (
                                    <UploadIndicator
                                      style={{ marginLeft: "10px" }}
                                      progress={loadingFileState}
                                      fail={loadingFileState === -1}
                                    />
                                  )}
                                </>
                              </div>

                              {loadingFileState == 100 && (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Button
                                    onClick={handleUpload}
                                    className={`me-2 ${loadingFileState === 100 ? "" : "d-none"
                                      }`}
                                    variant="falcon-primary"
                                    size="sm"
                                    style={{ margin: "0.5rem" }}
                                    type="submit"
                                  >
                                    {t("resource_1:submit")}
                                  </Button>
                                </div>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Dropdown.Divider />
                    <div
                      style={{ width: "23rem" }}
                      className="row g-2 align-items-sm-center"
                    >
                      <div
                        className="col"
                        style={{ marginTop: "0.5rem", marginLeft: "2.0rem" }}
                      >
                        <div className="row align-items-center">
                          <div className="col ">
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              className="fs-0  text-primary"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {/* <label onClick={exportDataAsCSV} className='btn btn-sm btn-falcon-primary' style={{marginBottom:'0rem',margin:'0.5rem'}}>
                                  <FontAwesomeIcon
                                    icon={faDownload}
                                    className='me-2 '
                                    style={{
                                      fontSize: '10px !important',
                                      color: '#f5822a',
                                      marginLeft:'0.5rem',
                                      
                                    }}
                                    title='Download'
                                  />
                                  </label> */}
                                <span
                                  onClick={() => {
                                    window.open(
                                      "/xlsx/BulkPaymentUploadBankTransferTemplate.xlsx"
                                    );
                                  }}
                                  onMouseEnter={() => setIsUnderlined(true)}
                                  onMouseLeave={() => setIsUnderlined(false)}
                                  style={{ ...linkStyle, marginTop: "5px" }}
                                >
                                  Click here to download the sample excel format
                                </span>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </Dropdown.Menu>
            )}
          </Dropdown>
        ) : (
          ""
        )}

        {showRefresh && (
          <Button
            className=""
            variant="falcon-primary"
            size="sm"
            onClick={() =>
              setTimeout(() => {
                dispatch(getExchangeRateRefresh("USD"));
                dispatch(
                  getDashboardData({
                    gridName: "OPC_EXCHANGE_RATE",
                    payload: {
                      fields: [],

                      filters: /*   selectedTopSearchFilter?.where === ''
                          ? instructionTopSearchState
                          :  */ {
                        from_ccy: ["USD"],
                        exchange_to: ["TZS"],
                      },
                      size: 20,
                      sorts: sort ? [sort] : ["exchange_rate_date desc"],
                      page: currentPage,
                    },
                    dataCountry: dataCountry,
                    partnerId: profileDetails.partnerId,

                    where: roeGrids?.[0]?.whereClause
                      ? AESEncrypt(roeGrids?.[0]?.whereClause)
                      : null,
                    parameter:
                      selectedTopSearchFilter?.where !== ""
                        ? {
                          searchParameter: searchInputValue,
                          searchWhere: selectedTopSearchFilter?.where,
                        }
                        : {},
                  })
                );
                // dispatch(
                //   /*    getDashboardData({
                //     gridName: 'OPC_INSTRUCTION_DASHBOARD',
                //     payload: {
                //       fields: [],

                //       filters: /*  !selectedTopSearchFilter?.where
                //         ? finalFilterApiState
                //         : */ //{},

                //   //   size: 20,
                //   //  sorts: '',
                //   /*  sortState &&
                //         sortState?.key != null &&
                //         sortState?.value != null
                //           ? [`${sortState?.key} ${sortState?.value}`]
                //           : sort
                //           ? [sort]
                //           : [''] */
                //   //  page: filterUpdated ? 1 : currentPage,
                //   //  },
                //   // dataCountry: dataCountry,
                //   // partnerId:
                //   //  profileDetails.partnerType === 'customer'
                //   //  ? profileDetails.partnerId
                //   //  : null,
                //   // where: instructionsGrids?.[0]?.whereClause
                //   //? AESEncrypt(instructionsGrids?.[0]?.whereClause)
                //   // : null,
                //   //  parameter:
                //   //  selectedTopSearchFilter?.where !== ''
                //   //  ? {
                //   //    searchParameter: searchInputValue,
                //   //  searchWhere: selectedTopSearchFilter?.where,
                //   // }
                //   // : {},
                //   //  })
                //   getDashboardData({
                //     fields: [],
                //     filters: {},
                //     size: 20,
                //     sorts: sort ? [sort] : ['requested_on desc'],
                //     /* sortState?.key != null && sortState?.value != null
                //         ? [`${sortState?.key} ${sortState?.value}`]
                //           ? [sort]
                //           : ['requested_on desc']
                //         : ['requested_on desc'], */
                //     page: 1,
                //     view: 'E58NbJpkbNRw9+y/FKnz4+ZLO9CsHv/yAz6QBCjYEpM=',
                //     where: '',
                //   })
                // );
              }, 150)
            }
          >
            <FontAwesomeIcon icon={faRefresh} />
          </Button>
        )}
        {showAddDoButton && profileDetails.opcPartnerType !== "PTO" && (
          <Button
            className=""
            variant="falcon-primary"
            size="sm"
            onClick={() => navigate("/opc/doupload/details")}
          >
            <FontAwesomeIcon icon={faAdd} />
          </Button>
        )}

        <span
          style={{
            fontSize: "14px",
            color: "#f5822a",
            fontWeight: "bold",
            marginLeft: "111px",
            position: "absolute",
            top: "15px",
            width: "47%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            zIndex: "10",
          }}
        >
          {location.pathname === "opc/home" && storedCountry == "ZA" ? (
            <span>
              Please refer to the{" "}
              <a
                onClick={openLink}
                target="_blank"
                style={{ color: "#2c7be5", cursor: "pointer" }}
              >
                Transnet Port Terminals{" "}
              </a>
              website for updated vessel ETA.{""}
            </span>
          ) : (
            ""
          )}
        </span>

        {showAddUserButton && (
          <Button
            variant="falcon-primary"
            // onClick={() => navigate(`/payment/addInvoice${location.search}`)}
            //disabled={!(selectedBls ?.length > 0)}
            onClick={() =>
              navigate(`/opc/users/details/${AESEncrypt("0").toString()}`)
            }
            size="sm"
            style={{
              marginRight: "0.5rem",
              position: "relative",
              float: "left",
            }}
          //   disabled={totalAmount.toFixed(2) > 0 ? false : true}
          >
            <div className="d-flex">
              <FontAwesomeIcon icon={faPlus} className="mt-1 mb-1" />
            </div>
          </Button>
        )}

        {profileDetails.opcPartnerType === "ICD" &&
          matchPath("opc/bestPick", location.pathname) && (
            <Button
              variant="falcon-primary"
              // onClick={() => navigate(`/payment/addInvoice${location.search}`)}
              //disabled={!(selectedBls ?.length > 0)}
              onClick={() =>
                navigate(`/opc/bestPick/details/${AESEncrypt("0").toString()}`)
              }
              size="sm"
              style={{
                marginRight: "0.5rem",
                position: "relative",
                float: "left",
              }}
            //   disabled={totalAmount.toFixed(2) > 0 ? false : true}
            >
              <div className="d-flex">
                <FontAwesomeIcon icon={faPlus} className="mt-1 mb-1" />
              </div>
            </Button>
          )}

        {matchPath("opc/vessel-master", location.pathname) &&
          profileDetails.opcPartnerType == "PTO" && (
            <Button
              variant="falcon-primary"
              onClick={() =>
                dispatch(
                  getVesselTableData({
                    tpoId: "",
                  })
                )
                  .unwrap()
                  .then(() => {
                    setTimeout(() => {
                      dispatch(updateDashbaordRefresh(true));
                    }, 0);
                  })
              }
              // onClick={() => navigate(`/payment/addInvoice${location.search}`)}
              //disabled={!(selectedBls ?.length > 0)}
              // onClick={() =>
              //   navigate(`/opc/users/details/${AESEncrypt('0').toString()}`)
              // }
              size="sm"
              style={{
                marginRight: "0.5rem",
                position: "relative",
                float: "left",
              }}
            //   disabled={totalAmount.toFixed(2) > 0 ? false : true}
            >
              <div className="d-flex">
                <FontAwesomeIcon icon={faRefresh} className="mt-1 mb-1" />
              </div>
            </Button>
          )}

        {(location.pathname.includes("invoice") ||
          location.pathname.includes("home")) &&
          selectedInvoiceType === "CWPDRAFTINVOICES" &&
          selectedRowsInvoice.length > 0 && (
            <Button
              variant="falcon-primary"
              className="ms-0 ps-0"
              size="sm"
              data-placement="left"
              data-toggle="tooltip"
              style={{
                position: "relative",
                boxShadow: "none",
                background: "transparent",
                border: "none",
              }}
            >
              {selectedRowsInvoice.length > 0 ? (
                <div>
                  {selectedRowsInvoice[0]?.ccy}{" "}
                  <span>
                    {selectedRowsInvoice
                      .reduce(
                        (sum: any, item: any) => sum + parseFloat(item.owed),
                        0
                      )
                      .toFixed(2)}
                    ({selectedRowsInvoice.length})
                  </span>
                </div>
              ) : (
                ""
              )}
            </Button>
          )}

        {/* {(rbac?.rbacDetails?.multiInstructCreate && 
          rbac.rbacDetails.multiInstructCreate === 'Y' &&
          selectedData.length > 0 ) && (
          <Button
            variant='falcon-primary'
            className='ms-0 ps-0'
            size='sm'
            data-placement='left'
            data-toggle='tooltip'
            style={{
              position: 'relative',
              boxShadow: 'none',
              background: 'transparent',
              border: 'none',
            }}
            title={formattedContent}
          >
            {location.pathname.includes('/home') ? (
              <div>
                
                <span>
                  ({selectedData.filter((value:any, index: any, self: any) =>self.indexOf(self.find((v:any)=>v.blid==value.blid)) === index).length})
                </span>
              </div>
            ) : (
              ''
            )}
          </Button>
        )} */}

        {(location.pathname.includes("invoice") ||
          location.pathname.includes("home")) &&
          selectedInvoiceType === "CWPDRAFTINVOICES" &&
          selectedRowsInvoice.length > 0 && (
            <Button
              variant="falcon-primary"
              className="ms-0 ps-0"
              onClick={() => {
                //dispatch(updateDashbaordRefresh(true));
                dispatch(updateSelectedRowsInvoice({}));
                // dispatch(updateSelectedBlListMultiPayTable({}));
                // dispatch(updateSelectedRowsInvoice([]));
                // dispatch(updateSelectedAmount([]));
                // dispatch(updateSelectedBlListMultiPay([]));
                // dispatch(loadPaymentDashboard(true))
                // if (loadList) loadList(false);
              }}
              size="sm"
              style={{
                position: "relative",
              }}
            >
              <FontAwesomeIcon
                style={{ marginLeft: "10px" }}
                color="#f5822a"
                icon={faTrash}
              />
            </Button>
          )}
        {rbac.rbacDetails.standaloneInvPymtCUD === "Y" &&
          location.pathname.split("/")[1] === "payment" && (
            <Button
              variant="falcon-primary"
              onClick={() => navigate(`/payment/addInvoice${location.search}`)}
              //disabled={!(selectedBls ?.length > 0)}

              size="sm"
              style={{
                marginRight: "0.5rem",
                position: "relative",
                float: "left",
              }}
            //   disabled={totalAmount.toFixed(2) > 0 ? false : true}
            >
              <div className="d-flex">
                <FontAwesomeIcon icon={faPlus} className="me-2 mt-1 " />
                <span>{t("resource_2:addInvoice")}</span>
              </div>
            </Button>
          )}
      </div>

      {exchangeRate && (
        <div
          className="exchangeRate"
        >
          <span
            style={{
              background:
                "linear-gradient(90deg, #1e90ff, #00bfff, #ff8c00, #ff4500)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontWeight: "bold",
              animation: "colorChange 4s infinite",
              backgroundSize: "300% 300%",

              // textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
            }}
            className="rateOfExchangeText"
          >
            Rate of Exchange =
            <span
              style={{
                background:
                  "linear-gradient(90deg, #ff4500, #ff8c00, #1e90ff, #00bfff)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: "bold",
                animation: "colorChange 4s infinite",
                backgroundSize: "300% 300%",
                // textShadow: '2px 2px 6px rgba(0, 0, 0, 0.3)',
                marginLeft: "5px",
              }}
            >
              {exchangeRate}
            </span>{" "}
            TZS
          </span>
          <style>
            {`
      @keyframes colorChange {
          0% {
              background-position: 0% 50%;
          }
          50% {
              background-position: 100% 50%;
          }
          100% {
              background-position: 0% 50%;
          }
      }

      span {
          background-size: 300% 300%; /* Ensures smooth gradient transition across multiple colors */
      }
    `}
          </style>
        </div>
      )}

      <div className="d-flex minWidthFilterView justify-content-end align-items-center">
        {showRightDiv && (
          <>
            {showReportDropdown && (
              <InputGroup className="z-index-agent mx-2">
                <Form.Select
                  aria-label="Config"
                  className="rounded-pill text-primary fs--1"
                  size="lg"
                  value={selectedInst}
                  disabled={reportList.length <= 1}
                  onChange={(e) => {
                    dispatch(selectReportInstruction(e?.target?.value));
                    if (e?.target?.value == "") return;
                    window.localStorage.setItem(
                      "@linerReportInstruct",
                      e?.target?.value
                    );
                  }}
                >
                  <option value="" hidden={true}>
                    none
                  </option>

                  {reportList.map(({ value, text }: any) => (
                    <option value={value}>{text}</option>
                  ))}
                </Form.Select>
              </InputGroup>
            )}

            {location.pathname.includes("home") ||
              location.pathname.includes("invoice") ? (
              <div style={{ display: "flex" }}>
                <Button
                  variant="falcon-primary"
                  onClick={() => {
                    dispatch(updateSelectedInvoiceType("CWPDRAFTINVOICES"));
                    if (profileDetails?.opcPartnerType !== "PTO" || selectedPayee?.value) {
                      dispatch(updateDashbaordRefresh(true));
                      dispatch(updateSelectedRowsInvoice({}));
                      dispatch(
                        getInvoiceTableData({
                          tpoId: tpoId,
                          invoiceType: "CWPDRAFTINVOICES",
                          payeeId: selectedPayee?.value || "",
                        })
                      );
                    }
                  }}
                  size="sm"
                  className="invoiceBtns"
                  style={{
                    border:
                      selectedInvoiceType === "CWPDRAFTINVOICES"
                        ? "2px solid #007bff"
                        : "none",
                  }}
                >
                  <div className="d-flex" style={{ justifyContent: "center" }}>
                    <span>Proforma</span>
                  </div>
                </Button>

                <Button
                  variant="falcon-primary"
                  onClick={() => {
                    dispatch(updateSelectedInvoiceType("TAXINVOICES"));

                    if (profileDetails?.opcPartnerType !== "PTO" || selectedPayee?.value) {
                      dispatch(
                        getInvoiceTableData({
                          tpoId: tpoId,
                          invoiceType: "TAXINVOICES",
                          payeeId: selectedPayee?.value || "",
                        })
                      );
                      dispatch(updateDashbaordRefresh(true));
                      dispatch(updateSelectedRowsInvoice({}));
                    }
                  }}
                  size="sm"
                  className="invoiceBtns"
                  style={{
                    border:
                      selectedInvoiceType === "TAXINVOICES"
                        ? "2px solid #007bff"
                        : "none",
                  }}
                >
                  <div className="d-flex" style={{ justifyContent: "center" }}>
                    <span>TAX</span>
                  </div>
                </Button>
              </div>
            ) : (
              ""
            )}

            {showChooseConfigView() ? (
              <InputGroup className="z-inxe-agent">
                <InputGroup.Text className="rounded-pill-l bg-100 fs--1">
                  View
                </InputGroup.Text>
                <Form.Select
                  aria-label="Config"
                  className="rounded-pill-r text-primary fs--1"
                  size="sm"
                  value={
                    uniqueFilterKey
                      ? selectedConfigId?.[uniqueFilterKey]?.toString() || ""
                      : ""
                  }
                  onChange={(e) => {
                    if (uniqueFilterKey) {
                      const newValue = parseInt(e?.target?.value) || null;
                      localStorage.setItem(
                        `selectedConfigId_${dashboardName}`,
                        JSON.stringify(newValue)
                      );
                      dispatch(
                        updateSelectedConfigId({
                          ...selectedConfigId,
                          [uniqueFilterKey]: newValue,
                        })
                      );

                      const newdata = location.pathname.includes(
                        "opc/instruction"
                      )
                        ? instructionsGrids[0].configGroup?.find(
                          (c: any) => c.userGridId == newValue
                        )?.columnConfigGrid
                        : location.pathname.includes("standing")
                          ? standingInstructionGrids[0].configGroup?.find(
                            (c: any) => c.userGridId == newValue
                          )?.columnConfigGrid
                          : location.pathname.includes("paymentDashboard")
                            ? paymentGrids[0].configGroup?.find(
                              (c: any) => c.userGridId == newValue
                            )
                            : location.pathname.includes("payment")
                              ? invoiceGrids[0].configGroup?.find(
                                (c: any) => c.userGridId == newValue
                              )?.columnConfigGrid
                              : location.pathname.includes("registrationmaster")
                                ? registrationMasterGrid[0].configGroup?.find(
                                  (c: any) => c.userGridId == newValue
                                )?.columnConfigGrid
                                : location.pathname.includes("opc/doupload")
                                  ? doGrids[0].configGroup?.find(
                                    (c: any) => c.userGridId == newValue
                                  )?.columnConfigGrid
                                  : location.pathname.includes("users")
                                    ? userGrids[0].configGroup?.find(
                                      (c: any) => c.userGridId == newValue
                                    )?.columnConfigGrid
                                    : grids[0].configGroup?.find(
                                      (c: any) => c.userGridId == newValue
                                    )?.columnConfigGrid;

                      if (newdata) {
                        setTimeout(() => {
                          if (JSON.parse(newdata)[0].sorts) {
                            const input = `${JSON.parse(newdata)[0].sorts}`;
                            const [firstWord, lastWord]: any =
                              input.match(/\w+/g) || [];
                            dispatch(
                              updateSortStateDataTable({
                                key: firstWord,
                                value: lastWord,
                              })
                            );
                            dispatch(
                              updateParams({
                                key: firstWord,
                                value: lastWord,
                              })
                            );
                            const queryParams = queryString.parse(
                              location.search
                            );
                            const sortString = {
                              sort: `${firstWord},${lastWord}`,
                              seek: undefined,
                              direction: undefined,
                            };

                            navigate(
                              `${location.pathname}?${queryString.stringify({
                                ...queryParams,
                                ...sortString,
                              })}`
                            );
                          } else {
                            dispatch(
                              updateSortStateDataTable({
                                key: null,
                              })
                            );

                            navigate(`${location.pathname}`);
                          }
                        }, 200);
                      }
                    }
                  }}
                //disabled={dataLoading}
                >
                  <option
                    disabled
                    value="disable-1"
                    className="text-dark"
                  ></option>
                  <optgroup label="System">
                    {/*  <option value='' className='text-dark'>
                    Default
                  </option> */}
                    {configViews &&
                      configViews
                        .filter((v: any) => v?.type === "system")
                        .map((i: any) => (
                          <option
                            value={i?.userGridId || ""}
                            className="text-dark"
                            selected={i?.userGridId === savedViewValue}
                          >
                            {i?.columnConfigName}{" "}
                            {/* ({i?.type?.toUpperCase()}) */}
                          </option>
                        ))}
                  </optgroup>

                  {/* <option disabled value='disable-2' className='text-dark'></option> */}

                  <optgroup label="Private">
                    {configViews &&
                      configViews
                        .filter((v: any) => v?.type === "private")
                        .map((i: any) => (
                          <option
                            value={i?.userGridId || ""}
                            className="text-dark"
                            selected={i?.userGridId === savedViewValue}
                          >
                            {i?.columnConfigName}{" "}
                            {/* ({i?.type?.toUpperCase()}) */}
                          </option>
                        ))}
                  </optgroup>

                  <optgroup label="Shared">
                    {configViews &&
                      configViews
                        .filter((v: any) => v?.type === "shared")
                        .map((i: any) => (
                          <option
                            value={i?.userGridId || ""}
                            className="text-dark"
                            selected={i?.userGridId === savedViewValue}
                          >
                            {i?.columnConfigName}
                          </option>
                        ))}
                  </optgroup>

                  <option
                    disabled
                    value="disable-4"
                    className="text-dark"
                  ></option>
                </Form.Select>
              </InputGroup>
            ) : null}

            {showColumnSettings ? (
              <Button
                style={{ height: 27, marginTop: 2 }}
                variant="falcon-primary z-inxe-agent"
                onClick={onShowColumnSettings}
                size="sm"
                className="ms-3"
              >
                <FontAwesomeIcon icon={faGear} />
              </Button>
            ) : (
              ""
            )}
            {showExport ? (
              <>
                <Dropdown drop="down">
                  <Dropdown.Toggle
                    className="no-caret-dropdown-toggle cursor-pointer z-inxe-agent ms-2 csvDownload"
                    as="div"
                    style={{ marginRight: "0.5rem", padding: 0, borderRadius: 5 }}
                  >
                    <Button
                      variant="falcon-primary"
                      size="sm"
                      style={{ position: "relative" }}
                      onClick={(e) => {
                        if (!csvByMail) {
                          e.stopPropagation();
                          exportDataAsCSV();
                        }
                      }}
                    >
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon={faFileCsv}
                          className="me-2 mt-1 text-warning"
                        />
                        <span> {t("resource_1:csv")} </span>
                      </div>
                    </Button>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div>
                      <Dropdown.Item onClick={exportDataAsCSV} disabled={false}>
                        <span> {t("resource_2:currentPage")}</span>
                      </Dropdown.Item>
                      <Dropdown.Divider />

                      {csvByMail && (
                        <Dropdown.Item
                          onClick={() => {
                            // loadDashboardData(true);

                            loadDashboardData(true);
                          }}
                          disabled={false}
                        >
                          <span>{t("resource_2:allPages")} </span>
                        </Dropdown.Item>
                      )}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>

                {/* <Button
                variant='falcon-primary z-inxe-agent'
                className='ms-2'
                size='sm'
                onClick={exportDataAsCSV}
                style={
                  /* location.pathname === '/payment' ||
                location.pathname === '/home'  
                  configViews !== undefined && configViews.length > 1
                    ? { width: 110 }
                    : { width: 80 }
                }
                disabled={dataLoading}>
                <FontAwesomeIcon
                  className='text-warning me-2'
                  icon={faFileCsv}
                />
                <span>CSV</span>
              </Button> */}
              </>
            ) : (
              ""
            )}
          </>
        )
        }
      </div>
    </Card.Header>
  );
};

export default FilterView;
