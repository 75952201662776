import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import Papa from "papaparse";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";

export default function CsvExport({ fileName, data, columns, className }: any) {
  return <Button
    variant="falcon-primary"
    className={className}
    size="sm"
    title="Download Data As CSV"
    style={{ position: "relative" }}
    onClick={(e) => {
      exportDataAsCSV(fileName, data, columns?.filter((v: any) => v.csv != false))
    }}
  >
    <div className="d-flex">
      <FontAwesomeIcon
        icon={faFileCsv}
        className="me-2 mt-1 text-warning"
      />
      <span> CSV </span>
    </div>
  </Button>
}


const exportDataAsCSV = (filename: string, data: any, csvLabels: any) => {
  let csvData;
  let fileName = `${filename}.csv`;

  if (csvLabels?.length > 0) {
    csvData = Papa.unparse(
      data.map((v: any) => {
        let finalOut: any = {};

        for (let i of csvLabels) {
          finalOut[i.Header] =
            i?.timeFormat && i?.timeFormat !== "null"
              ? moment(v?.[i.accessor] + " UTC").format(i.timeFormat)
              : v?.[i.accessor];
        }
        return finalOut;
      }),
      { columns: csvLabels.map((m: any) => m.Header) }
    );
    exportData(csvData, fileName, "text/csv;");
  }
};


const exportData = (data: any, fileName: string, type: string) => {
  try {

    // Create a link and download the file
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    toast.success("File downloaded successfully", {
      theme: "colored",
      toastId: 'exportDataToastSuccess'
    });
  } catch (error) {
    toast.error("something went wrong", {
      theme: "colored",
      toastId: 'exportDataToastFailed'
    });
  }
};