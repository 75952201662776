import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { BLDetailsList } from '../../dashboard/types';
import { PaymentBlDetails } from '../../payment/paymentSlice';

interface MultiBlPaymentTypes {
  selectedRowsInvoice: any;
  selectedInvoiceType:any;
}

const initialState: MultiBlPaymentTypes = {
  selectedRowsInvoice: {},
  selectedInvoiceType:"CWPDRAFTINVOICES"
};

export const multiBlPaymentSlice = createSlice({
  name: 'multiBlPayment',
  initialState,
  reducers: {
    updateSelectedRowsInvoice: (state, action: PayloadAction<any>) => {
      state.selectedRowsInvoice = action.payload;
    },
    updateSelectedInvoiceType: (state, action: PayloadAction<any>) => {
      state.selectedInvoiceType = action.payload;
    },
  },
});

export const {
  updateSelectedRowsInvoice,
  updateSelectedInvoiceType
} = multiBlPaymentSlice.actions;

export default multiBlPaymentSlice.reducer;
