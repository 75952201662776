import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import PaymentData from "./data/payment.json";
import {
  getInvoiceList,
  getInvoiceListFilter,
  getInvoiceListWithBlnumber,
  getInvoiceListWithSearch,
  getInvoiceListWithShipContainerNo,
  searchWithInternalVoyageNoPaymentDashboard,
} from "../dashboard/actions/dashboard";
import { getPymtRefNo } from "../blPayment/actions/payments";

export interface PaymentBlDetails {
  invamt: any;
  invno: any;
  paymentAmt?: any;
  paymentFundingId?: any;
  number?: any;
  paidIndicator: string;
  id?: any;
  invid?:any;
  blNumber: string;
  consignee: string;
  invoiceType: string;
  invoiceNo: string;
  paymentRefNo: string;
  currency: string;
  amount: string;
  payable: string;
  ccy?: string | number;
  paymentInv?: any;
  invdt: string;
  blno: string;
  eximindicator: string;
  shipmentno: string;
  blEventId:number|null;
}
export interface IInvoiceList {
  list: PaymentBlDetails[];
  next: string | null;
  previous: string | null;
}
export interface PaymentSlice {
  data: IInvoiceList;
  dataLoading: boolean;
  showColumnSettings: boolean;
  pmtRefNo: string;
}

const initialState: PaymentSlice = {
  data: {
    next: null,
    previous: null,
    list: [],
  },
  dataLoading: false,
  showColumnSettings: false,
  pmtRefNo: "",
};

export const paymentSlice = createSlice({
  name: "invoicedashboard",
  initialState,
  reducers: {
    updatePaymentBlList: (state, action: PayloadAction<IInvoiceList>) => {
      state.data = action.payload;
    },
    updatePaidBlList: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    toggleColumnSettingsPaymentList: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.showColumnSettings = action.payload;
    },
    updatePymtRefNo: (state, action: PayloadAction<any>) => {
      state.pmtRefNo = action.payload;
    },
  },
  extraReducers: (builder) => {
    //todo bllist
    //update common list slice
    builder.addCase(getInvoiceList.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(getInvoiceList.fulfilled, (state, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });

    builder.addCase(getInvoiceListFilter.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(getInvoiceListFilter.fulfilled, (state, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });

    builder.addCase(getInvoiceListWithBlnumber.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(getInvoiceListWithBlnumber.fulfilled, (state, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });

    builder.addCase(
      searchWithInternalVoyageNoPaymentDashboard.pending,
      (state, action) => {
        state.dataLoading = true;
      }
    );
    builder.addCase(
      searchWithInternalVoyageNoPaymentDashboard.fulfilled,
      (state, action) => {
        state.dataLoading = false;
        state.data = action.payload;
      }
    );

    builder.addCase(
      getInvoiceListWithShipContainerNo.pending,
      (state, action) => {
        state.dataLoading = true;
      }
    );
    builder.addCase(
      getInvoiceListWithShipContainerNo.fulfilled,
      (state, action) => {
        state.dataLoading = false;
        state.data = action.payload;
      }
    );

    builder.addCase(getPymtRefNo.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(getPymtRefNo.fulfilled, (state, action) => {
      state.dataLoading = false;
    });
    //todo bllist
    //update common list slice
    builder.addCase(getInvoiceListWithSearch.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(getInvoiceListWithSearch.fulfilled, (state, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });
  },
});

export const {
  updatePaymentBlList,
  toggleColumnSettingsPaymentList,
  updatePaidBlList,
  updatePymtRefNo,
} = paymentSlice.actions;

export default paymentSlice.reducer;
