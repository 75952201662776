import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
export default i18n.use(initReactI18next).init({
  lng: window?.localStorage?.getItem?.('@odex-user-lng') || 'TZ',
  debug: false,
  fallbackLng: 'TZ',
  resources: {
    TZ: {
      resource_1: require('./resources/TZ/resource-1.json'),
      resource_2: require('./resources/TZ/resource-2.json'),
      resource_3: require('./resources/TZ/resource-3.json'),
      resource_4: require('./resources/TZ/resource-4.json'),
      resource_5: require('./resources/TZ/resource-5.json'),
      resource_7: require('./resources/TZ/resource-7.json'),
      resource_12: require('./resources/TZ/resource-12.json'),
      resource_message: require('./resources/TZ/resource-message.json'),
    },
  },
});
