const TermsAndConditions = () => {
  return (
    <div>
      <ol>
        <li>
          <p>
            These Terms of Use contain the complete terms and conditions that
            apply to an individual's or entity's participation in the ODeX
            program/use of the ODeX Site (defined below), its contents and
            services as provided on the said web-portal.
          </p>
        </li>
        <li>
          <p>
            In these Terms of Use, "we", "us", "our" or “ODeX” means ODeX India
            Solutions Private Limited (including its successors and assigns),
            and "you" means the applicant or any user of the ODeX Site and the
            Services (defined below). “ODeX Site” or “Website” means the ODeX.co
            website, owned by EA Technologies, FZE, Dubai and operated in India
            by ODeX, being the exclusive licensee of EA Technologies, FZE. "Your
            site" means your website where you will link to the ODeX Site (and
            which you will identify in your Program application).
          </p>
        </li>
        <li>
          <p>
            Through the ODeX Site, ODeX provides a platform with a host of
            services, including but not limited to:
            <ul>
              <li>
                {' '}
                data entry and documents upload for invoice details and shipment
                enquiries;
              </li>
              <li>
                {' '}
                online document management including issue and transfer of
                documents pertaining to shipment enquiries and transactions;
              </li>
              <li>
                {' '}
                facilitation of electronic payments between payer and
                beneficiary thereof;
              </li>
              <li>
                {' '}
                data collection and payment management to enable addition of
                e-convenience fees, seamless payment and information management;
              </li>
              <li>
                all services and products offered by ODeX, including through the
                ODeX Site; and
              </li>
              <li> such other ancillary services to facilitate the above;</li>
            </ul>
          </p>
        </li>
        <li>
          <p>
            Use of the Website and the Services is offered to you conditional
            upon your irrevocable consent and acceptance of all the terms,
            conditions and notices contained in these Terms of Use, along with
            any amendments made by ODeX, at its sole discretion, and notified on
            the Website from time to time, including by way of imposing
            additional charges for access to or use of any Service(s).
          </p>
        </li>
        <li>
          <p>
            Please read the Terms of Use carefully before accessing or using the
            Website. By accessing or using any part of the Website and/or the
            Services, you agree to become bound by the Terms of Use. If you do
            not agree to all or any of the Terms of Use, you may not access the
            Website or use any Services. If these terms and conditions are
            considered an offer, acceptance is expressly limited to these terms.
            The Website is available only to individuals who are at least 18
            years old and otherwise capable of contracting under Indian laws.
          </p>
        </li>
        <li>
          <p>
            You acknowledge and agree that we have the right to revise and amend
            any of the Terms of Use from time to time to reflect inter alia,
            changes in market conditions affecting business, changes in
            technology, changes in relevant laws and regulatory requirements and
            changes in our system's capabilities. We shall not be under the
            obligation to separately or personally notify you, whether as a
            registered user or not, of any changes made to the Terms of Use and
            shall not be obligated to make available the revised Terms of Use on
            the Website prior to them becoming effective. Your use of the
            Website and the Services is subject to the most current version of
            the Terms of Use made available on the Website at the time of such
            use. You are requested to regularly visit the Website to view the
            most current Terms of Use. Your continued use of the Website
            subsequent to the modified Terms of Use will constitute your
            acceptance of those changes.
          </p>
        </li>
        <li>
          <p>
            You hereby certify that the products being shipped and/or the
            payments being made using ODeX Services will not be exported,
            reexported, transferred or sold in violation of the US and EU laws
            and regulations, including the US Department of Treasury
            administered economic sanctions. You also certify that you will
            adhere to applicable local laws.
            <ul>
              <li>
                You hereby agree and acknowledge that ODeX is merely engaged in
                providing an intermediary platform for providing services to
                streamline invoicing, billings, and payments in the shipping
                industry and is in no way involved in your business or your
                goods and services, and therefore, not liable under applicable
                sanctions laws.
              </li>
              <li>
                You are solely responsible and liable for ensuring ongoing
                compliance with OFAC sanctions and ODeX disclaims all liability
                for ensuring such compliance.
              </li>
              <li>
                The products or services being shipped and involving ODeX’s
                services will not have an end-use location in Cuba Iran, North
                Korea, North Sudan, or Syria or be used for an end-use in China,
                Iraq or Russia or any other country as prohibited by US Law.
              </li>
              <li>
                You and your end users are not a Specially Designated National
                (SDN) or other blocked restricted or denied party under the laws
                of the US (list accessible{' '}
                <a
                  href='https://ofac.treasury.gov/specially-designated-nationals-and-blocked-persons-list-sdn-human-readable-lists'
                  target='_blank'>
                  here
                </a>
                ), EU, or other applicable laws or any country and is not owned
                50% or more by any or more SDNs or blocked, restricted or denied
                persons.
              </li>
              <li>
                No payment or offer of payment has been made to a foreign
                national to influence the government act or decision to assist
                in obtaining or retaining business associated with you in any
                way.
              </li>
              <li>
                You agree that all the facts contained in the above section are
                true and correct to the best of your knowledge and you do not
                know of any additional facts that are inconsistent with the
                above statements. You will inform ODeX of any material changes
                to the statements mentioned herein.
              </li>
            </ul>
          </p>
        </li>
      </ol>
    </div>
  );
};

export default TermsAndConditions;
