import { Card } from 'react-bootstrap';

import { matchPath, Outlet, useLocation, useParams } from 'react-router-dom';
import Header from '../../common/components/DetailsViewHeader';
import DocumentHistory from '../blDetails/DocumentHistory';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useDispatch } from 'react-redux';
import { updateBlDetailsRowColumns } from '../blDetails/slices/blDetailsTable';
import { toggleDocumentHistoryColumnShort } from '../blDetails/slices/documentHistorySlice';
import OblDetails from '../../common/components/OblDetails';

import { toggleObldetails } from './slices/dashboardSlice';
import DashboardRoot from './components/DashboardRoot';
import MainTable from './components/Maintable';

const OpcUserDetails = () => {
  const location = useLocation();
  const { blNum } = useParams();

  const dispatch = useDispatch();
  const [instructionStatusNew, setInstructionStatusNew] = useState();
  const paymentStatus = useSelector(
    (state: RootState) => state.registrationDashboard.showPayment
  );
  const releaseStatus = useSelector(
    (state: RootState) => state.registrationDashboard.showRelease
  );
  const registrationMasterGrid = useSelector(
    (state: RootState) => state.dataTable.registrationMasterGrid
  );
  const instructionStatus = useSelector(
    (state: RootState) => state.registrationDashboard.showInstruction
  );
  const instId = location.pathname.split('/')[3];
  useEffect(() => {
    if (instructionStatus !== null) {
      setInstructionStatusNew(instructionStatus.trim());
    }
  }, [instructionStatus]);

  const rowColumns = useSelector(
    (state: RootState) => state.instructionTable.rowColumns
  );

  var path = location.pathname;
  var parts = path.split('/');
  var blslider = parts[1];

  useEffect(() => {
    if (matchPath('/registrationmaster', location.pathname)) {
      dispatch(toggleObldetails(true));
      dispatch(
        updateBlDetailsRowColumns({
          ...rowColumns,
          mainTableClass: 'col-lg-12',
          documentMainViewClass: 'd-none',
          documentHistoryClass: 'd-none',
          outletClass: 'd-none',
        })
      );
    } else if (
      matchPath(`/instruction/:blNum/${instId}/:blId`, location.pathname) ||
      matchPath(
        `/instruction/${location.pathname.split('/')[2]}`,
        location.pathname
      )
    ) {
      dispatch(toggleObldetails(false));
      setTimeout(() => {
        dispatch(
          updateBlDetailsRowColumns({
            ...rowColumns,
            mainTableClass: 'col-lg-3',
            documentMainViewClass: 'col-lg-9',
            documentHistoryClass: 'col-lg-12',
            outletClass: 'd-none',
          })
        );
      }, 100);
    } else if (
      matchPath('/blslider/:blNum', location.pathname) ||
      matchPath('/blsliderinvoice/:blNum', location.pathname) ||
      matchPath('/blsliderinstruction/:blNum', location.pathname)
    ) {
      dispatch(toggleObldetails(false));
      dispatch(
        updateBlDetailsRowColumns({
          ...rowColumns,
          mainTableClass: 'col-lg-3',
          documentMainViewClass: 'col-lg-9',
          documentHistoryClass: 'col-lg-12',
          outletClass: 'd-none',
        })
      );
    } else {
      dispatch(toggleDocumentHistoryColumnShort(false)); //revert back once close

      dispatch(
        updateBlDetailsRowColumns({
          ...rowColumns,
          mainTableClass: 'col-lg-3',
          documentMainViewClass: 'col-lg-9',
          documentHistoryClass: 'd-none',
          outletClass: 'd-none',
        })
      );

      setTimeout(() => {
        dispatch(
          updateBlDetailsRowColumns({
            ...rowColumns,
            mainTableClass: 'col-lg-3',
            documentMainViewClass: 'col-lg-9',
            documentHistoryClass: 'col-lg-12',
            outletClass: 'd-none',
          })
        );
      }, 1000);
    }
  }, [location.pathname]);

  return (
    <Card className='w-100 h-100'>
      <div className='bg-holder bg-card odex-bldetails-img-r' />

      <Card.Body className='p-0'>
        <MainTable />
      </Card.Body>
    </Card>
  );
};

export default OpcUserDetails;
