import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Form, InputGroup, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import DataTable from "../../../common/components/DataTable";
import { Controller, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axiosOdex, { axiosCacheInstance } from "../../../../axiosSetup";
import {
  faCheck,
  faTimes,
  faSave,
  faRedo,
  faLocation,
  faChevronDown,
  faUpload,
  faCancel,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { MouseEventHandler } from "react";
import { IDataTableColumn } from "../../../common/types/dataTable";
import { matchPath, useLocation } from "react-router-dom";
import { ChangeEvent } from "react";
import {
  faArrowAltCircleLeft,
  faCheckCircle,
} from "@fortawesome/free-regular-svg-icons";
import { useBackNavigation } from "../../../../hooks/BackNavigation";
import { useParams } from "react-router-dom";
import {
  AESDecrypt,
  AESEncrypt,
  AESEncryptforDashboard,
  Base64Encode,
} from "../../../../encrypt-util";
import { toggleDocumentHistoryColumnShort } from "../../blDetails/slices/documentHistorySlice";
import { updateBlDetailsRowColumns } from "../../blDetails/slices/blDetailsTable";
import { AppDispatch, RootState } from "../../../store";
import {
  updateSelectedAttachmentFilePath,
  updateSelectedAttachmentName,
  updateSelectedDocId,
  updateSelectedFileServerPath,
} from "./instructionSlice/DemurrageSlice";
import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosSetup";
import UploadIndicator from "../../../../components/common/UploadIndicator";
import { getAppConfig } from "../../../common/actions/userDetails";
import { useAsyncDebounce } from "react-table";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Select from "react-select";
import { FixedSizeList as List } from "react-window";
import {
  addPermitRequest,
  blValidation,
  cancelPermitRequest,
  fetchTruckingCompanySuggestions,
  fetchTruckingTrailerSuggestions,
  fetchVesselSuggestions,
  generateInvReferenceNo,
  generatePermitReferenceNo,
  getPermitRequest,
  validateRequestedDateForExport,
} from "./actions/Instruction";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { blNumbList } from "./actions/Instruction";
import axios, { AxiosError } from "axios";
import { customDropdownStyles } from "../../../common/customStyles";
import { updateDashbaordRefresh } from "../../../common/components/DataTable/slices/DataTable";
import {
  bestPickGroupID,
  bestPickTotalContainers,
  ICDCode,
} from "../../createBestPick/action/BestPickDetails";
import moment from "moment";
import useDebounce from "../../../../hooks/useDebounce";
import { useLoading } from "../../../../middleware/loadingMiddleware";
import retry from "../../../../helpers/axiosRetry";

interface BookingOption {
  codeId: number;
  codeDesc: string;
}
interface BlSuggestion {
  blNumber: string;
}

const PermitRequest = () => {
  const dispatch = useDispatch<AppDispatch>();
  // const docList = useSelector((state: RootState) => state.demurrage.docList);
  /*   const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm(); */
  const VesselData: any = useSelector(
    (state: RootState) => state.permitrequest.data
  );
  const blNumbListVal: any = useSelector(
    (state: RootState) => state.permitrequest.blNumbList
  );

  const TruckingCompanyData: any = useSelector(
    (state: RootState) => state.permitrequest.dataTruck
  );
  const TruckingTrailerData: any = useSelector(
    (state: RootState) => state.permitrequest.dataTrailer
  );
  const DriverLicenceData: any = useSelector(
    (state: RootState) => state.permitrequest.dataDriver
  );
  const loadingVesselList = useLoading([
    "PermitRequest/fetchVesselSuggestions",
  ]);

  const { blNum1, encrypt, encryptdoc, blId } = useParams();
  const { requesttp, invoice_request_id } = useParams();
  const decrptdoc = AESDecrypt(encryptdoc);
  const decrptins = AESDecrypt(encrypt);

  useState<any>("");
  var newval: any;
  // const [showdata, setShowData] = useState(false);
  const [containerDetails, setContainerDetails] = useState<any>([]);
  const [ICDcodeList, setICDcodeList] = useState<any>([]);
  const [bestPickGroupOptions, setbestPickGroupOptions] = useState<any>([]);
  const [selectedICDcode, setselectedICDcode] = useState<any>("");
  const [selectedBestPickGrpId, setselectedBestPickGrpId] = useState<any>("");
  const [selectedVessel, setselectedVessel] = useState<any>("");
  const [shortCodeVal, setshortCodeVal] = useState<any>("");
  const [filteredcontainerDetails, setfilteredcontainerDetails] = useState<any>(
    []
  );

  const [bookingOption, setBookingOption] = useState<number>();
  const [licenseValidation, setlicenseValidation] = useState<any>(false);
  const [gridName, setGridName] = useState<any>("PERMIT");
  const [nameValidation, setnameValidation] = useState<any>(false);
  const [showTruckingCompanydata, setShowTruckingCompanyData] = useState(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [VesselList, setVesselList] = useState<any>();
  const [licenceList, setLicenceList] = useState<any>();
  const [TruckingCompanyList, setTruckingCompanyList] = useState<any>();
  const [bestpickCon, setbestpickCon] = useState<any>("");

  const [TruckingTrailerList, setTruckingTrailerList] = useState<any>();
  const [DriverLicenceListNameSelected, setDriverLicenceListNameSelected] =
    useState<any>(null);
  const [DriverNameListLicenceSelected, setDriverNameListLicenceSelected] =
    useState<any>(null);
  const permitRefNo_New = useSelector(
    (state: RootState) => state.creditBalanceBlPayment.generatePermitReferenceNo
  );
  const ICDCodeVal = useSelector(
    (state: RootState) => state.addbestPickSlice.ICDCodeVal
  );
  const bestPickContainerVal = useSelector(
    (state: RootState) => state.addbestPickSlice.bestPickContainerVal
  );
  const bestPickGroupIDval = useSelector(
    (state: RootState) => state.addbestPickSlice.bestPickGroupIDval
  );
  const docId = decrptins;
  const selectedLinerId = useSelector(
    (state: RootState) => state.userProfile.selectedLinerId
  );
  const refresh = useSelector(
    (state: RootState) => state.dataTable.refreshDashboard
  );
  const tpoId = useSelector((state: RootState) => state.agentDelegation.tpoId);
  const [status, setStatus] = useState<string>("");
  // const docAttachment = useSelector(
  // (state: RootState) => state.demurrage.attData
  // );

  const [vesselSuggestions, setVesselSuggestions] = useState<any[]>([]);
  const [blSuggestions, setBlSuggestions] = useState<BlSuggestion[]>([]);
  const [ContainerNumberSuggestions, setContainerNumberSuggestions] = useState<
    any[]
  >([]);
  const [trucksuggestions, settrucksuggestions] = useState<any[]>([]);
  const [DriverNamesuggestions, setDriverNamesuggestions] = useState([]);
  const [containerSuggestions, setContainerSuggestions] = useState([]);
  const [showcontainerSuggestions, setShowContainerSuggestions] =
    useState(false);

  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  // const validationSchema = Yup.object().shape({
  //   // invoiceNumber: Yup.string().required(
  //   //   'Invoice Reference Number is required'
  //   // ),
  //   blno: Yup.string().required('BL Number is required'),
  //   customerTin: Yup.string().required('Shipper/Consignee TIN is required'),
  //   shipperConsigneeName: Yup.string().required(
  //     'Shipper/Consignee Name is required'
  //   ),
  //   traffic: Yup.string().required('Traffic is required'),
  //   invoiceType: Yup.string().required('Invoice Type is required'),
  //   paidThrough: Yup.date()
  //     .nullable()
  //     .transform((curr, orig) => (orig === '' ? null : curr))
  //     .required('Paid Thru Date is required'),
  //   deliveryType: Yup.string().required('Local/Transit is required'),
  //   vesselVoyage: Yup.object().required('Vessel is required'),
  //   remarks: Yup.string(),
  // });
  // const {
  //   register,
  //   handleSubmit,
  //   setValue,
  //   formState: { errors },
  //   control,
  // } = useForm({
  //   resolver: yupResolver(validationSchema),
  // });
  const [inputValue, setInputValue] = useState("");
  const [permitType, setPermitType] = useState("");
  const [inputTruckingCompanyValue, setInputTruckingCompanyValue] =
    useState("");
  const CFAschema = Yup.object().shape({
    vesselVoyage: Yup.object().required("Vessel is required").nullable(),
    // blNumber: Yup.string().required('BL Number is required'),
    // containerNumber: Yup.string().required('This field is required'),
    representativeName: Yup.string().required(
      "Representative Name is required"
    ),
    representativeId: Yup.string().required("Representative ID is required"),
    truckHorse:
      bookingOption === 6 && permitType != "Export Delivery"
        ? Yup.string().required("Truck Horse is required")
        : Yup.string(),
    driverName:
      bookingOption === 6 && permitType != "Export Delivery"
        ? Yup.string().required("Driver Name is required")
        : Yup.string(),
    driverLicense:
      bookingOption === 6 && permitType != "Export Delivery"
        ? Yup.string().required("Driver License is required")
        : Yup.string(),
    blNumber: Yup.string().required("BL Number is required"),

  });
  const ICDschema = Yup.object().shape({
    vesselVoyage: Yup.object().required("Vessel is required").nullable(),
    // blNumber: Yup.string().required("BL Number is required"),
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
    clearErrors,
    setError,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(
      profileDetails?.opcPartnerType === "CFA" ? CFAschema : ICDschema
    ),
  });

  // Define state variables
  const [permitTypeMenu, setPermitTypeMenu] = useState<PermitType[]>([]);

  const [isUnderlined, setIsUnderlined] = useState(false);

  const linkStyle = {
    cursor: "pointer",
    fontSize: "13px",
    fontStyle: "italic",
    textDecoration: isUnderlined ? "underline" : "none",
  };

  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const [requestedDate, setRequestedDate] = useState<any>(new Date());
  const [vessel, setVessel] = useState("");
  const [PickGroupIDView, setPickGroupIDView] = useState<any>("");
  const [placeOfDeliveryView, setplaceOfDeliveryView] = useState<any>("");
  const [ICDShortCodeView, setICDShortCodeView] = useState<any>("");
  const [bestPickTotalContainersVal, setbestPickTotalContainersVal] =
    useState<any>("");
  const [blNumber, setBlNumber] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [showCancelPermit, setshowCancelPermit] = useState(false);
  const [totalContainers, setTotalContainers] = useState<any>("");
  const [containerNumber, setContainerNumber] = useState("");

  const [representativeName, setRepresentativeName] = useState("");
  const [representativeId, setRepresentativeId] = useState("");
  const [driverLicense, setDriverLicense] = useState("");

  const [truckHorse, setTruckHorse] = useState("");
  const [truckTrailer, setTruckTrailer] = useState("");
  const [truckingCompany, setTruckingCompany] = useState("");
  const [remarks, setRemarks] = useState("");
  const [documents, setDocuments] = useState([]);
  const [byteArr, setByteArr] = useState("");
  const [permitRefNo, setpermitRefNo] = useState();
  const [permitTypeCodeId, setPermitTypeCodeId] = useState<number | null>(null);
  const oblForm = useSelector((state: RootState) => state.oblDetails.oblForm);
  const [loiUpload, setLoiUpload] = useState("");
  const [disableFields, setdisableFields] = useState(false);
  const [options, setOptions] = useState<BookingOption[]>([]);
  const rowColumns = useSelector(
    (state: RootState) => state.instructionTable.rowColumns
  );

  const [searchBLNumberQuery, setsearchBLNumberQuery] = useState("");

  useEffect(() => {
    if (shortCodeVal && vessel) {
      dispatch(bestPickGroupID({ vessel: vessel, shortCodeVal: shortCodeVal }));
    }
  }, [shortCodeVal, vessel]);

  useEffect(() => {
    if (tpoId) {
      dispatch(ICDCode(tpoId));
    }
  }, [tpoId]);

  useEffect(() => {
    if (bestPickContainerVal) {
      setbestpickCon(bestPickContainerVal);
    }
  }, [bestPickContainerVal]);

  useEffect(() => {
    if (selectedBestPickGrpId && vessel && selectedICDcode) {
      dispatch(
        bestPickTotalContainers({
          vessel: vessel,
          selectedBestPickGrpId: selectedBestPickGrpId,
          placeOfDelivery: selectedICDcode,
        })
      );
    }
  }, [selectedBestPickGrpId, vessel, selectedICDcode]);

  useEffect(() => {
    if (ICDCodeVal) {
      if (Array.isArray(ICDCodeVal)) {
        const formattedOptions = ICDCodeVal.map((icd: any) => ({
          value: `${icd.customsDeliveryCode}`,
          label: `${icd.customsDeliveryCode}`,
          shortCode: icd.shortCode,
        }));
        setICDcodeList(formattedOptions);

        if (formattedOptions.length === 1) {
          const singleOption = formattedOptions[0];
          setValue("ICDShortCode", singleOption);
          setselectedICDcode(singleOption.value);
          setshortCodeVal(singleOption.shortCode);
        }
      }
    }
  }, [ICDCodeVal]);

  useEffect(() => {
    if (bestPickGroupIDval) {
      if (Array.isArray(bestPickGroupIDval)) {
        const formattedOptions = bestPickGroupIDval.map((icd: any) => ({
          value: `${icd.grpId}`,
          label: `${icd.grpId} - ${icd.description}`,
          // shortCode:icd.shortCode
        }));
        setbestPickGroupOptions(formattedOptions);

        if (formattedOptions.length === 1) {
          const singleOption = formattedOptions[0];
          setValue("ICDShortCode", singleOption);
          setselectedBestPickGrpId(singleOption.value);
          // setshortCodeVal(singleOption.shortCode)
        }
      }
    }
  }, [bestPickGroupIDval]);

  // useEffect(() => {
  // if (containerNumber) {
  // fetchNavisAPI(containerNumber, blNumber)
  // .then((response:any) => {
  // // Handle the response data as needed
  // console.log(response);
  // })
  // .catch(error => {
  // console.error(error);
  // });
  // }
  // }, [containerNumber, blNumber]);
  const handleDateChange = (date: Date | null) => {
    if (date !== null) {
      setRequestedDate(date);
    }
  };

  const onBack = useBackNavigation();

  const onSubmit = () => {
    //const countryCode = 'TZ';
    //const url = `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/permit/generatePermitReferenceNoByCountry?countryCode=${countryCode}`;

    // console.log('Request URL:', url);
    const selectedCodePermit = permitTypeMenu?.find(
      (option) => option.codeDesc === permitType
    );

    if (selectedCodePermit) {
      setPermitTypeCodeId(selectedCodePermit.codeId);
    }

    const payload = {
      // blId: 1,
      tpoId: tpoId,
      blno: blNumber,
      bookingType: bookingOption,
      permitType: permitTypeCodeId
        ? permitTypeCodeId
        : selectedCodePermit?.codeId,
      requestedDate: moment(requestedDate).format("YYYY-MM-DDTHH:mm:ss"),
      vessel: vessel,
      noOfContainer: totalContainers,
      representativeNm: representativeName,
      representativeId: representativeId,
      remarks: remarks,
      driverLicense: driverLicense,
      driverName: getValues("driverName"),
      truckHorse: truckHorse,
      truckTrailer: truckTrailer,
      truckingCompany: truckingCompany,
      truckingCompanyId: companyId,
      containerNumber: containerNumber,
      permitRefNo: permitRefNo_New,
      navisRefId: "2373",
      //bookingOption == 'Book Specific Container' ?
      containerDetails: containerDetails,

      ...(profileDetails?.opcPartnerType === "ICD" &&
        bookingOption == 8 && { icdShortCode: shortCodeVal }),
      ...(profileDetails?.opcPartnerType === "ICD" &&
        bookingOption == 8 && { bestPickGroupId: selectedBestPickGrpId }),
      ...(profileDetails?.opcPartnerType === "ICD" &&
        bookingOption == 8 && { placeOfDelivery: selectedICDcode }),

      /*   documents: [
        {
          docId: 123,
          fileName: 'test.pdf',
        },
      ], */
    };

    try {
      /*   const response: any = axiosOdex.post(
        `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/permit/generatePermitReferenceNoByCountry?countryCode=TZ`
      );
      if (response.data) {
        response.data;
      }
      console.log('Response data:', response.data); */
      dispatch(addPermitRequest(payload)).then((response: any) => {
        if (response?.payload?.data?.responseCode === "SUCCESS") {
          toast.success(response.payload.data.responseMsg, {
            theme: "colored",
            toastId: 'addPermitSuccess'
          });
          setTimeout(() => {
            onBack();
            setTimeout(() => {
              dispatch(updateDashbaordRefresh(true));
            }, 2000);
          }, 500);
          _onCloseForm();
        } else {
          const errMessage = `${response.payload.data.responseMsg.replaceAll(
            "##NL##",
            "<br/>"
          )}`;
          toast.error(
            <div dangerouslySetInnerHTML={{ __html: errMessage }}></div>,
            {
              theme: "colored",
              toastId: 'validateVesselError'
            },
          );
          // toast.error(response?.data?.message, {
          //   theme: 'colored',
          // });
          // resetForm();
        }
      });
      /*    dispatch(generatePermitReferenceNo('TZ')).then((response: any) => {
        setpermitRefNo(response.data);
      }); */
    } catch (err: any) {
      console.error(err.message);
    }
  };
  const serverFilePath = useSelector(
    (state: RootState) => state.demurrage.selectedFileServerPath
  );
  const validateBl = (val: any) => {
    if (permitType.includes("Import")) var permitParam = "Import";
    else permitParam = "Export";
    var containerList: any = [];

    if (val.length > 0) {
      if (blNumbListVal?.find((item: any) => item.blNumber == val)) {
        setBlNumber(val);
        clearErrors("blNumber");
      } else {
        setError("blNumber", {
          type: "manual",
          message: "SO/BL number is invalid",
        });
      }
      dispatch(blValidation({ blnumber: val, shipmentType: permitParam })).then(
        (response: any) => {
          const transformedData = response.payload.data.map((item: any) => ({
            containerNo: item.containerNumber,
          }));
          if (bookingOption !== 7) {
            setContainerDetails(transformedData);
          } else {
            setfilteredcontainerDetails(transformedData);
            setContainerDetails(transformedData);
          }

          setTotalContainers(transformedData.length);
          setBlNumber(val);
        }
      );
    }
  };

  const handleContainerNumberBlur = useAsyncDebounce(async (val: any) => {
    setContainerNumber(val);
    const isValid = await trigger("containerNumber");

    if (isValid) {
      const isValidContainer = Array.isArray(containerDetails)
        ? containerDetails.some(
          (detail) => detail.containerNo === containerNumber
        )
        : containerDetails.containerNo === containerNumber;

      if (!isValidContainer) {
        setError("containerNumber", {
          type: "manual",
          message: "Container number is invalid",
        });
        setfilteredcontainerDetails([]);
        // setTotalContainers('');
      } else {
        clearErrors("containerNumber");
        setfilteredcontainerDetails([{ containerNo: containerNumber }]);
        setContainerDetails([{ containerNo: containerNumber }]);
      }
    }
  }, 100);

  const location = useLocation();

  const [showBLNumSuggestions, setshowBLNumSuggestions] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (tpoId) {
          const response = await retry(
            async () =>
              await axiosCacheInstance.get(
                `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=PERMIT_REQUEST&linerId=${tpoId}&countryCode=TZ`,
                {
                  cache: {
                    ttl: 10 * 60 * 1000,
                  },
                }
              ),
            2
          );
          setPermitTypeMenu(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (!decrptdoc) {
      fetchData();
    }
  }, [tpoId]);
  useEffect(() => {
    if (bookingOption == 6) {
      setPermitType("Import Pick up");
    }
    dispatch(generatePermitReferenceNo("TZ")).unwrap().then((val) => {
      if (!val || val == '') {
        onBack()
        toast.error('Failed to generate reference number, Please Try again !', { toastId: 'failedToGenerateRefNo' })
      }
    }).catch(() => {
      onBack()
      toast.error('Failed to generate reference number, Please Try again !', { toastId: 'failedToGenerateRefNo2' })
    });
  }, []);

  const handleBookingOptionChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedOption = parseInt(e.target.value);
    setBookingOption(selectedOption);
    if (profileDetails?.opcPartnerType === "CFA") {
      if (selectedOption == 7) {
        const payload: any = { target: { value: "Export Delivery" } }
        handlePermitTypeChange(payload)
        setPermitTypeCodeId(5);
      } else {
        const payload: any = { target: { value: "Import Pick up" } }
        handlePermitTypeChange(payload);
        setPermitTypeCodeId(4);
      }
    }
    // // Find the corresponding codeId from the options
  };

  interface PermitType {
    codeDesc: string;
    codeMstId: number;
    codeMstType: string;
    codeId: number;
    permitType: string;
    codeLang: string;
    linerId: number;
    countryCode: string;
  }
  useEffect(() => {
    if (VesselData) {
      if (Array.isArray(VesselData)) {
        const formattedOptions = VesselData.map((vessel: any) => ({
          value: `${vessel.visitKey}`,
          label: `${vessel.vesselName} - ${vessel.visitKey}${vessel.ata ? " - " + new Date(vessel.ata).toLocaleString() : ""
            }`,
          visitKey: vessel.visitKey,
        }));
        setVesselList(formattedOptions);
      }
    }
  }, [VesselData]);

  useEffect(() => {
    if (TruckingCompanyData) {
      if (Array.isArray(TruckingCompanyData)) {
        const formattedOptions = TruckingCompanyData.map(
          (truckingCompany: any) => ({
            value: truckingCompany.companyName,
            label: truckingCompany.companyName,
          })
        );

        //setVesselList(formattedOptions);
        setTruckingCompanyList(formattedOptions);
        //  console.log('setTruckingCompanyList', formattedOptions);
      }
    }
  }, [TruckingCompanyData]);
  useEffect(() => {
    if (TruckingTrailerData) {
      if (Array.isArray(TruckingTrailerData)) {
        const formattedOptions = TruckingTrailerData.map(
          (truckingTrailer: any) => ({
            value: `${truckingTrailer.truckId} `,
            label: `${truckingTrailer.truckId}`,
          })
        );

        //setVesselList(formattedOptions);
        setTruckingTrailerList(formattedOptions);
        //  console.log('setTruckingCompanyList', formattedOptions);
      }
    }
  }, [TruckingCompanyData]);
  const customFilterOption = (option: any, inputValue: any) => {
    // if (inputValue.length < 3) {
    //   return false;
    // }
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  };

  const handleInputChange = useDebounce((inputValue: string) => {
    if (inputValue.length >= 3) {
      //   dispatch(fetchVesselSuggestions(inputValue.toUpperCase()));

      dispatch(
        fetchVesselSuggestions({
          vesselKey: inputValue.toUpperCase(),
          requestType: "902",
          permitId: permitTypeCodeId,
        })
      );
    }
    if (inputValue.length < 3) {
      setVesselList([]);
    }
  }, 500);
  const handleInputChangeTruckingCompany = useDebounce((inputValue: string) => {
    if (inputValue.length >= 3) {
      dispatch(fetchTruckingCompanySuggestions(inputValue.toUpperCase()));
    }
    if (inputValue.length < 3) {
      setTruckingCompanyList([]);
    }
  }, 500);

  const [showCancel, setShowCancel] = useState(false);

  // useEffect(() => {
  // if (location.state?.from === '/home') {
  // setBlNumber(oblForm?.number ?? '');
  // } else {
  // setBlNumber('');
  // }
  // }, [location, oblForm]);

  // const showCancel =
  //   location.pathname.includes('instruction/bl') &&
  //   location.pathname.split('/')[3] != 'instruction';
  // const handleCancel = async () => {
  //   try {
  //     dispatch(cancelPermitRequest({}));
  //   } catch (error) {
  //     console.error('Error canceling permit request:', error);
  //   }
  // };

  const handlePermitTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = e.target.value;
    setPermitType(e.target.value);
    setValue("permitType", selectedOption);
    console.log("herepermitType", selectedOption);
    const selectedCode = permitTypeMenu?.find(
      (option) => option.codeDesc === selectedOption
    );
    setRequestedDate(null);
    clearErrors();
    // setValue('permitType', '');
    setValue("vesselVoyage", "");
    //  setPermitType('');
    setValue("blNumber", "");
    setBlNumber("");
    setValue("containerNumber", "");
    setContainerNumber("");
    // setValue('totalContainers', '');
    setTotalContainers("");

    setTruckHorse("");

    setValue("representativeName", "");
    setRepresentativeName("");
    setValue("representativeId", "");
    setRepresentativeId("");
    setValue("driverLicense", "");

    setValue("truckHorse", "");
    setValue("truckTrailer", "");
    setValue("truckingCompany", "");
    setDriverLicense("");
    setDriverNameListLicenceSelected(null);
    setValue("driverName", "");
    setValue("remarks", "");
    setnameValidation(false);

    setDriverLicenceListNameSelected(null);
    setRemarks("");

    if (selectedCode) {
      setPermitTypeCodeId(selectedCode.codeId);
      if (profileDetails?.opcPartnerType === "CFA") {
        if (selectedCode.codeId == 4) {
          setBookingOption(6);
        } else if (selectedCode.codeId == 5) {
          setBookingOption(7);
        }
      }
    }
  };
  useEffect(() => {
    // setShowCancel(requesttp || invoice_request_id ? false : true);
    if (requesttp && invoice_request_id) {
      if (
        matchPath(
          "/opc/instruction/:requesttp/:invoice_request_id",
          location.pathname
        )
      ) {
        setShowCancel(true);
      }
    }
    if (location.state?.from === "/bl") {
      setBlNumber(oblForm?.number ?? "");
      setdisableFields(true);
    } else if (location.pathname.split("/")[2] == "instruction") {
      setdisableFields(false);
    } else {
      setBlNumber("");
      setdisableFields(false);
    }
  }, [location, oblForm, disableFields]);
  useEffect(() => {
    // Clear the form state when the component mounts
    setVessel("");
    setBlNumber("");
    setContainerNumber("");
    setTotalContainers("");
    setValue("blNumber", "");
    setVesselList([]);
    clearErrors("vesselVoyage");
    clearErrors("blNumber");
    clearErrors("containerNumber");
  }, []); // Empty dependency array ensures this effect runs only on component mount

  const uploadDoument = (rowData: any) => {
    const { docId, dndInstructionAttId, fileName, permitNumber, permitNo } =
      rowData.row.original;

    const charLimit = (name: string, limit: number) =>
      name && name?.length >= limit ? name?.substring(0, limit) + "..." : name;

    if (!disableFields) {
      return (
        <div className="d-flex justify-content-start align-items-center">
          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
            className="fs-0  text-primary"
          >
            {permitNumber || permitNo ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      `/api/opc-management/v1/permit/verifyPermit?appointmentNbr=${Base64Encode(
                        AESEncryptforDashboard(permitNumber || permitNo)
                      )}&permitType=${Base64Encode(
                        AESEncryptforDashboard(
                          permitType == "Export Delivery"
                            ? "CWPEBS"
                            : "CWPEIROUT"
                        )
                      )}`,
                      "_blank"
                    );
                  }}
                  onMouseEnter={() => setIsUnderlined(true)}
                  onMouseLeave={() => setIsUnderlined(false)}
                  style={{ ...linkStyle, marginTop: "5px" }}
                >
                  <FontAwesomeIcon icon={faDownload} />
                </span>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{ ...linkStyle, marginTop: "5px", cursor: "auto" }}
                  title="Permit not released yet"
                >
                  <FontAwesomeIcon color="grey" icon={faDownload} />
                </span>
              </div>
            )}
          </span>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-start align-items-center">
          {/* <label
 className='btn btn-sm btn-dark m-0 me-2'
 htmlFor={`${docId}-input`}>
 Choose
 </label>
 <input
 id={`${docId}-input`}
 title='Input'
 type='file'
 className='fs--2 form-control ms-1'
 disabled={disableFields}
 onChange={(e) => {
 handleFileChange(e, docId);
 }}
 hidden
 /> */}

          <span
            className="text-primary cursor-pointer"
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_CLIENT_APP_HOST
                }/api/invoice-management/v1/common/openDocument?id=${dndInstructionAttId}&moduleTp=DND&linerId=${profileDetails.partnerType === "liner"
                  ? profileDetails?.partnerId
                  : selectedLinerId
                }`,
                "_blank"
              );
            }}
          >
            {charLimit(fileName, 50)}
          </span>
        </div>
      );
    }
  };
  const resetForm = () => {
    clearErrors();
    // setValue('permitType', '');
    setValue("vesselVoyage", "");
    //  setPermitType('');
    setValue("blNumber", "");
    setBlNumber("");
    setValue("containerNumber", "");
    setContainerNumber("");
    // setValue('totalContainers', '');
    setTotalContainers("");

    setTruckHorse("");

    setValue("representativeName", "");
    setRepresentativeName("");
    setValue("representativeId", "");
    setRepresentativeId("");
    setValue("driverLicense", "");

    setValue("truckHorse", "");
    setValue("truckTrailer", "");
    setValue("truckingCompany", "");
    setDriverLicense("");
    setDriverNameListLicenceSelected(null);
    setValue("driveName", "");
    setValue("remarks", "");
    setnameValidation(false);

    setDriverLicenceListNameSelected(null);
    setRemarks("");
  };

  useEffect(() => {
    if (profileDetails?.opcPartnerType === "ICD") {
      setPermitTypeCodeId(4);
      setPermitType("Import Pick Up");
    }
    setBookingOption(profileDetails?.opcPartnerType === "ICD" ? 7 : 6);
  }, [profileDetails?.opcPartnerType]);
  const handleDriverLicenceAndName = useAsyncDebounce(async (event) => {
    if (event.target.value === "") {
      setDriverLicense("");
      setValue("driverName", "");
      setnameValidation(false);
      clearErrors("driverLicense");
      return;
    }
    try {
      const response = await axiosInstance.post(
        `/api/opc-rest-v1/v1/master/getDriversList`,
        {
          license: event.target.value,
          driverName: null,
        }
      );
      // console.log(
      //   'responseeee',
      //   response.data.map((item: any) => item.driverName)
      // );

      // response.data.map((item: any) =>
      //   item.license == event.target.value
      //     ? setDriverName(item.driverName)
      //     : setDriverName('')
      // );
      let found = false;
      response.data.map((item: any) => {
        if (item.license == event.target.value) {
          setValue("driverName", item.driverName);
          clearErrors("driverLicense");
          found = true;
          setnameValidation(true);
        } else {
          setValue("driverName", "");
          setnameValidation(false);
        }
      });

      if (!found) {
        setValue("driverName", "");
        setnameValidation(false);
        setError("driverLicense", {
          type: "manual",
          message: "Invalid Driver License",
        });
      }

      // handleValidation();
    } catch (error) {
      console.error(error);
      /*  setError('driverLicense', {
        type: 'manual',
        message: 'Error fetching driver information',
      }); */
    }
  }, 100);

  const handleTruckHorseValidation = useAsyncDebounce(async (event) => {
    const license = event.target.value;

    if (license.trim() === "") {
      clearErrors("truckHorse");
      return;
    }
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/master/getTrucksList`,
        { license }
      );

      let isValid = false;
      response.data?.forEach((item: any) => {
        if (item.license === event.target.value) {
          isValid = true;
        }
      });

      if (isValid) {
        clearErrors("truckHorse");
      } else {
        setError("truckHorse", {
          type: "manual",
          message: "Invalid Truck Horse License",
        });
      }
    } catch (error) {
      console.error(error);
      setError("truckHorse", {
        type: "manual",
        message: "Error fetching truck information",
      });
    }
  }, 20);
  const vesselVoyage = watch("vesselVoyage");
  useEffect(() => {
    if (
      requestedDate &&
      vesselVoyage?.visitKey &&
      permitType === "Export Delivery" &&
      !disableFields
    ) {
      const payload = {
        visitKey: vesselVoyage?.visitKey,
        requestedDate: moment(requestedDate).format("YYYY-MM-DD"),
      };
      dispatch(validateRequestedDateForExport(payload))
        .unwrap()
        .then((res) => {
          if (res?.responseCode === "INVALID") {
            const errMessage = `${res?.responseMsg?.replaceAll(
              "##NL##",
              "<br/>"
            )}`;
            toast.error(
              <div dangerouslySetInnerHTML={{ __html: errMessage }}></div>,
              {
                toastId: 'validateVesselDateError'
              }
            );
            setRequestedDate(null);
          } else {
            setRequestedDate(requestedDate);
          }
        })
        .catch((err) => {
          console.error(err, "<<error in validating request date");
        });
    }
  }, [requestedDate, vesselVoyage, disableFields]);

  const handleDriverName = useAsyncDebounce(async (event) => {
    if (event.target.value === "") {
      setDriverLicense("");
      setValue("driverName", "");
      setlicenseValidation(false);
      clearErrors("driverName");
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/master/getDriversList?license`,
        { driverName: event.target.value, license: null }
      );

      let found = false;
      response.data.map((item: any) => {
        if (item.driverName == event.target.value) {
          setDriverLicense(item.license);
          setValue("driverLicense", item.license);
          clearErrors("driverName");
          found = true;
          setlicenseValidation(true);
        } else {
          setDriverLicense("");
          setValue("driverLicense", "");
          setlicenseValidation(false);
        }
      });

      if (!found) {
        setDriverLicense("");
        setValue("driverLicense", "");
        setlicenseValidation(false);
        setError("driverName", {
          type: "manual",
          message: "Invalid Driver Name",
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, 100);

  const _onCloseForm = () => {
    function extractValuesFromUrl(url: any) {
      const params = new URLSearchParams(url);
      const sortParam = params.get("sort");

      if (sortParam) {
        const arr: any = sortParam.split("%2C");
        return arr;
      } else {
        return [];
      }
    }
    if (decrptins == "902") {
      dispatch(toggleDocumentHistoryColumnShort(false));
    }
    if (window.location.pathname.includes("/opc/instruction")) {
      dispatch(
        updateBlDetailsRowColumns({
          ...rowColumns,
          mainTableClass: "col-lg-2",
          documentMainViewClass: "col-lg-10",
          documentHistoryClass: "col-lg-12",
          outletClass: "d-none",
        })
      );
    }

    setTimeout(() => {
      onBack();
    }, 100);
  };
  useEffect(() => {
    const getPermitData = async () => {
      try {
        if (tpoId && invoice_request_id) {
          const response = await axiosInstance.get(
            `${process.env.REACT_APP_API_GATEWAY_HOST
            }/api/opc-rest-v1/v1/permit/getPermitRequest?id=${AESDecrypt(
              invoice_request_id
            )}`,
            {}
          );
          const response2 = await retry(
            async () =>
              await axiosCacheInstance.get(
                `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=BOOKING_OPTION&linerId=${tpoId}&countryCode=TZ`,
                {
                  cache: {
                    ttl: 10 * 60 * 1000,
                  },
                }
              ),
            2
          );
          const response3 = await retry(
            async () =>
              await axiosCacheInstance.get(
                `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=PERMIT_REQUEST&linerId=${tpoId}&countryCode=TZ`,
                {
                  cache: {
                    ttl: 10 * 60 * 1000,
                  },
                }
              ),
            2
          );

          setPermitTypeMenu(response3.data ?? []);
          setOptions(response2.data ?? []);
          if (response.data && response2.data && response3.data) {
            // setShowCancel(true);
            const foundBookingOption = response2.data?.find(
              (item: any) => item.codeId === response.data?.bookingType
            );
            if (foundBookingOption) {
              setBookingOption(foundBookingOption.codeId);
            } else {
              console.error(`Error`);
            }
            const foundPermitType = response3.data?.find(
              (item: any) => item.codeId === response.data?.permitType
            );
            if (foundPermitType) {
              setPermitType(foundPermitType.codeDesc);
            } else {
              console.error(`Error`);
            }
            setStatus(response.data.status);
            setBlNumber(response.data.blno);
            setTotalContainers(response.data.containerDetails.length);
            setVessel(response.data.vessel);
            setPickGroupIDView(response.data.bestPickGroupId);
            setplaceOfDeliveryView(response.data.placeOfDelivery);
            // setICDShortCodeView(response.data.bestPickGroupId)
            setfilteredcontainerDetails(response.data.containerDetails);
            setRepresentativeName(response.data.representativeNm);
            setRepresentativeId(response.data.representativeId);
            setRemarks(response.data.remarks);
            setDriverLicense(response.data.driverLicense);
            setValue("driverName", response.data.driverName);
            setTruckHorse(response.data.truckHorse);
            setTruckTrailer(response.data.truckTrailer);
            setTruckingCompany(response.data.truckingCompany);
            response.data.requestedDate && setRequestedDate(new Date(response.data.requestedDate))
            setContainerNumber(
              response.data.containerDetails.map(
                (item: any) => item.containerNo
              )
            );
            /*  setContainerDetails([
              {
                permitRequestContainerId: 1,
                permitRequestId: 1,
                containerNo: 'BSR2778377',
                permitNo: 'ABC2735673',
              },
              {
                permitRequestContainerId: 1,
                permitRequestId: 1,
                containerNo: 'BSR2778378',
                permitNo: 'ABC2735674',
              },
            ]); */
          }
        }
      } catch (error) {
        console.error("Error fetching", error);
      }
    };

    getPermitData();
  }, [requesttp, invoice_request_id, tpoId]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        if (tpoId) {
          const { data } = await retry(
            async () =>
              await axiosCacheInstance.get(
                `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=BOOKING_OPTION&linerId=${tpoId}&countryCode=TZ`,
                {
                  cache: {
                    ttl: 10 * 60 * 1000,
                  },
                }
              ),
            2
          );
          if (data) {
            // setBookingOption(data?.[0]?.codeId);
            if (profileDetails?.opcPartnerType === "ICD") {
              const filteredData = data.filter(
                (option: any) => option.codeId !== 6
              );
              setOptions(filteredData);
            } else {
              const filteredData = data.filter(
                (option: any) => option.codeId !== 8
              );
              setOptions(filteredData);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching booking options:", error);
      }
    };
    if (!decrptdoc) {
      fetchOptions();
    }
  }, [tpoId]);
  const bestPickAttachmentColumns: IDataTableColumn[] = [
    {
      accessor: "permitNo",
      Header: t("resource_2:permit_number"),
      width: 200,
      maxWidth: 150,
    },
    {
      accessor: "byteArray",
      Header: t("resource_1:download"),
      Cell: uploadDoument,
      width: 450,
      maxWidth: 450,
    },
    {
      accessor: "fileName",
      Header: t("resource_1:fileName"),
    },
  ];

  const docAttachmentColumns: IDataTableColumn[] = [
    {
      accessor: "containerNo",
      Header: t("resource_2:containerNumber"),
      width: 200,
      maxWidth: 150,
    },
  ];

  const minDateForVesselVoyage = () => {
    if (
      profileDetails?.opcPartnerType === "CFA" &&
      permitType === "Export Delivery"
    ) {
      let date = new Date();
      date.setHours(0, 0, 0, 0);
      return date;
    }
  };

  const maxDateForVesselVoyage = () => {
    if (
      profileDetails?.opcPartnerType === "CFA" &&
      permitType === "Export Delivery"
    ) {
      let date = new Date();
      date.setHours(0, 0, 0, 0);
      date.setDate(date.getDate() + 1);
      return date;
    }
  };

  const docviewColumns: IDataTableColumn[] = [
    {
      accessor: "containerNo",
      Header: t("resource_2:containerNumber"),
      width: 200,
      maxWidth: 150,
    },
    {
      accessor: "permitNo",
      Header: t("resource_2:permit_number"),
      width: 200,
      maxWidth: 150,
    },
    {
      accessor: "byteArray",
      Header: t("resource_1:download"),
      Cell: uploadDoument,
      width: 450,
      maxWidth: 450,
    },
    {
      accessor: "fileName",
      Header: t("resource_1:fileName"),
    },
  ];
  const [columns, setColumns] = useState(docviewColumns);
  const [bestPickCol, setbestPickCol] = useState<any>(
    bestPickAttachmentColumns
  );

  useEffect(() => {
    const decryptedId = AESDecrypt(invoice_request_id);
    const isDocAttachment = window.location.pathname.includes(decryptedId);
    setColumns(isDocAttachment ? docAttachmentColumns : docviewColumns);
    setbestPickCol(bestPickAttachmentColumns);
  }, [invoice_request_id]);
  return (
    <Card>
      <Card.Header className="d-flex align-items-center border-bottom sticky-top bg-white">
        <FontAwesomeIcon
          icon={faArrowAltCircleLeft}
          className="text-warning cursor-pointer fa-lg mb-1 me-1"
          onClick={_onCloseForm}
        />
        <h2 className="fs-0 text-primary mb-1">{t("Permit Request")}</h2>
      </Card.Header>

      <Card.Body style={{ minHeight: "90vh" }}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            {/* Booking Option */}
            <div className="col-lg-6 mb-3">
              <Form.Group controlId="permitRequest.bookingOption">
                <span className="mandatory">*</span>
                <Form.Label className="fs--1">
                  {t("resource_2:bookingOption")}
                </Form.Label>

                <Form.Select
                  {...register("bookingOption")}
                  as="select"
                  value={bookingOption}
                  className="fs--1 form-control form-select"
                  onChange={handleBookingOptionChange}
                  defaultValue={bookingOption}
                  disabled={
                    disableFields ||
                    // profileDetails?.opcPartnerType === "ICD" ||
                    // profileDetails?.opcPartnerType === "CFA" ||
                    showCancel
                  }
                >
                  {/*  <option value='' disabled>
                    Select
                  </option> */}
                  {options?.map((option) => (
                    <option key={option.codeId} value={option.codeId}>
                      {option.codeDesc}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>

            <>
              <div className="col-lg-6 mb-3">
                <Form.Group controlId="permitType">
                  <span className="mandatory">*</span>
                  <Form.Label className="fs--1">
                    {t("resource_2:permitType")}
                  </Form.Label>
                  <Form.Select
                    className="fs--1 form-control form-select"
                    {...register("permitType")}
                    as="select"
                    value={permitType}
                    defaultValue={permitType}
                    onChange={handlePermitTypeChange}
                    disabled={
                      disableFields ||
                      profileDetails?.opcPartnerType === "ICD" ||
                      showCancel
                    }
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {/* {profileDetails?.opcPartnerType === 'ICD' ? (
                        <option value='Import Pick Up'>Import Pick Up</option>
                      ) : (
                        <>
                          <option value='Export Delivery'>
                            Export Delivery
                          </option>
                          <option value='Import Pick Up'>Import Pick Up</option>
                        </>
                      )} */}
                    {[7, 8].includes(bookingOption ?? 0)
                      ? permitTypeMenu.map((item, index) => (
                        <option key={index} value={item.codeDesc}>
                          {item.codeDesc}
                        </option>
                      ))
                      : //<option value='Import Pick Up'>Import Pick Up</option>
                      permitTypeMenu.map((item, index) => (
                        <option key={index} value={item.codeDesc}>
                          {item.codeDesc}
                        </option>
                      ))}

                    {/*options */}
                  </Form.Select>
                </Form.Group>
              </div>
              {/* Requested Date */}
              <div className="col-lg-6 mb-3">
                <Form.Group controlId="requestedDate">
                  <span className="mandatory">*</span>
                  <Form.Label className="fs--1">
                    {t("resource_2:requestedDate")}
                  </Form.Label>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <DatePicker
                      timeIntervals={15}
                      minDate={minDateForVesselVoyage()}
                      maxDate={maxDateForVesselVoyage()}
                      value={requestedDate}
                      selected={requestedDate}
                      onChange={handleDateChange}
                      className="form-control"
                      disabled={disableFields || showCancel}
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                </Form.Group>
              </div>
              {/* Vessel */}
              <div className="col-lg-6 mb-3">
                <Form.Group style={{ padding: "0px" }} controlId="vesselVoyage">
                  <span className="mandatory">*</span>
                  <Form.Label>{t("resource_1:vessel")}</Form.Label>
                  {!showCancel && (
                    <div>
                      <Controller
                        name="vesselVoyage"
                        control={control}
                        rules={{ required: "This field is required" }}
                        render={({ field }: any) => (
                          <Select
                            {...field}
                            options={VesselList}
                            noOptionsMessage={() => null}
                            isClearable={true}
                            maxMenuHeight={150}
                            //  isDisabled={showdata}
                            /*  onChange={(e: any) => {
                              field.onChange(e);

                              dispatch(blNumbList(e.visitKey));
                              setVessel(e.value);
                              console.log('vessel', vessel);
                            }} */
                            onChange={(e: any) => {
                              if (watch("permitType") == "") {
                                setValue("vesselVoyage", "");
                                setselectedVessel(" ");
                                setError("vesselVoyage", {
                                  type: "manual",
                                  message: "Please select permit Type first.",
                                });
                              } else if (e === null) {
                                field.onChange(null);
                                setBlNumber("");
                                setContainerNumber("");
                                setValue("blNumber", "");
                                clearErrors("blNumber");
                                clearErrors("containerNumber");
                                setTotalContainers("");
                                setselectedVessel("");
                              } else {
                                setBlNumber("");
                                setTotalContainers("");
                                setContainerNumber("");
                                setValue("blNumber", "");
                                clearErrors("blNumber");
                                clearErrors("containerNumber");
                                field.onChange({
                                  label: e.label,
                                  value: e.value,
                                  visitKey: e.visitKey,
                                });
                                setVessel(e.value);
                                setselectedVessel(e.value);
                                dispatch(
                                  blNumbList({
                                    visit: e.visitKey,
                                    permit: permitType.includes("Import")
                                      ? "Import"
                                      : "Export",
                                  })
                                );
                              }
                            }}
                            styles={customDropdownStyles}
                            filterOption={customFilterOption}
                            onInputChange={handleInputChange}
                            className={`fs--1 form-control form-control-sm ${errors.vesselVoyage ? "is-invalid" : ""
                              } ${loadingVesselList ? "loadingPulse" : ""}`}
                          />
                        )}
                      />
                      <div className="invalid-feedback">
                        {errors.vesselVoyage?.message?.toString()}
                      </div>
                    </div>
                  )}
                  {showCancel && (
                    <div>
                      <Form.Control
                        disabled={true}
                        value={vessel}
                        size="sm"
                        /*        {...register('vesselVoyage')}
                        className={`fs--1 form-control form-control-sm ${
                          errors.vesselVoyage ? 'is-invalid' : ''
                        }`} */
                        type="text"
                        maxLength={20}
                      />
                    </div>
                  )}
                </Form.Group>
              </div>
              {/* ICD Short Code */}
              {
                bookingOption == 8 && (
                  <div className="col-lg-6 mb-2">
                    <Form.Group controlId="ICDShortCode">
                      <span className="mandatory">*</span>
                      <Form.Label className="fs--1">
                        {/* {t("resource_3:icd_short_code")} */}
                        {t("resource_3:place_of_delivery")}
                      </Form.Label>
                      {!showCancel && (
                        <div>
                          <Controller
                            name="ICDShortCode"
                            control={control}
                            rules={{ required: "This field is required" }}
                            render={({ field }: any) => (
                              <Select
                                {...field}
                                maxMenuHeight={150}
                                menuPlacement="auto"
                                {...register("ICDShortCode")}
                                options={ICDcodeList}
                                noOptionsMessage={() => null}
                                isClearable={true}
                                onChange={(e: any) => {
                                  if (e === null) {
                                    field.onChange(null);
                                    setselectedICDcode("");
                                    setshortCodeVal("");
                                  } else {
                                    field.onChange({
                                      label: e.value,
                                      value: e.value,
                                      visitKey: e.visitKey,
                                    });
                                    setselectedICDcode(e.value);
                                    setshortCodeVal(e.shortCode);
                                  }
                                }}
                                styles={customDropdownStyles}
                                className={`fs--1 form-control form-control-sm ${errors.ICDShortCode ? "is-invalid" : ""
                                  }`}
                              />
                            )}
                          />
                          <div className="invalid-feedback">
                            {errors.ICDShortCode?.message?.toString()}
                          </div>
                        </div>
                      )}

                      {showCancel && (
                        <div>
                          <Form.Control
                            disabled={true}
                            value={placeOfDeliveryView}
                            size="sm"
                            type="text"
                            maxLength={20}
                          />
                        </div>
                      )}
                    </Form.Group>
                  </div>
                )}

              {/* Best Pick Group ID */}
              {
                bookingOption == 8 && (
                  <div className="col-lg-6 mb-2">
                    <Form.Group controlId="bestPickGroup">
                      <span className="mandatory">*</span>
                      <Form.Label className="fs--1">
                        {/* {t("resource_3:icd_short_code")} */}
                        Best Pick Group ID
                      </Form.Label>
                      {!showCancel && (
                        <div>
                          <Controller
                            name="bestPickGroup"
                            control={control}
                            rules={{ required: "This field is required" }}
                            render={({ field }: any) => (
                              <Select
                                {...field}
                                maxMenuHeight={150}
                                menuPlacement="auto"
                                {...register("bestPickGroup")}
                                options={bestPickGroupOptions}
                                noOptionsMessage={() => null}
                                isClearable={true}
                                onChange={(e: any) => {
                                  if (e === null) {
                                    field.onChange(null);
                                    setselectedBestPickGrpId("");
                                    // setshortCodeVal("")
                                  } else {
                                    field.onChange({
                                      label: e.value,
                                      value: e.value,
                                      visitKey: e.visitKey,
                                    });
                                    setselectedBestPickGrpId(e.value);
                                    // setshortCodeVal(e.shortCode)
                                  }
                                }}
                                styles={customDropdownStyles}
                                className={`fs--1 form-control form-control-sm ${errors.bestPickGroup ? "is-invalid" : ""
                                  }`}
                              />
                            )}
                          />
                          <div className="invalid-feedback">
                            {errors.bestPickGroup?.message?.toString()}
                          </div>
                        </div>
                      )}

                      {showCancel && (
                        <div>
                          <Form.Control
                            disabled={true}
                            value={PickGroupIDView}
                            size="sm"
                            type="text"
                            maxLength={20}
                          />
                        </div>
                      )}
                    </Form.Group>
                  </div>
                )}

              {/*Best Pick Total Containers */}
              {
                bookingOption == 8 &&
                !showCancel && (
                  <div className="col-lg-6 mb-2">
                    <Form.Group controlId="totalContainersICD">
                      {/* <span className="mandatory">*</span> */}
                      <Form.Label className="fs--1">
                        Total Containers
                      </Form.Label>

                      <div>
                        <Form.Control
                          disabled={true}
                          value={bestpickCon}
                          size="sm"
                          type="text"
                          maxLength={20}
                        />
                      </div>
                    </Form.Group>
                  </div>
                )}

              {/* BL Number */}
              {bookingOption != 8 && (
                <div className="col-lg-6 mb-3 odex-position-relative align-items-start justify-content-center">
                  <Form.Group controlId="blNumber">
                    <span className="mandatory">*</span>
                    <Form.Label className="fs--1">
                      {t("resource_2:bl_so_number")}
                    </Form.Label>
                    {!showCancel && (
                      <Form.Control
                        type="text"
                        className={`fs--1 form-control form-control-sm ${errors.blNumber ? "is-invalid" : ""
                          }`}
                        //   {...register('blNumber')}
                        //   //  as='textarea'
                        //   // value={blNumber}
                        //   /*     onChange={async ({ target }) => {
                        //   setsearchBLNumberQuery(target.value);
                        //   setBlNumber(target.value);
                        //   //  _onBlChange(target.value);
                        //   clearErrors('blNumber');
                        //   if (target.value === '') {
                        //     await trigger('blNumber');
                        //   }
                        // }} */
                        //   onBlur={(e) => validateBl(e.target.value.trim())}
                        //   // rows={2}
                        //   //onChange={(e) => setBlNumber(e.target.value)}
                        //   disabled={disableFields || showCancel}
                        //   isInvalid={!!errors.blNumber}
                        {...register("blNumber", {
                          required: "BL/SO Number is required",
                          onBlur: (e) => {
                            const blNumberValue = e.target.value.trim();
                            if (!vessel) {
                              setError("blNumber", {
                                type: "manual",
                                message: "Please select a vessel first",
                              });
                            } else {
                              validateBl(blNumberValue);
                            }
                          },
                          onChange: (e) => {
                            const blNumberValue = e.target.value.trim();
                            setBlNumber(blNumberValue); // Update BL Number state
                            setContainerNumber(""); // Clear Container Number
                            if (blNumberValue === "") {
                              setError("blNumber", {
                                type: "manual",
                                message: "BL Number is required",
                              });
                            } else {
                              clearErrors("blNumber");
                            }
                          },
                        })}
                        disabled={disableFields || showCancel}
                        isInvalid={!!errors.blNumber}
                      />
                    )}
                    {errors.blNumber && (
                      <div className="invalid-feedback">
                        {errors.blNumber?.message?.toString()}
                      </div>
                    )}
                    {showCancel && (
                      <div>
                        <Form.Control
                          disabled={true}
                          value={blNumber}
                          size="sm"
                          type="text"
                          maxLength={20}
                        />
                      </div>
                    )}
                  </Form.Group>
                  {/*     {blNumber &&
                  filteredBLNumberSuggestions.length > 0 &&
                  showBLNumSuggestions && (
                    <div className='rounded border bg-white w-100 odex-position-absolute odex-registration-address d-flex flex-column justify-content-start align-items-start'>
                      {filteredBLNumberSuggestions.map((v: any) => (
                        <div
                          className='p-2 d-flex flex-row justify-content-start align-items-center fs--1 cursor-pointer'
                          // key={v.visitKey}
                          onClick={() => {
                            setBlNumber(v.blNumber);
                            setsearchBLNumberQuery(v.blNumber);
                            setshowBLNumSuggestions(false);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faLocation}
                            className='text-primary me-2'
                          />
                          <span>{v.blNumber}</span>
                        </div>
                      ))}
                    </div>
                  )} */}

                  {/*
                  {blNumber ? (
                <div className='rounded border bg-white w-100 odex-position-absolute odex-registration-address d-flex flex-column justify-content-start align-items-start'>
                  {blNumbersuggestion.map((v: any) => (
                    <div
                      className='p-2 d-flex flex-row justify-content-start align-items-center fs--1 cursor-pointer'
                      key={v.placeId}
                      onClick={() => {
                        setBlNumber(v.text);
                      }}>
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        className='text-primary me-2'
                      />
                      <span>{v.text}</span>
                    </div>
                  ))}
                </div>
              ) : (
                ''
              )} */}
                </div>
              )}
              {/* Container Number */}
              {profileDetails?.opcPartnerType === "CFA" &&
                bookingOption != 8 &&
                bookingOption !== 7 && (
                  <div className="col-lg-6 mb-3 odex-position-relative align-items-start justify-content-center">
                    <Form.Group controlId="carrierHaulage.containerNumber">
                      <Form.Label className="fs--1">
                        {t("Container Number")}
                      </Form.Label>
                      <Controller
                        name="containerNumber"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <>
                            <Form.Control
                              className={`fs--1 form-control form-control-sm ${errors.containerNumber ? "is-invalid" : ""
                                }`}
                              {...field}
                              value={containerNumber}
                              /*     onChange={({ target }) =>
                                setContainerNumber(target.value)*/
                              // onChange={(e) => {
                              //   field.onChange(e); // Update the form state
                              //   setContainerNumber(e.target.value); // Update your local state
                              // }}
                              // onBlur={(e) => {
                              //   handleContainerNumberBlur(e.target.value);
                              // }}
                              ///onBlur={handleContainerNumberBlur}
                              onChange={(e) => {
                                field.onChange(e); // Update the form state
                                setContainerNumber(e.target.value); // Update your local state
                                const value = e.target.value;
                                setContainerNumber(value);
                                if (!value.trim()) {
                                  setError("containerNumber", {
                                    type: "manual",
                                    message: "Container Number is required",
                                  });
                                } else {
                                  clearErrors("containerNumber");
                                }
                              }}
                              onBlur={(e) => {
                                const containerNumber = e.target.value.trim();
                                if (!containerNumber) {
                                  setError("containerNumber", {
                                    type: "manual",
                                    message: "Container Number is required",
                                  });
                                  return;
                                } else if (!vessel) {
                                  setError("containerNumber", {
                                    type: "manual",
                                    message: "Please select a vessel first",
                                  });
                                } else if (!blNumber) {
                                  setError("containerNumber", {
                                    type: "manual",
                                    message:
                                      "Please select a BL/SO Number first",
                                  });
                                } else {
                                  handleContainerNumberBlur(e.target.value);
                                }
                              }}
                              disabled={showCancel}
                            />
                            {errors.containerNumber && (
                              <div className="invalid-feedback">
                                {errors.containerNumber?.message?.toString()}
                              </div>
                            )}
                          </>
                        )}
                      />
                    </Form.Group>
                  </div>
                )}
              {/* Total Containers */}
              {bookingOption != 8 && (
                <div className="col-lg-6 mb-3">
                  <Form.Group controlId="totalContainers">
                    <Form.Label className="fs--1">
                      {t("resource_2:totalContainers")}
                    </Form.Label>
                    <Form.Control
                      className="fs--1 form-control"
                      {...register("totalContainers")}
                      type="text"
                      value={totalContainers}
                      // onChange={(e) => setTotalContainers(e.target.value)}
                      disabled={true}
                    />
                  </Form.Group>
                </div>
              )}
              {/* Representative Name */}
              {bookingOption == 6 && (
                <div className="col-lg-6 mb-3">
                  <Form.Group controlId="representativeName">
                    <span className="mandatory">*</span>
                    <Form.Label className="fs--1">
                      {t("resource_2:representativeName")}
                    </Form.Label>

                    <Form.Control
                      className={`fs--1 form-control ${errors.representativeName ? "is-invalid" : ""
                        }`}
                      {...register("representativeName")}
                      type="text"
                      value={representativeName}
                      onChange={async (e) => {
                        setRepresentativeName(e.target.value);
                        clearErrors("representativeName");
                        if (e.target.value === "") {
                          await trigger("representativeName");
                        }
                      }}
                      disabled={disableFields || showCancel}
                    // isInvalid={!!errors.representativeName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.representativeName?.message?.toString()}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              )}
              {/* Representative ID */}
              {bookingOption == 6 && (
                <div className="col-lg-6 mb-3">
                  <Form.Group controlId="representativeId">
                    <span className="mandatory">*</span>
                    <Form.Label className="fs--1">
                      {t("resource_2:representativeId")}
                    </Form.Label>
                    <Form.Control
                      className="fs--1 form-control"
                      {...register("representativeId")}
                      type="text"
                      value={representativeId}
                      onChange={async (e) => {
                        setRepresentativeId(e.target.value);
                        clearErrors("representativeId");
                        if (e.target.value === "") {
                          await trigger("representativeId");
                        }
                      }}
                      disabled={disableFields || showCancel}
                      isInvalid={!!errors.representativeId}
                    />
                    <div className="invalid-feedback">
                      {errors.representativeId?.message?.toString()}
                    </div>
                  </Form.Group>
                </div>
              )}
              {/* Representative Name */}
              {[7, 8].includes(bookingOption ?? 0) && (
                <div className="col-lg-6 mb-3">
                  <Form.Group controlId="representativeName">
                    <span className="mandatory">*</span>
                    <Form.Label className="fs--1">
                      {t("resource_2:representativeName")}
                    </Form.Label>

                    <Form.Control
                      className={`fs--1 form-control ${errors.representativeName ? "is-invalid" : ""
                        }`}
                      {...register("representativeName")}
                      type="text"
                      value={representativeName}
                      onChange={async (e) => {
                        setRepresentativeName(e.target.value);
                        clearErrors("representativeName");
                        if (e.target.value === "") {
                          await trigger("representativeName");
                        }
                      }}
                      disabled={disableFields || showCancel}
                    // isInvalid={!!errors.representativeName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.representativeName?.message?.toString()}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              )}
              {/* Representative ID */}
              {[7, 8].includes(bookingOption ?? 0) && (
                <div className="col-lg-6 mb-3">
                  <Form.Group controlId="representativeId">
                    <span className="mandatory">*</span>
                    <Form.Label className="fs--1">
                      {t("resource_2:representativeId")}
                    </Form.Label>
                    <Form.Control
                      className="fs--1 form-control"
                      {...register("representativeId")}
                      type="text"
                      value={representativeId}
                      onChange={async (e) => {
                        setRepresentativeId(e.target.value);
                        clearErrors("representativeId");
                        if (e.target.value === "") {
                          await trigger("representativeId");
                        }
                      }}
                      disabled={disableFields || showCancel}
                      isInvalid={!!errors.representativeId}
                    />
                    <div className="invalid-feedback">
                      {errors.representativeId?.message?.toString()}
                    </div>
                  </Form.Group>
                </div>
              )}
              {/* Driver License */}
              {bookingOption == 6 &&
                permitType != "Export Delivery" &&
                DriverLicenceListNameSelected == null &&
                bookingOption === 6 && (
                  <div className="col-lg-6 mb-3 odex-position-relative align-items-start justify-content-center">
                    <Form.Group controlId="driverLicense">
                      {permitType != "Export Delivery" && (
                        <span className="mandatory">*</span>
                      )}
                      <Form.Label className="fs--1">
                        {t("Driver License")}
                      </Form.Label>
                      {!showCancel && (
                        <div>
                          <Form.Control
                            disabled={licenseValidation}
                            size="sm"
                            {...register("driverLicense")}
                            className={`fs--1 form-control form-control-sm ${errors.driverLicense ? "is-invalid" : ""
                              }`}
                            type="text"
                            placeholder={
                              showCancel
                                ? ""
                                : "Enter Pickup Driver License Number"
                            }
                            onChange={(e) => setDriverLicense(e.target.value)}
                            onBlur={(e) => {
                              handleDriverLicenceAndName(e);
                              setValue("driverLicense", e.target.value, {
                                shouldValidate:
                                  bookingOption === 6 ? true : false,
                              });
                            }}
                            value={driverLicense}
                          />
                          <div className="invalid-feedback">
                            {errors.driverLicense?.message?.toString()}
                          </div>
                        </div>
                      )}
                      {showCancel && (
                        <div>
                          <Form.Control
                            disabled={true}
                            value={driverLicense}
                            size="sm"
                            type="text"
                            maxLength={20}
                          />
                        </div>
                      )}
                    </Form.Group>
                  </div>
                )}
              {/*     {profileDetails?.opcPartnerType === 'CFA' &&
                DriverLicenceListNameSelected != null && (
                  <div className='col-lg-6 mb-3'>
                    <Form.Group>
                      <span className='mandatory'>*</span>
                      <Form.Label className='fs--1'>
                        {t('Driver Name')}
                      </Form.Label>
                      <Form.Control
                        className='fs--1 form-control'
                        type='text'
               
                        disabled={true}
                      />
                    </Form.Group>
                  </div>
                )}
 */}
              {/* Driver Name */}
              {
                permitType != "Export Delivery" &&
                DriverNameListLicenceSelected == null &&
                bookingOption === 6 && (
                  <div className="col-lg-6 mb-3 odex-position-relative align-items-start justify-content-center">
                    <Form.Group controlId="driverName">
                      {permitType != "Export Delivery" && (
                        <span className="mandatory">*</span>
                      )}
                      <Form.Label className="fs--1">
                        {t("Driver Name")}
                      </Form.Label>
                      {!showCancel && (
                        <div>
                          <Form.Control
                            disabled={nameValidation}
                            size="sm"
                            {...register("driverName")}
                            className={`fs--1 form-control form-control-sm ${errors.driverName ? "is-invalid" : ""
                              }`}
                            type="text"
                            placeholder={
                              showCancel ? "" : "Enter pickup driver name"
                            }
                            onBlur={(e) => {
                              handleDriverName(e);
                              setValue("driverName", e.target.value, {
                                shouldValidate: true,
                              });
                            }}
                            defaultValue={getValues("driverName")}
                          />
                          <div className="invalid-feedback">
                            {errors.driverName?.message?.toString()}
                          </div>
                        </div>
                      )}
                      {showCancel && (
                        <div>
                          <Form.Control
                            disabled={true}
                            value={getValues("driverName")}
                            size="sm"
                            type="text"
                            maxLength={20}
                          />
                        </div>
                      )}
                    </Form.Group>
                  </div>
                )}
              {/*      {profileDetails?.opcPartnerType === 'CFA' &&
                DriverNameListLicenceSelected != null && (
                  <div className='col-lg-6 mb-3'>
                    <Form.Group>
                      <span className='mandatory'>*</span>
                      <Form.Label className='fs--1'>
                        {t('Driver Name')}
                      </Form.Label>
                      <Form.Control
                        className='fs--1 form-control'
                        type='text'
                        value={DriverNameListLicenceSelected}
                        // onChange={(e) => setRepresentativeId(e.target.value)}
                        disabled={true}
                      />
                    </Form.Group>
                  </div>
                )} */}
              {/* Truck Horse */}
              {
                permitType != "Export Delivery" &&
                bookingOption === 6 && (
                  <div className="col-lg-6 mb-3">
                    <Form.Group controlId="truckHorse">
                      {permitType != "Export Delivery" && (
                        <span className="mandatory">*</span>
                      )}
                      <Form.Label className="fs--1">
                        {t("Truck Horse")}
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        {...register("truckHorse")}
                        className={`fs--1 form-control ${errors.truckHorse ? "is-invalid" : ""
                          }`}
                        type="text"
                        placeholder={
                          showCancel ? "" : "Enter Truck Horse Plate Number"
                        }
                        value={truckHorse}
                        onBlur={(e) => {
                          handleTruckHorseValidation(e);
                          setValue("truckHorse", e.target.value, {
                            shouldValidate: bookingOption === 6 ? true : false,
                          });
                        }}
                        onChange={(e) => {
                          setTruckHorse(e.target.value);
                        }}
                        /*   className={`fs--1 form-control ${
                          errors.truckHorse ? 'is-invalid' : ''
                        }`} */
                        disabled={showCancel}
                      />
                      {/*     {errors.truckHorse && ( */}
                      <div className="invalid-feedback">
                        {errors.truckHorse?.message?.toString()}
                      </div>
                      {/*      )} */}
                      {/*     <div className='invalid-feedback'>
                        {errors.truckHorse?.message?.toString()}
                      </div> */}
                    </Form.Group>
                  </div>
                )}
              {/* Truck Trailer */}
              {
                permitType != "Export Delivery" &&
                bookingOption === 6 && (
                  <div className="col-lg-6 mb-3">
                    <Form.Group
                      style={{ padding: "0px" }}
                      controlId="truckTrailer"
                    >
                      <Form.Label className="fs--1">
                        {t("Truck Trailer")}
                      </Form.Label>
                      {!showCancel && (
                        <div>
                          {/*    <Controller
                            name='truckTrailer'
                            control={control}
                            rules={{ required: 'This field is required' }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                options={TruckingTrailerList}
                                isDisabled={showTruckingCompanydata}
                                  onChange={(e: any) => {
                                  field.onChange(e);
                                  setTruckTrailer(e.value);
                                }}
                                styles={customDropdownStyles}
                                className={`fs--1 form-control form-control-sm ${
                                  errors.truckTrailer ? 'is-invalid' : ''
                                }`}
                              />
                            )}

                          /> */}
                          <Form.Control
                            size="sm"
                            className="fs--1 form-control"
                            {...register("truckTrailer")}
                            //     {...register('truckTrailer')}
                            // className={`fs--1 form-control ${
                            //   errors.truckHorse ? 'is-invalid' : ''
                            // }`}
                            type="text"
                            placeholder={
                              showCancel ? "" : "Enter Truck Trailer Number"
                            }
                            value={truckTrailer}
                            onChange={(e) => setTruckTrailer(e.target.value)}
                            disabled={showCancel}
                          />
                          {/*  <div className='invalid-feedback'>
                            {errors.truckTrailer?.message?.toString()}
                          </div> */}
                        </div>
                      )}
                      {showCancel && (
                        <div>
                          <Form.Control
                            disabled={true}
                            value={truckTrailer}
                            size="sm"
                            type="text"
                            maxLength={20}
                          />
                        </div>
                      )}

                      {/*       {showdata && showCancel && (
                        <div>
                          <Form.Control
                            disabled={showTruckingCompanydata}
                            size='sm'
                            {...register('truckingCompany')}
                            className={`fs--1 form-control form-control-sm ${
                              errors.truckTrailer ? 'is-invalid' : ''
                            }`}
                            type='text'
                            maxLength={20}
                          />
                        </div>
                      )} */}
                    </Form.Group>
                  </div>
                )}
              {/* Trucking Company */}
              {
                permitType != "Export Delivery" &&
                bookingOption === 6 && (
                  <div className="col-lg-6 mb-3">
                    <Form.Group
                      style={{ padding: "0px" }}
                      controlId="truckingCompany"
                    >
                      {permitType != "Export Delivery" && (
                        <span className="mandatory">*</span>
                      )}
                      <Form.Label className="fs--1">
                        {t("Trucking Company")}
                      </Form.Label>
                      {!showCancel && (
                        <div>
                          <Controller
                            name="truckingCompany"
                            control={control}
                            rules={{ required: "This field is required" }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                options={TruckingCompanyList}
                                noOptionsMessage={() => null}
                                maxMenuHeight={150}
                                isDisabled={showTruckingCompanydata}
                                isClearable={true}
                                /*    onChange={(e) => {
                                  // setselectedTruckingCompany(e.value);
                                  setTruckingCompany(e.value);
                                }} */

                                onChange={(e: any) => {
                                  field.onChange(e);
                                  //  fetchTruckingCompanySuggestions(e.value);
                                  setTruckingCompany(e.value);
                                }}
                                onBlur={() => {
                                  if (truckingCompany) {
                                    dispatch(
                                      fetchTruckingCompanySuggestions(
                                        truckingCompany
                                      )
                                    ).then((response: any) => {
                                      response.payload.map((company: any) => {
                                        if (
                                          company.companyName ===
                                          truckingCompany
                                        ) {
                                          setCompanyId(company.companyId);
                                        }
                                      });
                                    });
                                  }
                                }}
                                filterOption={customFilterOption}
                                onInputChange={handleInputChangeTruckingCompany}
                                styles={customDropdownStyles}
                                className={`fs--1 form-control form-control-sm ${errors.truckingCompany ? "is-invalid" : ""
                                  }`}
                              />
                            )}
                          />
                          <div className="invalid-feedback">
                            {errors.truckingCompany?.message?.toString()}
                          </div>
                        </div>
                      )}
                      {showCancel && (
                        <div>
                          <Form.Control
                            disabled={true}
                            value={truckingCompany}
                            size="sm"
                            {...register("truckingCompany")}
                            className={`fs--1 form-control form-control-sm ${errors.vesselVoyage ? "is-invalid" : ""
                              }`}
                            type="text"
                            maxLength={20}
                          />
                        </div>
                      )}

                      {/* {showCancel && (
                        <div>
                          <Form.Control
                            disabled={showTruckingCompanydata}
                            size='sm'
                            {...register('truckingCompany')}
                            className={`fs--1 form-control form-control-sm ${
                              errors.truckingCompany ? 'is-invalid' : ''
                            }`}
                            type='text'
                            maxLength={20}
                          />
                        </div>
                      )} */}
                    </Form.Group>
                  </div>
                )}
              {/* Remarks */}
              <div className="col-lg-6 mb-3">
                <Form.Group controlId="remarks">
                  <Form.Label className="fs--1">
                    {t("resource_1:remarks")}
                  </Form.Label>
                  <Form.Control
                    className="fs--1 form-control"
                    {...register("remarks")}
                    as="textarea"
                    rows={3}
                    value={remarks}
                    //   onChange={(e) => setRemarks(e.target.value)}
                    onChange={(e) => {
                      const value = e.target.value;

                      // Trim the value to 250 characters and update the state
                      setRemarks(value.substring(0, 250));
                    }}
                    disabled={disableFields || showCancel}
                  />
                </Form.Group>
                {showCancel && (
                  <div className="mt-2">
                    {/*   <a
                      href='#'
                      className='fs--1'
                      onClick={handleDownload}
                      style={{ fontStyle: 'italic' }}
                    >
                      Download Permit Request
                    </a> */}
                  </div>
                )}
              </div>
              {/* {profileDetails?.opcPartnerType === 'CFA' && */}
              {bookingOption == 8 && showCancel && (
                <p style={{ color: "#5E6E82", marginBottom: "0.5rem" }}>
                  Permit Document Number
                </p>
              )}
              {bookingOption != 8 && (
                <DataTable
                  data={filteredcontainerDetails || []}
                  gridName={gridName}
                  columns={columns}
                  sortable={false}
                  searchable={false}
                  showColumnFilter={false}
                  selection={false}
                  perPage={100}
                  pagination={false}
                  showExport={false}
                  uniqueFilterKey="permit"
                  autoHeight={true}
                  staticHiddenCols={["fileName"]}
                />
              )}

              {bookingOption == 8 && showCancel && (
                <DataTable
                  data={filteredcontainerDetails || []}
                  gridName={gridName}
                  columns={bestPickCol}
                  sortable={false}
                  searchable={false}
                  showColumnFilter={false}
                  selection={false}
                  perPage={100}
                  pagination={false}
                  showExport={false}
                  uniqueFilterKey="permit"
                  autoHeight={true}
                  staticHiddenCols={["fileName"]}
                />
              )}

              {/* Document Attachment List */}
              {/* <div className='col-lg-12 mb-3'>
 <Form.Group controlId='carrierHaulage.documents'>
 <Form.Label className='fs--1'>
 {t('resource_3:documentAttachmentList')}
 </Form.Label>
 <Form.File
 {...register('documents')}
 multiple
 accept='application/pdf'
 onChange={(e: any) => handleFileChange(e.target.files)}
 disabled={disableFields}
 />
 </Form.Group>
 </div> */}
            </>
          </div>
          {/*    {bookingOption &&
            profileDetails?.opcPartnerType === 'CFA' &&
            !showCancel && (
              <div className='col-lg-12 mt-2 mb-2 demmurageTable'>
                <span className='badge bg-secondary mb-2'>
                  {t('Only PDF documents are allowed. (per file below 10MB)')}{' '}
                </span>
                <div className='col-lg-6 mb-3'>
                  <DataTable
                    data={docAttachment}
                    gridName='RELEASE_ATTACHMENT'
                    columns={docAttachmentColumns}
                    sortable={false}
                    searchable={false}
                    showColumnFilter={false}
                    selection={false}
                    perPage={10}
                    pagination={false}
                    showExport={false}
                    uniqueFilterKey='releaseAttachment'
                    autoHeight={true}
                    staticHiddenCols={['dndInstructionAttId', 'fileName']}
                  />
                </div>
              </div>
            )} */}
          {bookingOption &&
            // vessel &&
            // blNumber &&
            // representativeName &&
            // representativeId &&
            !showCancel &&
            location.pathname.split("/")[1] != "instruction" && (
              <div className="d-flex justify-content-center">
                <Button
                  className="me-2"
                  variant="falcon-primary"
                  size="sm"
                  type="submit"
                  // disabled={bookingOption == '' || permitType == ''}
                  disabled={
                    Object.entries(errors).length > 0 ||
                    !permitRefNo_New ||
                    permitRefNo_New == "" ||
                    !bookingOption ||
                    requestedDate == null ||
                    permitType === "" ||
                    (bookingOption === 6 &&
                      (!vesselVoyage ||
                        !blNumber ||
                        !representativeName ||
                        !representativeId ||
                        (permitType == "Export Delivery" &&
                          profileDetails?.opcPartnerType === "CFA"
                          ? false
                          : !driverLicense) ||
                        (permitType == "Export Delivery" &&
                          profileDetails?.opcPartnerType === "CFA"
                          ? false
                          : !getValues("driverName")) ||
                        (permitType == "Export Delivery" &&
                          profileDetails?.opcPartnerType === "CFA"
                          ? false
                          : !truckHorse))) ||
                    (bookingOption === 8 &&
                      (!vesselVoyage ||
                        !representativeId ||
                        !representativeName ||
                        !selectedICDcode ||
                        !selectedBestPickGrpId)) ||
                    (bookingOption === 7 &&
                      profileDetails.opcPartnerType !== "ICD" &&
                      (!vesselVoyage ||
                        !blNumber ||
                        !representativeName ||
                        !representativeId)) ||
                    (profileDetails.opcPartnerType == "ICD" &&
                      bookingOption != 8 &&
                      (!vesselVoyage ||
                        !blNumber ||
                        !representativeName ||
                        !representativeId))
                  }
                  onClick={handleSubmit(onSubmit)}
                >
                  <FontAwesomeIcon icon={faSave} className="me-2" />
                  {t("resource_1:submit")}
                </Button>
                <Button
                  className="me-2"
                  variant="falcon-danger"
                  size="sm"
                  onClick={resetForm}
                  style={{ zIndex: 1 }}
                >
                  <FontAwesomeIcon icon={faRedo} className="me-2" />
                  {/* {t('resource_1:reset')} */}
                  {t("Reset")}
                </Button>
              </div>
            )}
          {profileDetails.opcPartnerType != 'PTO' && showCancel && ![307, 309].includes(+status) && (
            <div className="d-flex justify-content-center">
              <Button
                className="me-2"
                variant="falcon-danger"
                size="sm"
                // type='submit'
                onClick={() => setshowCancelPermit(true)}
              >
                <FontAwesomeIcon icon={faCancel} className="me-2" />
                {t("resource_2:cancel")}
              </Button>
            </div>
          )}
          <Modal
            show={showCancelPermit}
            onHide={() => setshowCancelPermit(false)}
          >
            <Modal.Header className="p-2 pb-1" closeButton>
              <Modal.Title className="h6">
                Do you want to Cancel the Permit Request?
              </Modal.Title>
            </Modal.Header>

            <div className="d-flex justify-content-center align-items-center p-2">
              <Button
                variant="falcon-primary"
                size="sm"
                className="me-2"
                onClick={() => {
                  setshowCancelPermit(false);

                  if (requesttp && invoice_request_id) {
                    dispatch(
                      cancelPermitRequest({
                        request_id: AESDecrypt(invoice_request_id),
                        tpoId: tpoId,
                      })
                    )
                      .then((response: any) => {
                        if (response.payload.data.responseCode == "SUCCESS") {
                          toast.success(response.payload.data.responseMsg, {
                            theme: "colored",
                            toastId: "cancelPermitRequestToast"
                          });
                        } else {
                          toast.error("Something Went Wrong", { toastId: "cancelPermitRequestToast" });
                        }

                        resetForm();

                        setTimeout(() => {
                          onBack();
                        }, 500);
                      })
                      .catch(() => { });
                  }
                }}
              // disabled={inputValue.length > 0 ? false : true}
              >
                Yes
              </Button>
              <Button
                className="me-2"
                variant="falcon-danger"
                size="sm"
                onClick={() => {
                  setshowCancelPermit(false);
                  setLoading(false);
                }}
              >
                No
              </Button>
            </div>
          </Modal>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default PermitRequest;
