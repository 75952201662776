// import SoftBadge from '../../../../components/common/SoftBadge';
// import {
//   faBars,
//   faCreditCard,
//   faPlus,
//   faCheck,
//   faCheckCircle,
//   faClock,
//   faFolder,
//   faSave,
// } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { t } from 'i18next';

// export const getBadges = (instruction: string | null) => {
//   const status = Object.freeze({
//     Requested: t('resource_1:requested').toString(),
//     Pending: 'Pending',
//     cancelled: t('resource_1:cancelled').toString(),
//     exRequested: t('resource_2:exRequested').toString(),
//     pmtInitiated: t('resource_2:pmtInitiated').toString(),
//     submitted: t('resource_1:submitted').toString(),
//     paid: t('resource_1:paid').toString(),
//     received: t('resource_1:received').toString(),
//     completed: t('resource_1:completed').toString(),
//     approved: t('resource_1:approved').toString(),
//     exReleased: t('resource_2:exReleased').toString(),
//     released: t('resource_1:released').toString(),
//     draft: t('resource_1:draft').toString(),
//     docPending: t('resource_2:docPending').toString(),
//     overdue: t('resource_1:overdue').toString(),
//     due: t('resource_1:due').toString(),
//     inWork: t('resource_2:inWork').toString(),
//     open: t('resource_1:open').toString(),
//     rejected: t('resource_1:rejected').toString(),
//     Released: t('resource_1:released').toString(),
//     partial: t('resource_1:partial').toString(),
//     referPortSite: t('resource_1:referportsite').toString(),

//     SELF: t('resource_1:SELF').toString(),
//     UNASSIGNED: t('resource_1:UNASSIGNED').toString(),
//     OTHER: t('resource_1:OTHER').toString(),
//     YES: t('resource_1:yes').toString(),
//     NO: t('resource_1:no').toString(),
//   });
//   /*  if (window.location.pathname.includes('opc/doupload')) {
//     instruction = 'Requested';
//   } */
//   if (
//     instruction?.toUpperCase() === status.Requested.toUpperCase() || //changed
//     instruction?.toUpperCase() === status.exRequested.toUpperCase() ||
//     instruction?.toUpperCase() === status.Pending.toUpperCase() ||
//     instruction?.toUpperCase() === status.pmtInitiated.toUpperCase() ||
//     instruction?.toUpperCase() === status.submitted.toUpperCase() ||
//     instruction?.toUpperCase() === status.partial.toUpperCase()
//   ) {
//     return (
//       <SoftBadge pill bg='primary' className='me-2'>
//         {instruction?.toUpperCase()}
//         {/* &nbsp; <FontAwesomeIcon icon={faPlus} /> */}
//       </SoftBadge>
//     );
//   } else if (
//     instruction?.toUpperCase() === status.completed.toUpperCase() ||
//     instruction?.toUpperCase() === status.referPortSite.toUpperCase() ||
//     instruction?.toUpperCase() === status.released.toUpperCase()
//   ) {
//     return (
//       <SoftBadge pill bg='success' className='me-2'>
//         {instruction?.toUpperCase()}
//         {/* &nbsp; <FontAwesomeIcon icon={faCheck} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction?.toUpperCase() === status.approved.toUpperCase()) {
//     return (
//       <SoftBadge pill bg='success' className='me-2'>
//         {instruction?.toUpperCase()}
//         {/* &nbsp; <FontAwesomeIcon icon={faCheck} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction?.toUpperCase() === status.rejected.toUpperCase()) {
//     return (
//       <SoftBadge pill bg='danger' className='me-2'>
//         {instruction?.toUpperCase()}
//         {/* &nbsp; <FontAwesomeIcon icon={faCheck} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction?.toUpperCase() === status.cancelled.toUpperCase()) {
//     return (
//       <SoftBadge pill bg='primary' className='me-2'>
//         {instruction?.toUpperCase()}
//         {/* &nbsp; <FontAwesomeIcon icon={faCheck} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction === status.paid.toUpperCase()) {
//     return (
//       <SoftBadge pill bg='dark' className='me-2 text-blue-1'>
//         {instruction}
//         {/* &nbsp; <FontAwesomeIcon icon={faCreditCard} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction === status.received) {
//     return (
//       <SoftBadge pill bg='dark' className='me-2 text-green-1'>
//         {instruction}
//         {/* &nbsp; <FontAwesomeIcon icon={faFolder} /> */}
//       </SoftBadge>
//     );
//   } else if (
//     instruction === status.released ||
//     instruction === status.exReleased
//   ) {
//     return (
//       <SoftBadge pill bg='dark' className='me-2 text-green-1'>
//         {instruction}
//         {/* &nbsp; <FontAwesomeIcon icon={faCheckCircle} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction === status.docPending) {
//     return (
//       <SoftBadge pill bg='warning' className='me-2'>
//         {instruction}
//         {/* &nbsp; <FontAwesomeIcon icon={faBars} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction === 'Draft') {
//     return (
//       <SoftBadge pill bg='warning' className='me-2'>
//         {'Not Submitted'}
//         {/* &nbsp; <FontAwesomeIcon icon={faBars} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction === status.inWork) {
//     return (
//       <SoftBadge pill bg='warning' className='me-2'>
//         {instruction}
//         {/* &nbsp; <FontAwesomeIcon icon={faSave} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction === status.overdue) {
//     return (
//       <SoftBadge pill bg='warning' className='me-2'>
//         {instruction}
//         {/* &nbsp; <FontAwesomeIcon icon={faClock} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction === status.overdue) {
//     return (
//       <SoftBadge pill bg='danger' className='me-2'>
//         {instruction}
//         {/* &nbsp; <FontAwesomeIcon icon={faClock} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction === status.due) {
//     return (
//       <SoftBadge pill bg='dark' className='me-2 text-green-1'>
//         {instruction}
//         {/* &nbsp; <FontAwesomeIcon icon={faClock} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction === status.open) {
//     return (
//       <SoftBadge pill bg='primary' className='me-2 text-green-1'>
//         {instruction}
//         {/* &nbsp; <FontAwesomeIcon icon={faPlus} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction?.toUpperCase() === status.SELF.toUpperCase()) {
//     return (
//       <SoftBadge pill bg='success' className='me-2'>
//         {instruction?.toUpperCase()}
//         {/* &nbsp; <FontAwesomeIcon icon={faCheck} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction?.toUpperCase() === status.UNASSIGNED.toUpperCase()) {
//     return (
//       <SoftBadge pill bg='dark' className='me-2 text-green-1'>
//         {instruction?.toUpperCase()}
//         {/* &nbsp; <FontAwesomeIcon icon={faCheck} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction?.toUpperCase() === status.OTHER.toUpperCase()) {
//     return (
//       <SoftBadge pill bg='primary' className='me-2'>
//         {instruction?.toUpperCase()}
//         {/* &nbsp; <FontAwesomeIcon icon={faCheck} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction?.toUpperCase() === status.YES.toUpperCase()) {
//     return (
//       <SoftBadge pill bg='dark' className='me-2 text-green-1'>
//         {instruction?.toUpperCase()}
//         {/* &nbsp; <FontAwesomeIcon icon={faCheck} /> */}
//       </SoftBadge>
//     );
//   } else if (instruction?.toUpperCase() === status.NO.toUpperCase()) {
//     return (
//       <SoftBadge pill bg='primary' className='me-2'>
//         {instruction?.toUpperCase()}
//         {/* &nbsp; <FontAwesomeIcon icon={faCheck} /> */}
//       </SoftBadge>
//     );
//   } else {
//     return '';
//   }
// };

import SoftBadge from '../../../../components/common/SoftBadge';
import { t } from 'i18next';

export const getBadges = (instruction: string | null) => {
  const styleMap: any = {
    REQUESTED: 1,
    CANCELLED: 1,
    "EX REQUESTED": 1,
    "PMT INITIATED": 1,
    SUBMITTED: 1,
    PENDING: 1,
    PARTIAL: 1,
    NO: 1,
    OTHER: 1,
    COMPLETED: 2,
    APPROVED: 2,
    "AVAILABLE AT TERMINAL WEBSITE": 2,
    SELF: 2,
    REJECTED: 3,
    PAID: 4,
    RECEIVED: 5,
    UNASSIGNED: 5,
    YES: 5,
    "EX RELEASED": 5,
    RELEASED: 5,
    DUE: 5,
    "Not Submitted": 6,
    "DOC. PENDING": 6,
    OVERDUE: 6,
    "IN WORK": 6,
    OPEN: 7,
    "PARTIAL APPROVED": 5,
    "CONFIRMED":2,
    INVOICED: 2,
    DRAFT:6
  };

  const getStyle = (style: number) => {
    switch (style) {
      case 1:
        return { pill: true, bg: 'primary', className: 'me-2' };
      case 2:
        return { pill: true, bg: 'success', className: 'me-2' };
      case 3:
        return { pill: true, bg: 'danger', className: 'me-2' };
      case 4:
        return { pill: true, bg: 'dark', className: 'me-2 text-blue-1' };
      case 5:
        return { pill: true, bg: 'dark', className: 'me-2 text-green-1' };
      case 6:
        return { pill: true, bg: 'warning', className: 'me-2' };
      case 7:
        return { pill: true, bg: 'primary', className: 'me-2 text-green-1' };
      default:
        return { pill: true, bg: 'primary', className: 'me-2' };
    }
  };

  const style = getStyle(styleMap[instruction?.toUpperCase() ?? '']);

  if (style) {
    return (
      <SoftBadge {...style}>
        {instruction === 'Draft' ? 'Not Submitted' : instruction?.toUpperCase()}
      </SoftBadge>
    );
  } else {
    return '';
  }
};
