import { Form, InputGroup, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import Logo from '../../../components/common/Logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faCheck,
  faLocation,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Divider from '../../../components/common/Divider';
import { ChangeEvent, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import {
  addRegistrationDetails,
  addRegistrationDetailsCompany,
  approveRejectRegistration,
  fetchAddressSuggestions,
  fetchCompanyDetailsForVerification,
  fetchLinerNameByCountry,
  getCountryList,
  getRegistrationDetails,
  registerCustomerAndUser,
} from './actions/Registration';
import { useSelector } from 'react-redux';
import { updateSelectedAddressResult } from './registraionSlice';
import { Value } from 'sass';

import { useAsyncDebounce } from 'react-table';
import Section from '../../../components/common/Section';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { Modal } from 'react-bootstrap';
import TermsAndConditions from './termsAndCondition';
import PrivacyPolicy from './privacyPolicy';
import { toast } from 'react-toastify';
import { Item } from 'react-bootstrap/lib/Breadcrumb';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import { updateRegistrationMaster } from '../registrationMaster/slices/dashboardSlice';
import { AESDecrypt } from '../../../encrypt-util';
import {
  getGroupList,
  getGroupListRegistrationMaster,
} from '../createUser/action/UserDetails';
import { useLoading } from '../../../middleware/loadingMiddleware';
const Registration = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const data = useSelector((state: RootState) => state.registration.data);
  const companyName1 = useSelector(
    (state: RootState) => state.registration.companyName
  );
  const address1 = useSelector(
    (state: RootState) => state.registration.address
  );
  const dataCompany = useSelector(
    (state: RootState) => state.registrationCompany.data
  );
  const registrationMasterDisable = useSelector(
    (state: RootState) => state.registrationDashboard.registrationMasterDisable
  );

  const registrationdata = useSelector(
    (state: RootState) => state.registration
  );
  const countryOptions = useSelector(
    (state: RootState) => state.registration.countryOptions
  );
  const [country, setRegCountry] = useState('');
  const linerOptions = useSelector(
    (state: RootState) => state.registration.linerOptions
  );
  const companyDetails = useSelector(
    (state: RootState) => state.registrationCompany.companyDetails
  );

  const dispatch = useDispatch<AppDispatch>();
  const [companyName, setCompanyName] = useState(companyName1 || '');
  const [groupList, setGroupList] = useState([]);
  const [registrationDetails, setRegistrationDetails] = useState({
    countryCode: '',
    customerTaxId: '',
    customerName: '',
    customerAddress: '',
    userName: '',
    email: '',
    contactNo: '',
    language: '',
    customerMapping: [
      {
        linerId: '',
        mapCode: '',
      },
    ],
    isTNCAccepted: '',
    mstGrp: [],
    requestId: '',
    mstUsrId: '',
    remarks: '',
    status: 0,
  });
  const [address, setAddress] = useState(address1 || '');
  const [userName, setuserName] = useState('');
  const [remarks, setremarks] = useState('');
  const [selectedOptions, setSelectedOptions] = useState<any>([]);

  const [phonenumber, setphonenumber] = useState('');
  const [taxid, setTaxid] = useState('');
  const [liner, setLiner] = useState('');
  const [customerCode, setCustomerCode] = useState('');
  const linerListLoading = useLoading(['registration/fetchLinerName']);

  const encryptrequestId = location.pathname.split('/')[2];
  const decrptrequestId = AESDecrypt(encryptrequestId);
  var bodyFormData = new FormData();
  bodyFormData.append('company_number', taxid);
  bodyFormData.append('country_code', 'US');
  bodyFormData.append('source', '2');
  bodyFormData.append('request_id', '0001');
  bodyFormData.append('state', 'NJ');

  bodyFormData.append('company_name', companyName);
  useEffect(() => {
    dispatch(getCountryList(1))
      .unwrap()
      .then((data) => {
        handleCountryChange(data?.[0]?.countryName);
      });
  }, []);

  useEffect(() => {
    if (country)
      dispatch(
        fetchLinerNameByCountry({
          country_code: country.substring(0, 2),
        })
      )
        .unwrap()
        .then((data: any) => {
          setLiner(data?.[0]?.value);
          setValue('liner', data?.[0]?.value);
        });
  }, [country]);

  useEffect(() => {
    if (location.pathname.includes('/registrationmaster'))
      dispatch(updateRegistrationMaster(true));
  }, []);
  useEffect(() => {
    if (taxid != '') {
      setCompanyName(companyName1);
      setAddress(address1);
    } else {
      setCompanyName('');
      setAddress('');
    }
  }, [companyName1, address1]); // set the relation between redux campaign and local state

  const handleCheckboxChange = (label: string, checked: boolean, id: any) => {
    if (checked) {
      setSelectedOptions([
        ...selectedOptions,
        { mstGrpId: id, usrGrpNm: label },
      ]);
    } else {
      setSelectedOptions(
        selectedOptions.filter((option: any) => option.mstGrpId !== id)
      );
    }
  };
  /* useEffect(() => {
    if (taxid.length > 1) {
      alert('TEst' + country);
      dispatch(
        fetchCompanyDetailsForVerification({
          //company_name: 'HAPAG-LLOYD (AMERICA) LLC',
          // company_name: companyName || null,
          // state: 'NJ',
          // source: '2',
          // request_id: '0001',
          company_number: taxid || null,
          country_code: country.substring(0, 2),
        })
      );
    }
  }, [taxid]);*/
  /*  useEffect(() => {
    if (companyName.length > 1) {
      dispatch(
        fetchCompanyDetailsForVerification({
          company_name: companyName || null,
          source: '2',
          request_id: '0001',
          country_code: 'US',
        })
      );
    }
  }, [companyName, taxid]); */
  /* useEffect(()=>{
  if ( taxid.length > 1 ) {
  dispatch(
    fetchCompanyDetailsForVerification({
      company_name: 'HAPAG-LLOYD (AMERICA) LLC',
      // company_name: companyName || null,
      state: 'NJ',
      source: '2',
      request_id: '0001',
      company_number: taxid || null,
      country_code: 'US',

    })
  );
  }
},[taxid]) */
  /*  useEffect(() => {
    if (companyName.length > 1) {
      dispatch(
        fetchCompanyDetailsForVerification({
          company_name: companyName || null,
          //  state: 'NJ',
          source: '2',
          request_id: '0001',
          // company_number: taxid || null,
          country_code: 'US',
        })
      );
    }
  }, [companyName]); */
  /*   useEffect(() => {
    if (address.length > 0) {
      const registrationDetails = {
        FilterCategories: ['AddressType', 'StreetType', 'IntersectionType'],
        FilterCountries: country,
        Language: 'en',
        MaxResults: 5,
        Text: address,
      };

      dispatch(addRegistrationDetails(registrationDetails));

      data.list.map((item) => setAddress(item.text));
      //companyName.list.map((any) => setCompanyName(Item.text));
    }
  }, [address]);
 */
  type FormValues = {
    country: string;
    //ein: string;
    userName: string;
    taxID: string;
    companyName: string;
    primaryContact: string;
    email: string;
    phoneNumber: string;
    address: string;
    acceptTerms: boolean;
    countryCode: string;
    liner: string;
    customerCode: string;
  };

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required('Company Name is required'),
    // ein: Yup.string().required('EIN is required'),
    userName: Yup.string().required('User Name is required'),
    email: Yup.string().email('Email is invalid').required('Email is required'),
    country: Yup.string().required('Country is required'),
    //primaryContact: Yup.string().required('Primary Contact is required'),
    // phoneNumber: Yup.string()
    //   .required('Phone Number is required')
    //   .matches(
    //     /(\d{0,3})-(\d{0,3})-(\d{4}$)/,
    //     'Invalid phone number format. It should match the pattern "###-###-####"'
    //   ),
    taxID: Yup.string().required(
      t('resource_3:isRequired', { field: t('resource_2:taxId') })
    ),
    /*  .when('country', {
        is: 'USA',
        then: Yup.string().matches(
          /^\d\d-\d{7}$/,
          'Invalid input format. It should match the pattern "DD-#######"'
        ),
      }), */
    address: Yup.string(),
    liner: Yup.string().required('Liner is required'),
    acceptTerms: Yup.boolean().oneOf(
      [true],
      'You need to accept the terms and conditions'
    ),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const onSubmit = handleSubmit(
    async (payload) => {
      try {
        payload.address = address;
        dispatch(
          registerCustomerAndUser({
            ...payload,
          })
        )
          .unwrap()
          .then((response: any) => {
            setResponseCodeValue(response.data.responseCode);
            if (
              response.data.responseCode === 'CREATE' ||
              response.data.responseCode === 'EXIST/CREATE'
            ) {
              reset();
              setRedirectUrl('opc/home');
              setRenderMessage(t('resource_12:registerSuccessfull'));
              handleShowMessage();
              //toast.dark(response.data.responseMsg);
            } else if (response.data.responseCode === 'MANUAL') {
              setRenderMessage(response.data.responseMsg);
              handleShowMessage();
              // Redirect based on environment
              const redirectUrl = process.env.REACT_APP_API_GATEWAY_HOST
                ? process.env.REACT_APP_API_GATEWAY_HOST
                : '';
              setRedirectUrl(redirectUrl);
            } else {
              toast.dark(response.data.responseMsg, {
                toastId: '8a7b41b'
              });
              setRedirectUrl('');
              setRenderMessage(response.data.responseMsg);
              handleShowMessage();
            }
          });
      } catch (err: any) {
        console.error(err);
      }
    },
    (error) => {
      console.error('Handle submit error: ', error);
    }
  );

  const [regPhoneNo, setRegPhoneNo] = useState('+1');
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);

  const registrationAddressSuggesstion = useSelector(
    (state: RootState) => state.registration.registrationAddressSuggesstion
  );
  const isAddressSuggesstionLoading = useSelector(
    (state: RootState) => state.registration.isAddressSuggesstionsLoading
  );

  const selectedAddressResult = useSelector(
    (state: RootState) => state.registration.selectedAddressResult
  );

  const _onAddressChange = useAsyncDebounce((value: string) => {
    dispatch(
      fetchAddressSuggestions({
        text: value,
        country:
          countryOptions.find((i: any) => i.value === country).full ?? 'USA',
      })
    );
  }, 100);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showPolicy, setShowPolicy] = useState(false);

  const handleClosePolicy = () => setShowPolicy(false);
  const handleShowPolicy = () => setShowPolicy(true);
  const [responseCodeValue, setResponseCodeValue] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [renderMessage, setRenderMessage] = useState('');
  const handleCloseMessage = () => {
    if (responseCodeValue == 'MANUAL') {
      setShowMessage(false);
      navigate(`/`);
      // window.location.href = `${process.env.REACT_APP_CLIENT_APP_HOST}`;
    } else if (responseCodeValue == 'CREATE') {
      setShowMessage(false);
      window.location.href = `opc/home`;
    } else {
      if (redirectUrl !== '') {
        setShowMessage(false);
        window.location.href = `opc/home`;
      } else {
        setShowMessage(false);
      }
    }
  };
  const handleShowMessage = () => setShowMessage(true);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [taxIdFormat, setTaxIdFormat] = useState('');
  const [contactNoFormat, setContactNoFormat] = useState('999-999-9999');
  const [placeId, setPlaceId] = useState('');
  const [email, setEmail] = useState('');

  function handleCountryChange(value: string) {
    countryOptions.map((option: any) => {
      if (option.value == value) {
        setTaxIdFormat(option.taxIdFormat);
        setContactNoFormat(option.contactNoFormat);
      }
    });
  }
  useEffect(() => {
    if ((!country || country === '') && countryOptions?.length > 0) {
      setRegCountry(countryOptions[0].value);
    }
  }, [countryOptions]);
  useEffect(() => {
    // window.localStorage.setItem(
    //   '@user-selected-country',
    //   country.substring(0, 2)
    // );
    const userSelectedCountry = [
      {
        loginId: email,
        country: country.substring(0, 2),
      },
    ];
    // window.localStorage.setItem(
    //   '@user-selected-datacountry',
    //   JSON.stringify(userSelectedCountry)
    // );
    i18next.changeLanguage(country.substring(0, 2));
  }, [email, country]);
  useEffect(() => {
    if (decrptrequestId != '') {
      dispatch(
        getRegistrationDetails({
          decrptrequestId,
        })
      )
        .unwrap()
        .then((response: any) => {
          setRegistrationDetails({
            countryCode: response.data.countryCode,
            customerTaxId: response.data.customerTaxId,
            customerName: response.data.customerName,
            customerAddress: response.data.customerAddress,
            userName: response.data.userName,
            email: response.data.email,
            contactNo: response.data.contactNo,
            language: response.data.language,
            customerMapping: response.data.customerMapping,
            isTNCAccepted: response.data.isTNCAccepted,
            mstGrp: response.data.mstGrp,
            requestId: response.data.requestId,
            mstUsrId: response.data.mstUsrId,
            remarks: response.data.remarks,
            status: response.data.status,
          });

          dispatch(getGroupListRegistrationMaster(response.data.countryCode))
            .unwrap()
            .then((response: any) => {
              setGroupList(
                response.map((v: any) => {
                  return { label: v?.usrGrpNm, value: v?.mstGrpId };
                })
              );
            });
        });
    }
  }, [decrptrequestId]);

  return (
    <div>
      {location.pathname.split('/')[1] !== 'registrationmaster' && (
        <h3 className='fs-1'>{t('resource_1:registration')}</h3>
      )}
      {location.pathname.split('/')[1] !== 'registrationmaster' && (
        <Divider></Divider>
      )}

      <div className='h2 text-secondary fs-0 pb-2 border-bottom'>
        {t('resource_2:companyDetails')}
      </div>
      <Form onSubmit={onSubmit} autoComplete='off'>
        <div className='row mt-2'>
          <div className='col-lg-6 mb-2'>
            <Form.Group controlId='registration.country'>
              <Form.Label className='fs--1'>
                <span className='mandatory'>*</span>
                {t('resource_1:country')}
              </Form.Label>

              <Form.Select
                {...register('country')}
                className={`fs--1 form-control ${errors.country ? 'is-invalid' : ''
                  }`}
                value={
                  registrationDetails.countryCode != ''
                    ? registrationDetails.countryCode
                    : country
                }
                disabled={registrationMasterDisable}
                onChange={(e) => {
                  setValue('country', e.target.value);
                  setRegCountry(e.target.value);
                  handleCountryChange(e.target.value);
                }}
              >
                {countryOptions.map((option: any) => (
                  <option key={option.value} value={option.value}>
                    {option.text}
                  </option>
                ))}
                {registrationMasterDisable && (
                  <option value={registrationDetails.countryCode}>
                    {registrationDetails.countryCode != ''
                      ? registrationDetails.countryCode
                      : country}
                  </option>
                )}
              </Form.Select>

              <div className='invalid-feedback'>
                {errors.country?.message?.toString()}
              </div>
            </Form.Group>
          </div>
          <div className='col-lg-6 mb-2'>
            <Form.Group controlId='registration.taxID'>
              <Form.Label className='fs--1'>
                <span className='mandatory'>*</span>
                {t('resource_2:taxId')}
                {/*  {country === 'ZA'
                  ? t('resource_2:vatId')
                  : country === 'SG'
                  ? t('resource_2:CompanyUEN')
                  : country === 'MY'
                  ? t('resource_2:ssmNumber')
                  : t('resource_2:ein')} */}
              </Form.Label>
              <Form.Control
                type='text'
                value={
                  registrationDetails.customerTaxId != ''
                    ? registrationDetails.customerTaxId == null
                      ? ''
                      : registrationDetails.customerTaxId
                    : taxid
                }
                placeholder={
                  t('resource_2:taxId')

                  /*  country === 'ZAF'
                    ? t('resource_2:vatId')
                    : country === 'SGP'
                    ? t('resource_2:CompanyUEN')
                    : country === 'MY'
                    ? t('resource_2:ssmNumber')
                    : t('resource_2:ein') */
                }
                {...register('taxID')}
                className={`fs--1 form-control ${errors.taxID ? 'is-invalid' : ''
                  }`}
                onChange={({ target }) => {
                  setTaxid(target.value.trim());
                  // _onTaxIdChange(target.value);
                }}
                disabled={registrationMasterDisable}
                onBlur={(e) => {
                  if (taxid !== '') {
                    setValue('taxID', e.target.value);
                    //alert(country);
                    // dispatch(
                    //   fetchCompanyDetailsForVerification({
                    //     //company_name: 'HAPAG-LLOYD (AMERICA) LLC',
                    //     // company_name: companyName || null,
                    //     //state: 'NJ',
                    //     //source: '2',
                    //     //   request_id: '0001',
                    //     //   company_number: taxid || null,
                    //     // country_code: country.substring(0, 2),
                    //     company_number: taxid || null,
                    //     country_code: country.substring(0, 2),
                    //   })
                    // );
                  }
                }}
              />
              <div className='invalid-feedback'>
                {errors.taxID?.message?.toString()}
              </div>
            </Form.Group>

            <>
              {registrationdata.taxId?.data?.reg_number !== undefined &&
                registrationdata.taxId?.data?.reg_number === taxid && (
                  <span className='mt-2 text-primary fs--1 fw-bold'>
                    <FontAwesomeIcon icon={faCheckCircle} className='me-2' />
                    <span>{t('resource_4:companyNoVerifiedSuccessfully')}</span>
                  </span>
                )}
            </>
          </div>

          <div className='col-lg-6 mb-2'>
            <Form.Group controlId='registration.liner'>
              <Form.Label className='fs--1'>
                <span className='mandatory'>*</span>
                {t('resource_1:liner')}
              </Form.Label>
              <Form.Select
                {...register('liner')}
                value={liner}
                disabled={registrationMasterDisable || linerListLoading}
                onChange={(e) => {
                  setLiner(e.target.value);
                  setValue('liner', e.target.value);
                }}
                className='fs--1 form-control  form-select'
              >
                {linerOptions.map((i: any) => (
                  <option value={`${i.value}`}>{i.text}</option>
                ))}
              </Form.Select>

              <div className='invalid-feedback'>
                {errors.liner?.message?.toString()}
              </div>
            </Form.Group>
          </div>

          <div className='col-lg-6 mb-2'>
            <Form.Group controlId='registration.customerCode'>
              <Form.Label className='fs--1'>
                {t('resource_2:customerCode')}
              </Form.Label>

              <Form.Control
                type='text'
                placeholder={t('resource_1:customerCode')}
                {...register('customerCode')}
                className={`fs--1 form-control ${errors.customerCode ? 'is-invalid' : ''
                  }`}
                value={
                  registrationDetails?.customerMapping?.[0]?.mapCode &&
                    registrationDetails?.customerMapping?.[0]?.mapCode !== ''
                    ? registrationDetails.customerMapping?.[0]?.mapCode
                    : customerCode
                }
                disabled={registrationMasterDisable}
                onChange={({ target }) => {
                  setCustomerCode(target.value);
                }}
                onBlur={(e) => {
                  if (taxid !== '') {
                    setValue('customerCode', e.target.value);
                  }
                }}
              />
            </Form.Group>
          </div>

          <div className='col-lg-12 mb-2'>
            <Form.Group controlId='registration.companyName'>
              <Form.Label className='fs--1'>
                <span className='mandatory'>*</span>
                {t('resource_2:companyName')}
              </Form.Label>
              <Form.Control
                type='text'
                placeholder={t('resource_1:company')}
                {...register('companyName')}
                className={`fs--1 form-control ${errors.companyName ? 'is-invalid' : ''
                  }`}
                value={
                  registrationDetails.customerName != ''
                    ? registrationDetails.customerName
                    : companyName
                }
                disabled={registrationMasterDisable}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
              />

              <div className='invalid-feedback'>
                {errors.companyName?.message?.toString()}
              </div>
            </Form.Group>
          </div>

          <div className='col-lg-12 mb-2'>
            <div className='d-flex flex-column justify-content-center align-items-start odex-position-relative w-100'>
              <Form.Group
                controlId='registration.address.address'
                className='w-100'
              >
                <Form.Label className='fs--1'>
                  <span className='mandatory'>*</span>
                  {t('resource_1:address')}
                </Form.Label>
                <Form.Control
                  type='textarea'
                  placeholder={t('resource_1:address')}
                  {...register('address')}
                  className={`fs--1 form-control ${errors.companyName ? 'is-invalid' : ''
                    }`}
                  as='textarea'
                  onChange={({ target }) => {
                    dispatch(updateSelectedAddressResult(''));
                    setAddress(target.value);
                    _onAddressChange(target.value);
                    if (target.value == '') {
                      setPlaceId('');
                    }
                  }}
                  disabled={registrationMasterDisable}
                  value={
                    registrationDetails.customerAddress != ''
                      ? registrationDetails.customerAddress
                      : address
                  }
                  rows={2}
                />
                <div className='invalid-feedback'>
                  {errors.address?.message?.toString()}
                </div>
              </Form.Group>

              {address &&
                !selectedAddressResult &&
                !isAddressSuggesstionLoading &&
                !placeId &&
                registrationAddressSuggesstion?.length > 0 ? (
                <div className='rounded border bg-white w-100 odex-position-absolute odex-registration-address d-flex flex-column justify-content-start align-items-start'>
                  {registrationAddressSuggesstion.map((v: any) => (
                    <div
                      className='p-2 d-flex flex-row justify-content-start align-items-center fs--1 cursor-pointer'
                      key={v.placeId}
                      onClick={() => {
                        dispatch(updateSelectedAddressResult(v.text));
                        setAddress(v.text);
                        setPlaceId(v.placeId);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faLocation}
                        className='text-primary me-2'
                      />
                      <span>{v.text}</span>
                    </div>
                  ))}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className='h2 text-secondary fs-0 pb-1 border-bottom'>
            {t('resource_2:contactDetails')}
          </div>
          <div className='row mt-1'>
            <div className='col-lg-6 mb-2'>
              <Form.Group controlId='registration.companyName'>
                <Form.Label className='fs--1'>
                  <span className='mandatory'>*</span>
                  {t('resource_1:primary_contact')}
                </Form.Label>
                <Form.Control
                  type='text'
                  value={
                    registrationDetails.userName != ''
                      ? registrationDetails.userName
                      : userName
                  }
                  disabled={registrationMasterDisable}
                  placeholder={t('resource_1:primary_contact')}
                  {...register('userName')}
                  onChange={({ target }) => {
                    setuserName(target.value);
                  }}
                  className={`fs--1 form-control ${errors.companyName ? 'is-invalid' : ''
                    }`}
                />
                <div className='invalid-feedback'>
                  {errors.userName?.message?.toString()}
                </div>
              </Form.Group>
            </div>
            <div className='col-lg-6 mb-2'>
              <Form.Group controlId='registration.companyName'>
                <Form.Label className='fs--1'>
                  <span className='mandatory'>*</span>
                  {t('resource_1:email')}
                </Form.Label>
                <Form.Control
                  type='text'
                  disabled={registrationMasterDisable}
                  placeholder={t('resource_1:email')}
                  {...register('email')}
                  value={
                    registrationDetails.email != ''
                      ? registrationDetails.email
                      : email
                  }
                  className={`fs--1 form-control ${errors.companyName ? 'is-invalid' : ''
                    }`}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className='invalid-feedback'>
                  {errors.email?.message?.toString()}
                </div>
              </Form.Group>
            </div>

            <div className='col-lg-6 mb-2'>
              <Form.Group controlId='registration.company.phoneNo'>
                <Form.Label className='fs--1'>
                  {t('resource_2:phoneNo')}
                </Form.Label>
                <Form.Control
                  type='text'
                  disabled={registrationMasterDisable}
                  placeholder={t('resource_2:phoneNo')}
                  {...register('phoneNumber')}
                  onChange={({ target }) => {
                    setphonenumber(target.value);
                  }}
                  value={
                    registrationDetails.contactNo != ''
                      ? registrationDetails.contactNo
                      : phonenumber
                  }
                  className={`fs--1 form-control ${errors.phoneNumber ? 'is-invalid' : ''
                    }`}
                />
                <div className='invalid-feedback'>
                  {errors.phoneNumber?.message?.toString()}
                </div>
              </Form.Group>
            </div>
            {registrationMasterDisable && (
              <div className='col-lg-12 mb-2 mt-2'>
                <Form.Label className='h2 text-secondary fs-0 pb-1 border-bottom'>
                  User Groups
                </Form.Label>
                {groupList.map((groupList: any, index: any) => (
                  <>
                    {registrationDetails.status != 2 ? (
                      <Form.Check
                        key={groupList.value}
                        type='checkbox'
                        id={groupList.label}
                        label={groupList.label}
                        disabled={
                          registrationDetails.status != 2 ? true : false
                        }
                        checked={
                          registrationDetails.status == 2 ||
                            registrationDetails.mstGrp.find(
                              (item: any) => item.mstGrpId == groupList.value
                            )
                            ? true
                            : false
                        }
                      />
                    ) : (
                      <Form.Check
                        key={groupList.value}
                        type='checkbox'
                        id={groupList.label}
                        label={groupList.label}
                        onChange={(e) =>
                          handleCheckboxChange(
                            groupList.label,
                            e.target.checked,
                            groupList.value
                          )
                        }
                      />
                    )}
                  </>
                ))}
              </div>
            )}
          </div>
        </div>
        {!registrationMasterDisable && (
          <Form.Group>
            <Form.Check
              type='checkbox'
              id='registration.acceptTerms'
              feedback='invalid'
              label={
                <div>
                  <span>{t('resource_3:iAcceptThe')} </span>
                  <Link onClick={handleShow} to={''}>
                    {t('resource_1:terms').toLowerCase()}
                  </Link>
                  <span> {t('resource_1:and').toLowerCase()} </span>
                  <Link onClick={handleShowPolicy} to={''}>
                    {t('resource_2:privacyPolicy').toLowerCase()}
                  </Link>
                </div>
              }
              {...register('acceptTerms')}
              className={`${errors.acceptTerms ? 'is-invalid' : ''}`}
              onChange={({ target }) => {
                setAcceptTerms(target.checked);
              }}
              onBlur={(e) => {
                setValue('acceptTerms', e.target.checked);
              }}
            />
            <div className='invalid-feedback'>
              {errors.acceptTerms?.message?.toString()}
            </div>
          </Form.Group>
        )}
        {!registrationMasterDisable && (
          <div className='row'>
            <div className='col-4' />
            <div className='col-4'>
              <Form.Group className='d-flex justify-content-center align-items-center'>
                <button type='submit' className='custom-button mt-0 mb-2 w-100'>
                  <span style={{ fontSize: '13px' }}>
                    {t('resource_1:register')}
                  </span>
                </button>
              </Form.Group>
            </div>
            <div className='col-4' />
          </div>
        )}
        {registrationMasterDisable && (
          <div className='col-lg-6 mb-2'>
            <Form.Group controlId='reqForm.remarks'>
              <Form.Label>{t('resource_1:remark')}</Form.Label>
              <Form.Control
                as='textarea'
                disabled={registrationDetails.status != 2 ? true : false}
                // value={remarks}
                value={
                  registrationDetails.remarks != '' &&
                    registrationDetails.status != 2
                    ? registrationDetails.remarks
                    : remarks
                }
                onChange={({ target }) => {
                  setremarks(target.value);
                }}
              //  value={state.customerRemarks}

              //disabled={!!matchPth}
              />
            </Form.Group>
          </div>
        )}
        {registrationMasterDisable && registrationDetails.status == 2 && (
          <div className='d-flex justify-content-center align-items-center'>
            <Button
              onClick={(e) => {
                if (selectedOptions.length > 0) {
                  // onApproveReject('A');
                  const payload = {
                    //requestId: decrptrequestId,
                    requestType: 1,

                    countryCode: registrationDetails.countryCode,
                    mstGrp: selectedOptions,
                    email: registrationDetails.email,
                    requestId: decrptrequestId,
                    mstUsrId: registrationDetails.mstUsrId,
                    remarks: remarks,
                  };

                  dispatch(approveRejectRegistration(payload));
                  setTimeout(() => {
                    navigate('/registrationmaster');
                  }, 1000);
                } else {
                  toast.dark(t('resource_message:userGroupMandatory'), {
                    toastId: 'ffd7607'
                  });
                }
              }}
              variant='success'
              className='me-4 approve'
              size='sm'
            >
              <FontAwesomeIcon icon={faCheck} className='me-2 approve' />
              {t('resource_1:approve')}
            </Button>

            <Button
              variant='danger'
              className='reject'
              size='sm'
              onClick={(e) => {
                // onApproveReject('R');

                //   if (selectedOptions.length > 0) {
                if (remarks == '') {
                  toast.dark(t('resource_message:remarksMandatory'), {
                    toastId: '2eaf0eb'
                  });
                } else {
                  const payload = {
                    //requestId: decrptrequestId,
                    requestType: 3,

                    countryCode: registrationDetails.countryCode,
                    mstGrp: selectedOptions,
                    email: registrationDetails.email,
                    requestId: decrptrequestId,
                    mstUsrId: registrationDetails.mstUsrId,
                    remarks: remarks,
                  };
                  dispatch(approveRejectRegistration(payload));
                  setTimeout(() => {
                    navigate('/registrationmaster');
                  }, 1000);
                }
                //  } /*  else {
                //    toast.dark(t('resource_message:userGroupMandatory'));
                //  }
              }}
            >
              <FontAwesomeIcon icon={faTimes} className='me-2 reject' />
              {t('resource_1:reject')}
            </Button>
          </div>
        )}
      </Form>
      {
        <Modal show={show} onHide={handleClose} size='xl'>
          <Modal.Header className='p-2 pb-1' closeButton>
            <Modal.Title>{t('resource_3:termsAndConditions')}</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              height: '500px',
              overflow: 'auto',
              fontSize: '12px',
            }}
          >
            <TermsAndConditions />
          </Modal.Body>
        </Modal>
      }

      {
        <Modal show={showPolicy} onHide={handleClosePolicy} size='xl'>
          <Modal.Header className='p-2 pb-1' closeButton>
            <Modal.Title>{t('resource_2:privacyPolicy')}</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              height: '500px',
              overflow: 'auto',
              fontSize: '12px',
            }}
          >
            <PrivacyPolicy />
          </Modal.Body>
        </Modal>
      }
      {
        <Modal
          show={showMessage}
          //onHide={handleCloseMessage}
          size='lg'
        >
          <Modal.Body
            className='text-center'
            style={{
              height: 'auto',
              overflow: 'auto',
              fontSize: '12px',
            }}
          >
            <div className='fs-1 text-secondary'>{renderMessage}</div>
          </Modal.Body>
          <Modal.Footer
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              className='me-2 btn btn-falcon-primary btn-sm'
              onClick={handleCloseMessage}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      }
    </div>
  );
};
export default Registration;
