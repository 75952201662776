import { matchPath, Outlet, useLocation } from 'react-router-dom';
import Header from '../../common/components/DetailsViewHeader';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import OblDetails from '../../common/components/OblDetails';
import MainTable from './components/MainTable';
import { AESDecrypt } from '../../../encrypt-util';
import DashboardContainer from '../../../components/common/DashboardContainer';
import DocumentHistory from '../blDetails/DocumentHistory';
import { useDispatch } from 'react-redux';
import {
  updateDashbaordName,
  updateSearchInputValueNT,
} from '../../common/slices/navbarTop';
import { updateSelectedInvoiceType, updateSelectedRowsInvoice } from '../multiBlPayment/slices/multiBlPaymentSlice';

const InvoiceDtls = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const paymentStatus = useSelector(
    (state: RootState) => state.instructionDashboard.showPayment
  );

  const releaseStatus = useSelector(
    (state: RootState) => state.instructionDashboard.showRelease
  );
  const invGridName = useSelector(
    (state: RootState) => state.multiBlPayment.selectedInvoiceType == "CWPDRAFTINVOICES"?
    "OPC_INV_DASHBOARD":"OPC_INV_DASHBOARD_TAX"
  );

  useEffect(() => {
    dispatch(updateDashbaordName(invGridName));
    dispatch(updateSearchInputValueNT(''));
    dispatch(updateSelectedInvoiceType('CWPDRAFTINVOICES'));
    dispatch(updateSelectedRowsInvoice({}));
  }, []);

  const urlparts = location.pathname.split('/');
  const page = urlparts[urlparts.length - 1];
  const decpage = AESDecrypt(page);
  var path = location.pathname;
  var parts = path.split('/');
  var blslider = parts[1];

  const sideWidth =
    Object.entries({
      [`/opc/invoice`]: 0,
      [`/opc/home`]: 0,
      [`/opc/home/invoiced/:draft`]:8
    }).find(([path, _]) => matchPath(path, location.pathname))?.[1] ?? 9;

  const isInner = location.pathname.includes('instruction/bl/');

  return (
    <DashboardContainer
      sideWidth={sideWidth}
      middleScroll={
        !isInner &&
        (decpage == '812' ||
          decpage == '811' ||
          decpage == '813' ||
          decpage == '815' ||
          decpage == '902' ||
          !matchPath('/instruction/:encrypt', location.pathname))
      }
      inner={isInner}
      middleChildren={
        blslider == 'blsliderinstruction' ? (
          <OblDetails blslider={blslider} />
        ) : (
          <>
            {((decpage != '812' &&
              decpage != '811' &&
              decpage != '813' &&
              decpage != '815' &&
              decpage != '911' &&
              decpage != '902' &&
              decpage != '903' &&
              matchPath('/instruction/:encrypt', location.pathname)) ||
              isInner) &&
              !location.pathname.includes('snooze') && (
                <Header
                  paymentStatus={paymentStatus}
                  releaseStatus={releaseStatus}
                />
              )}
            {isInner ? <DocumentHistory /> : <Outlet />}
          </>
        )
      }
    >
      <MainTable />
    </DashboardContainer>
  );
};

export default InvoiceDtls;
