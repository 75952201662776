import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  addCarrierHaulage,
  approveCarrierHaulage,
} from "../actions/Instruction";
import { getCarrierHaulage } from "../actions/Instruction";
import { toast } from "react-toastify";
import { fetchAddressSuggestions2 } from "../../../dashboard/actions/instruction";

export interface CarrierHaulageDetails {
  blId: string;
  deliveryOption: string;
  warehouseName: string;
  // customerCode: string;
  contactPerson: string;
  emailId: string;
  contactNo: string;
  address: string;
  remarks: string;
  carrierHaulageId: any;
  status: any;
  rejectionRemarks: any;
  refPoNo: string;
  areaCode: string;
}
interface ICarrierHaulageList {
  list: CarrierHaulageDetails[];
  next: string | null;
  previous: string | null;
  blId: string;
  deliveryOption: string;
  warehouseName: string;
  // customerCode: string;
  contactPerson: string;
  emailId: string;
  contactNo: string;
  address: string;
  remarks: string;
  carrierHaulageId: any;
  status: any;
  rejectionRemarks: any;
  refPoNo: string;
  areaCode: string;
  deliveryDate: string;
  country: string;
}

export interface IRegistrationAddressSuggesstion {
  text: string;
  placeId: string;
}

export interface ICarrierHaulage {
  data: ICarrierHaulageList;
  registrationAddressSuggesstion: IRegistrationAddressSuggesstion[];
  selectedAddressResult: string;
  selectedPlaceId: string;
  isAddressSuggesstionsLoading: boolean;
  dataLoading: boolean;
  carrierHaulageCreateMessage: string;
}

const initialState: ICarrierHaulage = {
  data: {
    list: [],
    next: null,
    previous: null,
    blId: "",
    deliveryOption: "",
    warehouseName: "",
    // customerCode: string;
    contactPerson: "",
    emailId: "",
    contactNo: "",
    address: "",
    remarks: "",
    carrierHaulageId: "",
    status: "",
    rejectionRemarks: "",
    refPoNo: "",
    areaCode: "",
    deliveryDate: "",
    country: "",
  },
  selectedAddressResult: "",
  selectedPlaceId: "",
  isAddressSuggesstionsLoading: false,
  registrationAddressSuggesstion: [],
  dataLoading: true,
  carrierHaulageCreateMessage: "",
};

export const CarrierHaulageSlice = createSlice({
  name: "CarrierHaulage",
  initialState,
  reducers: {
    updateSelectedAddressResult: (state, action: PayloadAction<any>) => {
      state.selectedAddressResult = action.payload.text;
      state.selectedPlaceId = action.payload.placeId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addCarrierHaulage.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(addCarrierHaulage.fulfilled, (state, action) => {
      state.dataLoading = false;
      if (!action.payload?.success && action?.payload?.data) {
        state.carrierHaulageCreateMessage = action.payload.data;
        toast.dark(action.payload.data, { toastId: 'dd097b7' });
      } else {
        state.carrierHaulageCreateMessage = "Created Successfully!";
        toast.dark("Created Successfully!", { toastId: 'ab02cc7' });
      }
    });
    builder.addCase(addCarrierHaulage.rejected, (state, action) => {
      state.dataLoading = false;
    });
    builder.addCase(approveCarrierHaulage.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(approveCarrierHaulage.fulfilled, (state: any, action) => {
      state.dataLoading = false;
    });
    builder.addCase(getCarrierHaulage.pending, (state: any, action) => {
      state.dataLoading = true;
      state.data = action.payload;
    });
    builder.addCase(getCarrierHaulage.fulfilled, (state: any, action) => {
      state.dataLoading = false;

      state.data = action.payload;
    });
    builder.addCase(getCarrierHaulage.rejected, (state, action) => {
      state.dataLoading = false;
    });

    builder.addCase(fetchAddressSuggestions2.pending, (state, action) => {
      state.isAddressSuggesstionsLoading = true;
    });

    builder.addCase(fetchAddressSuggestions2.fulfilled, (state, action) => {
      state.isAddressSuggesstionsLoading = false;
      state.registrationAddressSuggesstion = action.payload;
    });
  },
});

export const { updateSelectedAddressResult } = CarrierHaulageSlice.actions;
export default CarrierHaulageSlice.reducer;
