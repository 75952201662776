import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, Card, Dropdown, Form, Modal } from "react-bootstrap";
import * as XLSX from "xlsx";
import {
  // faArrowAltCircleLeft,
  faRedo,
  faSave,
  faUpload,
  faCancel,
  faTrash,
  faAdd,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  faArrowAltCircleLeft,
  faCheckCircle,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toggleDocumentHistoryColumnShort } from "../../blDetails/slices/documentHistorySlice";
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useState, useEffect, useRef, useMemo } from "react";
import { AppDispatch, RootState } from "../../../store";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { AESDecrypt } from "../../../../encrypt-util";
import DataTable from "../../../common/components/DataTable";
import { IDataTableColumn } from "../../../common/types/dataTable";
import UploadIndicator from "../../../../components/common/UploadIndicator";
import {
  updateSelectedAttachmentFilePath,
  updateSelectedAttachmentName,
  updateSelectedDocId,
  updateSelectedFileServerPath,
} from "../../blRequestInstruction/components/instructionSlice/DemurrageSlice";
import { toast } from "react-toastify";
import axiosInstance, { axiosCacheInstance } from "../../../../axiosSetup";
import {
  blNumbList,
  blValidation,
  cancelInvoiceRequest,
  fetchVesselSuggestions,
  generateInvReferenceNo,
  getInvoiceRequest,
  getMstCodeListVal,
  invoiceApproveReject,
  submitInvoiceRequest,
} from "../../blRequestInstruction/components/actions/Instruction";
import { useBackNavigation } from "../../../../hooks/BackNavigation";
import { customDropdownStyles } from "../../../common/customStyles";
import { updateDashbaordRefresh } from "../../../common/components/DataTable/slices/DataTable";
import axios from "axios";
import SoftBadge from "../../../../components/common/SoftBadge";
import { getInvoiceAttachmentListExport, getInvoiceAttachmentListImport } from "../actions/instruction";
import moment from "moment";
import React from "react";
import useDebounce from "../../../../hooks/useDebounce";
import { useLoading } from "../../../../middleware/loadingMiddleware";
import retry from "../../../../helpers/axiosRetry";
import ReactDatePicker from "react-datepicker";

const InvoiceRequestInstruction = () => {
  const { t } = useTranslation();
  const { requesttp, invoice_request_id } = useParams();

  const dropdownRef = useRef(null);

  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const onBack = useBackNavigation();
  const navigate = useNavigate();
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const serverFilePath = useSelector(
    (state: RootState) => state.demurrage.selectedFileServerPath
  );

  const tpoId = useSelector((state: RootState) => state.agentDelegation.tpoId);

  const selectedAttachmentFilePath = useSelector(
    (state: RootState) => state.demurrage.selectedAttachmentFilePath
  );
  const selectedDocId = useSelector(
    (state: RootState) => state.demurrage.selectedDocId
  );
  const selectedLinerId = useSelector(
    (state: RootState) => state.userProfile.selectedLinerId
  );
  const selectedAttachmentName = useSelector(
    (state: RootState) => state.demurrage.selectedAttachmentName
  );
  const loadingVesselList = useLoading([
    "PermitRequest/fetchVesselSuggestions",
  ]);

  const mstcodeID = useSelector(
    (state: RootState) => state.creditBalanceBlPayment.mstcodeList
  );

  const invReferenceNoVal = useSelector(
    (state: RootState) => state.creditBalanceBlPayment.generateInvReferenceNo
  );
  const docList = useSelector((state: RootState) => state.demurrage.docList);
  const docExport = useSelector(
    (state: RootState) => state.demurrage.docExport
  );

  const VesselData: any = useSelector(
    (state: RootState) => state.permitrequest.data
  );

  const blNumbListVal: any = useSelector(
    (state: RootState) => state.permitrequest.blNumbList
  );

  const docAttachment = useSelector(
    (state: RootState) => state.demurrage.attData
  );

  const docAttachmentExport = useSelector(
    (state: RootState) => state.demurrage.attDataExport
  );
  // const docId = AESDecrypt(encryptdoc);

  const [attachedFileSize, setAttachedFileSize] = useState(1000);
  const [invoiceCancel, setinvoiceCancel] = useState(false);
  const [localTransitOptions, setLocalTransitOptions] = useState<any>([]);

  const [trafficVal, setTrafficVal] = useState<Number>(0);
  const [showdata, setShowData] = useState(false);
  const [selectedVessel, setselectedVessel] = useState<any>("");
  const [selectedPartnerType, setselectedPartnerType] = useState<any>("");
  const [status, setStatus] = useState<string>("");
  const [requestedOn, setRequestedOn] = useState<string | null>(null);
  const [vesselTxt, setVesselTxt] = useState<string>("");
  const [VesselList, setVesselList] = useState<any>([]);
  const [trafficOptions, setTrafficOptions] = useState<any>([]);
  const [invoiceOptions, setInvoiceOptions] = useState<any>([]);
  const rbac = useSelector((state: RootState) => state.userProfile.rbacDetails);
  const [selectedInvoiceOption, setSelectedInvoiceOptions] =
    useState<string>("");

  const [loadingFileState, setLoadingFileState] = useState<{
    [key: string]: number;
  }>({});
  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );
  const storedUserCountry = JSON.parse(
    window.localStorage.getItem("@user-selected-datacountry") || "{}"
  );
  const storedCountry =
    storedUserCountry?.[0]?.loginId === profileDetails.email
      ? storedUserCountry?.[0]?.country
      : null;

  const dataCountry = storedCountry
    ? storedCountry
    : linerDataCountry
      ? linerDataCountry
      : profileDetails.partnerCountry || null;
  useEffect(() => {
    if (dataCountry && !showdata && !invoice_request_id) dispatch(generateInvReferenceNo(dataCountry));
  }, [dataCountry, showdata, invoice_request_id]);
  useEffect(() => {
    // Clear the form state when the component mounts
    setselectedVessel("");
    setValue("blno", "");
    setVesselList([]);
    clearErrors("vesselVoyage");
    clearErrors("blno");

  }, []); // Empty dependency array ensures this effect runs only on component mount

  useEffect(() => {
    if (
      profileDetails?.opcPartnerType === "ICD" ||
      profileDetails?.opcPartnerType === "CFA"
    ) {
      setValue("invoiceType", "109");
    }

    if (profileDetails?.opcPartnerType === "ICD" && !showdata) {
      setTrafficVal(1);
      setValue("traffic", 1);
    }
  }, [profileDetails]);

  const fetchInvoiceOptions = async () => {
    try {
      const response = await retry(
        async () =>
          await axiosCacheInstance.get(
            `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=INVOICE_OPTIONS&linerId=${tpoId}&countryCode=TZ`
          ),
        2
      );
      setInvoiceOptions(response.data ?? []);
      setSelectedInvoiceOptions(response?.data?.[0]?.codeId);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTrafficOptions = async () => {
    try {
      const response = await retry(
        async () =>
          await axiosCacheInstance.get(
            `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=TRAFFIC_OPTIONS&linerId=${tpoId}&countryCode=TZ`
          ),
        2
      );
      setTrafficOptions(response.data);
      // (response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchLocalTransitOptions = async () => {
    try {
      const response = await retry(
        async () =>
          await axiosCacheInstance.get(
            `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=LOCAL/TRANSIT_OPTIONS&linerId=${tpoId}&countryCode=TZ`
          ),
        2
      );
      setLocalTransitOptions(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const onApproveReject = ({ arStatus }: any) => {
    if (
      arStatus == 304 &&
      (!getValues("linerRemarks") || getValues("linerRemarks") == "")
    ) {
      setError("linerRemarks", {
        message: "Remarks are mandatory for rejection",
      });
      return;
    }
    clearErrors("linerRemarks");
    dispatch(
      invoiceApproveReject({
        tpoId,
        requestId: AESDecrypt(invoice_request_id),
        status: arStatus,
        requestedOn,
        linerRemarks: getValues("linerRemarks"),
        shippingLine: getValues("shipperConsigneeName"),
        customerTin: getValues("customerTin"),
        croNo: getValues("croNo"),
        cifValue: getValues("cifNo"),
      })
    ).then(() => {
      setTimeout(() => {
        dispatch(updateDashbaordRefresh(true));
      }, 1000);
    });
    onBack();
  };

  useEffect(() => {
    if (tpoId) {
      fetchTrafficOptions();
      fetchLocalTransitOptions();
      fetchInvoiceOptions();
    }
  }, [tpoId]);

  useEffect(() => {
    dispatch(getMstCodeListVal());
    dispatch(updateSelectedAttachmentName({}));
    setLoadingFileState({});
    dispatch(updateSelectedFileServerPath({}));
  }, []);

  useEffect(() => {
    if (VesselData && vesselTxt) {
      if (Array.isArray(VesselData)) {
        const formattedOptions = VesselData.map((vessel: any) => ({
          value: `${vessel.visitKey}`,
          label: `${vessel.vesselName} - ${vessel.visitKey}${vessel.ata ? " - " + new Date(vessel.ata).toLocaleString() : ""
            }`,
          visitKey: vessel.visitKey,
        }));
        setVesselList(formattedOptions);
      }
    }
  }, [VesselData]);

  useEffect(() => {
    if (requesttp && invoice_request_id) {
      if (
        matchPath(
          "/opc/instruction/:requesttp/:invoice_request_id",
          location.pathname
        )
      ) {
        dispatch(getInvoiceRequest(AESDecrypt(invoice_request_id)))
          .then((response: any) => {
            let tempSelectedAttachmentName = {};

            for (var i = 0; i < response.payload.data.documents.length; i++) {
              tempSelectedAttachmentName = {
                ...tempSelectedAttachmentName,
                [response.payload.data.documents[i]?.docId]: {
                  name: response.payload.data.documents[i]?.fileName || null,
                  invoiceRequestAttId:
                    response.payload.data.documents[i]?.invoiceRequestAttId ||
                    null,
                },
              };
            }
            dispatch(updateSelectedAttachmentName(tempSelectedAttachmentName));

            setTimeout(() => {
              setShowData(true);
              setselectedPartnerType(response.payload.data.partnerType);
              setValue("invoiceNumber", response.payload.data.odexRefNo);

              setValue("invoiceType", response.payload.data.invoiceType);
              setValue("customerTin", response.payload.data.customerTin);
              setValue(
                "shipperConsigneeName",
                response.payload.data.shippingLine
              );
              setValue("croNo", response.payload.data.croNo);
              setValue("cifNo", response.payload.data.cifValue);
              setValue("doNo", response.payload.data.doNo);
              setValue("linerRemarks", response.payload.data.approvalRemarks);
              setValue(
                "traffic",
                response.payload.data.traffic === "Import" ? 1 : 2
              );
              setTrafficVal(response.payload.data.traffic === "Import" ? 1 : 2);
              setValue(
                "paidThrough",
                new Date(response.payload.data.paidThrough.split("T")[0])
              );
              setValue(
                "deliveryType",
                response.payload.data.deliveryType === "Local" ? 1 : 2
              );
              setValue("vesselVoyage", response.payload.data.vesselVoyage);
              setValue("blno", response.payload.data.blno);
              setValue("remarks", response.payload.data.remarks);
              setStatus(response.payload.data.status);
              setRequestedOn(response.payload.data.requestedOn);

              setAdditionalInputs(
                response.payload.data.blno.split(",").map((i: any) => ({
                  value: i,
                  rowNumber: crypto.randomUUID(),
                  error: null,
                }))
              );
            }, 100);
          })
          .catch(() => { });
      }
    }
  }, [requesttp, invoice_request_id]);

  useEffect(() => {
    if (invReferenceNoVal) setValue("invoiceNumber", invReferenceNoVal);
  }, [invReferenceNoVal]);

  const validationSchemaCFA = Yup.object().shape({
    invoiceNumber: Yup.string().required(
      "Invoice Reference Number is required"
    ),
    blno: Yup.string()
      .required("BL Number is required")
      .test("validate-bl", "BL/SO number is invalid", function (val) {
        if (!val) return false; // if val is undefined or null, fail the test
        if (val.length > 0) {
          if (blNumbListVal.find((item: any) => item.blNumber === val)) {
            return true; // Pass the validation
          } else {
            return false; // Fail the validation
          }
        }
        return true; // Pass the validation if no issues
      }),
    cifNo: Yup.string()
      .required("CIF Number is required")
      .matches(/^[0-9]+$/, "CIF Number must be a number"),
    doNo: Yup.string().required("DO Number is required"),
    croNo: Yup.string().required("CRO Number is required"),
    customerTin: Yup.string()
      .required("Shipper/Consignee TIN is required")
      .matches(/^[0-9-]+$/, "Shipper/Consignee TIN must be a number"),
    shipperConsigneeName: Yup.string().required(
      "Shipper/Consignee Name is required"
    ),
    traffic: Yup.string().required("Traffic is required"),
    invoiceType: Yup.string().required("Invoice Type is required"),
    paidThrough: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .required("Paid Thru Date is required"),
    deliveryType: Yup.string().required("Local/Transit is required"),
    vesselVoyage: Yup.object().required("Vessel is required").nullable(),
    // vesselVoyage: Yup.object().required('Vessel is required').nullable(),
    remarks: Yup.string().max(
      250,
      "Remarks cannot be more than 250 characters"
    ),
  });

  const validationSchemaCFAexport = Yup.object().shape({
    invoiceNumber: Yup.string().required(
      "Invoice Reference Number is required"
    ),
    blno: Yup.string()
      .required("BL Number is required")
      .test("validate-bl", "BL/SO number is invalid", function (val) {
        if (!val) return false; // if val is undefined or null, fail the test

        if (val.length > 0) {
          if (blNumbListVal.find((item: any) => item.blNumber === val)) {
            return true; // Pass the validation
          } else {
            return false; // Fail the validation
          }
        }
        return true; // Pass the validation if no issues
      }),
    cifNo: Yup.string().required("CIF Number is required"),
    // doNo: Yup.string().required('DO Number is required'),
    croNo: Yup.string().required("CRO Number is required"),
    customerTin: Yup.string()
      .required("Shipper/Consignee TIN is required")
      .matches(/^[0-9-]+$/, "Shipper/Consignee TIN must be a number"),
    shipperConsigneeName: Yup.string().required(
      "Shipper/Consignee Name is required"
    ),
    traffic: Yup.string().required("Traffic is required"),
    invoiceType: Yup.string().required("Invoice Type is required"),
    paidThrough: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .required("Paid Thru Date is required"),
    deliveryType: Yup.string().required("Local/Transit is required"),
    vesselVoyage: Yup.object().required("Vessel is required").nullable(),
    // vesselVoyage: Yup.object().required('Vessel is required').nullable(),
    remarks: Yup.string().max(
      250,
      "Remarks cannot be more than 250 characters"
    ),
  });

  const validationSchemaICD = Yup.object().shape({
    invoiceNumber: Yup.string().required("ODex Reference ID"),
    traffic: Yup.string().required("Traffic is required"),
    invoiceType: Yup.string().required("Invoice Type is required"),
    paidThrough: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .required("Paid Thru Date is required"),
    deliveryType: Yup.string().required("Local/Transit is required"),
    vesselVoyage: Yup.object().required("Vessel is required").nullable(),
    remarks: Yup.string().max(
      250,
      "Remarks cannot be more than 250 characters"
    ),
  });

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    clearErrors,
    setError,
    getValues,
  } = useForm({
    resolver: yupResolver(
      profileDetails?.opcPartnerType === "CFA"
        ? trafficVal == 1
          ? validationSchemaCFA
          : validationSchemaCFAexport
        : validationSchemaICD
    ),
  });


  const [additionalInputs, setAdditionalInputs] = useState<any[]>([]);

  const handleAddInput = () => {
    setAdditionalInputs((state) => [
      ...state,
      { value: "", rowNumber: crypto.randomUUID(), error: null },
    ]);
  };

  const handleInputChangeValue = (rowNumber: string, value: string) => {
    const newInputs = [...additionalInputs];
    const row = newInputs.find((i) => i.rowNumber == rowNumber);
    if (!row) return;
    row.value = value;
    row.error = null;
    setAdditionalInputs(newInputs);
  };

  const blListFieldError = (rowNumber: string, error: string) => {
    const newInputs = [...additionalInputs];
    const row = newInputs.find((i) => i.rowNumber == rowNumber);
    if (!row) return;
    row.error = error;
    setAdditionalInputs(newInputs);
  };

  const handleRemoveInput = (rowNumber: number) => {
    const newInputs = additionalInputs.filter((i) => i.rowNumber !== rowNumber);
    setAdditionalInputs(newInputs);
  };

  const customFilterOption = (option: any, inputValue: any) => {
    // if (inputValue.length < 3) {
    //   return false;
    // }
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  };

  const handleFileChange = (e: any, fileDocId: any, filePaths: any) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file.size > 10 * 1024 * 1024) {
        toast.error(
          "File size is greater than 10MB. Please upload file below 10MB",
          {
            toastId: 'd30ed75'
          }
        );
        e.target.value = null;
        return;
      }
      const allowedExtensions = [
        ".pdf",
        ".doc",
        ".docx",
        ".jpg",
        ".jpeg",
        ".png",
        ".xls",
        ".xlsx",
        ".eml",
      ];
      const fileExt = file.name.slice(-4).toLowerCase();
      const fileExtLong = file.name.slice(-5).toLowerCase();

      if (
        !allowedExtensions.includes(fileExt) &&
        !allowedExtensions.includes(fileExtLong)
      ) {
        toast.error("Please upload a valid file (PDF,JPEG,PNG,WORD,EXCEL)", { toastId: 'b57445e' });
        e.target.value = null;
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      try {
        setLoadingFileState((state: any) => {
          return { ...state, [fileDocId]: 0 };
        });
        axiosInstance
          .post("/api/opc-rest-v1/v1/view/file", formData, {
            headers: {
              accept: "*/*",
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress(progressEvent) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / (progressEvent.total ?? 1)
              );
              setLoadingFileState((state: any) => {
                return { ...state, [fileDocId]: percentCompleted };
              });
            },
          })
          .then((response) => {
            setLoadingFileState((state: any) => {
              return { ...state, [fileDocId]: 100 };
            });
            dispatch(
              updateSelectedFileServerPath({
                ...filePaths,
                [fileDocId]: [response.data.path, response.data.tempFileName],
              })
            );

            dispatch(
              updateSelectedAttachmentName({
                ...selectedAttachmentName,
                [fileDocId]: file,
              })
            );
            dispatch(
              updateSelectedAttachmentFilePath({
                ...selectedAttachmentFilePath,
                [fileDocId]: null,
              })
            );

            dispatch(
              updateSelectedDocId({
                ...selectedDocId,
                [fileDocId]: file[0],
              })
            );
          })

          .catch((err) => {
            setLoadingFileState((state: any) => {
              return { ...state, [fileDocId]: -1 };
            });

            dispatch(
              updateSelectedFileServerPath({
                ...filePaths,
                [fileDocId]: ["", ""],
              })
            );

            dispatch(
              updateSelectedAttachmentName({
                ...selectedAttachmentName,
                [fileDocId]: null,
              })
            );
            dispatch(
              updateSelectedAttachmentFilePath({
                ...selectedAttachmentFilePath,
                [fileDocId]: null,
              })
            );

            dispatch(
              updateSelectedDocId({
                ...selectedDocId,
                [fileDocId]: null,
              })
            );
            // const emptyFile: any = new File([""], " ")
            // dispatch(
            //   updateSelectedAttachmentName({
            //     ...selectedAttachmentName,
            //     [fileDocId]: emptyFile,
            //   })
            // );

            // dispatch(
            //   updateSelectedAttachmentFilePath({
            //     ...selectedAttachmentFilePath,
            //     [fileDocId]: null,
            //   })
            // );

            // dispatch(
            //   updateSelectedDocId({
            //     ...selectedDocId,
            //     [fileDocId]: emptyFile[0],
            //   })
            // );
          });
        // add server path to redux
      } catch (error) {
        console.error("File Upload Error: ", error);
        console.error("File Upload Error: ", error);
      }
    }
  };

  const TableCellTruckerId = (rowData: any) => {
    const { blContainerId, containerNo } = rowData.row.original;

    if (true) {
      return (
        <input
          type="text"
          className="fs--1 form-control"
          // disabled={true}
          title={containerNo}
          // defaultValue={selectedtruckerId?.[containerNo] || ''}
          onBlur={(e) => {
            e.preventDefault();

            // dispatch(
            //   updateSelectedtruckerId({
            //     ...selectedtruckerId,
            //     [containerNo]: e.target.value,
            //   })
            // );
          }}
        />
      );
    } else {
      return (
        <span>
          {/* {
            demurrageViewForm?.list?.find?.(
              (f: any) => f?.cntNo === containerNo
            )?.truckerId
          } */}
        </span>
      );
    }
  };

  const ptoEdit = useMemo(
    () =>
      status == "308" &&
      rbac.invoiceRequestApproveReject == "Y" &&
      profileDetails.opcPartnerType == "PTO",
    [status, rbac, profileDetails.opcPartnerType]
  );

  const resetForm = () => {
    /*     setValue('invoiceNumber', '');
    setValue('invoiceType', ''); */
    clearErrors("customerTin");
    clearErrors("shipperConsigneeName");
    clearErrors("paidThrough");
    clearErrors("blno");
    clearErrors("traffic");
    clearErrors("vesselVoyage");
    clearErrors("deliveryType");
    setValue("customerTin", "");
    setValue("shipperConsigneeName", "");
    setValue("doNo", "");
    setValue("croNo", "");
    setValue("cifNo", "");
    setValue("traffic", profileDetails.opcPartnerType == "ICD" ? 1 : "");
    setValue("paidThrough", "");
    setValue("deliveryType", "");
    setValue("vesselVoyage", "");
    setValue("blno", "");
    setValue("remarks", "");
    setTrafficVal(profileDetails.opcPartnerType == "ICD" ? 1 : 0);
    setAdditionalInputs([]);
    dispatch(updateSelectedAttachmentName({}));
    setLoadingFileState({});
    dispatch(updateSelectedFileServerPath({}));
  };

  const uploadDoument = (rowData: any) => {
    const { docId, dndInstructionAttId, fileName } = rowData.row.original;
    const charLimit = (name: string, limit: number) =>
      name && name?.length >= limit ? name?.substring(0, limit) + "..." : name;

    if (!showdata) {
      return (
        <div className="d-flex justify-content-start align-items-center">
          <label
            className="btn btn-sm btn-falcon-primary m-0 me-2"
            htmlFor={`${docId}-input`}
          >
            <FontAwesomeIcon icon={faUpload} />
          </label>
          <input
            id={`${docId}-input`}
            title="Input"
            type="file"
            className="fs--2 form-control ms-1"
            // disabled={disableFields}
            accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.xls,.xlsx"
            onClick={() => {
              updateSelectedFileServerPath({
                ...serverFilePath,
                [docId]: [null, null],
              });
            }}
            onChange={(e) => {
              handleFileChange(e, docId, serverFilePath);
            }}
            hidden
          />
          {selectedAttachmentFilePath?.[docId] ? (
            <span
              title={fileName}
              className="text-primary cursor-pointer"
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_CLIENT_APP_HOST
                  }/api/opc-rest-v1/v1/common/openDocument?id=${dndInstructionAttId}&moduleTp=DND&linerId=${profileDetails.partnerType === "liner"
                    ? profileDetails?.partnerId
                    : selectedLinerId
                  }`,
                  "_blank"
                );
              }}
            >
              {charLimit(fileName, 45)}
            </span>
          ) : (
            <>
              <span title={selectedAttachmentName?.[docId]?.name}>
                {charLimit(selectedAttachmentName?.[docId]?.name, 45)}
              </span>
              {loadingFileState[docId] != 0 && (
                <UploadIndicator
                  style={{ marginLeft: "20px" }}
                  progress={loadingFileState[docId]}
                  fail={loadingFileState[docId] === -1}
                />
              )}
            </>
          )}
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-start align-items-center">
          {/*  <label
            className='btn btn-sm btn-dark m-0 me-2'
            htmlFor={`${docId}-input`}>
            Choose
          </label>
          <input
            id={`${docId}-input`}
            title='Input'
            type='file'
            className='fs--2 form-control ms-1'
            disabled={disableFields}
            onChange={(e) => {
              handleFileChange(e, docId);
            }}
            hidden
          /> */}

          {/* <span
            className='text-primary cursor-pointer'
            onClick={() => {
              window.open(
                `${
                  process.env.REACT_APP_CLIENT_APP_HOST
                }/api/invoice-management/v1/common/openDocument?id=${dndInstructionAttId}&moduleTp=DND&linerId=${
                  profileDetails.partnerType === 'liner'
                    ? profileDetails?.partnerId
                    : selectedLinerId
                }`,
                '_blank'
              );
            }}
          >
            {charLimit(fileName, 50)}
          </span> */}
          <span
            className="text-primary cursor-pointer"
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_CLIENT_APP_HOST}/api/opc-rest-v1/v1/common/openDocument?id=${selectedAttachmentName?.[docId]?.invoiceRequestAttId}&moduleTp=INV_REQUEST&linerId=${tpoId}`,
                "_blank"
              );
            }}
            title={selectedAttachmentName?.[docId]?.name}
          >
            {charLimit(selectedAttachmentName?.[docId]?.name, 45)}
          </span>
        </div>
      );
    }
  };

  const docAttachmentColumns: IDataTableColumn[] = [
    {
      accessor: "dndInstructionAttId",
      Header: t("resource_1:dndInstructionAttId"),
    },
    {
      accessor: "docId",
      Header: t("resource_1:document"),
      Cell: (rowData: any) => {
        const { docId } = rowData.row.original;
        if (trafficVal == 2) {
          return (
            <>
              {docExport.find((v: any) => v?.id === docId)?.m === true ? (
                <span className="mandatory">*</span>
              ) : (
                ""
              )}
              {docExport.find((v: any) => v?.id === docId)?.name || ""}
            </>
          );
        } else {
          return (
            <>
              {docList?.find((v: any) => v?.id === docId)?.m === true ? (
                <span className="mandatory">*</span>
              ) : (
                ""
              )}
              {docList?.find((v: any) => v?.id === docId)?.name || ""}
            </>
          );
        }
      },
      width: 250,
      maxWidth: 250,
    },
    {
      accessor: "byteArray",
      Header: !showdata ? t("resource_1:upload") : "Download",
      Cell: uploadDoument,
      width: 450,
      maxWidth: 450,
    },
    {
      accessor: "fileName",
      Header: t("resource_1:fileName"),
    },
  ];

  const handleCancel = () => {
    if (requesttp && invoice_request_id) {
      dispatch(
        cancelInvoiceRequest({
          request_id: AESDecrypt(invoice_request_id),
          tpoId: tpoId,
        })
      )
        .then((response: any) => {
          if (response.payload.data.responseCode == "SUCCESS") {
            toast.success(response.payload.data.responseMsg, {
              theme: "colored",
              toastId: 'c375128'
            });
            setinvoiceCancel(false);
            setTimeout(() => {
              dispatch(updateDashbaordRefresh(true));
            }, 500);
          } else {
            toast.error("Something Went Wrong", {
              toastId: 'd35afae'
            });
          }

          resetForm();

          setTimeout(() => {
            onBack();
          }, 500);
        })
        .catch(() => { });
    }
  };

  const onSubmit = handleSubmit(
    async (data: any) => {
      const blData = blNumbListVal.find(
        (bl: any) => bl.blNumber === data.blno.trim()
      );

      // BL/SOLIST validations
      if (profileDetails.opcPartnerType == "ICD") {
        if (additionalInputs?.length < 1) {
          toast.error("Atlease one BL Number is required",
            {
              toastId: 'bb81564'
            }
          );
          return;
        }
        let errorFound = false;
        additionalInputs.forEach(({ value, rowNumber }, i) => {
          // blank or null error
          if (!value || value.trim() == "") {
            errorFound = true;
            blListFieldError(rowNumber, "BL Number is required");
            return;
          }

          // duplicate error
          if (
            additionalInputs
              .map((i) => i.value)
              .slice(i + 1)
              .includes(value)
          ) {
            errorFound = true;
            blListFieldError(rowNumber, "Duplicate BL Number Entry");
            return;
          }

          //invalid error
          if (blNumbListVal.find((item: any) => item.blNumber === value)) {
            return;
          } else {
            errorFound = true;
            blListFieldError(rowNumber, "BL Number is invalid");
          }
        });
        if (errorFound) return;
      }

      // Check if there's an error in the 'blno' field or if the DO number is missing
      /*   if (trafficVal == 1 && (errors.blno || (blData && !blData.doNumber))) { */
      if (
        trafficVal == 1 &&
        profileDetails?.opcPartnerType !== "ICD" && // Ensure profileDetails.opcPartnerType is not "ICD"
        (errors.blno || (blData && !blData.doNumber))
      ) {
        setError("blno", {
          type: "manual",
          message: "DO Number is not present for this BL/SO Number",
        });

        // Safely extract and display the error message
        const errorMessage =
          typeof errors.blno?.message === "string"
            ? errors.blno.message
            : "Please correct the BL/SO Number before submitting.";

        toast.error(errorMessage, {
          toastId: 'fcb5e5c'
        });
        return; // Stop form submission
      }
      if (
        profileDetails?.opcPartnerType === "CFA"
          ? (trafficVal == 1 &&
            Object.entries(selectedAttachmentName).filter(
              ([_, value]) => value
            ).length == 3) ||
          (trafficVal == 2 &&
            Object.entries(selectedAttachmentName).filter(
              ([_, value]) => value
            ).length == 5)
          : true
      ) {
        const selectedDocAttachment =
          trafficVal === 2 ? docAttachmentExport : docAttachment;

        const formattedData = {
          odexRefNo: data.invoiceNumber,
          invoiceType: parseInt(data.invoiceType),
          requestedStatus: "Requested",
          customerTin: data.customerTin,
          vesselVoyage: selectedVessel,
          traffic: data.traffic == 1 ? "Import" : "Export",
          deliveryType: data.deliveryType == 1 ? "Local" : "Transit",
          remarks: data.remarks || "",
          shippingLine: data.shipperConsigneeName,
          doNo: data.doNo,
          croNo: data.croNo,
          cifValue: profileDetails.opcPartnerType == "ICD" ? 0 : data.cifNo,
          documents: selectedDocAttachment?.list?.map?.(
            (d: any, index: any) => ({
              docId: d?.docId,
              filePath: d?.docId ? selectedAttachmentFilePath?.[d.docId] : null,
              fileName: d?.docId
                ? selectedAttachmentName?.[d.docId]?.name
                : null,
              serverFilePath: d?.docId ? serverFilePath?.[d.docId]?.[0] : null,
              tempFileName: d?.docId ? serverFilePath?.[d.docId]?.[1] : null,
            })
          ),
          paidThrough: moment(data.paidThrough).format("YYYY-MM-DDTHH:mm:ss"),
          blno:
            profileDetails?.opcPartnerType == "ICD"
              ? additionalInputs.map((i) => i.value).join(",")
              : data.blno,
          tpoId: tpoId,
        };
        try {
          dispatch(submitInvoiceRequest(formattedData))
            .unwrap()
            .then((response: any) => {
              if (response?.data?.responseCode === "SUCCESS") {
                toast.success("Invoice Request Submitted Successfully", {
                  theme: "colored",
                  toastId: 'd68de68'
                });
                setTimeout(() => {
                  onBack();
                  setTimeout(() => {
                    dispatch(updateDashbaordRefresh(true));
                  }, 2000);
                }, 500);
              } else {
                toast.error(response?.data?.responseMsg, {
                  theme: "colored",
                  toastId: '7d95900'
                });
                resetForm();
              }
            });

          /*     toast.success('Invoice Request Sumbitted Successfully', {
            theme: 'colored',
          }); */
          // resetForm();
        } catch (err: any) {
          console.error(err.message);
        }
      } else {
        toast.error("Upload all required documents",
          {
            toastId: '11c1787'

          }
        );
      }
    },
    (error: any) => {
      console.error("Handle submit error: ", error);
    }
  );

  // const _onCloseForm = () => {
  //   dispatch(toggleDocumentHistoryColumnShort(false));
  //   navigate('/instruction');
  // };

  const _onCloseForm = () => {
    dispatch(toggleDocumentHistoryColumnShort(false));
    setTimeout(() => {
      onBack();
    }, 500);
  };

  const formatTIN = (value: string) => {
    const cleaned = value.replace(/[^\w]/g, "");
    const match = cleaned.match(/^(\w{0,3})(\w{0,3})(\w{0,3})$/);

    if (match) {
      return [match[1], match[2], match[3]].filter((part) => part).join("-");
    }

    return value;
  };

  const validateBl = (val: any) => {
    var containerList: any = [];
    if (val.length > 0) {
      if (blNumbListVal.find((item: any) => item.blNumber == val)) {
        clearErrors("blno");
      } else {
        setError("blno", {
          type: "manual",
          message: "BL/SO number is invalid",
        });
      }

      /*   axios
        .get(
          //`${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-management/v1/common/getVesselVoyageFromTicts?shipmentTp=Import`
          `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-management/v1/common/getDoDataFromTicts?blNo=${val}`
        )
        .then((response: any) => {
          console.log('responseeee', response);
        });
 */
      // dispatch(blValidation({ blnumber: val, shipmentType: trafficVal })).then(
      //   (response: any) => {
      //     debugger
      //     if(response.payload.data.length > 0){
      //       const transformedData = response.payload.data.map((item:any) => ({
      //         containerNo: item.containerNumber
      //     }));

      //     }else{

      //     }

      //   }
      // );
    }
  };

  const handleTINChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const formattedValue = formatTIN(value);
    setValue("customerTin", formattedValue, { shouldValidate: true });
  };

  const handleInputChange = useDebounce((inputValue: string) => {
    setVesselTxt(inputValue);
    if (inputValue.length >= 3) {
      dispatch(
        fetchVesselSuggestions({
          vesselKey: inputValue.toUpperCase(),
          requestType: 911,
          permitId: 0,
        })
      );
    }
    if (inputValue.length < 3) {
      setVesselList([]);
    }
  }, 500);


  useEffect(() => {
    const catchResponseFunc = () => {
      setValue('traffic', "")
      toast.error('Failed to fetch documents due to network error', { toastId: 'doc_failure' });
    }
    if (tpoId && getValues('traffic') && profileDetails?.opcPartnerType && dataCountry) {
      if (getValues('traffic') == 1) {
        dispatch(getInvoiceAttachmentListImport({
          dataCountry,
          partner: profileDetails.opcPartnerType,
          tpoId
        })).unwrap().catch(catchResponseFunc);
      }
      else {
        dispatch(getInvoiceAttachmentListExport({
          dataCountry,
          partner: profileDetails.opcPartnerType,
          tpoId
        })).unwrap().catch(catchResponseFunc);
      }
    }
  }, [getValues('traffic')]);


  return (
    <>
      <Card style={{ minHeight: "100%" }}>
        <Card.Header className="d-flex align-items-center border-bottom sticky-top bg-white">
          <FontAwesomeIcon
            icon={faArrowAltCircleLeft}
            className="text-warning cursor-pointer fa-lg mb-1 me-1"
            onClick={_onCloseForm}
          />
          <h2 className="fs-0 text-primary mb-1">{t("Invoice Request")}</h2>
        </Card.Header>
        <Card.Body>
          <div className="container">
            <Form onSubmit={onSubmit} autoComplete="off">
              <div className="row">
                {(profileDetails?.opcPartnerType === "CFA" ||
                  profileDetails?.opcPartnerType === "ICD" ||
                  (selectedPartnerType == "CFA" &&
                    profileDetails?.opcPartnerType === "PTO") ||
                  (selectedPartnerType == "ICD" &&
                    profileDetails?.opcPartnerType === "PTO")) && (
                    <div className="col-lg-6 mb-3">
                      <Form.Group controlId="invoiceNumber">
                        <span className="mandatory">*</span>
                        {(profileDetails?.opcPartnerType === "CFA" ||
                          (selectedPartnerType == "CFA" &&
                            profileDetails?.opcPartnerType === "PTO")) && (
                            <Form.Label>
                              {t("resource_3:invoiceReferenceNumber")}
                            </Form.Label>
                          )}
                        {(profileDetails?.opcPartnerType === "ICD" ||
                          (selectedPartnerType == "ICD" &&
                            profileDetails?.opcPartnerType === "PTO")) && (
                            <Form.Label>
                              {t("resource_3:OdexReferenceID")}
                            </Form.Label>
                          )}
                        <Form.Control
                          size="sm"
                          {...register("invoiceNumber")}
                          className={`fs--1 form-control form-control-sm ${errors.invoiceNumber ? "is-invalid" : ""
                            }`}
                          type="text"
                          maxLength={20}
                          disabled={true}
                        />
                        <div className="invalid-feedback">
                          {errors.invoiceNumber?.message?.toString()}
                        </div>
                      </Form.Group>
                    </div>
                  )}
                {(profileDetails?.opcPartnerType === "CFA" ||
                  profileDetails?.opcPartnerType === "ICD" ||
                  (selectedPartnerType == "CFA" &&
                    profileDetails?.opcPartnerType === "PTO") ||
                  (selectedPartnerType == "ICD" &&
                    profileDetails?.opcPartnerType === "PTO")) && (
                    <div className="col-lg-6 mb-3">
                      <Form.Group controlId="traffic">
                        <span className="mandatory">*</span>
                        <Form.Label>{t("resource_2:invoice_type")}</Form.Label>
                        <Form.Select
                          disabled={true}
                          {...register("invoiceType")}
                          className={`fs--1 form-control form-control-sm ${errors.invoiceType ? "is-invalid" : ""
                            }`}
                          value={selectedInvoiceOption}
                        >
                          <option value="">Select Invoice Type</option>
                          {invoiceOptions.map(({ codeId, codeDesc }: any) => (
                            <option value={codeId}>{codeDesc}</option>
                          ))}
                        </Form.Select>
                        <div className="invalid-feedback">
                          {errors.invoiceType?.message?.toString()}
                        </div>
                      </Form.Group>
                    </div>
                  )}
                {(profileDetails?.opcPartnerType === "CFA" ||
                  (selectedPartnerType == "CFA" &&
                    profileDetails?.opcPartnerType === "PTO")) && (
                    <div className="col-lg-6 mb-3">
                      <Form.Group controlId="customerTin">
                        <span className="mandatory">*</span>
                        <Form.Label>
                          {t("resource_2:shipper_consignee_tin")}
                        </Form.Label>
                        <Form.Control
                          disabled={showdata && !ptoEdit}
                          size="sm"
                          {...register("customerTin")}
                          onChange={handleTINChange}
                          className={`fs--1 form-control form-control-sm ${errors.customerTin ? "is-invalid" : ""
                            }`}
                          type="text"
                          maxLength={20}
                        />
                        <div className="invalid-feedback">
                          {errors.customerTin?.message?.toString()}
                        </div>
                      </Form.Group>
                    </div>
                  )}
                {(profileDetails?.opcPartnerType === "CFA" ||
                  (selectedPartnerType == "CFA" &&
                    profileDetails?.opcPartnerType === "PTO")) && (
                    <div className="col-lg-6 mb-3">
                      <Form.Group controlId="shipperConsigneeName">
                        <span className="mandatory">*</span>
                        <Form.Label>
                          {t("resource_2:shipper_consignee_name")}
                        </Form.Label>
                        <Form.Control
                          disabled={showdata && !ptoEdit}
                          size="sm"
                          {...register("shipperConsigneeName")}
                          className={`fs--1 form-control form-control-sm ${errors.shipperConsigneeName ? "is-invalid" : ""
                            }`}
                          type="text"
                          maxLength={150}
                        />
                        <div className="invalid-feedback">
                          {errors.shipperConsigneeName?.message?.toString()}
                        </div>
                      </Form.Group>
                    </div>
                  )}

                {(profileDetails?.opcPartnerType === "CFA" ||
                  profileDetails?.opcPartnerType === "ICD" ||
                  (selectedPartnerType == "CFA" &&
                    profileDetails?.opcPartnerType === "PTO") ||
                  (selectedPartnerType == "ICD" &&
                    profileDetails?.opcPartnerType === "PTO")) && (
                    <div className="col-lg-6 mb-3">
                      <Form.Group controlId="traffic">
                        <span className="mandatory">*</span>
                        <Form.Label>{t("resource_1:traffic")}</Form.Label>
                        <Controller
                          name="traffic"
                          control={control}
                          render={({ field }) => (
                            <Form.Select
                              {...field}
                              disabled={
                                showdata ||
                                profileDetails?.opcPartnerType === "ICD"
                              }
                              className={`fs--1 form-control form-control-sm ${errors.traffic ? "is-invalid" : ""
                                }`}
                              onChange={(e) => {
                                field.onChange(e);
                                setTrafficVal(parseInt(e.target.value));
                                setValue("blno", "");
                                clearErrors("blno");
                                setValue("vesselVoyage", "");
                                clearErrors("vesselVoyage");
                                clearErrors();
                                dispatch(updateSelectedAttachmentName({}));
                                setLoadingFileState({});
                                dispatch(updateSelectedFileServerPath({}));
                              }}
                            >
                              <option value="">Select Traffic</option>
                              {trafficOptions?.map?.(
                                ({ codeId, codeDesc }: any) => (
                                  <option value={codeId}>{codeDesc}</option>
                                )
                              )}
                            </Form.Select>
                          )}
                        />
                        <div className="invalid-feedback">
                          {errors.traffic?.message?.toString()}
                        </div>
                      </Form.Group>
                    </div>
                  )}
                {(profileDetails?.opcPartnerType === "CFA" ||
                  profileDetails?.opcPartnerType === "ICD" ||
                  (selectedPartnerType == "CFA" &&
                    profileDetails?.opcPartnerType === "PTO") ||
                  (selectedPartnerType == "ICD" &&
                    profileDetails?.opcPartnerType === "PTO")) && (
                    <div className="col-lg-6 mb-3">
                      <Form.Group controlId="paidThrough">
                        <span className="mandatory">*</span>
                        <Form.Label>{t("resource_3:paidThruDate")}</Form.Label>
                        {/* <Form.Control
                          disabled={showdata}
                          size="sm"
                          {...register("paidThrough")}
                          className={`fs--1 form-control form-control-sm ${errors.paidThrough ? "is-invalid" : ""
                            }`}
                          type="date"
                          min={new Date().toISOString().split("T")[0]}
                          onKeyDown={(e) => e.preventDefault()} // Prevent typing
                        /> */}
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <ReactDatePicker
                            disabled={showdata}
                            minDate={new Date()}
                            showTimeInput={false}
                            selected={watch("paidThrough")}
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                            onChange={(value) => {
                              if (value != null) {
                                setValue("paidThrough", value)
                              }
                            }}
                          />
                        </div>
                        <div className="invalid-feedback">
                          {errors.paidThrough?.message?.toString()}
                        </div>
                      </Form.Group>
                    </div>
                  )}
                {(profileDetails?.opcPartnerType === "CFA" ||
                  profileDetails?.opcPartnerType === "ICD" ||
                  (selectedPartnerType == "CFA" &&
                    profileDetails?.opcPartnerType === "PTO") ||
                  (selectedPartnerType == "ICD" &&
                    profileDetails?.opcPartnerType === "PTO")) && (
                    <div className="col-lg-6 mb-3">
                      <Form.Group controlId="deliveryType">
                        <span className="mandatory">*</span>
                        <Form.Label>{t("resource_2:local_transit")}</Form.Label>
                        <Form.Select
                          disabled={showdata}
                          {...register("deliveryType")}
                          className={`fs--1 form-control form-control-sm ${errors.deliveryType ? "is-invalid" : ""
                            }`}
                        >
                          <option value="">Select Local/Transit</option>
                          {localTransitOptions?.map?.(
                            ({ codeId, codeDesc }: any) => (
                              <option value={codeId}>{codeDesc}</option>
                            )
                          )}
                        </Form.Select>
                        <div className="invalid-feedback">
                          {errors.deliveryType?.message?.toString()}
                        </div>
                      </Form.Group>
                    </div>
                  )}
                {(profileDetails?.opcPartnerType === "CFA" ||
                  profileDetails?.opcPartnerType === "ICD" ||
                  (selectedPartnerType == "CFA" &&
                    profileDetails?.opcPartnerType === "PTO") ||
                  (selectedPartnerType == "ICD" &&
                    profileDetails?.opcPartnerType === "PTO")) && (
                    <div className="col-lg-6 mb-3">
                      <Form.Group
                        style={{ padding: "0px" }}
                        controlId="vesselVoyage"
                      >
                        <span className="mandatory">*</span>
                        <Form.Label>{t("resource_1:vessel")}</Form.Label>

                        {!showdata && (
                          <div>
                            <Controller
                              name="vesselVoyage"
                              control={control}
                              rules={{ required: "This field is required" }}
                              render={({ field }: any) => (
                                <Select
                                  {...field}
                                  options={VesselList}
                                  noOptionsMessage={() => null}
                                  isDisabled={showdata}
                                  isClearable={true}
                                  maxMenuHeight={150}
                                  onChange={(e: any) => {
                                    if (!trafficVal) {
                                      setValue("vesselVoyage", "");
                                      setselectedVessel(" ");
                                      setError("vesselVoyage", {
                                        type: "manual",
                                        message: "Please select traffic first.",
                                      });
                                    } else if (e === null) {
                                      field.onChange(null);
                                      setselectedVessel("");
                                      setValue("doNo", "");
                                      setValue("blno", ""); // Clear the BL Number field when vessel is cleared
                                      clearErrors("blno");
                                      clearErrors("doNo");
                                    } else {
                                      field.onChange({
                                        label: e.label,
                                        value: e.value,
                                        visitKey: e.visitKey,
                                      });
                                      setselectedVessel(e.value);
                                      dispatch(
                                        blNumbList({
                                          visit: e.visitKey,
                                          permit:
                                            trafficVal == 1 ? "Import" : "Export",
                                        })
                                      );
                                      setValue("doNo", "");
                                      clearErrors("blno");
                                      clearErrors("doNo");
                                      setValue("blno", ""); // Clear the BL Number field when vessel is cleared
                                    }
                                  }}
                                  styles={customDropdownStyles}
                                  filterOption={customFilterOption}
                                  onInputChange={handleInputChange}
                                  className={`fs--1 form-control form-control-sm ${errors.vesselVoyage ? "is-invalid" : ""
                                    }  ${loadingVesselList ? "loadingPulse" : ""}`}
                                />
                              )}
                            />
                            <div className="invalid-feedback">
                              {errors.vesselVoyage?.message?.toString()}
                            </div>
                          </div>
                        )}

                        {showdata && (
                          <div>
                            <Form.Control
                              disabled={showdata}
                              size="sm"
                              {...register("vesselVoyage")}
                              className={`fs--1 form-control form-control-sm ${errors.vesselVoyage ? "is-invalid" : ""
                                }`}
                              type="text"
                              maxLength={20}
                            />
                          </div>
                        )}
                      </Form.Group>
                    </div>
                  )}

                {(profileDetails?.opcPartnerType === "CFA" ||
                  //profileDetails?.opcPartnerType === "ICD" ||
                  (selectedPartnerType == "CFA" &&
                    profileDetails?.opcPartnerType === "PTO") ||
                  (selectedPartnerType == "ICD" &&
                    profileDetails?.opcPartnerType === "PTO")) && (
                    <div className="col-lg-6 mb-3">
                      <Form.Group controlId="blno">
                        <span className="mandatory">*</span>
                        <Form.Label>{t("resource_2:bl_so_number")}</Form.Label>
                        <Form.Control
                          disabled={showdata}
                          size="sm"
                          {...register("blno")}
                          className={`fs--1 form-control form-control-sm ${errors.blno ? "is-invalid" : ""
                            }`}
                          type="text"
                          // maxLength={20}
                          //    onBlur={(e) => validateBl(e.target.value.trim())}
                          onBlur={(e) => {
                            const blNumber = e.target.value.trim();
                            if (!blNumber) {
                              setError("blno", {
                                type: "manual",
                                message: "BL number is required",
                              });
                              return;
                            }
                            const { doNumber } = blNumbListVal.find(
                              (data: any) => data?.blNumber === blNumber
                            ) ?? { doNumber: "" };
                            setValue("doNo", doNumber);

                            // Case 1: Validate the BL number using validateBl function
                            validateBl(blNumber);

                            // Case 2 & 3: Handle traffic-specific checks
                            if (
                              trafficVal === 1 &&
                              profileDetails.opcPartnerType === "CFA"
                            ) {
                              // Check if the BL number exists in the response and if it has a DO number
                              const blData = blNumbListVal.find(
                                (bl: any) => bl.blNumber === blNumber
                              );

                              if (blData && blData.doNumber) {
                                clearErrors("blno"); // Clear any errors if BL number is valid and has a DO number
                              } else if (blData && !blData.doNumber) {
                                setError("blno", {
                                  type: "manual",
                                  message:
                                    "DO Number is not present for this SO/BL Number",
                                });
                              } else {
                                setError("blno", {
                                  type: "manual",
                                  message: "SO/BL number is invalid",
                                });
                              }
                            } else if (trafficVal === 2) {
                              // Only validate the BL number for 'Export'
                              const blData = blNumbListVal.find(
                                (bl: any) => bl.blNumber === blNumber
                              );

                              if (!blData) {
                                setError("blno", {
                                  type: "manual",
                                  message: "SO/BL number is invalid",
                                });
                              } else {
                                clearErrors("blno"); // Clear any errors if the BL number is valid
                              }
                            }
                          }}
                          onChange={(e) => {
                            if (!selectedVessel) {
                              setError("blno", {
                                message: "Please select vessel first",
                              });
                              setValue("doNo", "");
                              setValue("blno", "");
                            }
                            setValue("blno", e.target.value.trim());
                          }}
                        />
                        <div className="invalid-feedback">
                          {errors.blno?.message?.toString()}
                        </div>
                      </Form.Group>
                    </div>
                  )}
                {profileDetails?.opcPartnerType == "ICD" && (
                  <div className="col-lg-12">
                    {!showdata && (
                      <div className="d-flex">
                        <button
                          type="button"
                          onClick={handleAddInput}
                          className="btn-falcon-primary btn btn-primary btn-sm mb-2"
                          style={{ width: "80px" }}
                        >
                          <FontAwesomeIcon className="me-1" icon={faAdd} /> Row
                        </button>
                        <Dropdown navbar={true} ref={dropdownRef} style={{}}>
                          <Dropdown.Toggle
                            bsPrefix="toggle"
                            className="btn-falcon-primary btn btn-primary btn-sm mb-2 mx-2"
                          >
                            Upload .xlsx
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            className="
                          dropdown-caret dropdown-menu-card  dropdown-menu-end p-3"
                            style={{ width: "500px" }}
                          >
                            <div>
                              <input
                                type="file"
                                className="form-control mb-2"
                                accept=".xlsx"
                                onChange={(e) => {
                                  consumeExcelFile(e, setAdditionalInputs);
                                  e.target.value = "";
                                  const curr: any = dropdownRef?.current;
                                  curr.click?.();
                                }}
                              />
                              <span
                                onClick={() => {
                                  window.open(
                                    `/api/opc-rest-v1/v1/common/openDocument?id=1&moduleTp=INV_REQ_TEMPLETE&linerId=1`,
                                    "_blank"
                                  );
                                }}
                                className="cursor-pointer mt-2"
                                style={{ color: "#2C7BE5" }}
                              >
                                Click here to download the sample excel format
                              </span>
                              <span style={{ color: "#f5803e" }}>
                                <br />
                                Note: First row will be skipped as it will be
                                the column name.
                              </span>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                        <div>
                          <Form.Label className="fs--1">BL Count : </Form.Label>
                          <SoftBadge pill className="fs--2 mx-1" bg="primary">
                            {`${additionalInputs.length}`.padStart(2, "0")}
                          </SoftBadge>
                        </div>
                      </div>
                    )}
                    <DataTable
                      gridName="BL/SOLIST"
                      columns={[
                        {
                          accessor: "value",
                          Header: "BL/SO Number",
                          width: 300,
                          maxWidth: 400,
                          Cell: (rowData: any) => {
                            const { rowNumber, value, error } =
                              rowData.row.original;
                            return (
                              <div className="position-relative mt-2">
                                <input
                                  type="text"
                                  defaultValue={value}
                                  disabled={showdata}
                                  onBlur={(e) =>
                                    handleInputChangeValue(
                                      rowNumber,
                                      e.target.value
                                    )
                                  }
                                  className={`form-control form-control-sm ${error ? "is-invalid" : ""
                                    }`}
                                />
                              </div>
                            );
                          },
                        },
                        {
                          accessor: "remove",
                          Header: "",
                          width: 40,
                          maxWidth: 40,
                          Cell: (rowData: any) => {
                            const { rowNumber } = rowData.row.original;
                            return (
                              <Button
                                variant="falcon-warning"
                                style={{
                                  background: "transparent",
                                  border: "none",
                                  boxShadow: "none",
                                  padding: "0px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  display: "flex",
                                  height: "100%",
                                }}
                                disabled={showdata}
                                onClick={() => handleRemoveInput(rowNumber)}
                              >
                                <FontAwesomeIcon icon={faTrash} size="sm" />
                              </Button>
                            );
                          },
                        },
                        {
                          accessor: "error",
                          Header: "",
                          width: 200,
                          maxWidth: 200,
                          Cell: (rowData: any) => {
                            const { error } = rowData.row.original;
                            if (!error) return <></>;
                            return (
                              <div className="d-flex align-items-center h-100">
                                <span
                                  style={{ color: "#E63757", fontWeight: 400 }}
                                >
                                  {error}
                                </span>
                              </div>
                            );
                          },
                        },
                      ]}
                      data={additionalInputs}
                      shortPrepend="qp_"
                      perPage={100}
                      sortable={false}
                      searchable={false}
                      showColumnFilter={false}
                      selection={false}
                      pagination={false}
                      seekPagination={false}
                      showExport={false}
                      localFilter={true}
                      uniqueFilterKey="invoicereq"
                      autoHeight={true}
                      tableMinHeight={100}
                      staticHiddenCols={showdata ? ["remove"] : []}
                    />
                  </div>
                )}

                {(profileDetails?.opcPartnerType === "CFA" ||
                  (selectedPartnerType == "CFA" &&
                    profileDetails?.opcPartnerType === "PTO")) &&
                  trafficVal == 1 && (
                    <div className="col-lg-6 mb-3">
                      <Form.Group controlId="doNo">
                        <span className="mandatory">*</span>
                        <Form.Label>{t("resource_2:do_number")}</Form.Label>
                        <Form.Control
                          disabled={true}
                          size="sm"
                          {...register("doNo")}
                          className={`fs--1 form-control form-control-sm ${errors.doNo ? "is-invalid" : ""
                            }`}
                          type="text"
                          maxLength={20}
                        />
                        <div className="invalid-feedback">
                          {errors.doNo?.message?.toString()}
                        </div>
                      </Form.Group>
                    </div>
                  )}
                {(profileDetails?.opcPartnerType === "CFA" ||
                  (selectedPartnerType == "CFA" &&
                    profileDetails?.opcPartnerType === "PTO")) &&
                  trafficVal !== 0 && (
                    <div className="col-lg-6 mb-3">
                      <Form.Group controlId="croNo">
                        <span className="mandatory">*</span>
                        <Form.Label>{t("resource_2:cro_number")}</Form.Label>
                        <Form.Control
                          disabled={showdata && !ptoEdit}
                          size="sm"
                          {...register("croNo")}
                          className={`fs--1 form-control form-control-sm ${errors.croNo ? "is-invalid" : ""
                            }`}
                          type="text"
                          maxLength={20}
                          onInput={(e: any) => {
                            e.target.value = e.target.value.replace(
                              /[^a-zA-Z0-9]/g,
                              ""
                            );
                          }}
                        />
                        <div className="invalid-feedback">
                          {errors.croNo?.message?.toString()}
                        </div>
                      </Form.Group>
                    </div>
                  )}
                {(profileDetails?.opcPartnerType === "CFA" ||
                  (selectedPartnerType == "CFA" &&
                    profileDetails?.opcPartnerType === "PTO")) &&
                  trafficVal !== 0 && (
                    <div className="col-lg-6 mb-3">
                      <Form.Group controlId="cifNo">
                        <span className="mandatory">*</span>
                        <Form.Label>{t("resource_3:cif_value_tzs")}</Form.Label>
                        <Form.Control
                          disabled={showdata && !ptoEdit}
                          size="sm"
                          {...register("cifNo")}
                          className={`fs--1 form-control form-control-sm ${errors.cifNo ? "is-invalid" : ""
                            }`}
                          type="text"
                          maxLength={32}
                          onInput={(e: any) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                        />
                        <div className="invalid-feedback">
                          {errors.cifNo?.message?.toString()}
                        </div>
                      </Form.Group>
                    </div>
                  )}
                {(profileDetails?.opcPartnerType === "CFA" ||
                  (selectedPartnerType == "CFA" &&
                    profileDetails?.opcPartnerType === "PTO")) &&
                  trafficVal !== 0 && (
                    <DataTable
                      data={
                        trafficVal == 2
                          ? docAttachmentExport?.list ?? []
                          : docAttachment?.list ?? []
                      }
                      gridName="RELEASE_ATTACHMENT"
                      columns={docAttachmentColumns}
                      sortable={false}
                      searchable={false}
                      showColumnFilter={false}
                      selection={false}
                      perPage={10}
                      pagination={false}
                      showExport={false}
                      uniqueFilterKey="releaseAttachment"
                      autoHeight={true}
                      staticHiddenCols={["dndInstructionAttId", "fileName"]}
                    />
                  )}

                {(profileDetails?.opcPartnerType === "CFA" ||
                  profileDetails?.opcPartnerType === "ICD" ||
                  (selectedPartnerType == "CFA" &&
                    profileDetails?.opcPartnerType === "PTO") ||
                  (selectedPartnerType == "ICD" &&
                    profileDetails?.opcPartnerType === "PTO")) && (
                    <div className="col-lg-6 mb-2">
                      <Form.Group controlId="remarks">
                        <Form.Label>{t("resource_2:user_remarks")}</Form.Label>
                        <Form.Control
                          disabled={showdata}
                          className={`fs--1 form-control form-control-sm ${errors.remarks ? "is-invalid" : ""
                            }`}
                          {...register("remarks")}
                          as="textarea"
                          onChange={(e) => {
                            const value = e.target.value;
                            // Trim the value to 250 characters
                            e.target.value = value.substring(0, 250);
                          }}
                        />
                        <div className="invalid-feedback">
                          {errors.remarks?.message?.toString()}
                        </div>
                      </Form.Group>
                    </div>
                  )}
                {((profileDetails?.opcPartnerType == "PTO" &&
                  status == "308") ||
                  (status != "303" &&
                    status != "307" &&
                    status != "308" &&
                    showdata)) && (
                    <div className="col-lg-6 mb-3">
                      <Form.Group controlId="invoiceNumber">
                        {profileDetails.opcPartnerType === "PTO" && (
                          <span className="mandatory">*</span>
                        )}
                        <Form.Label>Approver Remarks</Form.Label>
                        <Form.Control
                          size="sm"
                          className={`fs--1 form-control form-control-sm ${errors.linerRemarks ? "is-invalid" : ""
                            }`}
                          as="textarea"
                          disabled={
                            !(
                              profileDetails.opcPartnerType === "PTO" &&
                              !["311", "307", "304", "309"].includes(`${status}`)
                            ) || rbac.invoiceRequestApproveReject != "Y"
                          }
                          {...register("linerRemarks")}
                        />
                        <div className="invalid-feedback">
                          {errors.linerRemarks?.message?.toString()}
                        </div>
                      </Form.Group>
                    </div>
                  )}

                {/* <Button variant='falcon-primary me-2' size='sm' onClick={onSubmit}>
                <FontAwesomeIcon icon={faSave} className='me-2' />
                {t('resource_1:submit')}
              </Button> */}
                {!showdata && (
                  <div className="col-lg-8 offset-lg-2 mt-2 d-flex justify-content-center align-items-center">
                    <Button
                      variant="falcon-primary me-2"
                      size="sm"
                      disabled={Object.values(loadingFileState).some(
                        (data) => data !== 100 && data !== -1 && data !== 0
                      )}
                      onClick={onSubmit}
                    >
                      <FontAwesomeIcon icon={faSave} className="me-2" />
                      {t("resource_1:submit")}
                    </Button>

                    <Button
                      variant="falcon-danger"
                      onClick={() => {
                        resetForm();
                      }}
                      size="sm"
                    >
                      <FontAwesomeIcon icon={faRedo} className="me-2" />
                      Reset
                    </Button>
                  </div>
                )}

                {showdata &&
                  status == "308" &&
                  profileDetails.opcPartnerType !== "PTO" && (
                    <div className="col-lg-8 offset-lg-2 mt-2 d-flex justify-content-center align-items-center">
                      <Button
                        className="me-2"
                        variant="falcon-danger"
                        size="sm"
                        type="button"
                        onClick={() => setinvoiceCancel(true)}
                      >
                        <FontAwesomeIcon icon={faCancel} className="me-2" />
                        Cancel Request
                      </Button>
                    </div>
                  )}
                {profileDetails.opcPartnerType === "PTO" &&
                  rbac.invoiceRequestApproveReject == "Y" &&
                  !["311", "307", "304", "309"].includes(`${status}`) && (
                    <div className="d-flex justify-content-center align-items-center mt-4">
                      <>
                        <Button
                          variant="success"
                          className="me-4 approve"
                          size="sm"
                          onClick={() => {
                            clearErrors();
                            onApproveReject({ arStatus: 309 });
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="me-2 approve"
                          />
                          {t("resource_1:approve")}
                        </Button>

                        <Button
                          variant="danger reject"
                          size="sm"
                          onClick={() => {
                            clearErrors();
                            onApproveReject({ arStatus: 304 });
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="me-2 reject"
                          />
                          {t("resource_1:reject")}
                        </Button>
                      </>
                    </div>
                  )}
              </div>
            </Form>
          </div>
        </Card.Body>
      </Card>

      <Modal show={invoiceCancel} onHide={() => setinvoiceCancel(false)}>
        <Modal.Header className="p-2 pb-1" closeButton>
          <Modal.Title className="h6">
            Do you want to Cancel the Invoice?
          </Modal.Title>
        </Modal.Header>

        <div className="d-flex justify-content-center align-items-center p-2">
          <Button
            variant="falcon-primary"
            onClick={handleCancel}
            size="sm"
            className="me-2"
          >
            Yes
          </Button>
          <Button
            className="me-2"
            onClick={() => setinvoiceCancel(false)}
            variant="falcon-danger"
            size="sm"
          >
            No
          </Button>
        </div>
      </Modal>
    </>
  );
};

const consumeExcelFile = (event: any, setter: Function) => {
  const file = event.target.files[0];
  if (!file) return;

  // Check for .xlsx file type
  if (
    file.type !==
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  ) {
    toast.error("Please upload a valid .xlsx file.",
      {
        toastId: '7476d52'
      }
    );
    return;
  }

  const reader = new FileReader();

  reader.onload = function (event: any) {
    try {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      // Assuming you want to read only the first sheet
      const firstSheetName = workbook.SheetNames[0];
      if (!firstSheetName) {
        console.error("No sheets found in the workbook.");
        return;
      }

      const sheet = workbook.Sheets[firstSheetName];
      const json: any = XLSX.utils.sheet_to_json(sheet, {
        header: 1,
        defval: "",
      });

      const output = json
        .map((i: any) => ({
          value: `${i[0]}`,
          error: null,
          rowNumber: crypto.randomUUID(),
        }))
        .filter(
          ({ value }: any, i: number) => i != 0 && value && value?.trim() != ""
        );

      if (output.length == 0) {
        toast.error('Blank data file cannot be uploaded, Kindly insert at least 1 data entry and retry',
          {
            toastId: '2014f71'
          }
        );
        return;
      }
      toast.success("Added Entries Successfully!",
        {
          toastId: 'fae91ce'
        }
      );
      setter((state: any) => [
        ...state,
        ...json
          .map((i: any) => ({
            value: `${i[0]}`,
            error: null,
            rowNumber: crypto.randomUUID(),
          }))
          .filter(
            ({ value }: any, i: number) =>
              i != 0 && value && value?.trim() != ""
          ),
      ]);
    } catch (error) {
      console.error("Error reading file:", error);
      return;
    }
  };

  reader.readAsArrayBuffer(file);
};

export default InvoiceRequestInstruction;
