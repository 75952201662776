import { useRef } from "react";

const useDebounce = (func, wait) => {

    const timeoutHolder = useRef();
    return (...args) => {
      if(timeoutHolder.current)clearTimeout(timeoutHolder.current);
      timeoutHolder.current = setTimeout(() => func(...args), wait);
    }
  };
export default useDebounce