import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from '../../../../axiosSetup';
import axios, { AxiosError } from 'axios';
import queryString from 'query-string';

export const getAllUserDetailsByUser = createAsyncThunk(
  '/getAllUserDetails',
  async (payload: any) => {
    try {
      const response = await Axios.get(
        `api/user-management/v1/users/getAllUserDetailsByUser?partnerId=` +
          payload.partnerId +
          `&userId=` +
          payload.usrId
        //`api/user-management/v1/users/getAllUserDetailsByUser?partnerId=56&userId=3`,
      );

      return {
        userData: {
          next: null,
          previous: null,
          list: response?.data.userData.map((v: any) => ({
            ...v,
            mstPartnerId: v.mstPartnerId,
            mstUsrId: v.mstUsrId,
            loginId: v.loginId,
            usrNm: v.usrNm,
            contactNo: v.contactNo,
            title: v.title,
            contactPerson: v.contactPerson,
            partnerNm: v?.partnerNm,
            mstGrp: v.mstGrp.map((g: any) => {
              return {
                mstGrpId: g.mstGrpId,
                usrGrpNm: g.usrGrpNm,
              };
            }),
            grpNm: v.mstGrp?.map((v: any) => v.usrGrpNm).join(','),
            status: v.status,
            countryShortCode: v.countryShortCode,
            countryName: v.countryName,
            isRootUser: v.isRootUser,
          })),
        },
        mstPartnerId: response.data.mstPartnerId,
        grpNm: '',
        mstUsrId: response.data.mstUsrId,
        loginId: response.data.loginId,
        usrNm: response.data.usrNm,
        contactNo: response.data.contactNo,
        title: response.data.title,
        contactPerson: response.data.contactPerson,

        status: response.data.status,
        countryShortCode: response.data.countryShortCode,
        countryName: response.data.countryName,
        isRootUser: response.data.isRootUser,
        partnerNm: response?.data?.partnerNm,
      };
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const saveUserDetails = createAsyncThunk(
  '/saveUserDetails',
  async (payload: any) => {
    try {
      const response = await Axios({
        url: `/api/user-management/v1/users/saveUserDetails`,
        method: 'POST',
        data: {
          mstPartnerId: payload.mstPartnerId,
          mstUsrId: payload.mstUsrId,
          actionBy:payload.actionBy,
          actionOn:payload.actionOn,
          loginId: payload.loginId,
          usrNm: payload.usrNm,
          contactNo: payload.contactNo,
          userIdentityNo: payload.userIdentityNo,
          phnNo: payload.phnNo,
          title: payload.title,
          contactPerson: payload.contactPerson,
          mstGrp: payload.mstGrp.map((g: any) => {
            return {
              mstGrpId: g.mstGrpId,
              usrGrpNm: g.usrGrpNm,
            };
          }),
          status: payload.status,
          countryShortCode: payload.countryShortCode,
          isRootUser: payload.isRootUser,
          partnerNm: payload?.partnerNm,
          type: "opc"
        },
      });
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getGroupListRegistrationMaster = createAsyncThunk(
  '/getGroupList',
  async (payload: any) => {
    //  debugger
    try {
      const response = await Axios({
        url: `/api/user-management/v1/users/getGroupList?countryCode=${payload}`,
        method: 'POST',
      });
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);

      return [];
    }
  }
);

export const getGroupList = createAsyncThunk(
  '/getGroupList',
  async (payload: any) => {
    try {
      const response = await Axios({
        url: `/api/user-management/v1/users/getOpcGroupList`,
        method: 'POST',
      });
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);

      return [];
    }
  }
);
