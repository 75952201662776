import { useTranslation } from 'react-i18next';
import { faArrowAltCircleLeft } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo, faSave, } from '@fortawesome/free-solid-svg-icons';
import { t } from "i18next";
import { Button, Card, Form } from "react-bootstrap";
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import useDataCountry from '../../../../hooks/useDataCountry';
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { getDateFormat } from '../../../../helpers/dateFormat';
import { getSnooze, saveSnooze } from '../../dashboard/actions/instruction';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { Item } from 'react-bootstrap/lib/Breadcrumb';
import { AESDecrypt } from '../../../../encrypt-util';
import { useDispatch } from 'react-redux';
import { useBackNavigation } from '../../../../hooks/BackNavigation';
import { toast } from 'react-toastify';
import { clearData } from '../../dashboard/slices/snoozeSlice';
import { updateDashbaordRefresh } from '../../../common/components/DataTable/slices/DataTable';



const Snooze = () => {

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { blEventId, blNum } = useParams();
  const bl_event_id = AESDecrypt(blEventId)
  const blnum = AESDecrypt(blNum)
  const dispatch = useDispatch<AppDispatch>();

  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const selectedData: any = useSelector(
    (state: RootState) => state.dashboard.instructionDashboardList.result.filter((Item: any) => Item.bl_event_id == bl_event_id)
  );

  const blEventId_docHistory: any = useSelector(
    (state: RootState) => state.documentHistory.data?.[0]?.blEventId
  );

  const data = useSelector((state: RootState) => state.snooze.snoozeData)

  const demrageEmail = useSelector(
    (state: RootState) => state.demurrageViewSlice.demurrageForm.senderEmail)

  const demrageStatus = useSelector(
    (state: RootState) => state.demurrageViewSlice.demurrageForm?.status)

  const blNumber = useSelector(
    (state: RootState) => state.oblDetails.oblForm.number)

  const instructionType = useSelector(
    (state: RootState) => state.documentHistory.data.filter((item) => item.blEventId == bl_event_id ? item.document : '')
  );


  const onBack = useBackNavigation();
  const _onCloseForm = () => {
    if (location.pathname.split('/')[1] === 'instruction') {
      navigate('/instruction');
    } else {
      navigate(-1);
    }
  }

  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );
  const dataCountry = useDataCountry();




  type FormValues = {
    snooze: boolean;
    noOfDays: number | null;
    userSnoozeRemark: string;
    snoozeDate: Date;
    externalRemarks: boolean;
    emailTo: string;
    subject: string;
    remarkby: string;
  };

  const validationSchema = Yup.object().shape({
    noOfDays: Yup.number().required(
      t('resource_3:isRequired', { field: t('resource_3:noOfDays') })
    ).min(1, 'Please Select Value'),
    externalRemarks: Yup.boolean(),
    userSnoozeRemark: Yup.string().when('externalRemarks', {
      is: true, then: Yup.string().required(
        t('resource_3:isRequired', { field: t('resource_2:userRemark') })
      ), otherwise: Yup.string()
    }),
  });


  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    setValue('snoozeDate', new Date(new Date().getTime() + 86400000 * (+(watch('noOfDays') ?? 0))))
  }, [watch('noOfDays')])
  useEffect(() => {
    /* if(selectedData[0].snoozed=='Yes') */
    dispatch(clearData());
    dispatch(getSnooze({
      blEventId: selectedData[0]?.bl_event_id ?? blEventId_docHistory,

    }))
  }, [selectedData[0], blEventId_docHistory])

  useEffect(() => {

    if (data.snooze == '2013' || data.snooze == '2014') {
      setValue('snooze', data.snooze == '2014' ? false : true)
      //setValue('externalRemarks',data.)
      setValue('emailTo', data.emailTo)
      setValue('subject', data.subject)
      setValue('userSnoozeRemark', data.userSnoozeRemark)
      setValue('noOfDays', data.noOfDays)
      setValue('remarkby', data.remarkby)
      setValue('externalRemarks', data.externalRemarks)
    } else {
      setValue('snooze', true)
      setValue('externalRemarks', true)
      setValue('emailTo', selectedData[0]?.created_user ?? demrageEmail)
      setValue('subject', `Notification for ${selectedData[0]?.inst_type ?? instructionType[0]?.document} : ${selectedData[0]?.bl_no ?? blNumber}`)
      setValue('userSnoozeRemark', '')
      setValue('noOfDays', 1)
      setValue('remarkby', '')
    }

  }, [selectedData[0], data])

  const disableFields = !![
    { path: 'instruction/viewSnooze' },
  ].some(({ path, condition }: { path: string; condition?: boolean }) => {
    return (
      (condition ?? true) &&
      matchPath({ path, caseSensitive: true, end: true }, location.pathname)
    );
  });

  const [status, setStatus] = useState('');



  const resetForm = () => {
    // setNoOfDays(null);
    setValue('noOfDays', null)
    setValue('emailTo', '')
    setValue('userSnoozeRemark', '')
    setValue('subject', '')
    setValue('remarkby', '')
  };

  const onSubmit = handleSubmit(async (payload) => {
    try {
      dispatch(
        saveSnooze({
          ...payload,
          blEventId: selectedData[0]?.bl_event_id ?? blEventId_docHistory
        })
      ).unwrap()
        .then((response: any) => {
          toast.success(response?.data?.responseMsg, { toastId: 'b6f5da7' });
          dispatch(updateDashbaordRefresh(true))
          onBack()
        });
    } catch (err: any) {

    }

  });




  return (
    <Card className='h-100 overflow-auto'>
      <Card.Header className='d-flex align-items-center border-bottom sticky-top bg-white'>
        <FontAwesomeIcon
          icon={faArrowAltCircleLeft}
          className='text-warning cursor-pointer fa-lg mb-1 me-1'
          onClick={_onCloseForm}
        />

        <h2 className='fs-0 text-primary mb-1'>
          {profileDetails.partnerType === 'customer' ? t('resource_1:remark') : t('resource_1:snooze')}
        </h2>
      </Card.Header>
      <Card.Body>
        <div className='container'>
          <Form onSubmit={onSubmit} autoComplete='off'>
            <div className='row'>
              {profileDetails.partnerType === 'customer' ? <></> : <div className='col-lg-3 mb-2'>
                <Form.Group>
                  <Form.Label className='fs--1'>
                  </Form.Label>
                  <Form.Check
                    type='checkbox'
                    label={
                      <Form.Label className='fs--1'>
                        <span>
                          {t('resource_1:snooze')}
                        </span>
                      </Form.Label>
                    }

                    onChange={(e) => {
                      setValue('snooze', e.target.checked);

                    }}

                    checked={watch('snooze')}
                    //onChange={onToggleGrantFrtInvAccess}
                    disabled={data.snooze == null || data.snooze == undefined}
                  />
                </Form.Group>
              </div>}

              {profileDetails.partnerType === 'customer' ? <> </> : <div className='col-lg-4 mb-3'>
                <Form.Group controlId='pickUpInstruction.noOfDays'>
                  <Form.Label className='fs--1'>
                    <span className='mandatory'>*</span>
                    {t('resource_3:noOfDays')}&nbsp;
                  </Form.Label>
                  <Form.Control
                    {...register('noOfDays')}
                    className={`fs--1 form-control ${errors.noOfDays ? 'is-invalid' : ''
                      }`}
                    type='number'
                    min={0}
                    max={99}
                    onChange={({ target }) => {
                      if (0 <= +target.value && 99 >= +target.value)
                        setValue('noOfDays', +target.value);
                      // setValue('noOfDays',Math.max(0,Math.min(99,+target.value)));
                    }}

                    value={watch('noOfDays') ?? 0}
                    disabled={false}
                  />


                  <div className='invalid-feedback'>
                    {errors.noOfDays?.message?.toString()}
                  </div>
                </Form.Group>
              </div>}
              {profileDetails.partnerType === 'customer' ? <></> : <div className='col-lg-4 mb-2' style={{ zIndex: 501 }}>
                <Form.Group controlId='pickUpInstruction.snoozeDate'>
                  <Form.Label className='fs--1'>

                    {t('resource_1:Date')}{' '}
                  </Form.Label>

                  <DatePicker
                    selected={watch('snoozeDate')}
                    {...register('snoozeDate')}
                    className={`fs--1 form-control ${errors.snoozeDate ? 'is-invalid' : ''
                      }`}
                    onChange={(date: any) => {
                      setValue('snoozeDate', date);
                    }}

                    dateFormat={getDateFormat()}
                    popperProps={{
                      strategy: 'fixed',
                    }}
                    minDate={
                      watch('noOfDays') != 0
                        ? new Date(new Date().getTime() + 86400000)
                        : new Date()
                    }
                    //  placeholderText='Select Date'
                    disabled={true}
                  />


                </Form.Group>
              </div>}

              {profileDetails.partnerType === 'customer' ? <></> : <div className='col-lg-12 mb-0' >
                <Form.Group>
                  <Form.Check
                    type='checkbox'
                    id='externalRemarks'
                    label={
                      <Form.Label className='fs--1' htmlFor={'externalRemarks'}>
                        <span>
                          {t('resource_3:send_notification_to_customer')}
                        </span>
                      </Form.Label>
                    }
                    style={{ marginBottom: "0px" }}
                    onChange={(e) => {
                      setValue('externalRemarks', e.target.checked);
                    }}

                    checked={watch('externalRemarks')}
                    //onChange={onToggleGrantFrtInvAccess}
                    disabled={false}
                  />
                </Form.Group>
              </div>}
              {profileDetails.partnerType === 'liner' ? <></> : <div className='col-lg-12 mb-3 d-none'>
                <Form.Group controlId='snooze.remarkby'>
                  <Form.Label className='fs--1'>
                    {t('resource_2:remarkby')}&nbsp;
                  </Form.Label>
                  <Form.Control
                    {...register('remarkby')}
                    className={`fs--1 form-control ${errors.emailTo ? 'is-invalid' : ''
                      }`}
                    type='text'
                    maxLength={200}
                    onChange={({ target }) => {
                      setValue('remarkby', target.value);
                    }}
                    value={watch('remarkby')}
                    disabled={profileDetails.partnerType === 'customer' ? true : false}
                  />
                  <div className='invalid-feedback'>
                    {errors.remarkby?.message?.toString()}
                  </div>
                </Form.Group>
              </div>}
              {profileDetails.partnerType === 'customer' ? <></> : <div className='col-lg-12 mb-3'>
                <Form.Group controlId='invoiceChanges.emailTo'>
                  <Form.Label className='fs--1'>
                    {t('resource_2:emailTo')}&nbsp;
                  </Form.Label>
                  <Form.Control
                    {...register('emailTo')}
                    className={`fs--1 form-control ${errors.emailTo ? 'is-invalid' : ''
                      }`}
                    type='text'
                    maxLength={200}
                    onChange={({ target }) => {
                      setValue('emailTo', target.value);
                    }}
                    value={watch('emailTo')}
                    disabled={true}
                  />
                  <div className='invalid-feedback'>
                    {errors.emailTo?.message?.toString()}
                  </div>
                </Form.Group>
              </div>}
              <div className='col-lg-12 mb-3'>
                <Form.Group controlId='snooze.subject'>
                  <Form.Label className='fs--1'>
                    {t('resource_1:subject')}&nbsp;
                  </Form.Label>
                  <Form.Control
                    {...register('subject')}
                    className={`fs--1 form-control ${errors.subject ? 'is-invalid' : ''
                      }`}
                    type='text'
                    maxLength={200}
                    onChange={({ target }) => {
                      setValue('subject', target.value);
                      //_onCompanyNameChange(target.value);
                    }}
                    value={watch('subject')}
                    disabled={profileDetails.partnerType === 'customer' ? true : false}
                  />
                  <div className='invalid-feedback'>
                    {errors.subject?.message?.toString()}
                  </div>
                </Form.Group>
              </div>
              <div className='col-lg-12 mb-3'>
                <Form.Group controlId='pickUpInstruction.userSnoozeRemark'>
                  <Form.Label className='fs--1'>
                    {t('resource_1:remark')}
                  </Form.Label>
                  <Form.Control
                    {...register('userSnoozeRemark')}
                    className={`fs--1 form-control ${errors.userSnoozeRemark ? 'is-invalid' : ''
                      }`}
                    as='textarea'
                    onChange={(e) => setValue('userSnoozeRemark', e.target.value)}
                    maxLength={100}
                    value={watch('userSnoozeRemark')}
                    disabled={profileDetails.partnerType === 'customer' ? true : false}
                  />
                  <div className='invalid-feedback'>
                    {errors.userSnoozeRemark?.message?.toString()}
                  </div>
                </Form.Group>
              </div>
              {disableFields && (
                <div className='col-lg-5 mb-3'>
                  <Form.Group controlId='invoiceChanges.status'>
                    <Form.Label className='fs--1'>
                      {t('resource_1:status')}&nbsp;
                    </Form.Label>
                    <Form.Control
                      className={'fs--1 form-control'}
                      type='text'
                      value={status}
                      disabled={true}
                    />

                  </Form.Group>
                </div>
              )}


              <div className='d-flex justify-content-center align-items-center'>
                {profileDetails.partnerType === 'customer' ? <></> : <Button
                  className='me-2'
                  variant='falcon-primary'
                  size='sm'
                  type='submit'
                  disabled={
                    !watch('externalRemarks') && !watch('snooze') ? true :
                      selectedData[0]?.status === 'Submitted' ? false : true
                    /*  (selectedData[0]?.status==='Submitted'??demrageStatus==='Submitted') && 
                     (!selectedData || selectedData.length==0 || selectedData[0]?.inst_type==='Container Release Request') ? false : true    */
                  }
                >
                  <FontAwesomeIcon icon={faSave} className='me-2' />
                  {t('resource_1:submit')}
                </Button>}

                {profileDetails.partnerType === 'customer' ? <></> : <Button
                  className='me-2'
                  variant='falcon-danger'
                  size='sm'
                  onClick={resetForm}
                  disabled={
                    location.pathname.includes('bl') ? false :
                      selectedData[0]?.status === 'Submitted' && selectedData[0]?.inst_type === 'Container Release Request' ? false : true
                  }
                >
                  <FontAwesomeIcon icon={faRedo} className='me-2' />
                  {t('resource_1:reset')}
                </Button>}
              </div>


            </div>
          </Form>
        </div>
      </Card.Body>
    </Card>
  )
}

export default Snooze;

