import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
// import { addAgentNnominate } from '../actions/Instruction';
import {
  addPermitRequest,
  blNumbList,
  blValidation,
  fetchTruckingCompanySuggestions,
  fetchTruckingTrailerSuggestions,
  fetchVesselSuggestions,
} from '../actions/Instruction';
import { fetchCompanyDetailsForAgentDelegation } from '../../../dashboard/actions/instruction';
import { string } from 'yup';
import { toast } from 'react-toastify';

interface agentAccessList {
  agentAccessId: number | string | null;
  agentNominationId: number | string | null;
  type: string | null;
  allowAccess: string | null;
}

export interface PermitRequestDetails {
  blId: string;
  tpoId: string;
  blno: string;
  bookingType: string;
  permitType: string;
  requestedDate: string;
  vessel: string;
  noOfContainer: string;
  representativeNm: string;
  representativeId: string;
  remarks: string;
  driverLicense: string;
  driverName: string;
  truckHorse: string;
  truckTrailer: string;
  truckingCompany: string;
  navisRefId: string;
  documents: [
    {
      docId: string;
      fileName: string;
    }
  ];
}

interface IPermitRequestList {
  list: any;
  next: string | null;
  previous: string | null;
}

export interface IPermitRequest {
  data: IPermitRequestList;
  dataTruck: IPermitRequestList;
  dataTrailer: IPermitRequestList;
  dataDriver: IPermitRequestList;
  dataLoading: boolean;
  PermitRequestCreateMessage: string;
  blNumbList: any;
}

const initialState: IPermitRequest = {
  data: {
    list: [],
    next: null,
    previous: null,
  },
  dataTruck: {
    list: [],
    next: null,
    previous: null,
  },
  dataTrailer: {
    list: [],
    next: null,
    previous: null,
  },
  dataDriver: {
    list: [],
    next: null,
    previous: null,
  },
  dataLoading: true,
  PermitRequestCreateMessage: '',
  blNumbList: [],
};

export const PermitRequestSlice = createSlice({
  name: 'Permit',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addPermitRequest.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(addPermitRequest.fulfilled, (state, action) => {
      state.dataLoading = false;
      if (!action.payload?.success && action?.payload?.data) {
        state.PermitRequestCreateMessage = action.payload.data;
        toast.dark(action.payload.data, { toastId: 'ce650b0' });
      } else {
        state.PermitRequestCreateMessage = 'Created Successfully!';
        // toast.dark('Created Successfully!');
      }
    });
    /*     builder.addCase(getPermitRequest.pending, (state: any, action) => {
      state.dataLoading = true;
      state.data = action.payload;
    });
    builder.addCase(getPermitRequest.fulfilled, (state: any, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    }); */
    builder.addCase(fetchVesselSuggestions.pending, (state: any, action) => {
      state.dataLoading = true;
    });
    builder.addCase(fetchVesselSuggestions.fulfilled, (state: any, action) => {
      state.dataLoading = false;
      if (action.payload == null) return
      state.data = action.payload;
    });
    builder.addCase(
      fetchTruckingCompanySuggestions.pending,
      (state: any, action) => {
        state.dataLoading = true;
        state.dataTruck = action.payload;
      }
    );
    builder.addCase(
      fetchTruckingCompanySuggestions.fulfilled,
      (state: any, action) => {
        state.dataLoading = false;
        state.dataTruck = action.payload;
      }
    );
    builder.addCase(
      fetchTruckingTrailerSuggestions.pending,
      (state: any, action) => {
        state.dataLoading = true;
        state.dataTrailer = action.payload;
      }
    );
    builder.addCase(
      fetchTruckingTrailerSuggestions.fulfilled,
      (state: any, action) => {
        state.dataLoading = false;
        state.dataTrailer = action.payload;
      }
    );
    builder.addCase(blValidation.pending, (state: any, action) => {
      state.dataLoading = true;

      // state.dataDriver = action.payload;
    });
    builder.addCase(blValidation.fulfilled, (state: any, action) => {
      state.dataLoading = false;

      // state.dataDriver = action.payload;
    });

    builder.addCase(blNumbList.pending, (state: any, action) => {
      state.dataLoading = true;
      state.blNumbList = action.payload;
    });
    builder.addCase(blNumbList.fulfilled, (state: any, action) => {
      state.dataLoading = false;
      state.blNumbList = action.payload;
    });
  },
});

export const { } = PermitRequestSlice.actions;

export default PermitRequestSlice.reducer;
