import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getCurrencyDDList } from '../actions/payments';

export interface StandaloneInvoiceDetails {
  rowNumber?: number;
  invoiceId: number | null;
  invoiceNo: string | null;
  invoiceAmount: BigInteger | number | null;
  invoiceCurrency: string | null;
  message?: string | null;
  remove: boolean
  invDt: string;
  shipmentNumber: string;
  blNo: string;
  eximIndicator:string;
}

export interface StandaloneInvoicePaymentSlice {
  data: StandaloneInvoiceDetails[];
  countryDDList: any[]
}

const initialState: StandaloneInvoicePaymentSlice = {
  data: [],
  countryDDList: []
};

const standaloneInvoicePaymentSlice = createSlice({
  name: 'standaloneInvoicePayment',
  initialState,
  reducers: {
    updateStandaloneInvoiceList: (
      state,
      action: PayloadAction<StandaloneInvoiceDetails[]>
    ) => {
      state.data = action.payload;
    },
    clearStandaloneInvoiceListState: (
      state,
      action: PayloadAction<StandaloneInvoiceDetails[]>
    ) => {
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getCurrencyDDList.pending,
      (state, action: any) => {
        //nothing
      }
    );
    builder.addCase(
      getCurrencyDDList.fulfilled,
      (state, action: any) => {
        state.countryDDList = action?.payload
      }
    );
  },
});

export const { updateStandaloneInvoiceList, clearStandaloneInvoiceListState } =
  standaloneInvoicePaymentSlice.actions;
export default standaloneInvoicePaymentSlice.reducer;
