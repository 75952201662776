import { Card, Button, InputGroup, Form, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks, faTheaterMasks, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { assignInstructionsToSelf } from '../../blRequestInstruction/components/actions/Instruction';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { useNavigate } from 'react-router-dom';
import { updateAssigneTo, updateSelectedTableData } from '../../dashboard/slices/dashboardSlice';
interface IProps {
  isDisabled?: boolean;
  selectedRows?: any[];
}
const AssignedToButton = ({ isDisabled, selectedRows }: IProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  return (
    <Button
      className=''
      variant='falcon-primary z-inxe-agent'
      size='sm'
      onClick={() => {
        if (!selectedRows?.length || selectedRows?.length == 0) {
          toast.dark('Only selected instruction can be assigned.', {
            toastId: '552ea95'
          });
        } else if (
          selectedRows?.filter((v: any) => v?.status != 'Submitted').length != 0
        ) {
          toast.dark('Only submitted Instruction can be assigned.', {
            toastId: 'e573e1d'
          });
        } else {
          //call api
          dispatch(
            assignInstructionsToSelf(
              selectedRows.map((v: any) => v.bl_event_id)
            )
          )
            .unwrap()
            .then((response: any) => {
              if (response.responseCode == 'SUCCESS') {
                toast.success(response.responseMessage, {
                  toastId: '7250f4b'
                });
                dispatch(updateAssigneTo('1'));
                //reset selection
                dispatch(updateSelectedTableData([]));
              } else {
                toast.error(response.responseMessage, {
                  toastId: '6fc0197'
                });
              }
            });
        }
      }}
      disabled={isDisabled}
    >
      <FontAwesomeIcon
        icon={faUserCheck}
        className='me-2'
        style={{ color: '#f5822a' }}
      />
      <span>Self Assign</span>
    </Button>
  );
};
export default AssignedToButton;
