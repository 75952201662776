/* eslint-disable react-hooks/exhaustive-deps */

import { useDispatch } from 'react-redux';

import { useSelector } from 'react-redux';

import {
  Link,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import React, { useEffect, useRef, useState } from 'react';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DashboardRoot from './DashboardRoot';
import { graphql } from 'msw';

import { Card } from 'react-bootstrap';
import { AppDispatch, RootState } from '../../store';
import { IDataTableColumn } from '../../common/types/dataTable';
import {
  IConfigsDefinition,
  generateColumnConfig,
} from '../../utils/columnConfig';
import { getOBLDetails } from '../dashboard/actions/instruction';
import { AESDecrypt } from '../../../encrypt-util';
import { updateInstructionID } from '../blDetails/slices/documentHistorySlice';
import {
  toggleAddAgent,
  toggleAddAgentButton,
  toggleColumnSettingsDashboard,
} from '../instructionDashboard/slices/dashboardSlice';
import {
  updateCurrentPageDoDashboard,
  updateCurrentPageInstructionDashboard,
  updateSelectedInstRow,
} from '../dashboard/slices/dashboardSlice';
import DataTable from '../../common/components/DataTable';
import DeliveryOrderInstruction from '../dashboard/components/deliveryOrderInstruction';
import { updateRowColumns } from './DoMasterDetailsSlice';
import DashboardContainer from '../../../components/common/DashboardContainer';

const TableComponent = () => {
  const tableRef = useRef<any>(null);
  const { t } = useTranslation();
  const { userID } = useParams();
  const blDocId = userID;
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const grids = useSelector((state: RootState) => state.dataTable.doGrids);
  const currentPage = useSelector(
    (state: RootState) => state.dashboard.currentPageInstruction
  );

  const rbac = useSelector((state: RootState) => state.userProfile);
  const selectionInitialState = useSelector(
    (state: RootState) => state.dashboard.selectedInstDataRows
  );
  // const configs = useSelector(
  //   (state: RootState) => state.instructionDashboard.configs
  // );
  const obldetails = useSelector(
    (state: RootState) => state.instructionDashboard.obldetails
  );
  // const columns = useSelector((state: RootState) => state.dashboard.columns);
  const [columns, setColumns] = useState<IDataTableColumn[]>([]);
  const [configs, setConfigs] = useState<IConfigsDefinition>({
    columns: [],
    staticHiddenCols: [],
    defaultHiddenCols: [],
  });

  const data = useSelector(
    (state: RootState) => state.dashboard.doDashboardList
  );

  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );
  const userDetails = useSelector((state: RootState) => state.userList);
  // const dataCountry = linerDataCountry || profileDetails.partnerCountry || '';
  const storedUserCountry = JSON.parse(
    window.localStorage.getItem('@user-selected-datacountry') || '{}'
  );
  const storedCountry =
    storedUserCountry?.[0]?.loginId === profileDetails.email
      ? storedUserCountry?.[0]?.country
      : null;
  const dataCountry: any = storedCountry
    ? storedCountry
    : linerDataCountry
      ? linerDataCountry
      : profileDetails.partnerCountry || null;
  const dataLoading = useSelector(
    (state: RootState) => state.dashboard.dataLoading
  );
  const selectedLinerId = useSelector(
    (state: RootState) => state.userProfile.selectedLinerId
  );

  const instructionIdurl = useSelector(
    (state: RootState) => state.documentHistory.instructionIdurl
  );

  const getParams = () => {
    dispatch(
      updateInstructionID(AESDecrypt(location?.pathname?.split?.('/')?.[3]))
    );
    return {
      bl_id: userID ? AESDecrypt(userID) : '',
      doc_id: userID ? AESDecrypt(userID) : '',
      // inst_id: parseInt(location?.pathname?.split?.('/')?.[3]) || '',
      inst_id: userID ? AESDecrypt(userID) : '',
    };
  };

  /*  const userGridDefinitions = useSelector(
    (state: RootState) => state.userProfile.userGridDefinitions
  ); */
  const userGridDefinitions = useSelector(
    (state: RootState) => state.dataTable.grids
  );

  const showColumnSettings = useSelector(
    (state: RootState) => state.instructionDashboard.showColumnSettings
  );

  const showAddAgent = useSelector(
    (state: RootState) => state.instructionDashboard.showAddAgent
  );

  const showNominaationReq = useSelector(
    (state: RootState) => state.instructionDashboard.showNonimationReq
  );

  const rowColumns = useSelector(
    (state: RootState) => state.instructionTable.rowColumns
  );

  const onHideColumnSettings = () => {
    dispatch(toggleColumnSettingsDashboard(false));
  };
  const loadingGridConfigs = useSelector(
    (state: RootState) => state.dataTable.loadingGridConfigs
  );

  const accessorEtd = () => (rowData: any) => {
    const { etd } = rowData.row.original;

    try {
      const value = etd
        .split('-')
        .map((v: string, index: number) => (index === 0 ? v.slice(2, 4) : v))
        .join('-');

      return (
        <div className='text-truncate' data-toggle='tooltip' title={value}>
          {value}
        </div>
      );
    } catch (e) {
      return (
        <div className='text-truncate' data-toggle='tooltip' title={etd}>
          {etd}
        </div>
      );
    }
  };

  const accessorEta = () => (rowData: any) => {
    const { eta } = rowData.row.original;

    try {
      const value = eta
        .split('-')
        .map((v: string, index: number) => (index === 0 ? v.slice(2, 4) : v))
        .join('-');

      return (
        <div className='text-truncate' data-toggle='tooltip' title={value}>
          {value}
        </div>
      );
    } catch (e) {
      return (
        <div className='text-truncate' data-toggle='tooltip' title={eta}>
          {eta}
        </div>
      );
    }
  };
  const selectedData = useSelector(
    (state: RootState) => state.dashboard.selectedData
  );
  useEffect(() => {
    let selections: any = {};
    data.result?.forEach((d: any, i: any) => {
      if (
        selectedData?.map((v: any) => v?.bl_event_id).includes(d?.bl_event_id)
      )
        selections[i] = true;
    });

    dispatch(updateSelectedInstRow(selections));
  }, [selectedData, data]);
  const setCurrentPage = (page: number) => {
    dispatch(updateCurrentPageInstructionDashboard(page));
  };

  // useEffect(()=>{
  //   dispatch(updateSelectedTableData([]));
  // },[dataCountry])

  // const columns = columnConfig(AESDecrypt(blNum) || null);

  // useEffect(() => {
  //   const blDashboardDefinition = grids?.[0]?.columnConfigGrid || '';

  //   if (!blDashboardDefinition) return;

  //   const configs = generateColumnConfig(JSON.parse(blDashboardDefinition));

  //   const columns = configs?.columns?.map((c) =>
  //     c.accessor === 'eta'
  //       ? { ...c, Cell: accessorEta() }
  //       : c.accessor === 'etd'
  //       ? { ...c, Cell: accessorEtd() }
  //       : c
  //   );

  //   dispatch(updateColumnDefinitionConfigs(configs));
  //   dispatch(updateColumnDefinition(columns));
  // }, [grids, location]);
  useEffect(() => {
    if (
      matchPath('/opc/doupload/details/:userID', location.pathname) ||
      matchPath('/opc/doupload/:userID', location.pathname)
    ) {
      dispatch(
        updateRowColumns({
          mainTableClass: 'col-lg-4',
          outletClass: 'col-lg-8',
        })
      );

      //setAccountList(partnerData.data.partnerAccountList.list.map((v:any)=>v));
    } else if (matchPath('/opc/doupload', location.pathname)) {
      dispatch(
        updateRowColumns({
          mainTableClass: 'col-lg-12',
          outletClass: 'd-none',
        })
      );
    }
  }, [location.pathname]);
  useEffect(() => {
    const blDashboardDefinition = grids?.[0]?.columnConfigGrid || '';

    if (!blDashboardDefinition) return;

    try {
      const configs = generateColumnConfig(
        JSON.parse(JSON.parse(blDashboardDefinition)),
        getParams,
        navigate,
        selectedData,
        dispatch
      );

      const columns = configs?.columns?.map((c) => c);
      setConfigs(configs);
      setColumns(configs?.columns);
    } catch (e) {
      console.error('Column Error: ', e);
    }
  }, [grids, location, selectedData]);

  useEffect(() => {
    if (
      matchPath('/opc/doupload/details/:userID', location.pathname) ||
      matchPath('/opc/doupload/:userID', location.pathname)
    ) {
      dispatch(
        updateRowColumns({
          mainTableClass: 'col-lg-4',
          outletClass: 'col-lg-8',
        })
      );

      //setAccountList(partnerData.data.partnerAccountList.list.map((v:any)=>v));
    } else if (matchPath('/opc/doupload', location.pathname)) {
      dispatch(
        updateRowColumns({
          mainTableClass: 'col-lg-12',
          outletClass: 'd-none',
        })
      );
    }
  }, [location.pathname]);
  useEffect(() => {
    const sliderOpened = true;

    if (sliderOpened && tableRef.current) {
      const tableElement = tableRef.current;
      const rowIndexToShow = 0;

      const rows = tableElement.getElementsByTagName('tr');
      const rowToScroll = rows[rowIndexToShow];

      if (rowToScroll) {
        const tableBounds = tableElement.getBoundingClientRect();
        const rowBounds = rowToScroll.getBoundingClientRect();

        if (
          rowBounds.bottom > tableBounds.bottom ||
          rowBounds.top < tableBounds.top
        ) {
          // Scroll the row into view smoothly
          rowToScroll.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }
  }, [location]);

  const sideWidth =
    Object.entries({
      [`/opc/doupload`]: 0,
      [`/opc/doupload/:userId`]: 8,
    }).find(([path, _]) => matchPath(path, location.pathname))?.[1] ?? 10;

  return <DashboardContainer
    sideWidth={sideWidth}>
    {columns?.length > 0 ? (
      <>
        <DashboardRoot />
        <DataTable
          data={data?.result || []}
          columns={columns}
          sortable={false}
          searchable={false}
          showColumnFilter={false}
          selection={
            rbac.rbacDetails.allowInstructionAssignment == 'Y'
          }
          selectionInitialState={selectionInitialState}
          perPage={20}
          pagination
          configGroup={
            //  grids?.find?.((g) => g?.gridId === 2)?.configGroup || []
            grids?.find?.((g) => g?.gridName === 'OPC_DO_DASHBOARD')
              ?.configGroup || []
          }
          onChangePageNumber={(pageNumber) =>
            setCurrentPage(pageNumber)
          }
          numberPagination
          maxPage={parseInt(data?.maxPage || '100')}
          hasNext={data?.hasNext}
          showExport={false}
          currentPage={currentPage}
          localFilter={true}
          showColumnSettings={showColumnSettings}
          gridName='OPC_DO_DASHBOARD'
          onHide={onHideColumnSettings}
          columnSettingsPersistKey='@odex/maintable/v6'
          onCustomSelectChange={(d) => {
            if (d?.list?.length > 0) {
              dispatch(toggleAddAgentButton(true));
            } else {
              dispatch(toggleAddAgentButton(false));
              dispatch(toggleAddAgent(false));
            }
          }}
          customSort={true}
          shortPrepend='bl_'
          uniqueFilterKey='doDashboard'
          staticHiddenCols={configs?.staticHiddenCols || []}
          defaultHiddenCols={configs?.defaultHiddenCols || []}
          autoHeight={false}
          tableMinHeight={400}
          dataCountry={dataCountry}
          gridId={grids?.[0]?.gridId || 0}
          dataLoading={false}
        />
      </>
    ) : (
      ''
    )}
  </DashboardContainer>

  // Keeping older code temporarily in case there is a need to revert
  return (
    <div className='row g-0 w-100 h-100'>
      <div className={`${userDetails.rowColumns.mainTableClass}`}>
        <div className=' row'>
          <div className='d-flex flex-column justify-content-between align-items-start w-100 h-100'>
            <div className='d-flex flex-column justify-content-start align-items-start w-100 h-100'>
              <div className='row g-0 col-transition-animation h-100 w-100'>
                {columns?.length > 0 ? (
                  <>
                    {location.pathname !== '/opc/doupload' ? (
                      <Card.Header className='p-0 ps-2 pe-2 d-flex justify-content-start align-items-center odex-mh-60px col-transition-animation' />
                    ) : (
                      <DashboardRoot />
                    )}
                    <DataTable
                      data={data?.result || []}
                      columns={columns}
                      sortable={false}
                      searchable={false}
                      showColumnFilter={false}
                      selection={
                        rbac.rbacDetails.allowInstructionAssignment == 'Y'
                      }
                      selectionInitialState={selectionInitialState}
                      perPage={20}
                      pagination
                      configGroup={
                        //  grids?.find?.((g) => g?.gridId === 2)?.configGroup || []
                        grids?.find?.((g) => g?.gridName === 'OPC_DO_DASHBOARD')
                          ?.configGroup || []
                      }
                      onChangePageNumber={(pageNumber) =>
                        setCurrentPage(pageNumber)
                      }
                      numberPagination
                      maxPage={parseInt(data?.maxPage || '100')}
                      hasNext={data?.hasNext}
                      showExport={false}
                      currentPage={currentPage}
                      localFilter={true}
                      showColumnSettings={showColumnSettings}
                      gridName='OPC_DO_DASHBOARD'
                      onHide={onHideColumnSettings}
                      columnSettingsPersistKey='@odex/maintable/v6'
                      onCustomSelectChange={(d) => {
                        if (d?.list?.length > 0) {
                          dispatch(toggleAddAgentButton(true));
                        } else {
                          dispatch(toggleAddAgentButton(false));
                          dispatch(toggleAddAgent(false));
                        }
                      }}
                      customSort={true}
                      shortPrepend='bl_'
                      uniqueFilterKey='doDashboard'
                      staticHiddenCols={configs?.staticHiddenCols || []}
                      defaultHiddenCols={configs?.defaultHiddenCols || []}
                      autoHeight={false}
                      tableMinHeight={400}
                      dataCountry={dataCountry}
                      gridId={grids?.[0]?.gridId || 0}
                      dataLoading={false}
                    />
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {(matchPath('/opc/doupload/details/:userID', location.pathname) ||
        matchPath('/opc/doupload/:userID', location.pathname)) && (
          <div
            className={`/*  odex-bg-aliceblue  */ ${userDetails.rowColumns.outletClass}`}
          >
            <DeliveryOrderInstruction />
          </div>
        )}
    </div>
  );
};

export default TableComponent;
