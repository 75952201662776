/* eslint-disable react-hooks/exhaustive-deps */
import DataTable from '../../../common/components/DataTable';
import { useDispatch } from 'react-redux';
import {
  toggleAddAgent,
  toggleAddAgentButton,
  toggleColumnSettingsDashboard,
  updateColumnDefinition,
  updateColumnDefinitionConfigs,
} from '../slices/dashboardSlice';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import NominateAgent from './NominateAgent';
import NominationReq from '../../nominationReq/NominationReq';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { generateColumnConfig } from '../../../utils/columnConfig';
import { useEffect } from 'react';
import { updateSelectedRow } from '../slices/dashboardSlice';
import { AESDecrypt } from '../../../../encrypt-util';
import DashboardRoot from './DashboardRoot';

interface IProps {
  currentPage: number;
  setCurrentPage: (pageNumber: number) => void;
  dataCountry: string;
}

const TableComponent = ({
  currentPage,
  setCurrentPage,
  dataCountry,
}: IProps) => {
  const { t } = useTranslation();
  const { blNum } = useParams();

  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();

  const columns = useSelector((state: RootState) => state.dashboard.columns);
  const configs = useSelector((state: RootState) => state.dashboard.configs);

  const data = useSelector(
    (state: RootState) => state.dashboard.blDashboardList
  );
  const dataLoading = useSelector(
    (state: RootState) => state.dashboard.dataLoading
  );
  const grids = useSelector((state: RootState) => state.dataTable.grids);

  var path = location.pathname;
  var parts = path.split('/');
  var blslider = parts[1];

  const getParams = () => {
    return { blid: blNum ? AESDecrypt(blNum) : '' };
  };

  const showColumnSettings = useSelector(
    (state: RootState) => state.dashboard.showColumnSettings
  );

  const showAddAgent = useSelector(
    (state: RootState) => state.dashboard.showAddAgent
  );

  const showNominaationReq = useSelector(
    (state: RootState) => state.dashboard.showNonimationReq
  );

  const rowColumns = useSelector(
    (state: RootState) => state.blDetailsTable.rowColumns
  );

  const onHideColumnSettings = () => {
    dispatch(toggleColumnSettingsDashboard(false));
  };
  const selectedData = useSelector(
    (state: RootState) => state.dashboard.selectedData
  );
  const selectionInitialState = useSelector(
    (state: RootState) => state.dashboard.selectedBlDataRows
  );

  const rbac = useSelector((state: RootState) => state.userProfile);
  var path = location.pathname;
  var parts = path.split('/');
  var blslider = parts[1];

  useEffect(() => {
    let selections: any = {};
    data.result?.forEach((d: any, i: any) => {
      if (selectedData.map((v: any) => v.blid).includes(d.blid))
        selections[i] = true;
    });
    dispatch(updateSelectedRow(selections));
  }, [selectedData, data]);

  const loadingGridConfigs = useSelector(
    (state: RootState) => state.dataTable.loadingGridConfigs
  );

  // const columns = columnConfig(AESDecrypt(blNum) || null);

  useEffect(() => {
    const blDashboardDefinition = grids?.[0]?.columnConfigGrid || '';

    if (!blDashboardDefinition) return;
    try {
      const configs = generateColumnConfig(
        JSON.parse(JSON.parse(blDashboardDefinition)),
        getParams,
        navigate
      );

      const columns = configs?.columns?.map((c) => c);
      dispatch(updateColumnDefinitionConfigs(configs));
      dispatch(updateColumnDefinition(columns));
    } catch (e) {}
  }, [grids, location]);

  return (
    <div
      className={`row g-0 col-transition-animation h-100 ${
        rowColumns.mainTableClass === 'col-lg-12'
          ? 'w-100 '
          : 'odex-width-14rem'
      }`}>
      <div
        className={`d-flex flex-column ${
          showAddAgent
            ? 'col-sm-0 col-md-7 col-lg-7'
            : showNominaationReq
            ? 'col-sm-0 col-md-6 col-lg-6'
            : 'col-md-12 col-lg-12'
        }`}>
        {columns?.length > 0 ? (
          <>
            {blslider !== 'bl' &&
              blslider !== 'blslider' &&
              blslider !== 'multiBl' && <DashboardRoot />}

            <DataTable
              data={data?.result || []}
              columns={columns}
              sortable={true}
              searchable={false}
              showColumnFilter={false}
              selection={
                rbac?.rbacDetails?.multiInstructCreate &&
                rbac?.rbacDetails?.multiInstructCreate === 'Y'
                  ? true
                  : false
              }
              perPage={20}
              pagination
              numberPagination
              maxPage={data?.maxPage || 100}
              hasNext={data?.hasNext}
              currentPage={currentPage}
              showExport={false}
              showColumnSettings={showColumnSettings}
              onChangePageNumber={(pageNumber) => setCurrentPage(pageNumber)}
              gridName='BL_DASHBOARD'
              onHide={onHideColumnSettings}
              columnSettingsPersistKey='@odex/maintable/v6'
              onCustomSelectChange={(d) => {
                if (d?.list?.length > 0) {
                  dispatch(toggleAddAgentButton(true));
                } else {
                  dispatch(toggleAddAgentButton(false));
                  dispatch(toggleAddAgent(false));
                }
              }}
              // seekPagination={true}
              // previous={data.previous ? data.previous : ""}
              // next={data.next ? data.next : ""}
              customSort={true}
              shortPrepend='bl_'
              uniqueFilterKey='mainDashboard'
              staticHiddenCols={configs?.staticHiddenCols || []}
              defaultHiddenCols={configs?.defaultHiddenCols || []}
              autoHeight={false}
              tableMinHeight={400}
              // gridId={1}
              gridId={grids?.[0]?.gridId || 0}
              dataLoading={false}
              dataCountry={dataCountry}
              selectionInitialState={selectionInitialState}
            />
          </>
        ) : (
          ''
        )}
      </div>
      <div
        className={`${
          showAddAgent
            ? 'col-sm-12 col-md-5 col-lg-5 odex-overlap-card h-100'
            : showNominaationReq
            ? 'col-sm-12 col-md-6 col-lg-6 odex-overlap-card h-100'
            : 'd-none'
        }`}>
        {showAddAgent && <NominateAgent />}

        {showNominaationReq && <NominationReq />}
      </div>
    </div>
  );
};

export default TableComponent;
