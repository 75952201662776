/* eslint-disable react-hooks/exhaustive-deps */
import DataTable from "../../common/components/DataTable";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { Button, InputGroup } from "react-bootstrap";
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import {
  IConfigsDefinition,
  generateColumnConfig,
} from "../../utils/columnConfig";
import { useEffect, useState } from "react";

import { AESDecrypt } from "../../../encrypt-util";
import { IDataTableColumn } from "../../common/types/dataTable";

import { updateInstructionID } from "../blDetails/slices/documentHistorySlice";
import useDataCountry from "../../../hooks/useDataCountry";
import DashboardContainer from "../../../components/common/DashboardContainer";
import {
  updateDashbaordName,
  updateSearchInputValueNT,
} from "../../common/slices/navbarTop";
import {
  updateDashbaordRefresh,
  updateParams,
} from "../../common/components/DataTable/slices/DataTable";
import { getColumnViews } from "../../common/actions/dataTable";
import FilterView from "../../common/components/FilterView";
import { getEnquiryTableDataForContainer } from "../dashboard/actions/dashboard";
import { AnyAction } from "@reduxjs/toolkit";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { ICDCode } from "../createBestPick/action/BestPickDetails";
import { customDropdownStyles } from "../../common/customStyles";
import { fetchVesselSuggestions } from "../blRequestInstruction/components/actions/Instruction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import useDebounce from "../../../hooks/useDebounce";

const gridName = "OPC_CONTAINER_ENQUIRY";
const pathname = "/opc/enqContainer";
const uniqueFilterKey = "enqDashboardcontainer";
const EnquiryDashboardForContainer = () => {
  const { encryptdoc, blNum, encrypt } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const refresh = useSelector(
    (state: RootState) => state.dataTable.refreshDashboard
  );

  const tpoId = useSelector((state: RootState) => state.agentDelegation.tpoId);

  const grids = useSelector(
    (state: RootState) => state.dataTable.enquiryDashboardContainerGrids
  );
  const dataCountry = useDataCountry();
  const tableData = useSelector(
    (state: RootState) => state.invoiceChange.enquiryDashboardListForContainer
  );

  const [columns, setColumns] = useState<IDataTableColumn[]>([]);
  const [showSettings, setShowSettings] = useState(false);
  const [configs, setConfigs] = useState<IConfigsDefinition>({
    columns: [],
    staticHiddenCols: [],
    defaultHiddenCols: [],
  });

  useEffect(() => {
    dispatch(updateDashbaordName(gridName));
    dispatch(updateSearchInputValueNT(""));
  }, []);

  useEffect(() => {
    if(tpoId)
    dispatch(ICDCode(tpoId));
  }, [tpoId]);

  useEffect(() => {
    if (refresh) loadEnquiryDashboard();
    setTimeout(() => dispatch(updateDashbaordRefresh(false)), 2000);
  }, [refresh]);

  const getParams = () => {
    dispatch(
      updateInstructionID(AESDecrypt(location?.pathname?.split?.("/")?.[3]))
    );
    return {
      bl_id: blNum ? AESDecrypt(blNum) : "",
      doc_id: encryptdoc ? AESDecrypt(encryptdoc) : "",
      inst_id: encrypt ? AESDecrypt(encrypt) : "",
    };
  };

  useEffect(() => {
    const blDashboardDefinition = grids?.[0]?.columnConfigGrid || "";

    if (!blDashboardDefinition) return;

    try {
      const configs = generateColumnConfig(
        JSON.parse(JSON.parse(blDashboardDefinition)),
        getParams,
        navigate,
        dispatch
      );

      setConfigs(configs);
      setColumns(configs?.columns);
    } catch (e) {
      console.error("Column Error: ", e);
    }
  }, [grids]);

  useEffect(() => {
    if (dataCountry && tpoId) {
      setTimeout(() => {
        dispatch(
          getColumnViews({
            gridName: gridName,
            country: dataCountry,
            linerId: tpoId,
          })
        );
      }, 150);
    }
    dispatch(updateParams({}));
  }, [dataCountry, grids, tpoId]);

  const loadEnquiryDashboard = () => {
    if (tpoId && selectedICDcode.length > 0 && selectedVessel.length > 0) {
      const payload = {
        selectedICDcode: selectedICDcode,
        selectedVessel: selectedVessel,
      };
      dispatch(getEnquiryTableDataForContainer({ payload }));
    }
  };

  const [selectedVessel, setselectedVessel] = useState<any>("");
  const [SelectedvesselName, setSelectedvesselName] = useState<any>("");
  const [vesselTxt, setVesselTxt] = useState<string>("");
  const [VesselList, setVesselList] = useState<any>([]);
  const ICDCodeVal = useSelector(
    (state: RootState) => state.addbestPickSlice.ICDCodeVal
  );
  const VesselData: any = useSelector(
    (state: RootState) => state.permitrequest.data
  );
  // const validationSchema = Yup.object().shape({
  //   //vesselVoyage: Yup.object().required("Vessel is required").nullable(),
  //   // ICDShortCode: Yup.object()
  //   // .required("Place of delivery is required")
  //   // .nullable(),
  //   // ... other validations
  // });
  const customFilterOption = (option: any, inputValue: any) => {
    // if (inputValue.length < 3) {
    //   return false;
    // }
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  };
  const handleInputChange = useDebounce((inputValue: string) => {
    setVesselTxt(inputValue);
    if (inputValue.length >= 3) {
      dispatch(fetchVesselSuggestions({
        vesselKey: inputValue.toUpperCase(),
        requestType: 0,
        permitId: 0
      }));
    }
    if (inputValue.length < 3) {
      setVesselList([]);
    }
  }, 500);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
    setValue,
    control,
    clearErrors,
  } = useForm<any>({
    // resolver: yupResolver(validationSchema),
    mode: "onChange",
  });
  const [refViewVal, setrefViewVal] = useState<any>("");
  const [placeOfDeliveryViewVal, setplaceOfDeliveryViewVal] = useState<any>("");
  const [placeOfDelivaryVal, setplaceOfDelivaryVal] = useState<any>("");
  const [ICDcodeList, setICDcodeList] = useState<any>([]);
  const [shortCodeVal, setshortCodeVal] = useState<any>("");
  const [selectedICDcode, setselectedICDcode] = useState<any>("");
  useEffect(() => {
    if (ICDCodeVal) {
      if (Array.isArray(ICDCodeVal)) {
        const formattedOptions = ICDCodeVal.map((icd: any) => ({
          value: `${icd.customsDeliveryCode}`,
          label: `${icd.customsDeliveryCode} - ${icd.partnerNm}`,
          shortCode: icd.shortCode,
        }));
        setICDcodeList(formattedOptions);

        if (formattedOptions.length === 1) {
          const singleOption = formattedOptions[0];
          // setselectedICDcode("WITZDL025");
          // setshortCodeVal('GL')
          setValue("ICDShortCode", singleOption);
          setselectedICDcode(singleOption.value);
          setshortCodeVal(singleOption.shortCode);
        }
      }
    }
  }, [ICDCodeVal]);
  useEffect(() => {
    dispatch(ICDCode(tpoId));
  }, []);
  useEffect(() => {
    if (VesselData && vesselTxt) {
      if (Array.isArray(VesselData)) {
        const formattedOptions = VesselData.map((vessel: any) => ({
          value: `${vessel.visitKey}`,
          label: `${vessel.vesselName} - ${vessel.visitKey}${
            vessel.ata ? " - " + new Date(vessel.ata).toLocaleString() : ""
          }`,
          visitKey: vessel.visitKey,
          vesselName: vessel.vesselName,
        }));
        setVesselList(formattedOptions);
      }
    }
  }, [VesselData]);
  useEffect(() => {
    if (location.pathname == pathname) loadEnquiryDashboard();
  }, [tpoId, selectedVessel, selectedICDcode]);
  return (
    <DashboardContainer sideWidth={0}>
      <>
        <FilterView
          showColumnSettings={true}
          toggleColumnSettings={(v: boolean) => {
            setShowSettings(v);
            const x: AnyAction = { type: "dummyactionmeansnothing" };
            return x;
          }}
          showExport
          columnSettingsPersistKey="@odex/maintable/v6"
          uniqueFilterKey={uniqueFilterKey}
          dataLoading={false}
          configViews={
            grids?.find?.((g: any) => g?.gridName === gridName)?.configGroup ||
            []
          }
          showRightDiv={
            !!matchPath(location.pathname, pathname) &&
            !(
              !selectedVessel ||
              !selectedICDcode ||
              selectedICDcode == "" ||
              selectedVessel == ""
            )
          }
          dashboardName={gridName}
          data={{ hasNext: true, result: tableData, size: 20 }}
          gridDef={grids}
          csvByMail={false}
          additionalChildren={
            <>
              <div className="d-flex flex-row" style={{ width: "350px" }}>
                {/*    <InputGroup.Text className={`rounded-pill-l bg-100 fs--1`}>
                  Vessel
                </InputGroup.Text> */}
                <Controller
                  name="vesselVoyage"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={VesselList}
                      placeholder="Vessel"
                      noOptionsMessage={() => null}
                      isClearable={true}
                      maxMenuHeight={150}
                      onChange={(e: any) => {
                        if (e === null) {
                          field.onChange(null);
                          setselectedVessel("");
                          setSelectedvesselName("");
                        } else {
                          field.onChange({
                            label: e.value,
                            value: e.value,
                            visitKey: e.visitKey,
                            vesselName: e.vesselName,
                          });
                          setselectedVessel(e.value);
                          setSelectedvesselName(e.vesselName);
                        }
                      }}
                      styles={{
                        ...customDropdownStyles,
                        container: (provided) => ({
                          ...provided,
                          width: "100%",
                        }),
                        menu: (provided: any) => ({
                          ...provided,
                          width: "500px", // Adjust the width as needed
                        }),
                        control: (provided) => ({
                          ...provided,
                          minHeight: "31px",
                          height: "31px",
                          width: "150px",
                        }),
                      }}
                      filterOption={customFilterOption}
                      onInputChange={handleInputChange}
                      className="text-primary fs--1"
                    />
                  )}
                />
                {/*   <InputGroup.Text className={`rounded-pill-l bg-100 fs--1 ms-1`}>
                  Place of Delivery
                </InputGroup.Text> */}
                <Controller
                  name="ICDShortCode"
                  control={control}
                  render={({ field }: any) => (
                    <Select
                      {...field}
                      menuPlacement="auto" // Automatically adjust dropdown placement
                      //  maxMenuHeight={Math.min(ICDcodeList.length * 40, 300)} // Dynamically adjust based on items, capped at 300px
                      placeholder="Place of Delivery" // Placeholder text
                      options={ICDcodeList}
                      isDisabled={ICDcodeList.length < 2}
                      noOptionsMessage={() => null}
                      isClearable={true}
                      onChange={(e: any) => {
                        if (e === null) {
                          field.onChange(null);
                          setselectedICDcode(""); // Reset the selected ICD code
                          setshortCodeVal(""); // Reset the short code value
                        } else {
                          field.onChange({
                            label: e.value,
                            value: e.value,
                            visitKey: e.visitKey,
                          });
                          setselectedICDcode(e.value);
                          setshortCodeVal(e.shortCode);
                        }
                      }}
                      styles={{
                        ...customDropdownStyles,
                        container: (provided) => ({
                          ...provided,
                          width: "100%",
                        }),
                        menu: (provided: any) => ({
                          ...provided,
                          width: "500px", // Adjust the width as needed
                        }),
                        control: (provided) => ({
                          ...provided,
                          minHeight: "31px",
                          height: "31px",
                          width: "160px",
                        }),
                      }}
                      filterOption={customFilterOption}
                      onInputChange={handleInputChange}
                      className="text-primary fs--1 ms-2" // Custom class for additional styling
                    />
                  )}
                />
              </div>
              {!(
                !selectedVessel ||
                !selectedICDcode ||
                selectedICDcode == "" ||
                selectedVessel == ""
              ) && (
                <Button
                  variant="falcon-primary"
                  size="sm"
                  style={{ marginLeft: "15px" }}
                  onClick={() => dispatch(updateDashbaordRefresh(true))}
                >
                  <div className="d-flex">
                    <FontAwesomeIcon icon={faRefresh} className="mt-1 mb-1" />
                  </div>
                </Button>
              )}
            </>
          }
        />
        {(!selectedVessel ||
          !selectedICDcode ||
          selectedICDcode == "" ||
          selectedVessel == "") && (
          <div className="flex-1 d-flex h-100 align-items-center justify-content-center">
            <span
              className="f-1"
              style={{
                userSelect: "none",
              }}
            >
              <FontAwesomeIcon icon={faXmarkCircle} /> Please Enter Vessel and
              Place of Delivery
              <br />
              <br />
              <br />
              <br />
            </span>
          </div>
        )}
        {columns.length > 0 &&
          !(
            !selectedVessel ||
            !selectedICDcode ||
            selectedICDcode == "" ||
            selectedVessel == ""
          ) && (
            <DataTable
              data={tableData || []}
              columns={columns}
              sortable={true}
              searchable={false}
              showColumnFilter={false}
              configGroup={
                grids?.find?.((g: any) => g?.gridName === gridName)
                  ?.configGroup || []
              }
              perPage={20}
              pagination={true}
              selection={false}
              showExport={false}
              localFilter={true}
              showColumnSettings={showSettings}
              onHide={() => {
                setShowSettings(false);
              }}
              gridName={gridName}
              columnSettingsPersistKey="@odex/enqtable/v1"
              customSort={false}
              shortPrepend="EQ_"
              uniqueFilterKey={uniqueFilterKey}
              staticHiddenCols={configs?.staticHiddenCols || []}
              defaultHiddenCols={configs?.defaultHiddenCols || []}
              autoHeight={false}
              tableMinHeight={400}
              dataCountry={dataCountry}
              gridId={grids?.[0]?.gridId || 0}
              dataLoading={false}
              manualFilter={true}
            />
          )}
      </>
    </DashboardContainer>
  );
};

export default EnquiryDashboardForContainer;
