import classNames from 'classnames';
import AppContext from '../../../context/Context';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Collapse, Nav } from 'react-bootstrap';
import { NavLink, matchPath, useLocation } from 'react-router-dom';
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  updateCurrentPageBlDashboard,
  updateSetDefaultViewBL,
  updateSetDefaultViewInvoice,
  updateCurrentPageInvoiceDashboard,
  updateCurrentPageUserDashboard,
  updateCurrentPageRegistrationDashboard,
} from '../../../app/features/dashboard/slices/dashboardSlice';

// Helper function to check if a route or its children are active
const isRouteActive = (route, pathname) => {
  if (route.to === pathname) return true;
  if (route.children) {
    return route.children.some(child => {
      // Check if the pathname starts with the child's route
      // This ensures parent stays active even when child's subroute is active
      return pathname.startsWith(child.to) || isRouteActive(child, pathname);
    });
  }
  return false;
};

const CollapseItems = ({ route, clickTrigger, setClickTrigger }) => {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(isRouteActive(route, pathname));

  useEffect(() => {
    if (clickTrigger) {
      setOpen(false);
      setClickTrigger(false);
    }
  }, [clickTrigger, setClickTrigger]);

  const isActive = isRouteActive(route, pathname);

  // Keep the menu open if it's active
  useEffect(() => {
    if (isActive && !open) {
      setOpen(true);
    }
  }, [isActive, open]);
  return (
    <Nav.Item as='li'>
      <Nav.Link
        onClick={() => {
          setOpen(!open);
        }}
        className={classNames('dropdown-indicator cursor-pointer', {
          'active': isActive
        })}
        aria-expanded={open}
      >
        <NavbarVerticalMenuItem route={route} />
      </Nav.Link>
      <Collapse in={open}>
        <Nav className='flex-column nav' as='ul'>
          <NavbarVerticalMenu routes={route.children} />
        </Nav>
      </Collapse>
    </Nav.Item>
  );
};

CollapseItems.propTypes = {
  route: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    children: PropTypes.array.isRequired,
    active: PropTypes.bool,
  }).isRequired,
  clickTrigger: PropTypes.bool.isRequired,
  setClickTrigger: PropTypes.func.isRequired,
};

const NavbarVerticalMenu = ({ routes }) => {
  const {
    config: { showBurgerMenu },
    setConfig,
  } = useContext(AppContext);
  const location = useLocation();
  const dispatch = useDispatch();
  const [clickTrigger, setClickTrigger] = useState(false);
  const activeIndex = useSelector((state) => {
    const getName = {
      BL_DASHBOARD: 'BL Dashboard',
      INVOICE_DASHBOARD: 'Invoice Dashboard',
      OPC_INSTRUCTION_DASHBOARD: 'Instruction',
      OPC_DO_DASHBOARD: 'DO Dashboard',
      OPC_USER_DASHBOARD: 'Users',
      STANDING_INSTRUCTION: 'Standing Instruction',
      PAYMENT_DASHBOARD: 'Payment Details',
    };
    return routes.findIndex(
      (route) => route.name === getName[state.navbarTop.selectedDashbaordName]
    );
  });

  const handleNavItemClick = () => {
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
    dispatch(updateCurrentPageBlDashboard(1));
    dispatch(updateCurrentPageInvoiceDashboard(1));
    dispatch(updateCurrentPageUserDashboard(1));
    dispatch(updateCurrentPageRegistrationDashboard(1));
    dispatch(updateSetDefaultViewBL(true));
    dispatch(updateSetDefaultViewInvoice(true));
    setClickTrigger(true);
  };

  return routes.map((route, i) => {
    if (!route.children) {
      return (
        <Nav.Item as='li' key={route.name} onClick={handleNavItemClick}>
          <NavLink
            end={route.exact}
            to={route.to}
            state={{ open: route.to === '/authentication-modal' }}
            className={({ isActive }) => {
              if (route.to === '#!') return 'nav-link';
              if (isActive) return 'active nav-link';
              if (routes.some((r) => isRouteActive(r, location.pathname)))
                return 'nav-link';
              if (i === activeIndex) return 'active nav-link';
              return 'nav-link';
            }}
          >
            <NavbarVerticalMenuItem route={route} />
          </NavLink>
        </Nav.Item>
      );
    }
    return (
      <CollapseItems
        route={route}
        key={route.name}
        clickTrigger={clickTrigger}
        setClickTrigger={setClickTrigger}
      />
    );
  });
};

NavbarVerticalMenu.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape(NavbarVerticalMenuItem.propTypes))
    .isRequired,
};

export default NavbarVerticalMenu; 