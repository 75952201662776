import { createSlice } from '@reduxjs/toolkit';
import { getContainerByBl, getInstructionConfig, getMpsCategory, getMpsDocList, getMPSDocumentNames, getMPSRequest, getMpsTypes, getStatusOptions, getTrafficOptions, getTransitOptions } from './mpsActions';
import { actions } from 'react-table';

interface IDocListData {
    rowNumber: any,
    attId: number | null,
    docId: number,
    docName: string,
    fileName: string,
    filePath: string,
    tempName: string,
    serverFilePath: string,
    m: boolean,
    loading: number
}
interface IContainerListData {
    rowNumber: any,
    containerNumber: string,
    selected: boolean;
}
interface IMpsSlice {
    trafficOptions: Array<any>;
    transitOptions: Array<any>;
    typeOptions: Array<any>;
    categoryOptions: Array<any>;
    statusOptions: Array<any>;
    documentData: Array<IDocListData>;
    containerData: Array<IContainerListData>;
    config: Record<string, { s: boolean, m: boolean }>
}

const initialState: IMpsSlice = {
    trafficOptions: [],
    transitOptions: [],
    typeOptions: [],
    statusOptions: [],
    categoryOptions: [],
    documentData: [],
    containerData: [],
    config: {
        referenceNumber: { s: false, m: false },
        companyTin: { s: false, m: false },
        companyName: { s: false, m: false },
        traffic: { s: false, m: false },
        vessel: { s: false, m: false },
        transit: { s: false, m: false },
        bl_so: { s: false, m: false },
        containerCount: { s: false, m: false },
        containerList: { s: false, m: false } ,
        documentList: { s: false, m: false },
        remarks: { s: false, m: false },
        type: { s: true, m: true }, // always true since we need it for config
        category: { s: true, m: true } // always true since we need it for config
    }
};

export const mappingDashboardSlice = createSlice({
    name: 'mps',
    initialState,
    reducers: {
        updateDocumentTableRow: (state, action) => {
            const row: any = state.documentData.find((x) => action.payload.rowNumber == x.rowNumber)
            if (!row) return
            Object.entries(action.payload).forEach(([key, val]) => { row[key] = val })
        },
        updateContainerTableRow: (state, action) => {
            const row: any = state.containerData.find((x) => action.payload.rowNumber == x.rowNumber)
            if (!row) return
            Object.entries(action.payload).forEach(([key, val]) => { row[key] = val })
        },
        removeDocumentTableRow: (state, action) => {
            state.documentData = state.documentData.filter((x) => x.rowNumber != action.payload)
        },
        addDocumentTableRow: (state, action) => {
            state.documentData.push({
                rowNumber: action.payload,
                attId: null,
                docId: 12,
                docName: 'Others',
                fileName: '',
                filePath: '',
                tempName: '',
                serverFilePath: '',
                m: true,
                loading: 0
            })
        },
        clearMpsConfig:(state) =>{
            state.config ={
                referenceNumber: { s: false, m: false },
                companyTin: { s: false, m: false },
                companyName: { s: false, m: false },
                traffic: { s: false, m: false },
                vessel: { s: false, m: false },
                transit: { s: false, m: false },
                bl_so: { s: false, m: false },
                containerCount: { s: false, m: false },
                containerList: { s: false, m: false } ,
                documentList: { s: false, m: false },
                remarks: { s: false, m: false },
                type: { s: true, m: true }, // always true since we need it for config
                category: { s: true, m: true } // always true since we need it for config
            }
        },
        clearMpsTypes:(state)=>{
            state.typeOptions = []
        },
        clearContainerList: (state)=>{
            state.containerData = []
        },
        clearDocumentList: (state)=>{
            state.documentData = state.documentData.filter((x)=>x.docId != 12).map((x)=>({
                ...x,
                serverFilePath:'',
                tempName: '',
                fileName: '',
                filePath: '',
                loading: 0,
                attId: null
            }))

        }

    },
    extraReducers: (builder) => {
        builder.addCase(getTrafficOptions.fulfilled, (state, action) => {
            state.trafficOptions = action.payload
        })
        builder.addCase(getTransitOptions.fulfilled, (state, action) => {
            state.transitOptions = action.payload
        })
        builder.addCase(getInstructionConfig.pending, (state, action) => {
            state.config = {
                referenceNumber: { s: false, m: false },
                companyTin: { s: false, m: false },
                companyName: { s: false, m: false },
                traffic: { s: false, m: false },
                vessel: { s: false, m: false },
                transit: { s: false, m: false },
                bl_so: { s: false, m: false },
                containerCount: { s: false, m: false },
                containerList: { s: false, m: false } ,
                documentList: { s: false, m: false },
                remarks: { s: false, m: false },
                type: { s: true, m: true }, // always true since we need it for config
                category: { s: true, m: true } // always true since we need it for config
            }
        })
        builder.addCase(getInstructionConfig.fulfilled, (state, action) => {
            state.config = action.payload
        })
        builder.addCase(getMpsCategory.fulfilled, (state, action) => {
            state.categoryOptions = action.payload
        })
        builder.addCase(getMpsTypes.fulfilled, (state, action) => {
            state.typeOptions = action.payload
        })
        builder.addCase(getMpsDocList.fulfilled, (state, action) => {
            state.documentData = action.payload
        })
        builder.addCase(getContainerByBl.pending, (state)=>{
            state.containerData = []
        })
        builder.addCase(getContainerByBl.fulfilled, (state, action)=>{
            state.containerData = action.payload
        })

        builder.addCase(getMPSDocumentNames.fulfilled, (state, action)=>{
            state.documentData = state.documentData.map((s)=>({
                ...s,
                docName: action.payload.find((x)=>x.docId == s.docId).docName,
                m: action.payload.find((x)=>x.docId == s.docId).m
            }))
        })

        builder.addCase(getMPSRequest.fulfilled, (state, action)=>{
            state.containerData = action.payload?.containerData
            state.documentData = action.payload?.documents
        })
        builder.addCase(getStatusOptions.fulfilled, (state, action)=>{
            state.statusOptions = action.payload
        })
    }

});

export const {
    updateDocumentTableRow, addDocumentTableRow, removeDocumentTableRow, updateContainerTableRow,
    clearMpsConfig,
    clearContainerList,
    clearDocumentList,
    clearMpsTypes
} = mappingDashboardSlice.actions;

export default mappingDashboardSlice.reducer;
