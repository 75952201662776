import { Card } from 'react-bootstrap';

import { Outlet, matchPath, useLocation } from 'react-router-dom';

import dataTableBgImg from '../../../../assets/img/illustrations/cornerRight-1.png';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import FilterView from '../../common/components/FilterView';
import { toggleColumnSettingsDashboard } from '../instructionDashboard/slices/dashboardSlice';

const DashboardRoot = () => {
  const location = useLocation();
  const dataLoading = useSelector(
    (state: RootState) => state.dashboard.dataLoading
  );
  const grids = useSelector((state: RootState) => state.dataTable.doGrids);

  // const data = useSelector((state: RootState) => state.instructionDashboard.data);
  const data = useSelector(
    (state: RootState) => state.dashboard.doDashboardList
  );

  return (
    <FilterView
      //           loadList={loadDoDashboard}
      showAddDoButton={location.pathname === '/opc/doupload'}
      showColumnSettings={location.pathname === '/opc/doupload'}
      toggleColumnSettings={toggleColumnSettingsDashboard}
      configViews={
        grids?.find?.((g) => g?.gridName === 'OPC_DO_DASHBOARD')?.configGroup ||
        []
      }
      showExport={location.pathname === '/opc/doupload'}
      showRightDiv={
        location.pathname.includes('/opc/doupload/details') ? false : true
      }
      uniqueFilterKey='doDashboard'
      data={data}
      //     columns={columns}
      dataLoading={dataLoading}
      columnSettingsPersistKey='@odex/user/v1'
      dashboardName={'OPC_DO_DASHBOARD'}
      gridDef={grids}

      //data={userDetails?.data?.userData}
    />
  );
};

export default DashboardRoot;
