import { useNavigate, useParams } from "react-router-dom"
import { AppDispatch, RootState } from "../../store";
import { useSelector } from "react-redux";
import DataTable from "../../common/components/DataTable";
import useDataCountry from "../../../hooks/useDataCountry";
import { useEffect, useState } from "react";
import { IDataTableColumn } from "../../common/types/dataTable";
import { generateColumnConfig, IConfigsDefinition } from "../../utils/columnConfig";
import { getEnquiryTableData } from "../dashboard/actions/dashboard";
import { useDispatch } from "react-redux";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-regular-svg-icons";
import { useBackNavigation } from "../../../hooks/BackNavigation";
import { getColumnViews } from "../../common/actions/dataTable";

export default function InvoicedBlsSlider(){
    const {draft} = useParams()
    const grids = useSelector(
        (state: RootState) => state.dataTable.enquiryDashboardGrids
    );
    const tableData = useSelector(
        (state: RootState) => state.invoiceChange.enquiryDashboardList
    );
    const dataCountry = useDataCountry()

    const [configs, setConfigs] = useState<IConfigsDefinition>({columns: [],
    staticHiddenCols: [],
    defaultHiddenCols: [],
    });
    
    const [columns, setColumns] = useState<IDataTableColumn[]>([]);
    const gridName = 'OPC_ENQ_DASHBOARD'
    const dispatch = useDispatch<AppDispatch>()
    const onBack = useBackNavigation()
    const tpoId = useSelector((state:RootState)=>state.agentDelegation.tpoId)

    const loadEnquiryDashboard = () => {
        if( draft && draft.trim() != '') {

      dispatch(
        getEnquiryTableData({
          draftNumber:draft 
        })
      );
    }
  };

  useEffect(()=>{
    loadEnquiryDashboard()
  },[draft])

  const navigate = useNavigate();
    useEffect(() => {
    const blDashboardDefinition = grids?.[0]?.columnConfigGrid || '';

    if (!blDashboardDefinition) return;

    try {
      const configs = generateColumnConfig(
        JSON.parse(JSON.parse(blDashboardDefinition)),
        ()=>{},  
        navigate,
        dispatch
      );

      setConfigs(configs);
      setColumns(configs?.columns);
    } catch (e) {
      console.error('Column Error: ', e);
    }
  }, [grids])

  useEffect(() => {
    if (dataCountry && tpoId) {
      setTimeout(() => {
        dispatch(
          getColumnViews({
            gridName: gridName,
            country: dataCountry,
            linerId: tpoId,
          })
        );
      }, 150);
    }
  }, [dataCountry, grids, tpoId]);

  
    return <Card className="h-100">
        <Card.Header className="d-flex align-items-center border-bottom sticky-top bg-white">
        <FontAwesomeIcon
          icon={faArrowAltCircleLeft}
          className="text-warning cursor-pointer fa-lg mb-1 me-1"
          onClick={onBack}
          
        />
        <h2 className="fs-0 text-primary mb-1">Invoiced Bls</h2>
        </Card.Header>

    {columns.length > 0 && <DataTable

            data={tableData || []}
            columns={columns}
            sortable={true}
            searchable={false}
            showColumnFilter={false}
            perPage={100}
            pagination={false}
            selection={false}
            showExport={false}
            localFilter={true}
            showColumnSettings={false}
            onHide={() => {}}
            gridName={gridName}
            customSort={false}
            shortPrepend='EQS_'
            uniqueFilterKey={'enquirySlider'}
            staticHiddenCols={configs?.staticHiddenCols || []}
            defaultHiddenCols={configs?.defaultHiddenCols || []}
            autoHeight={false}
            tableMinHeight={400}
            dataCountry={dataCountry}
            gridId={grids?.[0]?.gridId || 0}
            dataLoading={false}
            manualFilter={true}
          />}</Card>
}