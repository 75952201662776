import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AESDecrypt } from '../../../encrypt-util';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, Card, Form } from 'react-bootstrap';
import { updateRowColumns } from './UserDetailsSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { updateToggle, updateUserDetailsList } from './UserDetailsSlice';
import { faRedo, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import {
  saveUserDetails,
  getGroupList,
  getAllUserDetailsByUser,
} from './action/UserDetails';
import { useTranslation } from 'react-i18next';
import { getUserProfile } from '../../common/actions/userDetails';
import { updateDashbaordRefresh } from '../../common/components/DataTable/slices/DataTable';

const User = () => {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const userDetails = useSelector((state: RootState) => state.userList);
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const { userID } = useParams();
  const navigate = useNavigate();

  //User Id
  const [loginId, setLoginId] = useState('');

  //Title
  const [title, setTitle] = useState('');

  //User Name
  const [usrNm, setUsrNm] = useState('');

  //Contact Number
  const [contactNo, setContactNo] = useState('');

  //Contact Person
  const [contactPerson, setContactPerson] = useState('');

  const [groupList, setGroupList] = useState([]);

  const [status, setStatus] = useState('');

  const [disableFields, setDisableFields] = useState<any>(
    profileDetails?.partnerType === 'liner' ||
    (!!matchPath && profileDetails?.partnerType !== 'liner')
  );
  const rbac = useSelector((state: RootState) => state.userProfile.rbacDetails)

  const [user, setUser] = useState<any>(
    AESDecrypt(userID?.toString()).toString() !== '0'
      ? {
        mstPartnerId: userDetails.data?.mstPartnerId,
        mstUsrId: null,
        loginId: '',
        usrNm: '',
        contactNo: '',
        title: 'Mr.',
        contactPerson: '',
        mstGrp: [],
        status: '',
        grpNm: '',
        countryShortCode: userDetails.data?.countryShortCode,
        countryName: userDetails.data.countryName,
        isRootUser: 'N',
        partnerNm: userDetails.data.partnerNm,
        nationalId: '',
      }
      : userDetails?.data?.userData?.list?.find(
        (v: any) =>
          AESDecrypt(userID?.toString())?.toString() ===
          v.mstUsrId?.toString()
      )
  );

  const [mstGrp, setMstGrp] = useState(
    user?.mstGrp.map((c: any) => {
      return {
        value: c?.mstGrpId,
        label: c?.usrGrpNm,
      };
    })
  );
  type FormValues = {
    loginId: string;
    title: string;
    usrNm: string;
    contactNo: string;
    contactPerson: string;
    mstGrp: [];
    status: string;
    nationalId: string;
    phoneNumber: string;
  };

  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    loginId: Yup.string()
      .required(t('resource_3:isRequired', { field: 'Email ID' }))
      .matches(/^\S+@\S+\.\S+$/, t('Invalid Email ID', { field: 'Email ID' })),
    /*   title: Yup.string().required(
      t('resource_3:isRequired', { field: 'Title' })
    ), */
    usrNm: Yup.string().required(
      t('resource_3:isRequired', { field: 'User Name' })
    ),
    mstGrp: Yup.array().min(1, 'Pick at least 1 group'),
    status: Yup.string().required(
      t('resource_3:isRequired', { field: 'Status' })
    ),
    nationalId: Yup.string().required(
      t('resource_3:isRequired', { field: 'National ID' })
    ),
    phoneNumber: Yup.string().required(
      t('resource_3:isRequired', { field: 'Phone Number' })
    ),
  });

  //Setup
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const onSubmit = handleSubmit(async (payload) => {
    try {
      const finalList =
        user.mstUsrId === null
          ? [
            {
              mstPartnerId: userDetails.data?.mstPartnerId,
              mstUsrId: null,
              loginId: loginId,
              usrNm: usrNm,
              contactNo: contactNo,
              title: title,
              contactPerson: contactPerson,
              grpNm: mstGrp?.map((u: any) => u.label).join(','),
              mstGrp: mstGrp.map((g: any) => {
                return {
                  mstGrpId: g.value,
                  usrGrpNm: g.label,
                };
              }),
              status: status,
              countryShortCode: userDetails.data?.countryShortCode,
              countryName: userDetails.data.countryName,
              isRootUser: 'N',
              partnerNm: userDetails.data.partnerNm,
            },
          ].concat(userDetails.data.userData.list.map((v: any) => v))
          : userDetails.data.userData.list.map((v: any) => {
            if (user.mstUsrId === v.mstUsrId) {
              return {
                ...v,
                loginId: loginId,
                usrNm: usrNm,
                contactNo: contactNo,
                title: title,
                contactPerson: contactPerson,
                grpNm: mstGrp?.map((u: any) => u.label).join(','),
                partnerNm: userDetails.data.partnerNm,
                mstGrp: mstGrp.map((g: any) => {
                  return {
                    mstGrpId: g.value,
                    usrGrpNm: g.label,
                  };
                }),
                status: status,
              };
            } else {
              return v;
            }
          });

      dispatch(
        saveUserDetails({
          /*     mstPartnerId: user.mstPartnerId,
          mstUsrId: user.mstUsrId, */
          mstPartnerId: profileDetails.partnerId,
          mstUsrId: user.mstUsrId,
          loginId: loginId.toLocaleLowerCase(),
          actionBy: profileDetails.email,
          actionOn: new Date(),
          usrNm: usrNm,
          /*  contactNo: contactNo, */
          phnNo: watch('phoneNumber'),
          userIdentityNo: watch('nationalId'),

          /*   title: title,
          contactPerson: contactPerson, */
          title: user.title,
          contactPerson: user.contactPerson,
          grpNm: mstGrp?.map((u: any) => u.label).join(','),
          mstGrp: mstGrp.map((g: any) => {
            return {
              mstGrpId: g.value,
              usrGrpNm: g.label,
            };
          }),
          status: status,
          /*       countryShortCode: userDetails.data?.countryShortCode,
          countryName: userDetails.data.countryName, */
          countryShortCode: profileDetails.partnerCountry,
          countryName: 'Tanzania',
          isRootUser: user.isRootUser,
          /*   partnerNm: userDetails.data.partnerNm, */
          partnerNm: profileDetails.partnerName,
        })
      )
        .unwrap()
        .then((response: any) => {
          if (response === '') {
            toast.dark('Please Try Again', { toastId: '4a8bbf7' });
            navigate('opc/users');
          } else {
            navigate('/opc/users');
            if (response?.responseCode === "INVALID") {
              toast.error(response.responseMsg.toString(), { toastId: 'ce7fddc' });
            }
            else {
              toast.success(
                response.responseMsg.toString(),
                {
                  delay: 500
                });
            }
            dispatch(
              updateToggle({
                ...userDetails,
                toggle: true,
              })
            );
            dispatch(
              updateUserDetailsList({
                ...userDetails.data.userData,
                list: [],
              })
            );
            setTimeout(() => {
              dispatch(updateDashbaordRefresh(true));
            }, 1000);
            //navigate(-1);
          }
        });
    } catch (err) {
      toast.dark('Please Try Again', { toastId: '52b9266' });
    }
  });

  const handleReset = () => {
    setValue('mstGrp', []);
    reset();
    setLoginId('');
    setTitle('');
    setUsrNm('');
    setContactNo('');
    setContactPerson('');
    setStatus('1');
    setMstGrp([]);
  };

  useEffect(() => {
    if (matchPath('opc/users/details/:userID', location.pathname)) {
      // set form values as user changes
      setLoginId(user?.loginId);
      setValue('loginId', user?.loginId);
      setTitle(user?.title);
      setValue('title', user?.title);
      setUsrNm(user?.usrNm);
      setValue('usrNm', user?.usrNm);
      setContactNo(user?.contactNo);
      setValue('contactNo', user?.contactNo);
      setContactPerson(user?.contactPerson);
      setValue('contactPerson', user?.contactPerson);
      setStatus(user?.status || '1'); // Default to 'Active' if no status
      setValue('status', user?.status || '1');
      setValue('nationalId', user?.nationalId);
      setValue('phoneNumber', user?.phoneNumber);
      setMstGrp(
        user?.mstGrp.map((v: any) => {
          return { value: v?.mstGrpId, label: v?.usrGrpNm };
        })
      );
      setValue(
        'mstGrp',
        user?.mstGrp.map((v: any) => {
          return { value: v?.mstGrpId, label: v?.usrGrpNm };
        })
      );
      setValue('status', user?.status);
      dispatch(getGroupList({}))
        .unwrap()
        .then((response: any) => {
          // set group list to dropdown list
          setGroupList(
            response.map((v: any) => {
              return { label: v?.usrGrpNm, value: v?.mstGrpId };
            })
          );
        });
    }
  }, [user]);

  useEffect(() => {
    if (matchPath('opc/users/details/:userID', location.pathname)) {
      dispatch(
        updateRowColumns({
          mainTableClass: 'col-lg-2',
          outletClass: 'col-lg-10',
        })
      );
      if (AESDecrypt(userID?.toString()).toString() === '0') {
        //Set data for new user

        setUser({
          mstPartnerId: userDetails.data?.mstPartnerId,
          mstUsrId: null,
          loginId: '',
          usrNm: '',
          contactNo: '',
          title: 'Mr.',
          contactPerson: '',
          mstGrp: [],
          status: '1',
          countryShortCode: userDetails.data?.countryShortCode,
          isRootUser: 'N',
          partnerNm: userDetails.data.partnerNm,
        });
        setDisableFields(false);
      } else {
        //get selected user
        setDisableFields(true);
        dispatch(getUserProfile())
          .unwrap()
          .then((response: any) => {
            if (
              userDetails.data.userData.list.map((v: any) => v).length === 0
            ) {
              dispatch(
                getAllUserDetailsByUser({
                  partnerId: response?.data?.partnerId,
                  usrId: response?.data?.userId,
                })
              )
                .unwrap()
                .then((response: any) => {
                  const userData = response.userData.list.find(
                    (v: any) =>
                      AESDecrypt(userID?.toString()).toString() ===
                      v.mstUsrId.toString()
                  );

                  setUser({
                    mstPartnerId:
                      userData.mstPartnerId ?? userDetails.data?.mstPartnerId,
                    mstUsrId: userData.mstUsrId ?? null,
                    loginId: userData.loginId ?? '',
                    usrNm: userData.usrNm ?? '',
                    contactNo: userData.contactNo ?? '',
                    title: userData.title ?? 'Mr.',
                    contactPerson: userData.contactPerson ?? '',
                    mstGrp: userData.mstGrp ?? [],
                    status: userData.status ?? '',
                    grpNm: userData.grpNm ?? '',
                    countryShortCode:
                      userData.countryShortCode ??
                      userDetails.data?.countryShortCode,
                    countryName:
                      userData.countryName ?? userDetails.data.countryName,
                    isRootUser: userData.isRootUser ?? 'N',
                    partnerNm: userData.partnerNm ?? userDetails.data.partnerNm,
                    nationalId: userData.userIdentityNo ?? '',
                    phoneNumber: userData.phnNo ?? '',
                  });
                });
            } else {
              const userData: any = userDetails.data.userData.list.find(
                (v: any) =>
                  AESDecrypt(userID?.toString()).toString() ===
                  v.mstUsrId.toString()
              );
              setUser({
                mstPartnerId:
                  userData?.mstPartnerId ?? userDetails.data?.mstPartnerId,
                mstUsrId: userData?.mstUsrId ?? null,
                loginId: userData?.loginId ?? '',
                usrNm: userData?.usrNm ?? '',
                contactNo: userData?.contactNo ?? '',
                title: userData?.title ?? 'Mr.',
                contactPerson: userData?.contactPerson ?? '',
                mstGrp: userData?.mstGrp ?? [],
                status: userData?.status ?? '',
                grpNm: userData?.grpNm ?? '',
                countryShortCode:
                  userData?.countryShortCode ??
                  userDetails.data?.countryShortCode,
                countryName:
                  userData?.countryName ?? userDetails.data.countryName,
                isRootUser: userData?.isRootUser ?? 'N',
                partnerNm: userData?.partnerNm ?? userDetails.data.partnerNm,
                nationalId: userData?.userIdentityNo ?? '',
                phoneNumber: userData?.phnNo ?? '',
              });
            }
          });
      }
      // convert group list to dropdown list
      setMstGrp(
        user?.mstGrp?.map((v: any) => {
          return {
            value: v?.mstGrpId,
            label: v?.usrGrpNm,
          };
        })
      );
    } else if (matchPath('opc/users', location.pathname)) {
      dispatch(
        updateRowColumns({
          mainTableClass: 'col-lg-12',
          outletClass: 'd-none',
        })
      );

      // navigate('opc/users');
    }
  }, [location.pathname, userDetails.data.userData.list]);

  return (
    <div className=' h-100'>
      <Form onSubmit={onSubmit} autoComplete='off'>
        <div>
          <div className='row'>
            <div className='col-lg-6 '>
              <Form.Group controlId='user.loginId'>
                <Form.Label className='fs--1 '>
                  <span className='mandatory'>*</span>
                  {t('resource_2:emailID')}
                </Form.Label>
                <Form.Control
                  {...register('loginId')}
                  className={`fs--1 form-control textTransform ${errors.loginId ? 'is-invalid' : ''
                    }`}
                  type='text'
                  onChange={(e) => {
                    setLoginId(e.target.value);
                    clearErrors('loginId');
                    //_onAccountAliasChange(e.target.value);
                    setValue('loginId', e.target.value);
                  }}
                  onBlur={(e) => {
                    if (loginId !== '') {
                      setValue('loginId', e.target.value);
                    }
                  }}
                  size='sm'
                  maxLength={200}
                  value={loginId}
                  disabled={disableFields}
                />
                <div className='invalid-feedback'>
                  {errors.loginId?.message?.toString()}
                </div>
              </Form.Group>
            </div>
            <div className='col-lg-6 '>
              <Form.Group controlId='user.usrNm'>
                <Form.Label className='fs--1 '>
                  <span className='mandatory'>*</span>
                  {t('resource_2:userName')}
                </Form.Label>
                {/*<div className='col-lg-4 mb-2'>
                                    <Form.Select
                                        {...register('title')}
                                        className={`fs--1 form-control ${
                                        errors.title ? 'is-invalid' : ''
                                        }`}
                                        value={title}
                                        onChange={(e) => { 
                                            setTitle(e.target.value);
                                            setValue('title', e.target.value);
                                            }}>
                                        <option value='Mr.'  >Mr</option>
                                        <option value='Mrs.' >Mrs</option>
                                    </Form.Select>
                                        </div>
                                <div className='col-lg-8 '>*/}
                <Form.Control
                  {...register('usrNm')}
                  className={`fs--1 form-control ${errors.usrNm ? 'is-invalid' : ''
                    }`}
                  type='text'
                  maxLength={200}
                  disabled={false}
                  onChange={(e) => {
                    setUsrNm(e.target.value);
                    clearErrors('usrNm');
                    setValue('usrNm', e.target.value);
                    //_onAccountAliasChange(e.target.value);
                  }}
                  onBlur={(e) => {
                    if (usrNm !== '') {
                      setValue('usrNm', e.target.value);
                    }
                  }}
                  size='sm'
                  value={usrNm}
                />
                {/*</div>*/}

                <div className='invalid-feedback'>
                  {errors.title?.message?.toString()}
                  {errors.usrNm?.message?.toString()}
                </div>
              </Form.Group>
            </div>
            {/*For now hidding contact number and contact person <div className='col-lg-6 mb-3'>
                            <Form.Group controlId='user.contactNo'>
                                <Form.Label className='fs--1 '>
                                    <span className='mandatory'>*</span>
                                    {'Contact Number'}
                                </Form.Label>
                                <Form.Control
                                    {...register('contactNo')}
                                    className={`fs--1 form-control ${
                                        errors.contactNo ? 'is-invalid' : ''
                                    }`}
                                    type='text'
                                    onChange={(e) => {
                                        setContactNo(e.target.value);
                                        //_onAccountAliasChange(e.target.value);
                                    }}
                                    onBlur={(e) => {
                                        if (contactNo !== '') {
                                        setValue('contactNo', e.target.value);
                                        }
                                    }}
                                    size='sm'
                                    value={contactNo}        
                                />
                                <div className='invalid-feedback'>
                                    {errors.contactNo?.message?.toString()}
                                </div>
                            </Form.Group>       
                        </div>
                        <div className='col-lg-6 mb-3'>
                            <Form.Group controlId='user.contactPerson'>
                                <Form.Label className='fs--1 '>
                                    <span className='mandatory'>*</span>
                                    {'Contact Person'}
                                </Form.Label>
                                <Form.Control
                                    {...register('contactPerson')}
                                    className={`fs--1 form-control ${
                                        errors.contactPerson ? 'is-invalid' : ''
                                    }`}
                                    type='text'
                                    onChange={(e) => {
                                        setContactPerson(e.target.value);
                                        //_onAccountAliasChange(e.target.value);
                                    }}
                                    onBlur={(e) => {
                                        if (contactPerson !== '') {
                                        setValue('contactPerson', e.target.value);
                                        }
                                    }}
                                    size='sm'
                                    value={contactPerson}        
                                />
                                <div className='invalid-feedback'>
                                    {errors.contactPerson?.message?.toString()}
                                </div>
                            </Form.Group>       
                                </div>*/}
            {
              <div className='col-lg-6 ' style={{ zIndex: 999 }}>
                <Form.Label className='fs--1'>
                  <span className='mandatory'>*</span>
                  {t('resource_1:group')}
                </Form.Label>
                <Select
                  {...register('mstGrp')}
                  onChange={(u: any) => {
                    setMstGrp(u.map((v: any) => v));
                    clearErrors('mstGrp');
                    setValue(
                      'mstGrp',
                      u.map((v: any) => v)
                    );
                  }}
                  options={groupList}
                  isDisabled={false}
                  value={mstGrp}
                  className={`fs--1  ${errors.mstGrp?.toString ? 'is-invalid' : ''
                    }`}
                  noOptionsMessage={() => 'No group'}
                  isMulti={true}
                  autoFocus={true}
                  isSearchable={true}
                // value={mstGrp}
                />

                <div className='invalid-feedback'>
                  {errors.mstGrp?.message?.toString()}
                </div>
              </div>
            }
            <div className='col-lg-6 '>
              <Form.Group controlId='user.nationalId'>
                <Form.Label className='fs--1'>
                  <span className='mandatory'>*</span>
                  {t('resource_3:national_identity_card')}
                </Form.Label>
                <Form.Control
                  type='text'
                  {...register('nationalId', {
                    required: 'National ID is required',
                  })}
                  className={`fs--1 form-control ${errors.nationalId ? 'is-invalid' : ''
                    }`}
                  value={watch('nationalId')}
                  disabled={false}
                  onChange={(e) => {
                    setValue('nationalId', e.target.value);
                    clearErrors('nationalId');
                  }}
                />
                <div className='invalid-feedback'>
                  {errors.nationalId?.message}
                </div>
              </Form.Group>
            </div>

            <div className='col-lg-6 '>
              <Form.Group controlId='user.phoneNumber'>
                <Form.Label className='fs--1'>
                  <span className='mandatory'>*</span>
                  {t('resource_2:phoneNo')}
                </Form.Label>
                <Form.Control
                  type='text'
                  {...register('phoneNumber', {
                    required: 'Phone Number is required',
                  })}
                  className={`fs--1 form-control ${errors.phoneNumber ? 'is-invalid' : ''
                    }`}
                  value={watch('phoneNumber')}
                  disabled={false}
                  onChange={(e) => {
                    setValue('phoneNumber', e.target.value);
                    clearErrors('phoneNumber');
                  }}
                />
                <div className='invalid-feedback'>
                  {errors.phoneNumber?.message}
                </div>
              </Form.Group>
            </div>

            <div className='col-lg-6 '>
              <Form.Group controlId='user.status'>
                <Form.Label className='fs--1'>
                  <span className='mandatory'>*</span>
                  {t('resource_1:status')}
                </Form.Label>
                <Form.Select
                  {...register('status')}
                  className={`fs--1 form-control ${errors.status ? 'is-invalid' : ''
                    }`}
                  value={status}
                  disabled={false}
                  onChange={(e) => {
                    setStatus(e.target.value);
                    clearErrors('status');
                  }}
                >
                  <option value='1'>Active</option>
                  <option value='2'>Inactive</option>
                </Form.Select>
                <div className='invalid-feedback'>
                  {errors.status?.message?.toString()}
                </div>
              </Form.Group>
            </div>
            {
              <div className='d-flex justify-content-center align-items-center mt-4'>
                {rbac.userMgmtCreate == 'Y' && <Button
                  type='submit'
                  className='me-2'
                  variant='falcon-danger'
                  size='sm'
                >
                  <FontAwesomeIcon icon={faFloppyDisk} className='me-2' />
                  {'Save'}
                </Button>}
                {!disableFields && (
                  <Button
                    className='me-2'
                    variant='falcon-danger'
                    size='sm'
                    onClick={handleReset}
                    style={{ zIndex: 1 }}
                  >
                    <FontAwesomeIcon icon={faRedo} className='me-2' />
                    {/* {t('resource_1:reset')} */}
                    {t('Reset')}
                  </Button>
                )}
              </div>
            }
          </div>
        </div>
      </Form>
    </div>
  );
};

export default User;
