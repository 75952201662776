import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';

import { IDataTableColumn } from '../../common/types/dataTable';
import DataTable from '../../common/components/DataTable';
import CardWrapper from '../../common/components/CardWrapper';
import FilterView from '../../common/components/FilterView';
import { useEffect, useState } from 'react';
import {
  IConfigsDefinition,
  generateColumnConfig,
} from '../../utils/columnConfig';
import { getDashboardData } from '../dashboard/actions/dashboard';
import { useDispatch } from 'react-redux';

import {
  updateColumnDefinition,
  updateColumnDefinitionConfigs,
} from '../dashboard/slices/dashboardSlice';
import { getColumnViews } from '../../common/actions/dataTable';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { toggleColumnSettingsPaymentList } from '../payment/paymentSlice';
import { AESEncrypt } from '../../../encrypt-util';
import moment from 'moment';
import { updateParams } from '../../common/components/DataTable/slices/DataTable';

const PaymentFund = () => {
  // Packages
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  // States
  const [columns, setColumns] = useState<IDataTableColumn[]>([]);
  const [configs, setConfigs] = useState<IConfigsDefinition>({
    columns: [],
    staticHiddenCols: [],
    defaultHiddenCols: [],
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const grids = useSelector(
    (state: RootState) => state.dataTable.paymentFundGrids
  );
  // Selectors
  const data = useSelector(
    (state: RootState) => state.dashboard.paymentFundList
  );
  const linerId = useSelector((state: RootState) => state.navbarTop.linerId);
  const selectedConfigId = useSelector(
    (state: RootState) => state.dataTable.selectedConfigId
  );
  const filterState = useSelector(
    (state: RootState) => state.dataTable.filterState
  );

  const showColumnSettings = useSelector(
    (state: RootState) => state.payment.showColumnSettings
  );
  const onHideColumnSettings = () => {
    dispatch(toggleColumnSettingsPaymentList(false));
  };
  const filterStateLocal = filterState?.['paymentFund'];

  const findFieldsByConfigId = (selectedConfigId: any, configGroup: any) => {
    const matchedConfig = configGroup?.find(
      (config: any) => config?.userGridId == selectedConfigId
    );

    if (matchedConfig && matchedConfig?.columnConfig) {
      const fields = matchedConfig?.columnConfig
        .filter((column: any) => column?.visible === 'true')
        .map((column: any) => column?.accessor);

      return { fields };
    } else {
      return { fields: [] };
    }
  };
  const configGroup = grids?.[0]?.configGroupGrid;
  const selectedTopSearchFilter = useSelector(
    (state: RootState) => state.navbarTop.selectedTopSearchFilter
  );
  const searchInputValue = useSelector(
    (state: RootState) => state.navbarTop.searchInputValue
  );
  const instructionTopSearchState = useSelector(
    (state: RootState) => state.navbarTop.instructionTopSearchState
  );
  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );
  const paymentFundGrids = useSelector(
    (state: RootState) => state.dataTable.paymentFundGrids
  );
  const tpoId = useSelector((state: RootState) => state.agentDelegation.tpoId);
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const paymentFundTopSearch = useSelector(
    (state: RootState) => state.navbarTop.paymentFundTopSearch
  );
  const storedUserCountry = JSON.parse(
    window.localStorage.getItem('@user-selected-datacountry') || '{}'
  );
  const storedCountry =
    storedUserCountry?.[0]?.loginId === profileDetails.email
      ? storedUserCountry?.[0]?.country
      : null;
  const dataCountry = storedCountry
    ? storedCountry
    : linerDataCountry
      ? linerDataCountry
      : profileDetails.partnerCountry || null;

  useEffect(() => {
    //  if (location?.pathname && dataCountry && grids.length == 0 && linerId) {

    if (dataCountry && tpoId) {
      setTimeout(() => {
        //  dispatch(getUserGridsDefinition('TZ'));

        dispatch(
          getColumnViews({
            gridName: 'OPC_PAYMENT_FUNDING',
            country: dataCountry,
            linerId: tpoId,
          })
        );
      }, 150);
    }

    //  }
    dispatch(updateParams({}));
    // if (datatableState != 'INSTRUCTION_DASHBOARD') {
    //   dispatch(
    //     updateSortStateDataTable({
    //       key: null,
    //     })
    //   );
    // }
  }, [dataCountry, grids, tpoId]);

  useEffect(() => {
    loadPaymentFundings(false);
  }, [currentPage]);
  // const columns = useSelector((state: RootState) => state.dashboard.columns);
  let sort = (queryString.parse(location.search)?.sort as string)
    ?.split?.(',')
    ?.join?.(' ');
  useEffect(() => {
    const paymentRefuncdColumn = paymentFundGrids?.[0]?.columnConfigGrid || '';

    // const configs = generateColumnConfig(paymentRefuncdColumn);
    try {
      const configs = generateColumnConfig(
        JSON.parse(JSON.parse(paymentRefuncdColumn))
      );

      setColumns(configs.columns);
      setConfigs(configs);
      dispatch(updateColumnDefinitionConfigs(configs));
      dispatch(updateColumnDefinition(columns));
    } catch (e: any) {
      console.error('Column Error: ', e);
    }
  }, [paymentFundGrids]);

  useEffect(() => {
    loadPaymentFundings(true);
  }, [filterState, location.search, linerId]);

  const loadPaymentFundings = (filterUpdated: boolean) => {
    if (filterUpdated) {
      setCurrentPage(1);
    }

    if (filterStateLocal) {
      if (Object.keys(filterStateLocal)?.length > 0) {
        const filterApiState: any = {};

        const filterKeys = Object.keys(filterStateLocal);

        for (let k of filterKeys) {
          if (filterStateLocal?.[k]?.from) {
            filterApiState[k] = [
              filterStateLocal?.[k]?.from || '',
              filterStateLocal?.[k]?.to || '',
            ].filter((i) => i);
          } else if (filterStateLocal?.[k]?.from !== undefined) {
            filterApiState[k] = undefined;
          } else {
            filterApiState[k] = filterStateLocal?.[k]
              ? typeof filterStateLocal?.[k] === 'string'
                ? [filterStateLocal?.[k]]
                : filterStateLocal?.[k]
              : undefined || undefined;
          }
        }
        if (Object.keys(filterApiState)?.length > 0) {
          const finalFilterApiState: any =
            selectedTopSearchFilter?.id &&
              searchInputValue.trim() != '' &&
              Object.keys(instructionTopSearchState).length != 0
              ? { [selectedTopSearchFilter?.id]: [searchInputValue.trim()] }
              : {};
          const currentDate = new Date();

          if (grids[0]) {
            let timeFlag = '';
            let timeDiff = 0;
            let dateRange: any = {};

            const newdata = grids[0].configGroup?.find(
              (c: any) => c.userGridId == selectedConfigId?.['instDashboard']
            )?.columnConfigGrid;
            if (newdata) {
              timeFlag = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'timestamp'
              )?.filterState;
              timeDiff = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'timestamp'
              )?.timeDiff;
              dateRange = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'timestamp'
              )?.dateRange;
            }

            if (filterApiState.timestamp && timeFlag == 'day' && dateRange) {
              filterApiState.timestamp[1] = moment()
                .subtract(Math.abs(dateRange.to), 'days')
                .endOf('day')
                .toISOString();
              // const newDate = new Date(
              //   new Date(currentDate.toISOString()).getTime() -
              //     timeDiff * 24 * 60 * 60 * 1000
              // );
              filterApiState.timestamp[0] = moment()
                .subtract(Math.abs(dateRange.from), 'days')
                .startOf('day')
                .toISOString();
            }
          }
          for (let i of Object.keys(filterApiState)) {
            if (filterApiState[i]) {
              finalFilterApiState[i] = filterApiState[i];
            }
          }

          if (
            /*      profileDetails &&
            ((profileDetails.partnerType === 'customer' &&
              profileDetails.partnerId &&
              instructionsGrids?.[0]?.whereClause) ||
              profileDetails?.partnerType === 'liner') */
            tpoId
          ) {
            setTimeout(() => {
              dispatch(
                getDashboardData({
                  gridName: 'OPC_PAYMENT_FUNDING',
                  payload: {
                    fields: [],

                    filters: /*   selectedTopSearchFilter?.where === ''
                        ? instructionTopSearchState
                        :  */ finalFilterApiState ? finalFilterApiState : {},
                    size: 20,
                    sorts: sort ? [sort] : ['payment_funding_id desc'],
                    page: filterUpdated ? 1 : currentPage,
                  },
                  dataCountry: dataCountry,
                  partnerId: profileDetails.partnerId,

                  where: grids?.[0]?.whereClause
                    ? AESEncrypt(grids?.[0]?.whereClause)
                    : null,
                  parameter:
                    selectedTopSearchFilter?.where !== ''
                      ? {
                        searchParameter: searchInputValue,
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                      : {},
                })
              );
              // dispatch(
              //   /*    getDashboardData({
              //     gridName: 'OPC_INSTRUCTION_DASHBOARD',
              //     payload: {
              //       fields: [],

              //       filters: /*  !selectedTopSearchFilter?.where
              //         ? finalFilterApiState
              //         : */ //{},

              //   //   size: 20,
              //   //  sorts: '',
              //   /*  sortState &&
              //         sortState?.key != null &&
              //         sortState?.value != null
              //           ? [`${sortState?.key} ${sortState?.value}`]
              //           : sort
              //           ? [sort]
              //           : [''] */
              //   //  page: filterUpdated ? 1 : currentPage,
              //   //  },
              //   // dataCountry: dataCountry,
              //   // partnerId:
              //   //  profileDetails.partnerType === 'customer'
              //   //  ? profileDetails.partnerId
              //   //  : null,
              //   // where: instructionsGrids?.[0]?.whereClause
              //   //? AESEncrypt(instructionsGrids?.[0]?.whereClause)
              //   // : null,
              //   //  parameter:
              //   //  selectedTopSearchFilter?.where !== ''
              //   //  ? {
              //   //    searchParameter: searchInputValue,
              //   //  searchWhere: selectedTopSearchFilter?.where,
              //   // }
              //   // : {},
              //   //  })
              //   getDashboardData({
              //     fields: [],
              //     filters: {},
              //     size: 20,
              //     sorts: sort ? [sort] : ['requested_on desc'],
              //     /* sortState?.key != null && sortState?.value != null
              //         ? [`${sortState?.key} ${sortState?.value}`]
              //           ? [sort]
              //           : ['requested_on desc']
              //         : ['requested_on desc'], */
              //     page: 1,
              //     view: 'E58NbJpkbNRw9+y/FKnz4+ZLO9CsHv/yAz6QBCjYEpM=',
              //     where: '',
              //   })
              // );
            }, 150);
          }
        }
      }
    }
  };
  return (
    <CardWrapper>
      <FilterView
        showExport
        data={data}
        // dashboardName='PAYMENT_FUNDING'
        showColumnSettings={true}
        uniqueFilterKey={'paymentFund'}
        toggleColumnSettings={toggleColumnSettingsPaymentList}
        configViews={
          paymentFundGrids?.find?.((g) => g?.gridName === 'OPC_PAYMENT_FUNDING')
            ?.configGroup || []
        }
        dashboardName='OPC_PAYMENT_FUNDING'
        gridDef={paymentFundGrids}
      />
      {columns.length > 0 && (
        <DataTable
          columns={columns}
          data={data?.result || null}
          pagination
          numberPagination
          maxPage={parseInt(data?.maxPage || '100')}
          hasNext={data?.hasNext}
          currentPage={currentPage}
          showColumnSettings={showColumnSettings}
          onHide={onHideColumnSettings}
          onChangePageNumber={(pageNumber) => setCurrentPage(pageNumber)}
          selection={undefined}
          sortable={undefined}
          perPage={20}
          searchable={false}
          showExport={undefined}
          showColumnFilter={undefined}
          uniqueFilterKey={'paymentFund'}
          defaultHiddenCols={configs?.defaultHiddenCols || []}
          staticHiddenCols={configs?.staticHiddenCols || []}
          gridName='OPC_PAYMENT_FUNDING'
          configGroup={
            //  grids?.find?.((g) => g?.gridId === 2)?.configGroup || []
            paymentFundGrids?.find?.(
              (g) => g?.gridName === 'OPC_PAYMENT_FUNDING'
            )?.configGroup || []
          }
          gridId={paymentFundGrids?.[0]?.gridId || 0}
          customSort
        />
      )}
    </CardWrapper>
  );
};

export default PaymentFund;
