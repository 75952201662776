import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Nav, Navbar, Image } from 'react-bootstrap';
import handleNavbarTransparency from '../../../../helpers/handleNavbarTransparency';
import NavbarTopDropDownMenus from '../../../../components/navbar/top/NavbarTopDropDownMenus';
import LandingRightSideNavItem from './LandingRightSideNavItem';
import { topNavbarBreakpoint } from '../../../../config';
import AppContext from '../../../../context/Context';

import Logo from '../../../../assets/images/logo.png';

const NavbarStandard = () => {
  const showLogo = !window.location.href.includes('tpdcs')
  const {
    config: { isDark },
  } = useContext(AppContext);
  const [navbarCollapsed, setNavbarCollapsed] = useState(true);

  useEffect(() => {
    window.addEventListener('scroll', handleNavbarTransparency);
    return () => window.removeEventListener('scroll', handleNavbarTransparency);
  }, []);

  return (
    <Navbar
      variant={isDark ? 'light' : 'dark'}
      fixed='top'
      expand={topNavbarBreakpoint}
      className={classNames('navbar-standard navbar-theme', {
        'bg-100': !navbarCollapsed && isDark,
        'bg-dark': !navbarCollapsed && !isDark,
      })}>
      <Container>
        {showLogo && <Navbar.Brand className='text-white dark__text-white' as={Link} to='/'>
          <Image src={Logo} width={120} />
        </Navbar.Brand>}
        {<Navbar.Toggle onClick={() => setNavbarCollapsed(!navbarCollapsed)} />}
        <Navbar.Collapse className='scrollbar'>
          {
            <Nav>
              <NavbarTopDropDownMenus setNavbarCollapsed={setNavbarCollapsed} />
            </Nav>
          }
          <LandingRightSideNavItem />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarStandard;
