import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../../../axiosSetup";
import { AxiosError } from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export const getUserProfile = createAsyncThunk(
  "userDetails/getUserProfile",
  async () => {
    try {
      const response = await Axios.post(
        "api/user-management/v1/users/getLoginUserDetails"
      );

      return {
        status: response.status,
        data: response.data,
      };
    } catch (e) {
      const error = e as AxiosError;

      return {
        status: error.status,
        data: error.response?.data,
      };
    }
  }
);

export const getUserGridsDefinition = createAsyncThunk(
  "userDetails/getUserGridsDefinition",
  async (payload: any) => {
    try {
      const response = await Axios.get(
        `/api/user-management/v1/usergrid/masterGrids?linerId=${payload.linerId}&country=${payload.country}`
        // `/api/user-management/v1/usergrid/masterGrids?linerId=${tpoId}&&country=${partnerCountry}`
      );

      return response.data.list;
    } catch (error) {
      return [];
    }
  }
);

export const getRbacDetails = createAsyncThunk(
  "userDetails/getRbacDetails",
  async () => {
    try {
      const response = await Axios.get(
        "/api/user-management/v1/usergrid/fetchOpcRbacGroups"
      );
      return { response };
    } catch (e) {
      const error = e as AxiosError;

      return {};
    }
  }
);

export const getAppConfig = createAsyncThunk(
  "userDetails/getAppConfig",
  async (key: any) => {
    try {
      const response = await Axios.get(
        `/api/opc-rest-v1/v1/common/getAppValue?key=${key}`
      );
      return { response };
    } catch (e) {
      const error = e as AxiosError;

      return {};
    }
  }
);

export const updateCookie = createAsyncThunk(
  "userDetails/updateCookie",
  async (params: any) => {
    try {
      const response = Axios.post("api/opc-rest-v1/v1/view/params", {
        ...params,
      });
    } catch (e) {
      const error = e as AxiosError;

      return {
        status: error.status,
        data: error.response?.data,
      };
    }
  }
);

export const getUserTin = createAsyncThunk("mps/getUserTin", async () => {
  try {
    const response = await Axios.post(
      `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/v1/users/getIdentityDetails`
    );
    return { customerTin: response.data.t1 };
  } catch (e) {
    const error = e as AxiosError<any>;
    console.error(error);
    return { customerTin: "" };
  }
});
