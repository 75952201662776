import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  updateCompanyName,
  updateTax,
  updateAddress,
  updateCountryOptions,
  updateLinerOptions,
} from '../registraionSlice';
import axios, { AxiosError } from 'axios';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { axiosCacheInstance } from '../../../../axiosSetup';

const csrfMeta = document.querySelector<HTMLMetaElement>("meta[name='_csrf']");
// import axios from '../../../../axiosSetup';

/* export interface RegistrationData {
  company_name: string;
  address_details:string;
  state: string;
  country_code: string;
  request_id:string;
  source:string;
  company_number:string
} */
/*  export interface RegistrationData {
 country: string;
  state: string;
  company_name:string

  reg_number: string;
  current_status:string;
  address:string;
} */

export interface RegistrationData {
  country: string;
  state: string;
  company_name: string;

  reg_number: string;
  current_status: string;
  address: string;
  full_address: string;
}
export const addRegistrationDetails = createAsyncThunk(
  'registration/addEin',
  async (state: any) => {
    const response = await axios({
      url: `https://places.geo.us-east-1.amazonaws.com/places/v0/indexes/explore.place.Esri/search/suggestions`,
      method: 'POST',

      headers: {
        AccessKey: 'AKIARSXGUNDQFA7LKMV6',
        SecretKey: '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',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
      },
    });
    return {
      // next: null,
      // previous: null,
      list: response.data,
      // list: data,
    };
  }
);
export const addRegistrationDetailsCompany = createAsyncThunk(
  'registrationCompany/addCompanyRegistration',
  async (state: any) => {
    const response = await axios({
      url: `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/v1/users/kyb/company/search`,
      method: 'POST',

      data: state,
    });

    return {
      // next: null,
      // previous: null,
      list: response.data.data, // list: data,
    };
  }
);

export const fetchAddressSuggestions: any = createAsyncThunk(
  'registrationCompany/fetchAddressSuggestions',
  async (payload: any) => {
    try {
      if (payload?.text) {
        if (payload?.country !== null) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/places/v0/indexes/explore.place.Esri/search/suggestions?text=${payload?.text}&filterCountries=${payload?.country}`
          );
          return response.data;
        } else {
          const response = await axios.get(
            `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/places/v0/indexes/explore.place.Esri/search/suggestions?text=${payload?.text}`
          );
          return response.data;
        }
      }
      return [];
    } catch (error) {
      console.error(error);

      return [];
    }
  }
);

export const fetchCompanyDetailsForVerification = createAsyncThunk(
  'registrationCompany/companyNameVerification',
  async (state: any, { dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/v1/users/kyb/customer/details`,
        {
          // ...state
          // method: 'POST',
          companyNumber: state.company_number,
          countryCode: state.country_code,
        }
      );
      dispatch(updateTax(response.data));

      dispatch(updateCompanyName(response.data.data[0].company_name));
      dispatch(updateAddress(response.data.data[0].full_address));
      return response.data;
    } catch (error) {
      console.error(error);

      return {};
    }
  }
);

export const getCountryList = createAsyncThunk(
  'registrationCompany/getCountryList',
  async (state: any, { dispatch }) => {
    try {
      const response = await axiosCacheInstance.get(
        `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/v1/country/getCountryDetailsByOdexActive?odexActive=Y`
      );
      const options = response.data.map((item: any) => ({
        value: item.countryShortCode,
        text: item.countryName,
        full: item.countryIsoCode
      }));

      dispatch(updateCountryOptions(options));
      return options;
    } catch (error) {
      console.error(error);

      return {};
    }
  }
);

export const fetchLinerNameByCountry = createAsyncThunk(
  'registration/fetchLinerName',
  async (payload: any, { dispatch }) => {
    try {
      const response = await axios.get(
        `/api/invoice-management/v1/mstCode/getMstCodeListByTypeAndLanguageWithoutSession?language=EN&codeType=ACTIVE_LINERS&countryCode=${payload.country_code}`,
      );
      const options = response.data.map((item: any) => ({
        value: item.codeId,
        text: item.codeDesc,
      }));
      if (options?.length == 0) console.warn(`No Liner options found for country ${payload.country_code}`)
      dispatch(updateLinerOptions(options?.length == 0 ?
        [{
          value: 1,
          text: 'Hapag Lloyd'
        }] : options));
      return options;
    } catch (error) {
      console.error(error);
      return {};
    }
  }
);

export const registerCustomerAndUser = createAsyncThunk(
  'registration/add',
  async (payload: any) => {
    try {
      var data: { linerId: any; mapCode: string }[] = [];
      if (payload?.liner !== '') {
        data =
          payload?.customerCode &&
            payload?.customerCode !== null &&
            payload?.customerCode.toString().trim() !== ''
            ? [
              {
                linerId:
                  payload?.liner !== ''
                    ? parseInt(payload?.liner.toString())
                    : null,
                mapCode: payload?.customerCode,
              },
            ]
            : [];
      }

      const windowLoc = window.location.href.indexOf('.tpdcs.com');
      const windowurl = window.location.href.substring(0, windowLoc);

      const response = await axios({
        method: 'post',
        url: `${windowurl.replace(
          'https://',
          'https://'
        )}.tpdcs.com/api/user-management/v1/customer/registerCustomerAndUser`,
        headers: {
          'X-CSRF-TOKEN':
            csrfMeta && csrfMeta?.content !== null ? csrfMeta.content : '',
        },
        data: {
          countryCode: payload?.country.toString().substring(0, 2),
          customerTaxId: payload?.taxID,
          customerName: payload?.companyName,
          customerAddress: payload?.address,
          userName: payload?.userName,
          email: payload?.email,
          contactNo: payload?.phoneNumber,
          language: 'EN_US',
          customerMapping: data,
          isTNCAccepted:
            payload?.acceptTerms !== undefined &&
              payload?.acceptTerms !== null &&
              payload?.acceptTerms === true
              ? 'Y'
              : 'N',
        },
      });

      // const response = await axios.post(
      //   `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/v1/customer/registerCustomerAndUser`,
      //   {
      //     countryCode: payload?.country.toString().substring(0, 2),
      //     customerTaxId: payload?.taxID,
      //     customerName: payload?.companyName,
      //     customerAddress: payload?.address,
      //     userName: payload?.userName,
      //     email: payload?.email,
      //     contactNo: payload?.phoneNumber,
      //     language: 'EN_US',
      //     customerMapping: data,
      //   },

      //   { 'X-CSRF-TOKEN': csrfMeta?.content !== null ? csrfMeta.content : '' }
      // );

      return response;
    } catch (error) {
      console.error(error);
      return {};
    }
  }
);
export const getRegistrationDetails = createAsyncThunk(
  'registraion/getRegistrationDetails',
  async (decrptrequestId: any) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_GATEWAY_HOST
        }/api/user-management/v1/customer/getRegistrationDetails?requestId=${Object.values(decrptrequestId)[0]
        }`
      );
      return response;
      // return { list: response.data };
    } catch (error) {
      return {};
    }
  }
);

export const approveRejectRegistration = createAsyncThunk(
  'instruction/approveRejectReturn',
  async (payload: any) => {
    try {
      // const response = await axios({
      //   url: `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/v1/customer/approveRejectRegistrationDetails?requestType=${payload.requestType}`,
      //   method: 'POST',
      //   data: {
      //     payload
      //   },
      // });

      const response = await axios.post(
        `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/v1/customer/approveRejectRegistrationDetails?requestType=${payload.requestType}`,
        {
          ...payload,
        }
      );

      if (response.data.responseMsg) toast.success(response.data.responseMsg, {
        toastId: '2330008'
      });
      /*      setTimeout(() => {
        window.location.href = '/registrationmaster';
      }, 1000); */
      // return response.data;
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);
      toast.dark('Error', {
        toastId: '990f99a'
      });

      // return {};
    }
  }
);
