import { createAsyncThunk } from "@reduxjs/toolkit";

import axios, { Axios, AxiosError } from "axios";
import { sleep } from "../../../../utils/sleep";
import { InstructionList } from "../../../dashboard/types";
import axiosOdex, { axiosCacheInstance } from "../../../../../axiosSetup";
import { toast } from "react-toastify";
import { tpoId } from "../instructionSlice/AgentNominationSlice";
import axiosInstance from "../../../../../axiosSetup";
import { AESDecrypt } from "../../../../../encrypt-util";
import retry from "../../../../../helpers/axiosRetry";

// import { Axios } from 'axios';

export const addInstruction = createAsyncThunk(
  "instruction/addInstruction",
  async (state: InstructionList) => {
    //  const response = await axios.post('/api/user-management/v1/bl',state);
    const response = await axios.post(
      "https://jsonplaceholder.typicode.com/users",
      state
    );

    // const   response=state
    //await sleep(2000);

    /*   return {
    next: null,
    previous: null,
    list: response,
  }; */
    return response;
  }
);
export const approveInstruction = createAsyncThunk(
  "instruction/approveInstruction",
  async (state: InstructionList) => {
    //  const response = await axios.post('/api/user-management/v1/bl',state);
    const response = await axios.post(
      "https://jsonplaceholder.typicode.com/users",
      state
    );

    // const   response=state
    //await sleep(2000);

    /*   return {
   next: null,
   previous: null,
   list: response,
 }; */
    return response;
  }
);
export const rejectInstruction = createAsyncThunk(
  "instruction/rejectInstruction",
  async (state: InstructionList) => {
    //  const response = await axios.post('/api/user-management/v1/bl',state);
    const response = await axios.post(
      "https://jsonplaceholder.typicode.com/users",
      state
    );

    // const   response=state
    //await sleep(2000);

    /*   return {
   next: null,
   previous: null,
   list: response,
 }; */
    return response;
  }
);

export const addAgentNnominate = createAsyncThunk(
  "instruction/addAgentDelegation",
  async (payload: any) => {
    try {
      const response = await axiosOdex.post(
        `/api/invoice-management/v1/agentNomination/${payload.agentPartnerId && payload.agentNominationId !== "0"
          ? "updateAgentNominationDetails"
          : "saveAgentNominationDetails"
        }`,
        {
          requestedPartnerId: "57",
          agentPartnerId: payload?.agentPartnerId,
          taxId: payload?.taxId,
          companyName: payload?.companyName,
          emailId: payload?.email,
          allowFreightInvoice: payload?.allowFreightInvoice,
          blNo: payload?.blNo,
          fileName: payload?.fileName,
          byteArr: payload?.byteArr,
          address: payload?.address,
          blId: payload?.blId,
          agentNominationId:
            payload.agentPartnerId && payload.agentNominationId !== "0"
              ? payload?.agentNominationId
              : null,
          status: payload.agentPartnerId ? payload.status : "",
          timePendingInvoice: payload?.timePendingInvoice || null,
          blAccess: payload?.blAccess || null,
          agentType: payload?.agentType || null,
          customerRemarks: payload?.customerRemarks || null,
          accessList: payload?.accessList || [],
        }
      );

      return response;
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);

      return {};
    }
  }
);

export const addMultiAgentNnominate = createAsyncThunk(
  "instruction/addMultiAgentNnominate",
  async (payload: any) => {
    try {
      const response = await axiosOdex.post(
        `/api/invoice-management/v1/agentNomination/${payload.agentNominationId && payload.agentNominationId !== "0"
          ? "updateMultiAgentNominationDetails"
          : "saveMultiAgentNominationDetails"
        }`,
        payload?.blId && payload.blId !== null
          ? payload.blId
            .split(",")
            .filter(
              (value: any, index: any, self: any) =>
                self.indexOf(value) === index
            )
            .map((id: any) => {
              return {
                requestedPartnerId: payload?.requestedPartnerId,
                agentPartnerId: payload?.agentPartnerId,
                taxId: payload?.taxId,
                companyName: payload?.companyName,
                emailId: payload?.email,
                allowFreightInvoice: payload?.allowFreightInvoice,
                fileName: payload?.fileName,
                byteArr: payload?.byteArr,
                serverFilePath: payload?.serverPath,
                tempFileName: payload?.tempFileName,
                address: payload?.address,
                blId: id,
                agentNominationId:
                  payload.agentPartnerId && payload.agentNominationId !== "0"
                    ? payload?.agentNominationId
                    : null,
                status: payload.agentPartnerId ? payload.status : "",
                timePendingInvoice: payload?.timePendingInvoice || null,
                blAccess: payload?.blAccess || null,
                agentType: payload?.agentType || null,
                customerRemarks: payload?.customerRemarks || null,
                accessList: payload?.accessList || [],
                requestType: payload?.requestType || null,
                partyName: payload?.partyName || null,
                partyEmail: payload?.partyEmail || null,
                isMultiReq: payload?.isMultiReq,
              };
            })
          : null
      );

      return response;
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);

      return {};
    }
  }
);
export const getInvoiceDetailsByPmtId = createAsyncThunk(
  "instruction/getInvoiceDetailsByPmtId",
  async (state: any) => {
    const response = await axiosOdex.get(
      `/api/opc-management/v1/payment/getInvoiceDetailsByPmtId?pmtId=${state}`
    );
    return response;
  }
);

export const getMstCodeList = createAsyncThunk(
  "instruction/getMstCodeList",
  async (state: any) => {
    const response = await retry(
      async () =>
        await axiosCacheInstance.get(
          `/api/invoice-management/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=INV_TYPE&linerId=1&countryCode=US`
        ),
      2
    );
    // debugger
    return response;
  }
);

export const getAgentNnominate = createAsyncThunk(
  "instruction/getAgentNnominate",
  async (payload: any) => {
    const response = await axiosOdex.get(
      `/api/invoice-management/v1/agentNomination/getAgentNominationDetails/${payload?.decrptdoc}?linerId=${payload?.selectedLinerId}`
    );

    return { list: response.data };
  }
);

export const getCreditBalance = createAsyncThunk(
  "creditBalanceBlPayment/getCreditBalance",
  async (payload: any) => {
    const response = await axiosOdex.get(
      `api/invoice-management/v1/payment/listCreditBalance?linerId=${payload?.selectedLinerId}`
    );

    return response.data.list;
  }
);

export const generateInvReferenceNo = createAsyncThunk(
  "creditBalanceBlPayment/generateInvReferenceNo",
  async (dataCountry: any) => {
    const response = await retry(async () => await axiosOdex.post(
      `api/opc-rest-v1/v1/invoice/generateInvReferenceNoByCountry?countryCode=${dataCountry}&initCode=INV-`
    ), 3);
    return response.data;
  }
);
export const generateSSRReferenceNo = createAsyncThunk(
  "creditBalanceBlPayment/generateSSRReferenceNo",
  async (dataCountry: any) => {
    const response = await retry(async () => await axiosOdex.post(
      `api/opc-rest-v1/v1/specialService/generateSsrReferenceNoByCountry?countryCode=${dataCountry}`
    ), 3);
    return response.data;
  }
);
export const generatePermitReferenceNo = createAsyncThunk(
  "creditBalanceBlPayment/generatePermitReferenceNo",
  async (dataCountry: any) => {

    const response = await retry(async () => await axiosOdex.post(
      `api/opc-rest-v1/v1/permit/generatePermitReferenceNoByCountry?countryCode=${dataCountry}`
    ), 3)

    if (response) return response.data;
  }
);
export const generateDeliveryReferenceNo = createAsyncThunk(
  "creditBalanceBlPayment/generateDoReferenceNo",
  async (dataCountry: any) => {
    const response = await retry(async () => await axiosOdex.post(
      `api/opc-rest-v1/v1/do/generateDoReferenceNoByCountry?countryCode=${dataCountry}`
    ), 3);
    return response.data;
  }
);
export const generateMPSReferenceNo = createAsyncThunk(
  "creditBalanceBlPayment/generateMPSReferenceNo",
  async (dataCountry: any) => {
    const response = await retry(async () => await axiosOdex.post(
      `api/opc-rest-v1/v1/invoice/generateInvReferenceNoByCountry?countryCode=${dataCountry}&initCode=MPS-`
    ), 3);
    return response.data;
  }
);
export const addCarrierHaulage = createAsyncThunk(
  "instruction/addCarrierHaulage",
  async (payload: any) => {
    try {
      const response = await axiosOdex.post(
        "/api/invoice-management/v1/carrierHaulage",

        {
          blId: payload?.blId,
          deliveryOption: payload?.deliveryOption,
          warehouseName: payload?.warehouseName,
          // customerCode: payload?.customerCode,
          contactPerson: payload?.contactName,
          emailId: payload?.email,
          contactNo: payload?.contactNo,
          address: payload?.address,
          remarks: payload?.remark,
          refPoNo: payload?.refPoNo,
          areaCode: payload?.areaCode,
          linerId: payload?.linerId,
          status: "A",
          placeId: payload?.placeId,
          indexName: "explore.place.Esri",
          filterCountries: payload?.country,
          language: "en",
          deliveryDate: payload?.deliveryDate,
          rejectionRemarks: "",
        }
      );

      return { success: true, data: response.data };
    } catch (e) {
      const error = e as AxiosError<any>;
      console.error(error);
      return { success: false, data: error.response?.data?.message || "" };
    }
  }
);

export const getMstCodeListVal = createAsyncThunk(
  "creditBalanceBlPayment/getMstCodeListVal",
  async () => {
    const response = await retry(
      async () =>
        await axiosCacheInstance.get(
          `api/opc-rest-v1/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=INV_REQ_DOC&linerId=1&countryCode=TZ`
        ),
      2
    );
    return response.data;
  }
);

export const validateRequestedDateForExport = createAsyncThunk(
  "instruction/addPermitRequest24134",
  async (payload: any) => {
    try {
      const response = await axiosInstance.post(
        "api/opc-rest-v1/v1/master/validateQuata",
        payload
      );
      return response.data;
    } catch (e) {
      return e;
    }
  }
);

export const addPermitRequest = createAsyncThunk(
  "instruction/addPermitRequest24134",
  async (payload: any) => {
    try {
      const response = await axiosInstance.post(
        "api/opc-rest-v1/v1/permit/submitPermitRequest",
        payload
      );
      return { success: true, data: response.data };
    } catch (e) {
      const error = e as AxiosError<any>;
      console.error(error);
      return { success: false, data: error.response?.data?.message || "" };
    }
  }
);
export const cancelPermitRequest = createAsyncThunk(
  "permit/cancelPermitRequest",
  async (payload: any) => {
    try {
      const response = await axiosInstance.post(
        `api/opc-management/v1/permit/cancelPermitRequest?id=${payload.request_id}&nId=${payload.tpoId}`
      );
      if (response) {
        toast.success(`${Object.values(response?.data)[0]}`, {
          theme: "colored",
          toastId: '11aad12'
        });
        /*   setTimeout(() => {
        }, 2000); */
      }
    } catch (e) {
      const error = e as AxiosError;
      console.error(error.message);
      // return false;
    }
  }
);
export const getPermitRequest = createAsyncThunk(
  "invoice/getPermitRequest",
  async (state: any) => {
    const response = await axiosOdex.get(
      `/api/opc-rest-v1/v1/permit/getPermitRequest?id=3`
    );
    return response;
  }
);
/* export const cancelPermitRequest = createAsyncThunk(
  'permit/cancelPermitRequest',
  async (state: any) => {
    try {
      const response = await axiosOdex.post(
        `api/opc-management/v1/permit/cancelPermitRequest?id=6&nId=4`
      );
      if (response) {
        toast.success(`${Object.values(response?.data)[0]}`, {
          theme: 'colored',
        });
        /*   setTimeout(() => {
        }, 2000); */
//  }
//  } catch (e) {
//   const error = e as AxiosError;
//   console.error(error.message);
// return false;
//}
// }
//);
export const submitInvoiceRequest = createAsyncThunk(
  "instruction/submitInvoiceRequest",
  async (payload: any) => {
    try {
      const response = await axiosOdex.post(
        "/api/opc-rest-v1/v1/invoice/submitInvoiceRequest",
        payload
      );

      return { success: true, data: response?.data };
    } catch (e) {
      const error = e as AxiosError<any>;
      console.error(error);
      /*   toast.error(error.response?.data?.message, {
        theme: 'colored',
      }); */
      return { success: false, data: error.response?.data?.message || "" };
    }
  }
);

export const invoiceApproveReject = createAsyncThunk(
  "mps/MpsApproveReject",
  async ({
    tpoId,
    requestId,
    status,
    requestedOn,
    linerRemarks,
    shippingLine,
    customerTin,
    croNo,
    cifValue,
  }: any) => {
    try {
      const payload = {
        requestId: requestId,
        status: status, //311,
        tpoId: tpoId,
        instType: 911,
        requestedOn: requestedOn,
        approvalRemarks: linerRemarks,
        shippingLine,
        customerTin,
        croNo,
        cifValue,
        //"approvalRemarks": ptoRemarks,
      };
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/invoice/approveRejectInvoiceRequest`,
        payload
      );
      if (
        response?.data?.responseCode == "APPROVED" ||
        response?.data?.responseCode == "REJECTED" ||
        response?.data?.responseCode == "SUCCESS"
      ) {
        toast.success(response?.data?.responseMsg, { toastId: '6d56a17' })
      } else {
        toast.error(response?.data?.responseMsg ?? 'Error while updating Invoice Request', { toastId: '23e1250' });
      }
    } catch (e) {
      toast.error('Error while updating Invoice Request', { toastId: 'd9771c1' });
    }
  }
);

export const submitSSRRequest = createAsyncThunk(
  "instruction/submitSSRRequest",
  async (payload: any) => {
    try {
      const response = await axiosOdex.post(
        "/api/opc-management/v1/specialService/saveSpecialServiceRequest",
        payload
      );

      return { success: true, data: response?.data };
    } catch (e) {
      const error = e as AxiosError<any>;
      console.error(error);
      /*   toast.error(error.response?.data?.message, {
        theme: 'colored',
      }); */
      return { success: false, data: error.response?.data?.message || "" };
    }
  }
);

export const editSSRRequest = createAsyncThunk(
  "instruction/saveSpecialServiceRequest",
  async (payload: any) => {
    try {
      const response = await axiosOdex.post(
        "/api/opc-management/v1/specialService/saveSpecialServiceRequest",
        payload
      );

      return { success: true, data: response?.data };
    } catch (e) {
      const error = e as AxiosError<any>;
      console.error(error);
      /*   toast.error(error.response?.data?.message, {
        theme: 'colored',
      }); */
      return { success: false, data: error.response?.data?.message || "" };
    }
  }
);

export const submitDeliveryRequest = createAsyncThunk(
  "instruction/submitDeliveryRequest",
  async (payload: any) => {
    try {
      const response = await axiosOdex.post(
        "/api/opc-rest-v1/v1/do/submitDORequest",
        payload
      );
      if (response.data.responseCode === "SUCCESS")
        //  toast.dark(response.data.responseMsg);
        toast.success(response.data.responseMsg, {
          theme: "colored",
          toastId: 'a2dea3f'
        });
      //  toast.dark('DO data submitted successfully.');
      return { success: true, data: response.data };
    } catch (e) {
      const error = e as AxiosError<any>;
      console.error(error);
      return { success: false, data: error.response?.data?.message || "" };
    }
  }
);
export const cancelInvoiceRequest = createAsyncThunk(
  "instruction/submitInvoiceRequest",
  async (payload: any) => {
    try {
      const response = await axiosOdex.post(
        `/api/opc-management/v1/invoice/cancelInvoiceRequest?id=${payload.request_id}&nId=${payload.tpoId}`,
        {
          nId: "123",
        }
      );
      return { success: true, data: response.data };
    } catch (e) {
      const error = e as AxiosError<any>;
      console.error(error);
      return { success: false, data: error.response?.data?.message || "" };
    }
  }
);
export const CancelSpecialServiceRequest = createAsyncThunk(
  "instruction/CancelSpecialServiceRequest",
  async (payload: any) => {
    try {
      const response = await axiosOdex.post(
        `/api/opc-management/v1/specialService/approveRejectOrCancelSpecialServiceRequest`,
        {
          specialServiceReqId: payload.specialServiceReqId,
          status: payload.status,
          approvalRemarks: payload.approvalRemarks,
          requestedOn: payload.requestedOn,
          extInvoiceCutOff: payload.extInvoiceCutOff,
          extGateIn: payload.extGateIn,
          customerDetails: payload.customerDetails,
          serverFilePath: payload.serverFilePath,
          tempFileName: payload.tempFileName,
        }
      );
      return { success: true, data: response.data };
    } catch (e) {
      const error = e as AxiosError<any>;
      console.error(error);
      return { success: false, data: error.response?.data?.message || "" };
    }
  }
);
export const ApproveRejectSpecialServiceRequest = createAsyncThunk(
  "instruction/ApproveRejectSpecialServiceRequest",
  async (payload: any) => {
    try {
      const response = await axiosOdex.post(
        `/api/opc-management/v1/specialService/approveRejectOrCancelSpecialServiceRequest`,
        {
          specialServiceReqId: payload.specialServiceReqId,
          status: payload.status,
          approvalRemarks: payload.approvalRemarks,
          requestedOn: payload.requestedOn,
          extInvoiceCutOff: payload.extInvoiceCutOff,
          extGateIn: payload.extGateIn,
          customerDetails: payload.customerDetails,
          serverFilePath: payload.serverFilePath,
          tempFileName: payload.tempFileName,
        }
      );
      return { success: true, data: response.data };
    } catch (e) {
      const error = e as AxiosError<any>;
      console.error(error);
      return { success: false, data: error.response?.data?.message || "" };
    }
  }
);
export const blValidation = createAsyncThunk(
  "instruction/blValidation",
  async (payload: any) => {
    try {
      const response = await axiosOdex.get(
        `/api/opc-rest-v1/v1/common/getCntnrByBlNo?blNo=${payload.blnumber}&&shipmentType=${payload.shipmentType}`
      );
      return { success: true, data: response.data };
    } catch (e) {
      const error = e as AxiosError<any>;
      console.error(error);
      return { success: false, data: error.response?.data?.message || "" };
    }
  }
);

export const getInvoiceRequest = createAsyncThunk(
  "instruction/getInvoiceRequest",
  async (payload: any) => {
    try {
      const response = await axiosOdex.get(
        `/api/opc-management/v1/invoice/getInvoiceRequest?id=${payload}`
      );
      return { success: true, data: response.data };
    } catch (e) {
      const error = e as AxiosError<any>;
      console.error(error);
      return { success: false, data: error.response?.data?.message || "" };
    }
  }
);

export const getInvoiceRequestByReferenceNo = createAsyncThunk(
  "instruction/getInvoiceRequestByReferenceNo",
  async (payload: any) => {
    try {
      const response = await axiosOdex.get(
        `/api/opc-management/v1/invoice/getInvoiceRequestByReferenceNo/${payload}`
      );
      return { success: true, data: response.data };
    } catch (e) {
      const error = e as AxiosError<any>;
      console.error(error);
      return { success: false, data: error.response?.data?.message || "" };
    }
  }
);

export const getDoRequest = createAsyncThunk(
  "instruction/getDoRequest",
  async (payload: any) => {
    try {
      const response = await axiosOdex.get(
        `/api/opc-rest-v1/v1/do/getDORequest?id=${AESDecrypt(payload)}`
      );
      return { success: true, data: response.data };
    } catch (e) {
      const error = e as AxiosError<any>;
      console.error(error);
      return { success: false, data: error.response?.data?.message || "" };
    }
  }
);
export const getCarrierHaulage = createAsyncThunk(
  "instruction/getCarrierHaulage",

  async (payload: any) => {
    const response = await axiosOdex.get(
      `/api/invoice-management/v1/carrierHaulage/${payload.decrptdoc}?linerId=${payload?.selectedLinerId}`
    );
    return response.data;
  }
);
export const getSpecialServiceRequest = createAsyncThunk(
  "instruction/getInvoiceRequest",
  async (payload: any) => {
    try {
      const response = await axiosOdex.get(
        `/api/opc-management/v1/specialService/getSpecialServiceRequest?id=${payload}`
      );
      return { success: true, data: response.data };
    } catch (e) {
      const error = e as AxiosError<any>;
      console.error(error);
      return { success: false, data: error.response?.data?.message || "" };
    }
  }
);
export const approveCarrierHaulage = createAsyncThunk(
  "instruction/approveCarrierHaulage",
  async (payload: any) => {
    try {
      const response = await axiosOdex.post(
        `/api/invoice-management/v1/carrierHaulage/approveRejectCh/${payload.carrierHaulageId}`,

        {
          blId: payload?.blId,
          deliveryOption: payload?.deliveryOption,
          warehouseName: payload?.warehouseName,
          // customerCode: payload?.customerCode,
          contactPerson: payload?.contactName,
          emailId: payload?.email,
          contactNo: payload?.contactNo,
          address: payload?.adress,
          remarks: payload?.remark,
          refPoNo: payload?.refPoNo,
          areaCode: payload?.areaCode,
          linerId: payload?.linerId,
          status: "A",
        }
      );

      return response;
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);
      return {};
    }
  }
);
export const rejectCarrierHaulage = createAsyncThunk(
  "instruction/rejectarrierHaulage",
  async (payload: any) => {
    try {
      const response = await axiosOdex.post(
        `/api/invoice-management/v1/carrierHaulage/approveRejectCh/${payload.carrierHaulageId}`,

        {
          blId: payload?.blId,
          deliveryOption: payload?.deliveryOption,
          warehouseName: payload?.warehouseName,
          // customerCode: payload?.customerCode,
          contactPerson: payload?.contactName,
          emailId: payload?.email,
          contactNo: payload?.contactNo,
          address: payload?.adress,
          remarks: payload?.remark,
          rejectionRemarks: payload?.rejectionremark,
          refPoNo: payload?.refPoNo,
          areaCode: payload?.areaCode,
          status: "Rejected",
        }
      );

      return response;
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);
      return {};
    }
  }
);

export const addInvoiceChange = createAsyncThunk(
  "instruction/addInstruction",
  async (state: any) => {
    const response = await axiosOdex
      .post("/api/invoice-management/v1/bp/createBp", {
        blId: state?.blId || null,
        taxId: state?.taxId || null,
        masterPartnerId: state?.requestedPartnerId,
        standingInstructionId: 1,
        emailId: state?.email || null,
        contactNo: state?.contactNo || null,
        address: state?.address || null,
        allowFrieghtInvoice: state?.allowFrieghtInvoice === true ? "Y" : "N",
        allowLocalInvoice: state?.allowLocalInvoice === true ? "Y" : "N",
        status: "Submitted",
        requestedpartnernm: state?.requestedpartnernm,
        requestedPartnerId: state?.requestedPartnerId,
        allowTimePendingInvoice:
          state?.allowTimePendingInvoice === true ? "Y" : "N",
        poReference: state?.poReference,
        releaseType: state?.releaseType,
        returnType: state?.returnType,
        customerCode: state?.customerCode,
        userRemarks: state?.userRemarks,
        //loiUpload: state?.byteArr,
        // fileName: state?.fileName,
      })
      .then((data) => {
        toast.dark("Successfully created", { toastId: '51a0ea2' });
        return data;
      })
      .catch((error) => {
        toast.error(error.response.data.message, { toastId: '4e067a6' });
        return error.response.data;
      });

    return response.data;
  }
);

/* export const completeInvoiceChange = createAsyncThunk(
  'instruction/completeInvoiceChange',
  async (state: any) => {
    const response = await axiosOdex.patch(
      `/api/invoice-management/v1/bp/approveRejectBp/${
        state?.invoiceChangeId || ''
      }`,
      {
        blId: state?.blId || '',
        status: 'A',
        invoiceChangeId: state?.invoiceChangeId || '',
        standingInstructionId: 1,
      }
    );

    return response.data;
  }
); */

export const approveRejectInvoiceChange = createAsyncThunk(
  "instruction/completeInvoiceChange",
  async (state: any) => {
    const response = await axiosOdex.patch(
      `/api/invoice-management/v1/bp/approveRejectBp/${state?.invoiceChangeId || ""
      }`,
      {
        blId: state?.blId || "",
        status: state?.status || "",
        invoiceChangeId: state?.invoiceChangeId || "",
        remarks: state?.remarks || "",
        standingInstructionId: 1,
      }
    );

    return response.data;
  }
);

/* export const rejectInvoiceChange = createAsyncThunk(
  'instruction/rejectInvoiceChange',
  async (state: any) => {
    const response = await axiosOdex.patch(
      `/api/invoice-management/v1/bp/approveRejectBp/${
        state?.invoiceChangeId || ''
      }`,
      {
        blId: state?.blId || '',
        status: 'R',
        invoiceChangeId: state?.invoiceChangeId || '',
        standingInstructionId: 1,
      }
    );

    return response.data;
  }
);
 */

export const getCodeList = createAsyncThunk(
  "common/getcodeList",
  async (payload: any, { dispatch }) => {
    try {
      const response = await retry(
        async () =>
          await axiosCacheInstance.get(
            `/api/opc-rest-v1/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=${payload?.codeType
            }&linerId=${payload?.linerId.toString()}&countryCode=TZ`
          ),
        2
      );

      /*    const response = await axiosOdex.get(
        `/api/invoice-management/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=${
          payload?.codeType
        }&linerId=${payload?.linerId.toString()}&countryCode=US`
      );
 */
      const releaseTypeOptions = response.data.map((item: any) => ({
        value: /* item.codeId */ 1,
        text: item.codeDesc,
      }));

      const codeIdVal = response.data.map((item: any) => ({
        codeDesc: item.codeDesc,
        codeId: item.codeId,
      }));

      dispatch(tpoId(codeIdVal[0].codeId));

      return releaseTypeOptions;
    } catch (e) {
      const error = e as AxiosError;
      return [];
    }
  }
);

let cancelToken: any = null;
export const fetchVesselSuggestions: any = createAsyncThunk(
  "PermitRequest/fetchVesselSuggestions",
  async (payload: any) => {
    try {
      if (cancelToken?.cancel) {
        cancelToken?.cancel();
      }
      cancelToken = axios.CancelToken.source();

      const response = await axiosInstance.post(
        /*  `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-management/v1/master/getVesselMaster`, */
        `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/master/getVesselMaster`,
        {
          visitKey: payload.vesselKey,
          vesselName: payload.vesselKey,
          requestType: payload.requestType,
          permitId: payload.permitId,
        },
        { cancelToken: cancelToken.token }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      if (axios?.isCancel(error)) {
        return null;
      }
      return [];
    }
  }
);

export const fetchTruckingCompanySuggestions: any = createAsyncThunk(
  "PermitRequest/fetchTruckingCompanySuggestions",
  async (payload: any) => {
    try {
      // if (payload?.text) {
      //   if (payload?.country !== null) {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/master/getTruckCompanies?companyName`,
        { companyName: payload }
      );
      return response.data;
      //  }
      // }
      return [];
    } catch (error) {
      console.error(error);

      return [];
    }
  }
);
export const fetchTruckingTrailerSuggestions: any = createAsyncThunk(
  "PermitRequest/fetchTruckingTrailerSuggestions",
  async (payload: any) => {
    try {
      // if (payload?.text) {
      //   if (payload?.country !== null) {
      const response = await axios.get(
        `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-management/v1/common/getTrucksList`
      );
      return response.data;
      //  }
      // }
      return [];
    } catch (error) {
      console.error(error);

      return [];
    }
  }
);

export const blNumbList: any = createAsyncThunk(
  "PermitRequest/blNumbList",
  async (payload: any) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/common/getBlsByVisitKey?visitKey=${payload.visit}&shipmentType=${payload.permit}`
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
);

export const addBlanketDeligation = createAsyncThunk(
  "instruction/BLANKET_DELIGATION",
  async (payload: any) => {
    try {
      const response = await axiosOdex.post(
        `/api/invoice-management/v1/instruction/addStandingInstruction`,

        {
          linerId: payload?.linerId,
          blId: null,
          startPol: payload?.startCountry || null,
          pol: payload?.polCountry || null,
          pod: payload?.podCountry || null,
          finalPod: payload?.endCountry || null,
          cargoType: payload?.cargoType,
          validFrom: payload?.validForm || "",
          validTo: payload?.validTill,
          status: payload?.status,

          instructionName: payload?.instructionName,
          standingInstructionId: payload?.standingInstructionId,
          agentNominationInput: {
            agentNominationId: null,
            agentPartnerId: payload?.agentPartnerId,
            blId: null,
            requestedPartnerId: payload?.requestedPartnerId,
            agentTaxId: payload?.taxId,
            companyName: payload?.companyName,
            emailId: payload?.email,
            address: payload?.address,
            blAccess: payload?.blAccess,
            accessList: payload?.accessList || [],
            requestType: payload?.requestType || "",
            partyName: payload?.partyName,
            partyEmail: payload?.partyEmail,
          },
        }
      );

      return response;
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);
      return {};
    }
  }
);

export const getBlanketDeligation = createAsyncThunk(
  "instruction/getBlanketDeligation",
  async (payload: any) => {
    const response = await axiosOdex.get(
      `/api/invoice-management/v1/instruction/getStandingInstruction/${payload?.docId}?linerId=${payload?.selectedLinerId}`
    );
    return response?.data;
  }
);

export const assignInstructionsToSelf = createAsyncThunk(
  "common/assignInstructionsToSelf",
  async (payload: any[]) => {
    const response = await axiosOdex.post(
      "/api/invoice-management/v1/common/assignInstructionsToSelf",
      payload
    );
    return response?.data;
  }
);
