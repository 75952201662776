var CryptoJS = require("crypto-js");

const privateKey = ""; // Could use a date or something dynamic

export function AESEncrypt(pureText: string) {
  var ciphertext = encodeURIComponent(
    CryptoJS.AES.encrypt(JSON.stringify(pureText), `${process.env.REACT_APP_ENCRYPT_KEY}`).toString()
  );
  return ciphertext;
}
export function encryptWithSecret(plainText: string, secret: string): string | null {
  if (!plainText) {
    return null;
  }
  try {
    const key = CryptoJS.enc.Utf8.parse(secret.padEnd(16, ' ').substring(0, 16));
    const encrypted = CryptoJS.AES.encrypt(plainText, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  } catch (error) {
    // console.error(error.message);
    return null;
  }
}

export function Base64Encode(pureText: string){
  var cipherText = btoa(pureText);
  return cipherText;
}

export function AESDecrypt(encryptedText?: string) {
  try {
    if (encryptedText) {
      var bytes = CryptoJS.AES.decrypt(
        decodeURIComponent(encryptedText),
        `${process.env.REACT_APP_ENCRYPT_KEY}`
      );
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedData;
    }
    return "";
  } catch (e) {
    console.error("Decrypt Error: ", e);
    return encryptedText;
  }
}

const secretKey = "";

export function AESEncryptforDashboard(plainText: string) {
  const key = CryptoJS.enc.Utf8.parse(`${process.env.REACT_APP_DASHBOARD_KEY}`);
  const encrypted = CryptoJS.AES.encrypt(plainText, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });

  const encryptedBase64 = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
  return encryptedBase64;
}

export function AESDecryptforDashboard(encryptedText: string) {
  const key = CryptoJS.enc.Utf8.parse(`${process.env.REACT_APP_DASHBOARD_KEY}`);
  const decryptedBase64 = CryptoJS.enc.Base64.parse(encryptedText);
  const decrypted = CryptoJS.AES.decrypt(decryptedBase64, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted;
}

