import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../common/components/DataTable";
import { RootState } from "../../store";
import { Form } from "react-bootstrap";
import { updateContainerTableRow } from "./mpsSlice";

export default function MpsContainerTable({ showdata = false }) {
  const data = useSelector((state: RootState) => state.mps.containerData); 
  const dispatch = useDispatch();
  const columns = [
    {
      accessor: "rowId",
      Header: ""
    },
    {
      accessor: "selected",
      Header: '',
      width: 50,
      Cell: (rowData: any) => {
        const { selected, rowNumber } = rowData.row.original;
        return <Form.Check
          type='checkbox'
          checked={selected}
          disabled={showdata}
          onChange={(e) => {
            dispatch(updateContainerTableRow({ rowNumber: rowNumber, selected: e.target.checked}))
          }}
          className='form-check fs-0 mb-0 d-flex align-items-center' />
      }
    },
    {
      accessor: "containerNumber",
      Header: "Container Number",
      width: 250,
      maxWidth: 250,
    },
  ];

  return <div>
    <DataTable
      columns={columns}
      data={data}
      gridName="MPS_CONTAINER"
      pagination={false}
      selection={false}
      sortable={false}
      perPage={1000}
      seekPagination={false}
      searchable={false}
      showExport={false}
      showColumnFilter={false}
      uniqueFilterKey='mpsContainer'
      staticHiddenCols={['rowId']}
      autoHeight={true}
      tableMinHeight={100}
      defaultHiddenCols={['rowId']}
    />
  </div>
}