import { useSelector } from 'react-redux';
import { matchPath, useLocation, useNavigate, useParams } from 'react-router';
import { RootState } from '../app/store';

/**
 * Returns onBack function that on call will navigate to the previous
 * Card
 * @returns funnction
 */
export function useBackNavigation(inner = true) {
  const navigate = useNavigate();
  const location = useLocation();
  const { blNum } = useParams();

  let datatableState: string = useSelector(
    (state: RootState) => state.navbarTop.selectedDashbaordName
  );

  const paths = {
    BL_DASHBOARD: {
      home: '/opc/invoice',
      document: `/bl/${encodeURIComponent(blNum ?? '')}`,
    },
    INVOICE_DASHBOARD: {
      home: '/payment',
      document: `/payment/${encodeURIComponent(blNum ?? '')}`,
      standalone: '/payment/addInvoice',
    },
    OPC_INST_DASHBOARD: {
      home: '/opc/instruction',
      document: `/instruction/${encodeURIComponent(blNum ?? '')}`,
    },
    DO_DASHBOARD: {
      home: '/opc/doupload',
    },
    OPC_INV_DASHBOARD: {
      home: '/opc/home',
    },
    OPC_INV_DASHBOARD_TAX: {
      home: '/opc/home',
    },
    OPC_USER_DASHBOARD: { home: '/opc/user' },
    STANDING_INSTRUCTION: { home: '/standing' },
    OPC_PAYMENT_DASHBOARD: { home: '/opc/paymentDashboard' },
    OPC_PERMIT_DASHBOARD:{home:'/opc/permit-tracking'}

  };

  const onBack = () => {
    const search = location.search;
    if (!paths.hasOwnProperty(datatableState)) {
      datatableState = 'BL_DASHBOARD';
    }

    if (inner) {
      const outer: string = (() => {
        if (location.pathname.includes('addInvoice')) return 'standalone';
        if (
          location.pathname.includes('/bl/') &&
          !matchPath('/bl/:blNum/', location.pathname)
        )
          return 'document';
        return 'home';
      })();

      navigate((paths as any)[datatableState][outer] + search);
      return;
    }

    navigate((paths as any)[datatableState]['home'] + search);
  };
  return onBack;
}
