/***
 * returns format string with date only
 * for datepicker->dateformat value
 * based on the locale of the user
 */
export function getDateFormat(){
    const locale = new Intl.DateTimeFormat().resolvedOptions().locale
    const format = new Intl.DateTimeFormat(locale,
        {year:'numeric',month: '2-digit',day:'2-digit'})
        .formatToParts().map(part =>{
            switch(part.type){
                case 'day': return 'dd';
                case 'month':return 'MM';
                case 'year': return 'yyyy';
                default: return part.value
            }
        }).join('');
    return format
}
/***
 * returns format string with date and time
 * for datepicker dateformat value
 * based on the locale of the user
 */
export function getDateTimeFormat(){
    const locale = new Intl.DateTimeFormat().resolvedOptions().locale
    const format = new Intl.DateTimeFormat(locale,
        {year:'numeric',month: '2-digit',day:'2-digit',hour:'2-digit',minute:'2-digit'})
        .formatToParts().map(part =>{
            switch(part.type){
                case 'day': return 'dd';
                case 'month':return 'MM';
                case 'year': return 'yyyy';
                case 'minute': return 'mm';
                case 'hour':return 'hh';
                case 'dayPeriod':return 'a';
                default: return part.value
            }
        }).join('');
    return format
}

export function formatISODateToLocale(isoDateString, options = {}) {
    const date = new Date(isoDateString?.substring?.(0,isoDateString.length - 6));
    return date.toLocaleString()
    // const locale = new Intl.DateTimeFormat().resolvedOptions().locale
    // // Check if the date is valid
    // if (isNaN(date.getTime())) {
    //     throw new Error('Invalid ISO date string');
    // }

    // // Define default options for UTC
    // const defaultOptions = {
    //     timeZone: 'UTC',
    //     year: 'numeric',
    //     month: '2-digit',
    //     day: '2-digit',
    //     hour: '2-digit',
    //     minute: '2-digit',
    //     second: '2-digit',
    //     hour12: false // Use 24-hour time format
    // };

    // // Merge default options with user-provided options
    // const mergedOptions = { ...defaultOptions, ...options };

    // // Create a formatter with the specified locale and options
    // const formatter = new Intl.DateTimeFormat(locale, mergedOptions);

    // // Format the date and return it
    // return formatter.format(date);
}

export function formatISODateOnlyToLocale(isoDateString, options = {}) {
    const date = new Date(isoDateString);
    const locale = new Intl.DateTimeFormat().resolvedOptions().locale

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        throw new Error('Invalid ISO date string');
    }

    // Define default options for UTC
    const defaultOptions = {
        timeZone: 'UTC',
    };

    // Merge default options with user-provided options
    const mergedOptions = { ...defaultOptions, ...options };

    // Create a formatter with the specified locale and options
    const formatter = new Intl.DateTimeFormat(locale, mergedOptions);

    // Format the date and return it
    return formatter.format(date);
}