import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from '../../../axiosSetup';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { RootState } from '../../store';

export const getColumnViews = createAsyncThunk(
  'dataTable/getColumnViews',
  async (payload: any) => {
    if (payload.gridName && payload.country && payload.linerId) {
      try {
        const response = await Axios.get(
          /*   `/api/user-management/v1/usergrid/getOpcGrids?linerId=${
            payload.linerId
              ? payload.linerId
              : window.localStorage.getItem('liner_id')
          }&gridName=${payload.gridName}&country=${payload.country}` */
          `api/user-management/v1/usergrid/getOpcGrids?linerId=${payload.linerId}&gridName=${payload.gridName}&country=${payload.country}`
        );

        var updatedData = response.data[0].configGroup.map((element: any) => {
          const data = JSON.parse(element.columnConfigGrid);
          const today = moment();

          data.forEach((item: any) => {
            item.filterValues?.forEach((filterValue: any) => {
              const label = filterValue.label;
              if (label) {
                try {
                  const labelData = JSON.parse(label);
                  if (labelData.from && labelData.to) {
                    const fromDate = moment(labelData.from);
                    const toDate = moment(labelData.to);

                    const dateDiff = toDate.diff(fromDate, 'days');

                    const newToDate = today;
                    const newFromDate = today
                      .clone()
                      .subtract(dateDiff, 'days');

                    filterValue.label = JSON.stringify({
                      from: newFromDate.toISOString(),
                      to: newToDate.toISOString(),
                    });
                  }
                } catch (error) {
                  console.error('Error parsing label:', error);
                }
              }
            });
          });

          element.columnConfigGrid = JSON.stringify(data);
          return element;
        });
        response.data[0].configGroup = updatedData;

        return {
          gridName: payload.gridName,
          status: response.status,
          data: response.data,
        };
      } catch (e) {
        const error = e as AxiosError;

        return {
          status: error.status,
          data: error.response?.data,
        };
      }
    }
  }
);

export const saveColumnViewSettings = createAsyncThunk(
  'dataTable/saveColumnViewSettings',
  async (requestPayload: any) => {
    try {
      const response = await Axios.post(
        '/api/user-management/v1/usergrid/saveUserGrids',
        requestPayload.payload
      );

      requestPayload.dispatch(
        getColumnViews({
          gridName: requestPayload.payload.gridName,
          country: requestPayload.payload.country || '',
          linerId: requestPayload.payload.linerId,
        })
      );

      toast.success(response.data.message, {
        style: {
          color: 'white',
        },
      });

      return {
        status: response.status,
        data: response.data,
      };
    } catch (e) {
      const error = e as AxiosError;

      toast.error('Unable to create new configuration.',{toastId:'saveColumnViewSettingsToastFailed'});

      return {
        status: error.status,
        data: error.response?.data,
      };
    }
  }
);

export const updateColumnViewSettings = createAsyncThunk(
  'dataTable/updateColumnViewSettings',
  async (requestPayload: any) => {
    try {
      const response = await Axios.patch(
        `/api/user-management/v1/usergrid/update/${requestPayload.userGridId}`,
        requestPayload.payload
      );

      requestPayload.dispatch(
        getColumnViews({
          gridName: requestPayload.payload.gridName,
          country: requestPayload.payload.country || '',
          linerId: requestPayload.payload.linerId,
        })
      );

      toast.success('Updated Successfully!', {
        style: {
          color: 'white',
        },
        toastId:'updateColumnViewSettingsToastSuccess'
      });

      return {
        status: response.status,
        data: response.data,
      };
    } catch (e) {
      const error = e as AxiosError;

      toast.error('Unable to update a selected configuration.',{
        toastId:'updateColumnViewSettingsToastFailed'
      });

      return {
        status: error.status,
        data: error.response?.data,
      };
    }
  }
);

export const deleteColumnViewSettings = createAsyncThunk(
  'dataTable/deleteColumnViewSettings',
  async (requestPayload: any) => {
    try {
      const response = await Axios.delete(
        `/api/user-management/v1/usergrid/delete/${requestPayload.id}`
      );

      requestPayload.dispatch(
        getColumnViews({
          gridName: requestPayload.gridName,
          country: requestPayload.country || '',
          linerId: requestPayload.linerId,
        })
      );

      return {
        status: response.status,
        data: response.data,
      };
    } catch (e) {
      const error = e as AxiosError;

      return {
        status: error.status,
        data: error.response?.data,
      };
    }
  }
);
